import { gql } from 'graphql-tag';

export const companyUserGroupsQuery = gql`
  query CompanyUserGroupsOnPlatform($company_id: String!, $limit: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id
      company_name
      userGroupsOnPlatform(first: $limit, after: $cursor) {
        edges {
          node {
            groupId
            name

            platform {
              platform_id
              platform_name
            }

            company {
              company_id
              company_name
            }
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const companyUserGroupQuery = gql`
  query CompanyUserGroupOnPlatform($company_id: String!, $group_id: String!, $limit: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id
      company_name
      userGroupOnPlatform(groupId: $group_id) {
        groupId
        name

        platform {
          platform_id
          platform_name
        }

        company {
          company_id
          company_name
        }

        users(first: $limit, after: $cursor) {
          edges {
            node {
              user_id
              first_name
              last_name
              email
            }
          }

          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;
