import { Company } from './entities';

type CompanyToCompare = Pick<Company, 'company_name'>;

export const compareCompany = (c1: CompanyToCompare, c2: CompanyToCompare) => {
  return c1.company_name === c2.company_name || c1.company_name === null || c2.company_name === null
    ? 0
    : c1.company_name.localeCompare(c2.company_name);
};

export const mapIdentifierType = (identifierType: string): string => {
  switch (identifierType) {
    case 'VAT_NUMBER':
      return 'VAT';
    case 'USDOT_NUMBER':
      return 'DOT';
    case 'MC_NUMBER':
      return 'MC';
    case 'MISCELLANEOUS':
      return 'MISC ID';
    default:
      return identifierType;
  }
};
