import { exhaustiveCheck } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import React, { useReducer } from 'react';
import { Dropdown, Icon, Popup, Message, Button } from 'semantic-ui-react';

import { TrafficState, TrafficInputAction, TrafficAction } from './types';
import { TrafficApiName } from '../../../lib/graphql';
import { DateInput } from '../../date_input';

import styles from '../css/bigquery.module.css';

interface Props {
  trafficState: TrafficState;
  trafficDispatcher: React.Dispatch<TrafficAction>;
  loading: boolean;
}
function inputReducer(state: TrafficState, action: TrafficInputAction): TrafficState {
  switch (action.type) {
    case 'setApiOperation':
      return { ...state, apiOperation: action.value };
    case 'setFromDate':
      return { ...state, fromDate: action.value };
    case 'setToDate':
      return { ...state, toDate: action.value };
  }
  exhaustiveCheck(action['type']);
}

export const TourTrafficQueryInput: React.FunctionComponent<Props> = ({
  trafficState,
  trafficDispatcher,
  loading,
}: Props) => {
  const [inputState, inputDispatcher] = useReducer(inputReducer, trafficState);
  const timeZoneText = DateTime.now().toFormat("ZZZZZ (ZZ 'UTC')");

  const formatDate = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd');
  };

  const options: { text: string; value: TrafficApiName }[] = [
    { text: 'PlatformAPI.CREATE_OR_UPDATE_TRANSPORT', value: TrafficApiName.CREATE_OR_UPDATE_TRANSPORT },
    { text: 'PlatformAPI.FORWARD_TRANSPORT', value: TrafficApiName.FORWARD_TRANSPORT },
    { text: 'PlatformAPI.STATUS_UPDATE_TRANSPORT', value: TrafficApiName.STATUS_UPDATE_TRANSPORT },
    {
      text: 'PlatformAPI.CREATE_OR_UPDATE_VEHICLE_ALLOCATION',
      value: TrafficApiName.CREATE_OR_UPDATE_VEHICLE_ALLOCATION,
    },
  ];

  return (
    <div className="ui form">
      <div className="field">
        <label>API operation</label>
        <Dropdown
          className="tiny"
          selection
          options={options.map((option) => ({ text: option.text, value: option.value }))}
          value={inputState.apiOperation}
          onChange={(_e, { value: apiOperation }) => {
            inputDispatcher({ type: 'setApiOperation', value: apiOperation as TrafficApiName });
          }}
        />
      </div>
      <div className={classnames('two fields', styles.bigquery__input__fields)}>
        <div className={classnames('field', styles.bigquery__input__field__from)}>
          <label>
            <span>
              From <Popup content={`From start of day (${timeZoneText})`} trigger={<Icon name="question circle" />} />
            </span>
          </label>
          <DateInput
            placeholder={'From'}
            value={formatDate(inputState.fromDate)}
            onChange={({ target: { value } }) => {
              if (value) {
                inputDispatcher({ type: 'setFromDate', value: DateTime.fromISO(value).startOf('day') });
              }
            }}
            min={formatDate(DateTime.now().minus({ months: 1 }))}
            max={formatDate(DateTime.now())}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
        <div className={classnames('field', styles.bigquery__input__field__to)}>
          <label>
            <span>
              To <Popup content={`To end of day (${timeZoneText})`} trigger={<Icon name="question circle" />} />
            </span>
          </label>
          <DateInput
            placeholder={'To'}
            value={formatDate(inputState.toDate)}
            onChange={({ target: { value } }) => {
              if (value) {
                inputDispatcher({ type: 'setToDate', value: DateTime.fromISO(value).endOf('day') });
              }
            }}
            min={formatDate(inputState.fromDate)}
            max={formatDate(DateTime.now())}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
      </div>
      {inputState.fromDate > inputState.toDate && (
        <div className="field">
          <Message warning visible size="tiny">
            <Message.Header>&apos;From&apos; date must be before &apos;To&apos; date!</Message.Header>
          </Message>
        </div>
      )}
      <Button
        onClick={() => {
          trafficDispatcher({
            type: 'setInput',
            value: { ...inputState, loading: true, shouldSkipQuery: false },
          });
        }}
        disabled={loading || inputState.fromDate > inputState.toDate}>
        Fetch API traffic
      </Button>
    </div>
  );
};
