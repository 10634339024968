import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import {
  AddTourTagMutation as AddTourTagMutationResult,
  RemoveTourTagMutation as RemoveTourTagMutationResult,
  AddTourTagMutationVariables,
  RemoveTourTagMutationVariables,
} from '../../lib/graphql';
import { tourQuery } from '../graphql';

const addTourTagMutation = gql`
  mutation AddTourTag($tour_id: String!, $tag_name: String!) {
    addTourTag(tour_id: $tour_id, tag_name: $tag_name)
  }
`;

const removeTourTagMutation = gql`
  mutation RemoveTourTag($tour_id: String!, $tag_name: String!) {
    removeTourTag(tour_id: $tour_id, tag_name: $tag_name)
  }
`;

interface MutationsProps {
  children?: Children<{
    addTourTag: MutationFn<AddTourTagMutationResult, AddTourTagMutationVariables>;
    removeTourTag: MutationFn<RemoveTourTagMutationResult, RemoveTourTagMutationVariables>;
  }>;
}

class AddTourTagMutation extends Mutation<AddTourTagMutationResult, AddTourTagMutationVariables> {}
class RemoveTourTagMutation extends Mutation<RemoveTourTagMutationResult, RemoveTourTagMutationVariables> {}

export const Mutations: React.SFC<MutationsProps> = (props) => {
  const notify = useNotifications();

  return (
    <AddTourTagMutation mutation={addTourTagMutation} ignoreResults>
      {(addTourTag) => (
        <RemoveTourTagMutation mutation={removeTourTagMutation} ignoreResults>
          {(removeTourTag) => {
            return renderChildren(props.children, {
              addTourTag: async ({ variables }: { variables: AddTourTagMutationVariables }) => {
                const response = await addTourTag({
                  variables,
                  refetchQueries: [
                    {
                      query: tourQuery,
                      variables: {
                        tour_id: parseInt(variables.tour_id, 10),
                      },
                    },
                  ],
                });
                notify.success({ title: 'Tour tag added' });
                return response;
              },
              removeTourTag: ({ variables }: { variables: RemoveTourTagMutationVariables }) => {
                const response = removeTourTag({
                  variables,
                  refetchQueries: [
                    {
                      query: tourQuery,
                      variables: {
                        tour_id: parseInt(variables.tour_id, 10),
                      },
                    },
                  ],
                });
                notify.success({ title: 'Tour tag removed' });
                return response;
              },
            });
          }}
        </RemoveTourTagMutation>
      )}
    </AddTourTagMutation>
  );
};
