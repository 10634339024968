import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { TourContainer } from './tour_container';
import { TourListContainer } from './tour_list_container';
import { Routes } from '../../routes';

export const ToursContainer = () => (
  <Switch>
    <Route path={Routes.Tour.routerPath} component={TourContainer} />
    <Route path={Routes.Tours.routerPath} component={TourListContainer} />
  </Switch>
);
