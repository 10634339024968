import { Alert } from '@sixfold/common-ui';
import { Form as FormFactory } from '@sixfold/form-component';
import { BackofficeScope } from '@sixfold/session-interface';
import { History } from 'history';
import React from 'react';
import { MutationFn } from 'react-apollo';

import { Button } from '../../components/button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  CompanyDataSource,
  CompanyDetailsQuery,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '../../lib/graphql/result_types';

interface CompanyFormProps {
  data: {
    company: CompanyDetailsQuery['company'];
  };
  routeProps: {
    goBack: History['goBack'];
  };
  createCompany?: MutationFn<CreateCompanyMutation, CreateCompanyMutationVariables>;
  updateCompany?: MutationFn<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
}

class Form extends FormFactory<{
  company_name: string;
  is_shipper: boolean;
  is_carrier: boolean;
  is_pilot: boolean;
  is_paying_customer: boolean;
  is_pending_signup: boolean;
  is_test: boolean;
}> {}

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = ({
  routeProps,
  data,
  createCompany,
  updateCompany,
}) => {
  const company = data.company;
  const isNewCompany = company === null;
  const hasCompanyEditPermission = useHasScopes([BackofficeScope.companyWriteCompany]);
  const isPrivilegedUser = useIsAdmin() || hasCompanyEditPermission;

  return (
    <Form
      validateFields={{
        company_name: {
          exist: 'Enter a company name',
        },
      }}
      initialValues={{
        company_name: company?.company_name ?? '',
        is_carrier: company?.is_carrier ?? false,
        is_shipper: company?.is_shipper ?? false,
        is_pilot: company?.is_pilot ?? false,
        is_paying_customer: company?.is_paying_customer ?? false,
        is_test: company?.is_test ?? false,
        is_pending_signup: company?.isPendingSignup ?? false,
      }}
      onSubmit={async (form) => {
        if (createCompany !== undefined) {
          await createCompany({
            variables: {
              input: {
                company_name: form.company_name,
                is_carrier: form.is_carrier,
                is_shipper: form.is_shipper,
                is_pilot: form.is_pilot,
                is_paying_customer: form.is_paying_customer,
                is_test: form.is_test,
                is_pending_signup: form.is_pending_signup,
              },
            },
          });
        } else if (company !== null && updateCompany !== undefined) {
          await updateCompany({
            variables: {
              company_id: company.company_id,
              input: {
                company_name: form.company_name,
                is_carrier: form.is_carrier,
                is_shipper: form.is_shipper,
                is_pilot: form.is_pilot,
                is_paying_customer: form.is_paying_customer,
                is_test: form.is_test,
              },
            },
          });
        }
        routeProps.goBack();
      }}>
      {({ form, errors, onChange, onSubmit, isSubmittingForm }) => {
        return (
          <form className="ui form" onSubmit={onSubmit}>
            {Object.values(company?.fieldDataSources || {}).some((source) => source === CompanyDataSource.EXTERNAL) && (
              <div className="field">
                <Alert>
                  Some fields are disabled since their values are sourced from an external system. The disabled values
                  can be changed on the external system.
                </Alert>
              </div>
            )}
            <div className="field">
              <label>{errors.company_name ?? 'Company Name'}</label>
              <input
                type="text"
                name="company_name"
                placeholder="Company name"
                value={form.company_name}
                onChange={onChange}
                disabled={!isPrivilegedUser || company?.fieldDataSources?.companyName === CompanyDataSource.EXTERNAL}
              />
            </div>
            <div className="fields">
              <div className="inline field">
                <input
                  name="is_carrier"
                  id="is_carrier"
                  type="checkbox"
                  checked={form.is_carrier}
                  disabled={!isPrivilegedUser || company?.fieldDataSources?.isCarrier === CompanyDataSource.EXTERNAL}
                  onChange={onChange}
                />
                <label htmlFor="is_carrier">Carrier</label>
              </div>
              <div className="inline field">
                <input
                  name="is_shipper"
                  id="is_shipper"
                  type="checkbox"
                  checked={form.is_shipper}
                  disabled={!isPrivilegedUser || company?.fieldDataSources?.isShipper === CompanyDataSource.EXTERNAL}
                  onChange={onChange}
                />
                <label htmlFor="is_shipper">Shipper</label>
              </div>
              <div className="inline field">
                <input
                  name="is_pilot"
                  id="is_pilot"
                  type="checkbox"
                  checked={form.is_pilot}
                  disabled={!isPrivilegedUser}
                  onChange={onChange}
                />
                <label htmlFor="is_pilot">Pilot</label>
              </div>
              <div className="inline field">
                <input
                  name="is_paying_customer"
                  id="is_paying_customer"
                  type="checkbox"
                  checked={form.is_paying_customer}
                  disabled={!isPrivilegedUser}
                  onChange={onChange}
                />
                <label htmlFor="is_paying_customer">Paying customer</label>
              </div>
              <div className="inline field">
                <input
                  name="is_test"
                  id="is_test"
                  type="checkbox"
                  checked={form.is_test}
                  disabled={!isPrivilegedUser || company?.fieldDataSources?.isTest === CompanyDataSource.EXTERNAL}
                  onChange={onChange}
                />
                <label htmlFor="is_test">Test</label>
              </div>
              {createCompany !== undefined && (
                <div className="inline field">
                  <input
                    name="is_pending_signup"
                    id="is_pending_signup"
                    type="checkbox"
                    checked={form.is_pending_signup}
                    disabled={!isPrivilegedUser}
                    onChange={onChange}
                  />
                  <label htmlFor="is_pending_signup">Pending Signup</label>
                </div>
              )}
            </div>

            <Button type="submit" primary loading={isSubmittingForm} disabled={!isPrivilegedUser || isSubmittingForm}>
              {isNewCompany ? 'Create' : 'Update'}
            </Button>
            <Button type="button" onClick={() => routeProps.goBack()}>
              Cancel
            </Button>
          </form>
        );
      }}
    </Form>
  );
};
