import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { Button } from '../button';
import { Tags } from '../tags';

interface Tag {
  name: string;
}

interface Props {
  tags: Tag[];
  availableTags: Tag[];
  addTag: (tagName: string) => void;
  removeTag: (tagName: string) => void;
}

export class TagsInput extends React.Component<Props> {
  state = {
    isEditing: false,
    error: null,
  };

  setIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      this.setState({ error: null });
    }
    this.setState({ isEditing });
  };

  handleChange = (newTagNames: string[]) => {
    const { tags, addTag, removeTag } = this.props;

    this.setState({ error: null });

    const removedTags = tags.filter((existingTag) => {
      return newTagNames.indexOf(existingTag.name) === -1;
    });
    removedTags.forEach((removedTag) => {
      return removeTag(removedTag.name);
    });

    const addedTagNames = newTagNames.filter((newTagName) => {
      return tags.find((existingTag) => existingTag.name === newTagName) === undefined;
    });
    addedTagNames.forEach((addedTagName) => {
      const formattedTagName = addedTagName.trim();

      if (formattedTagName.toLowerCase() !== formattedTagName) {
        // only allow lowercase
        this.setState({
          error: 'Tag names may only be lowercase',
        });
        return false;
      }

      if (formattedTagName.length === 0) {
        return false;
      }

      return addTag(formattedTagName);
    });
    return true;
  };

  render() {
    const { tags, availableTags } = this.props;
    const { error } = this.state;

    const options = availableTags.map((tag) => ({ key: tag.name, value: tag.name, text: tag.name }));
    const value = tags.map((tag) => tag.name);

    if (!this.state.isEditing) {
      return (
        <span onClick={() => this.setIsEditing(true)}>
          {tags.length > 0 ? (
            <span
              style={{ display: 'inline-block', cursor: 'pointer', marginRight: 3 }}
              title="Click to add or change tags">
              <Tags tags={tags} />
            </span>
          ) : (
            <Button basic size="mini">
              Add tags
            </Button>
          )}
        </span>
      );
    }

    return (
      <div style={{ minWidth: 360 }}>
        {error !== null && <div style={{ color: 'red', marginBottom: 10 }}>{error}</div>}
        <Dropdown
          placeholder="No tags. Click to add some..."
          multiple
          search
          allowAdditions
          additionLabel="Create new tag: "
          selection
          fluid
          defaultOpen
          options={options}
          value={value}
          onClose={() => this.setIsEditing(false)}
          onChange={(_e, { value }) => this.handleChange(value as string[])}
        />
      </div>
    );
  }
}
