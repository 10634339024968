import React from 'react';
import { Button as SemanticButton, ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

interface State {
  isLoading: boolean;
}

export class Button extends React.Component<SemanticButtonProps, State> {
  private isMounted: boolean;

  constructor(props: SemanticButtonProps) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (this.props.onClick !== undefined) {
      this.setState({ isLoading: true });
      try {
        await Promise.resolve(this.props.onClick(event, this.props));
      } finally {
        if (this.isMounted) {
          this.setState({ isLoading: false });
        }
      }
    }
  };

  render() {
    const { disabled, loading, children, ...rest } = this.props;
    const isLoading = loading ?? this.state.isLoading;
    const isDisabled = disabled ?? this.state.isLoading;

    return (
      <SemanticButton {...rest} onClick={this.handleClick} loading={isLoading} disabled={isDisabled}>
        {children}
      </SemanticButton>
    );
  }
}
