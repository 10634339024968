import { Loader } from '@sixfold/loader-container';
import { OptionalString, parseQueryString, updateQueryString } from '@sixfold/query-string';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Label } from 'semantic-ui-react';

import { VehicleMutation } from './vehicle_mutation_container';
import { CompaniesDropdownContainer, CompanySearchResult } from '../../company/containers/companies_dropdown_container';
import { Delay } from '../../components/delay';
import { InfiniteScroll } from '../../components/infinitescroll';
import {
  getNodes,
  loadMoreFromConnection,
  PAGINATION_PAGE_SIZE,
  VehicleListQuery,
  VehicleListQueryVariables,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import { VehiclesList, VehiclesListDataProps } from '../components/vehicles_list';
import { VehiclesMap } from '../components/vehicles_map';
import { vehiclesQuery } from '../graphql';

class LoadingContainer extends Loader<VehicleListQuery, VehiclesListDataProps, VehicleListQueryVariables> {}

class VehicleListContainerQuery extends Query<VehicleListQuery, VehicleListQueryVariables> {}

export const VehicleListContainer: React.FunctionComponent<RouteComponentProps<object>> = ({ history, location }) => {
  const [selectedCompany, setSelectedCompany] = React.useState<CompanySearchResult | null>(null);
  const [integrationId, setIntegrationId] = useState<string | null>(null);
  const [licensePlate, setLicensePlate] = useState<string | null>(null);
  const debouncedIntegrationId = useDebounce(integrationId);
  const debouncedLicensePlate = useDebounce(licensePlate);

  const handleFilterToggle = React.useCallback(
    (filterType: string, filterValue?: string) => {
      return history.push(
        `${location.pathname}?${updateQueryString(location.search, {
          param: filterType,
          value: filterValue,
        })}`,
      );
    },
    [history, location.pathname, location.search],
  );

  useEffect(() => {
    const value = debouncedIntegrationId !== null ? debouncedIntegrationId : undefined;
    handleFilterToggle('integration_id', value);
  }, [debouncedIntegrationId, handleFilterToggle]);

  useEffect(() => {
    const value = debouncedLicensePlate !== null ? debouncedLicensePlate : undefined;
    handleFilterToggle('license_plate', value);
  }, [debouncedLicensePlate, handleFilterToggle]);

  const { company_id, license_plate, integration_id } = parseQueryString({
    queryParametersToParse: {
      company_id: OptionalString,
      license_plate: OptionalString,
      integration_id: OptionalString,
    },
    queryString: location.search,
  });

  const isValidLicensePlateSearch =
    license_plate !== undefined &&
    (license_plate.trim().length >= 2 || integration_id !== undefined || company_id !== undefined);

  return (
    <VehicleMutation>
      {({ deleteVehicles }) => (
        <VehicleListContainerQuery
          query={vehiclesQuery}
          variables={{
            limit: PAGINATION_PAGE_SIZE,
            companyId: company_id,
            licensePlateSearch: isValidLicensePlateSearch ? license_plate : undefined,
            integrationIds: integration_id !== undefined ? [integration_id] : undefined,
          }}>
          {(result) => {
            return (
              <div className="ui grid vehicles">
                <div className="ten wide mobile ten wide tablet ten wide computer ten wide large screen eleven wide widescreen column vehicles__list">
                  <h2 className="table__header">
                    <span>Vehicles</span>
                    <CompaniesDropdownContainer
                      inputProps={{ placeholder: 'All Companies' }}
                      onSelectCompany={(selectedCompany) => {
                        if (selectedCompany === null) {
                          setSelectedCompany(null);
                          return handleFilterToggle('company_id', undefined);
                        }

                        setSelectedCompany(selectedCompany);
                        handleFilterToggle('company_id', selectedCompany.company_id);
                      }}
                      selectedCompany={
                        selectedCompany !== null ? selectedCompany : company_id !== undefined ? { company_id } : null
                      }
                    />

                    <Input
                      placeholder="Integration id..."
                      value={integrationId === null ? '' : integrationId}
                      onChange={(_e, { value }) => {
                        setIntegrationId(value === '' ? null : value);
                      }}
                    />

                    <Input
                      placeholder="License plate..."
                      value={licensePlate === null ? '' : licensePlate}
                      onChange={(_e, { value }) => {
                        setLicensePlate(value === '' ? null : value);
                      }}
                    />
                    {license_plate !== undefined && !isValidLicensePlateSearch && (
                      <Delay showAfterMs={1_000}>
                        <Label pointing="left">Min. 2 characters</Label>
                      </Delay>
                    )}
                  </h2>
                  <LoadingContainer
                    result={result}
                    mapData={({ data }) => ({ data: { vehicles: getNodes(data.vehicles) } })}>
                    {({ data }) => (
                      <InfiniteScroll
                        className="vehicles__list__table"
                        triggerOnWindow={false}
                        loadMoreEntries={() => loadMoreFromConnection(vehiclesQuery, result, ['vehicles'])}>
                        <VehiclesList
                          data={data}
                          search={license_plate}
                          company={
                            selectedCompany !== null
                              ? selectedCompany
                              : company_id !== undefined
                                ? { company_id }
                                : undefined
                          }
                          onDeleteVehicles={async (vehicleIdsToDelete) => {
                            if (company_id === undefined) {
                              return;
                            }

                            await deleteVehicles(vehicleIdsToDelete, company_id);
                          }}
                        />
                      </InfiniteScroll>
                    )}
                  </LoadingContainer>
                </div>
                <div className="six wide mobile six wide tablet six wide computer six wide large screen five wide widescreen column">
                  <LoadingContainer
                    result={result}
                    mapData={({ data }) => ({ data: { vehicles: getNodes(data.vehicles) } })}>
                    {({ data }) => <VehiclesMap data={{ vehicles: data.vehicles }} />}
                  </LoadingContainer>
                </div>
              </div>
            );
          }}
        </VehicleListContainerQuery>
      )}
    </VehicleMutation>
  );
};
