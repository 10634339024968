import { default as DeckGL } from 'deck.gl';
import React from 'react';

import { useHighlightedStopIdContext } from './stop_highlight_context';
import {
  createRouteLayer,
  createStopLayer,
  createEventLayer,
  createVehicleBreaksLayer,
  createRulerLayer,
  createExternalEventLayer,
  createTourAreaLayer,
} from './tour_layer';
import { notEmpty } from '../../lib/graphql';
import { Viewport } from '../../map/components/map_viewport';
import {
  createVehiclesLayer,
  createGhostVehicleLayer,
  createVehiclesHistoryLayer,
} from '../../vehicle/components/vehicles_layer';
import { Vehicle, VehicleHistory, VehicleBreakHistory, VehicleStatus } from '../../vehicle/entities';
import { Route, Geofence, Event, Ruler, ExternalEvent, MappableStop, MapboxStyle } from '../entities';

interface Props {
  rulers: Ruler[];
  stops: MappableStop[];
  route: Route;
  tourArea: Geofence | null;
  events: Event[];
  externalEvents: ExternalEvent[];
  ghostVehicles: VehicleHistory[];
  vehicles: (Vehicle & {
    history: VehicleHistory[];
    breakHistory: VehicleBreakHistory[];
    status: VehicleStatus | null;
  })[];
  viewport: Viewport;
  onLayerClick(coordinates: { lat: number | null; lng: number | null }): void;
  layersToHide?: {
    breaks: Record<string, boolean>;
    history: Record<string, boolean>;
    geofences: boolean;
    events: boolean;
    externalEvents: boolean;
    historyPoints: boolean;
    tourArea: boolean;
  };

  shouldDisplayCrosshairCursor: boolean;
  mapStyle: MapboxStyle;
}

export const TourOverlay: React.StatelessComponent<Props> = ({
  stops,
  route,
  tourArea,
  rulers,
  shouldDisplayCrosshairCursor,
  events,
  externalEvents,
  vehicles,
  ghostVehicles,
  viewport,
  onLayerClick,
  layersToHide = {
    breaks: {},
    history: {},
    events: false,
    geofences: false,
    historyPoints: false,
    externalEvents: false,
    tourArea: false,
  },
  mapStyle,
}) => {
  const { highlightedStopId } = useHighlightedStopIdContext();

  const { highlightedStopLayer, stopLabelsLayer, stopZonesLayers, stopIconsLayer } = createStopLayer({
    stops,
    onStopIconClick: onLayerClick,
    mapStyle,
    highlightedStopId,
  });
  const tourAreaLyer = createTourAreaLayer({ tourArea, mapStyle });
  const routeLayer = createRouteLayer({ route, mapStyle });
  const vehiclesLayer = createVehiclesLayer({
    vehicles,
    onClick: (vehicle) => onLayerClick({ lat: vehicle.status.latitude, lng: vehicle.status.longitude }),
  });

  const ghostVehicleLayer = ghostVehicles.map((vehicle, idx) => createGhostVehicleLayer(vehicle, idx));

  const { pointLayer: historyLayer, pathLayer: historyPathLayer } = createVehiclesHistoryLayer({
    vehicles: vehicles.filter((vehicle) => !layersToHide.history[vehicle.vehicle_id]),
    onClick: onLayerClick,
  });

  const { eventLabelsLayer, eventIconsLayer } = createEventLayer({ events, onClick: onLayerClick, mapStyle });
  const { externalEventLabelsLayer, externalEventIconsLayer } = createExternalEventLayer({
    externalEvents,
    onClick: onLayerClick,
  });

  const { breakIconsLayer, breakLabelsLayer } = createVehicleBreaksLayer({
    vehicleBreaks: vehicles.reduce<VehicleBreakHistory[]>((combinedBreakHistory, vehicle) => {
      if (layersToHide.breaks[vehicle.vehicle_id]) {
        return [...combinedBreakHistory];
      }
      return [...combinedBreakHistory, ...vehicle.breakHistory];
    }, []),
    onBreakClick: onLayerClick,
    mapStyle,
  });

  const { rulersLayer, rulersLabelLayer, rulersTipsLayer } = createRulerLayer({
    rulers,
  });

  return (
    <DeckGL
      {...viewport}
      getCursor={({ isDragging }) => {
        if (shouldDisplayCrosshairCursor) {
          return 'crosshair';
        }
        return isDragging ? 'grabbing' : 'grab';
      }}
      layers={[
        highlightedStopLayer,
        stopLabelsLayer,
        ...(!layersToHide.geofences ? stopZonesLayers : []),
        !layersToHide.historyPoints ? historyLayer : null,
        routeLayer,
        !layersToHide.tourArea ? tourAreaLyer : null,
        historyPathLayer,
        !layersToHide.events ? eventIconsLayer : null,
        !layersToHide.externalEvents ? externalEventIconsLayer : null,
        breakIconsLayer,
        stopIconsLayer,
        ghostVehicleLayer,
        vehiclesLayer,
        !layersToHide.events ? eventLabelsLayer : null,
        !layersToHide.externalEvents ? externalEventLabelsLayer : null,
        breakLabelsLayer,
        rulersLayer,
        rulersLabelLayer,
        rulersTipsLayer,
      ].filter(notEmpty)}
    />
  );
};
