import {
  Button,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Paragraph,
  Radio,
  RadioGroup,
  Stack,
  useNotifications,
} from '@sixfold/common-ui';
import { OptionalString, parseQueryString } from '@sixfold/query-string';
import { isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Mutation, MutationFn, Query } from 'react-apollo';

import { createVehiclesMutation } from '../../company/graphql';
import {
  CreateVehiclesMutationVariables,
  PAGINATION_PAGE_SIZE,
  VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery,
  VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQueryVariables,
} from '../../lib/graphql';
import { managedVehiclesQuery } from '../../vehicle/graphql';
import { vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery } from '../graphql';

class VehicleTrackerQuery extends Query<
  VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery,
  VehicleTrackerByTelematicsIntegrationAndVehicleDetailsQueryVariables
> {}

type Props = {
  existingVehicle?: {
    license_plate: string;
    remote_id: string;
    tracker_id: string;
  };
  telematicsIntegrationId: string;
  trigger: React.ReactElement | string;
};

class CreateVehiclesMutation extends Mutation<CreateVehiclesMutation, CreateVehiclesMutationVariables> {}

export const ManageVehicleModal = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [licensePlate, setLicensePlate] = React.useState(props.existingVehicle?.license_plate);
  const [remoteId, setRemoteId] = React.useState(props.existingVehicle?.remote_id);
  const [isEditingRemoteId, setIsEditingRemoteId] = React.useState(false);
  const [licensePlateQueryResult, setLicensePlateQueryResult] = React.useState<{ vehicleTrackerId: string } | null>(
    null,
  );
  const [remoteIdQueryResult, setRemoteIdQueryResult] = React.useState<{ vehicleTrackerId: string } | null>(null);
  const [isProblemWithLicensePlate, setProblemWithLicensePlate] = React.useState(false);
  const [isProblemWithRemoteId, setProblemWithRemoteId] = React.useState(false);

  const isInvalidRemoteId = (remoteId: string | undefined) => {
    return remoteId !== undefined && remoteId.trim() !== remoteId;
  };

  const isInvalidLicensePlate = (licensePlate: string | undefined) => {
    return licensePlate !== undefined && licensePlate.trim() !== licensePlate;
  };

  const isDuplicateTracker = React.useCallback(
    (tracker: { vehicleTrackerId: string } | null) => {
      return tracker !== null && tracker.vehicleTrackerId !== props.existingVehicle?.tracker_id;
    },
    [props.existingVehicle],
  );

  React.useEffect(() => {
    const tracker = licensePlateQueryResult;
    const invalidPlate = isInvalidLicensePlate(licensePlate);
    const duplicatePlate = isDuplicateTracker(tracker);

    setProblemWithLicensePlate(invalidPlate || duplicatePlate);
  }, [licensePlateQueryResult, licensePlate, props.existingVehicle, isDuplicateTracker]);

  React.useEffect(() => {
    const tracker = remoteIdQueryResult;
    const invalidRemoteId = isInvalidRemoteId(remoteId);
    const duplicateRemoteId = isDuplicateTracker(tracker);

    setProblemWithRemoteId(invalidRemoteId || duplicateRemoteId);
  }, [remoteIdQueryResult, remoteId, props.existingVehicle, isDuplicateTracker]);

  const isEditing = !isNil(props.existingVehicle);
  const notify = useNotifications();

  const onToggle = () => {
    setLicensePlate(props.existingVehicle?.license_plate);
    setRemoteId(props.existingVehicle?.remote_id);
    setLicensePlateQueryResult(null);
    setRemoteIdQueryResult(null);
    setIsEditingRemoteId(false);
    setIsOpen(!isOpen);
  };

  const queryString = parseQueryString({
    queryParametersToParse: {
      license_plate: OptionalString,
    },
    queryString: location.search,
  });

  const getLicensePlateErrorMsg = (isInvalidLicensePlateError: boolean, isDuplicateLicensePlateError: boolean) => {
    if (isInvalidLicensePlateError) {
      return 'The license plate you are trying to insert is invalid! Check leading or trailing spaces.';
    } else if (isDuplicateLicensePlateError) {
      return 'The license plate you are trying to insert is already used by another vehicle in this integration!';
    }
    return 'The license plate you are trying to insert is invalid!';
  };

  const getRemoteIdErrorMsg = (isInvalidRemoteIdError: boolean, isDuplicateRemoteIdError: boolean) => {
    if (isInvalidRemoteIdError) {
      return 'The remote id you are trying to insert is invalid! Check leading or trailing spaces.';
    } else if (isDuplicateRemoteIdError) {
      return 'The remote id you are trying to insert is already used by another vehicle in this integration!';
    }
    return 'The remote id you are trying to insert is invalid!';
  };

  const handleConfirm = async (mutationFn: MutationFn<CreateVehiclesMutation, CreateVehiclesMutationVariables>) => {
    if (!licensePlate || !remoteId) {
      return;
    }

    await mutationFn({
      variables: {
        input: {
          vehicles: [
            {
              licensePlate,
              remoteId,
            },
          ],
          telematicsIntegrationId: props.telematicsIntegrationId,
        },
      },
      refetchQueries: [
        {
          query: managedVehiclesQuery,
          variables: {
            integrationIds: [props.telematicsIntegrationId],
            limit: PAGINATION_PAGE_SIZE,
            licensePlateSearch: queryString.license_plate || undefined,
          },
        },
      ],
    });
    notify.success({ title: isEditing ? 'Vehicle updated' : 'Vehicle added' });
    onToggle();
  };

  return (
    <CreateVehiclesMutation mutation={createVehiclesMutation}>
      {(createVehicles) => (
        <Modal trigger={props.trigger} open={isOpen} onToggle={onToggle}>
          <ModalHeader title={isEditing ? 'Edit vehicle' : 'Add vehicle'} />
          <ModalMain>
            {isEditing ? (
              <Stack>
                <Paragraph>You are allowed to edit one of the values at a time.</Paragraph>
                <RadioGroup
                  name="editing_choice"
                  label="Editing choice"
                  labelPosition="hidden"
                  onChange={(value) => {
                    setIsEditingRemoteId(value.value === 'remote_id');
                    if (value.value === 'remote_id') {
                      setLicensePlate(props.existingVehicle?.license_plate);
                    } else {
                      setRemoteId(props.existingVehicle?.remote_id);
                    }
                  }}
                  value={isEditingRemoteId ? 'remote_id' : 'licenseplate'}>
                  <VehicleTrackerQuery
                    query={vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery}
                    fetchPolicy={'no-cache'}
                    onCompleted={(data) =>
                      setLicensePlateQueryResult(data.vehicleTrackerByTelematicsIntegrationAndVehicleDetails)
                    }
                    variables={{ telematicsIntegrationId: props.telematicsIntegrationId, licensePlate }}>
                    {(result) => {
                      const tracker = result.data?.vehicleTrackerByTelematicsIntegrationAndVehicleDetails ?? null;

                      const invalidPlate = isInvalidLicensePlate(licensePlate);
                      const duplicatePlate = isDuplicateTracker(tracker);
                      return (
                        <Stack direction="row" wrap={false}>
                          <Radio value="licenseplate" label="" />
                          <Input
                            className={'maxWidth'}
                            onChange={(event) => setLicensePlate(event.value)}
                            errorMessage={
                              invalidPlate || duplicatePlate
                                ? getLicensePlateErrorMsg(invalidPlate, duplicatePlate)
                                : undefined
                            }
                            value={licensePlate}
                            disabled={isEditingRemoteId}
                            label="License plate"
                            name="license_plate"
                          />
                        </Stack>
                      );
                    }}
                  </VehicleTrackerQuery>

                  <VehicleTrackerQuery
                    query={vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery}
                    fetchPolicy={'no-cache'}
                    onCompleted={(data) =>
                      setRemoteIdQueryResult(data.vehicleTrackerByTelematicsIntegrationAndVehicleDetails)
                    }
                    variables={{ telematicsIntegrationId: props.telematicsIntegrationId, remoteId }}>
                    {(result) => {
                      const tracker = result.data?.vehicleTrackerByTelematicsIntegrationAndVehicleDetails ?? null;

                      const invalidRemoteId = isInvalidRemoteId(remoteId);
                      const duplicateRemoteId = isDuplicateTracker(tracker);
                      return (
                        <Stack direction="row" wrap={false}>
                          <Radio value="remote_id" label="" />
                          <Input
                            className={'maxWidth'}
                            errorMessage={
                              invalidRemoteId || duplicateRemoteId
                                ? getRemoteIdErrorMsg(invalidRemoteId, duplicateRemoteId)
                                : undefined
                            }
                            onChange={(event) => setRemoteId(event.value)}
                            value={remoteId}
                            disabled={!isEditingRemoteId}
                            label="Remote ID"
                            name="remote_id"
                          />
                        </Stack>
                      );
                    }}
                  </VehicleTrackerQuery>
                </RadioGroup>
              </Stack>
            ) : (
              <>
                <VehicleTrackerQuery
                  query={vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery}
                  fetchPolicy={'no-cache'}
                  onCompleted={(data) =>
                    setLicensePlateQueryResult(data.vehicleTrackerByTelematicsIntegrationAndVehicleDetails)
                  }
                  variables={{ telematicsIntegrationId: props.telematicsIntegrationId, licensePlate }}>
                  {(result) => {
                    const tracker = result.data?.vehicleTrackerByTelematicsIntegrationAndVehicleDetails ?? null;

                    const invalidPlate = isInvalidLicensePlate(licensePlate);
                    const duplicatePlate = isDuplicateTracker(tracker);
                    return (
                      <Input
                        onChange={(event) => setLicensePlate(event.value)}
                        value={licensePlate}
                        errorMessage={
                          invalidPlate || duplicatePlate
                            ? getLicensePlateErrorMsg(invalidPlate, duplicatePlate)
                            : undefined
                        }
                        label="License plate"
                        name="license_plate"
                      />
                    );
                  }}
                </VehicleTrackerQuery>
                <VehicleTrackerQuery
                  query={vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery}
                  fetchPolicy={'no-cache'}
                  onCompleted={(data) =>
                    setRemoteIdQueryResult(data.vehicleTrackerByTelematicsIntegrationAndVehicleDetails)
                  }
                  variables={{ telematicsIntegrationId: props.telematicsIntegrationId, remoteId }}>
                  {(result) => {
                    const tracker = result.data?.vehicleTrackerByTelematicsIntegrationAndVehicleDetails ?? null;
                    const invalidRemoteId = isInvalidRemoteId(remoteId);
                    const duplicateRemoteId = isDuplicateTracker(tracker);
                    return (
                      <Input
                        onChange={(event) => setRemoteId(event.value)}
                        value={remoteId}
                        errorMessage={
                          invalidRemoteId || duplicateRemoteId
                            ? getRemoteIdErrorMsg(invalidRemoteId, duplicateRemoteId)
                            : undefined
                        }
                        label="Remote ID"
                        name="remote_id"
                      />
                    );
                  }}
                </VehicleTrackerQuery>
              </>
            )}
          </ModalMain>
          <ModalFooter>
            <Stack justifyContent="end">
              <Button kind="subtle" onClick={onToggle}>
                Cancel
              </Button>
              <Button
                onClick={() => handleConfirm(createVehicles)}
                disabled={
                  isProblemWithLicensePlate ||
                  isProblemWithRemoteId ||
                  isNil(licensePlate) ||
                  isNil(remoteId) ||
                  licensePlate === '' ||
                  remoteId === ''
                }
                kind="primary">
                {isEditing ? 'Save' : 'Add'}
              </Button>
            </Stack>
          </ModalFooter>
        </Modal>
      )}
    </CreateVehiclesMutation>
  );
};
