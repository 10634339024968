import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  GridColumn,
  GridRow,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { capitalize } from '../../lib/format';
import {
  CompanyInvitationsQuery,
  CompanyInvite,
  ExternalCompanyInvitationRecipient,
  Maybe,
  UserProfile,
} from '../../lib/graphql';
import { Routes } from '../../routes';

export type InvitationsViewProps = Pick<CompanyInvitationsQuery, 'companyInvitations'>;

export const CompanyInvitations: React.FC<InvitationsViewProps> = (props: InvitationsViewProps) => {
  return (
    <GridRow>
      <GridColumn width="16">
        <Table>
          <CompanyInvitationsTableHeader />
          <TableBody>
            {props.companyInvitations.length === 0 ? (
              <NoInvitations />
            ) : (
              props.companyInvitations.map((invitation) => (
                <CompanyInvitationRow key={invitation.companyInviteId} {...invitation} />
              ))
            )}
          </TableBody>
        </Table>
      </GridColumn>
    </GridRow>
  );
};

type CompanyInvitationProps = Pick<
  CompanyInvite,
  'companyInviteId' | 'invitedAt' | 'companyName' | 'recipients' | 'invitedByCompany' | 'status' | 'inviteMethod'
> & {
  invitedByUser: Maybe<Partial<UserProfile>>;
};

const CompanyInvitationRow = (props: CompanyInvitationProps) => {
  const { invitedAt, companyName, invitedByUser, inviteMethod, recipients, invitedByCompany, status } = props;
  const multilineCellRowSpan = recipients.length > 0 ? recipients.length.toString() : '1';
  return (
    <>
      <TableRow>
        <TableCell rowSpan={multilineCellRowSpan}>
          <FormattedDate date={invitedAt} />
        </TableCell>
        <TableCell rowSpan={multilineCellRowSpan}>
          {invitedByCompany?.company_id ? (
            <NavLink to={Routes.Company.generatePath({ company_id: invitedByCompany.company_id })} className="item">
              {invitedByCompany.company_name}
            </NavLink>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell rowSpan={multilineCellRowSpan}>{capitalize(status)}</TableCell>
        <TableCell rowSpan={multilineCellRowSpan}>
          {invitedByUser ? `${invitedByUser.first_name ?? ''} ${invitedByUser.last_name ?? ''}` : '-'}
        </TableCell>
        {recipients.length > 0 ? (
          <CompanyInvitationRecipient {...recipients[0]} />
        ) : (
          <CompanyInvitationRecipient email="-" locale="-" />
        )}
        <TableCell rowSpan={multilineCellRowSpan}>{companyName}</TableCell>
        <TableCell rowSpan={multilineCellRowSpan}>{capitalize(inviteMethod ?? '-')}</TableCell>
      </TableRow>
      {[...recipients].slice(1).map((recipient) => (
        <TableRow key={recipient.email}>
          <CompanyInvitationRecipient {...recipient} />
        </TableRow>
      ))}
    </>
  );
};

const CompanyInvitationsTableHeader = () => {
  return (
    <TableHeader>
      <TableRow>
        <TableHeaderCell rowSpan="2">Time</TableHeaderCell>
        <TableHeaderCell rowSpan="2">Inviter company</TableHeaderCell>
        <TableHeaderCell rowSpan="2">Status</TableHeaderCell>
        <TableHeaderCell rowSpan="2">Inviter person</TableHeaderCell>
        <TableHeaderCell colSpan="2">Recipient(s)</TableHeaderCell>
        <TableHeaderCell rowSpan="2">Alias given</TableHeaderCell>
        <TableHeaderCell rowSpan="2">Invite method</TableHeaderCell>
      </TableRow>
      <TableRow>
        <TableHeaderCell>Locale</TableHeaderCell>
        <TableHeaderCell>Email</TableHeaderCell>
      </TableRow>
    </TableHeader>
  );
};

const NoInvitations = () => {
  return (
    <TableRow>
      <TableCell textAlign="center" colSpan="16">
        No invitations
      </TableCell>
    </TableRow>
  );
};

const CompanyInvitationRecipient = (props: ExternalCompanyInvitationRecipient) => {
  return (
    <>
      <TableCell>{props.locale}</TableCell>
      <TableCell>{props.email}</TableCell>
    </>
  );
};
