import React from 'react';

export const useDebounce = (value: string | null | undefined, delay: number = 300) => {
  const [debouncedValue, setDebouncedValue] = React.useState<string | null>(value ?? null);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value ?? null);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};
