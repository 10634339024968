import { getBounds, getBoundsCenter } from '@sixfold/geo-primitives';
import React from 'react';
import MapGL from 'react-map-gl';

import { VehiclesOverlay } from './vehicles_overlay';
import { getEmbedConfig } from '../../lib/data';
import { openSatelliteViewUrl } from '../../lib/util/url';
import { MapViewport } from '../../map/components/map_viewport';
import { Vehicle, VehicleHistory, VehicleBreakHistory, VehicleStatus } from '../entities';
import { vehiclesWithLocation } from '../utils';

interface MapVehicle extends Vehicle {
  history?: VehicleHistory[];
  breakHistory?: VehicleBreakHistory[];
  ghost?: VehicleHistory;
  status: VehicleStatus | null;
}

export interface VehiclesMapProps {
  data: {
    vehicles: MapVehicle[];
  };
}

const DefaultCenter = { lat: 50.9854724, lng: 10.8749089 };

export const VehiclesMap: React.StatelessComponent<VehiclesMapProps> = (props) => {
  const {
    data: { vehicles },
  } = props;
  const locations = vehiclesWithLocation(vehicles).map(({ status: { latitude, longitude } }) => ({
    lat: latitude,
    lng: longitude,
  }));
  const { lat: latitude, lng: longitude } =
    locations.length > 0 ? getBoundsCenter(getBounds(locations)) : DefaultCenter;
  const zoom = locations.length > 1 ? 1 : 5;
  const defaultViewport = { longitude, latitude, zoom, maxZoom: 20, pitch: 0, bearing: 0, width: 0, height: 0 };
  const config = getEmbedConfig();

  if (config === null) {
    throw Error('Missing config');
  }

  return (
    <MapViewport
      style={{ backgroundColor: 'rgba(0,0,0,0.1)', minHeight: 'calc(100vh - 100px)' }}
      defaultViewport={defaultViewport}>
      {({ width, height, viewport, onViewportChange }) => (
        <MapGL
          {...{ ...viewport, width, height }}
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={onViewportChange}
          mapboxApiAccessToken={config.mapbox_token}>
          <VehiclesOverlay
            viewport={{ ...viewport, width, height }}
            vehicles={vehicles}
            onLayerClick={openSatelliteViewUrl}
          />
        </MapGL>
      )}
    </MapViewport>
  );
};
