import { BackofficeScope } from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CredentialType, UserPersonaInCompany, UserRoleInCompany } from '../../lib/graphql';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';
import { UserInCompany, UserProfile } from '../entities';

export interface UserInCompanyListDataProps {
  data: {
    usersInCompany: UserInCompany[] | null;
  };
}

class UserTable extends Table<
  UserInCompany & {
    user: { name: string; credential_types: CredentialType[] };
    persona_sort: string;
  }
> {}

type Props = UserInCompanyListDataProps & {
  company: {
    company_id: string;
  };
};

function prettyProfile(profile: UserProfile | null) {
  if (isNil(profile)) {
    return '';
  }

  const { user_id, first_name, last_name, email } = profile;

  if (notNil(first_name) && notNil(last_name)) {
    return `${first_name} ${last_name}`;
  }

  if (notNil(email)) {
    return email;
  }

  return user_id;
}

export const UserInCompanyList: React.FC<Props> = ({ data, company }) => {
  const { usersInCompany } = data;
  const isAdmin = useIsAdmin();
  const hasCompanyUserEditPermission = useHasScopes([BackofficeScope.companyWriteUsers]);

  return (
    <div>
      <h2 className="table__header">
        <span>Users</span>
        {(isAdmin || hasCompanyUserEditPermission) && (
          <NavLink
            to={Routes.CompanyUserCreate.generatePath({ company_id: company.company_id })}
            className="ui button primary tiny">
            Add User To Company
          </NavLink>
        )}
      </h2>
      <UserTable
        className="ui very basic sortable unstackable compact table"
        data={(usersInCompany ?? []).map((userInCompany) => {
          const credentialTypes = Array.from(
            new Set((userInCompany.user.credentials ?? []).map((credential) => credential.type)),
          );
          credentialTypes.sort();

          return {
            user: {
              ...userInCompany.user,
              email: userInCompany.user.email ?? '-',
              name: [userInCompany.user.first_name, userInCompany.user.last_name].filter((i) => i).join(' '),
              credential_types: credentialTypes,
              credential_types_sort: credentialTypes.join(',') ?? '-',
            },
            role: userInCompany.role,
            persona: userInCompany.persona,
            persona_sort: userInCompany.persona ?? '-',
            invitedByUser: userInCompany.invitedByUser,
          };
        })}
        defaultSortBy={{ keyPath: 'user_id', value: 'ASC' }}
        onSortByChange={(sortBy) => {
          return { ...sortBy, fallback: 'user_id' };
        }}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            {
              keyPath: 'user.user_id',
              value: '#',
              className: (sortBy) => `right aligned ${getTableHeaderClassNames(sortBy)}`,
            },
            { keyPath: 'user.last_name', value: 'Name' },
            { keyPath: 'user.email', value: 'Email' },
            { keyPath: 'role', value: 'Role in company' },
            { keyPath: 'persona_sort', value: 'Persona in company' },
            { keyPath: 'user.credential_types_sort', value: 'Credentials' },
            { value: 'View' },
            { value: 'Invited by' },
          ],
        }}
        emptyStatePlaceholder={emptyTableState('No users')}>
        {({ row }) => {
          const { data: userInCompany } = row;
          return (
            <tr key={userInCompany.user.user_id} className="top aligned">
              <td className="right aligned">{userInCompany.user.user_id}</td>
              <td>{userInCompany.user.name}</td>
              <td>{userInCompany.user.email}</td>
              <td>
                <div
                  className={classnames('ui label tiny', {
                    purple: userInCompany.role === UserRoleInCompany.ADMIN,
                  })}
                  style={{ marginLeft: 10 }}>
                  {beautifyString(userInCompany.role)}
                </div>
              </td>
              <td>
                {userInCompany.persona !== null && (
                  <div
                    className={classnames('ui label tiny', {
                      pink: userInCompany.persona === UserPersonaInCompany.CUSTOMER_SUPPORT,
                      teal: userInCompany.persona === UserPersonaInCompany.TRANSPORT_PLANNER,
                      olive: userInCompany.persona === UserPersonaInCompany.WAREHOUSE_MANAGER,
                      red: userInCompany.persona === UserPersonaInCompany.DEVELOPER,
                    })}
                    style={{ marginLeft: 10 }}>
                    {beautifyString(userInCompany.persona)}
                  </div>
                )}
              </td>
              <td>
                {userInCompany.user.credential_types.indexOf(CredentialType.API_KEY) !== -1 && (
                  <div className="ui label blue tiny">API key</div>
                )}
                {userInCompany.user.credential_types.indexOf(CredentialType.EXT_SHIPMENT_STATUS_API_KEY) !== -1 && (
                  <div className="ui label blue tiny">Ext API key</div>
                )}
                <NavLink to={Routes.User.generatePath({ user_id: userInCompany.user.user_id })}>
                  <div className="ui label tiny">
                    {userInCompany.user.credential_types.length > 0 ? 'Manage' : 'Add'}
                  </div>
                </NavLink>
              </td>
              <td>
                <NavLink to={Routes.User.generatePath({ user_id: userInCompany.user.user_id })}>User</NavLink>
                <NavLink
                  to={Routes.CompanyUser.generatePath({
                    company_id: company.company_id,
                    user_id: userInCompany.user.user_id,
                  })}
                  style={{ marginLeft: '15px' }}>
                  User in Company
                </NavLink>
              </td>
              <td>
                {notNil(userInCompany.invitedByUser) && (
                  <div>
                    <NavLink to={Routes.User.generatePath({ user_id: userInCompany.invitedByUser.user_id })}>
                      {prettyProfile(userInCompany.invitedByUser)}
                    </NavLink>
                  </div>
                )}
              </td>
            </tr>
          );
        }}
      </UserTable>
    </div>
  );
};
