import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { CompanyRelationship } from '../../company/entities';
import { CompanyRelationshipType } from '../../lib/graphql';
import { Routes } from '../../routes';

export const RelationshipSnippet: React.FunctionComponent<{
  sourceCompany: CompanyRelationship['sourceCompany'] | null;
  relationshipType: CompanyRelationship['relationshipType'];
  targetCompany: CompanyRelationship['targetCompany'] | null;
}> = ({ sourceCompany, targetCompany, relationshipType }) => {
  return (
    <React.Fragment>
      {sourceCompany !== null && (
        <React.Fragment>
          <NavLink to={Routes.Company.generatePath({ company_id: sourceCompany.company_id })} className="item">
            {sourceCompany.company_name}
          </NavLink>

          <span style={{ margin: '0 4px' }}>is</span>
        </React.Fragment>
      )}
      <div
        className={classnames({
          'ui label orange tiny': true,
          purple: relationshipType === CompanyRelationshipType.REFERRER,
          blue: relationshipType === CompanyRelationshipType.CARRIER,
        })}>
        {relationshipType.toLowerCase()}
      </div>
      {targetCompany !== null && (
        <React.Fragment>
          <span style={{ margin: '0 4px' }}>of</span>
          <NavLink to={Routes.Company.generatePath({ company_id: targetCompany.company_id })} className="item">
            {targetCompany.company_name}
          </NavLink>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
