import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { getStreamPrecisionIcon } from './tour_event_list';
import { Routes } from '../../routes';
import { TourVisibilitySource } from '../entities';

export interface TourVisibilitySourceListProps {
  sources: TourVisibilitySource[];
}

export const TourVisibilitySourceList: React.FunctionComponent<TourVisibilitySourceListProps> = ({ sources }) => (
  <>
    <h3>Visibility sources</h3>
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Primary source</Table.HeaderCell>
          <Table.HeaderCell>Stream precision</Table.HeaderCell>
          <Table.HeaderCell>Origin</Table.HeaderCell>
          <Table.HeaderCell>Source company</Table.HeaderCell>
          <Table.HeaderCell>Source platform</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sources.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan="5">No visibility sources</Table.Cell>
          </Table.Row>
        ) : (
          sources.map((source, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{source.primaryEventSource}</Table.Cell>
              <Table.Cell>
                {source.streamPrecision ?? ''} <i className={getStreamPrecisionIcon(source.streamPrecision)} />
              </Table.Cell>
              <Table.Cell>{source.eventOrigin ?? '-'}</Table.Cell>
              <Table.Cell>
                {source.sourceCompany !== null ? (
                  <Link to={Routes.Company.generatePath({ company_id: source.sourceCompany.company_id })}>
                    {source.sourceCompany.company_name ?? source.sourceCompany.company_id}
                  </Link>
                ) : (
                  '-'
                )}
              </Table.Cell>
              <Table.Cell>{source.sourcePlatform?.platform_name ?? '-'}</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  </>
);
