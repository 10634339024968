import { gql } from 'graphql-tag';

export const kafkaMessagesQuery = gql`
  query BigQueryMessages(
    $keys: [String!]!
    $messageTypes: [String!]!
    $from: DateTime!
    $to: DateTime!
    $topics: [String!]!
  ) {
    bigQueryMessages(keys: $keys, messageTypes: $messageTypes, topics: $topics, from: $from, to: $to) {
      produced_at
      message
      message_type
    }
  }
`;

export const tourTrafficEntriesQuery = gql`
  query tourTrafficEntries(
    $tour_id: Int!
    $from: DateTime!
    $to: DateTime!
    $apiName: TrafficApiName!
    $first: Int
    $after: String
  ) {
    tour(tour_id: $tour_id) {
      tour_id
      tourTrafficEntries(from: $from, to: $to, apiName: $apiName, first: $first, after: $after) {
        edges {
          node {
            timestamp
            entry
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
