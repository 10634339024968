import { Loader } from '@sixfold/loader-container';
import { isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  getNodes,
  CompanyUserGroupOnPlatformQuery,
  CompanyUserGroupOnPlatformQueryVariables,
  PAGINATION_PAGE_SIZE,
  loadMoreFromConnection,
} from '../../lib/graphql';
import { UserGroupOnPlatformView } from '../components/user_group_on_platform';
import { UserGroupOnPlatform } from '../entities';
import { companyUserGroupQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyUserGroupOnPlatformQuery,
  UserGroupOnPlatform | undefined,
  CompanyUserGroupOnPlatformQueryVariables
> {}
class CompanyUserGroupQuery extends Query<CompanyUserGroupOnPlatformQuery, CompanyUserGroupOnPlatformQueryVariables> {}

export const CompanyUserGroupContainer = (props: RouteComponentProps<{ company_id: string; group_id: string }>) => {
  const { company_id, group_id } = props.match.params;

  return (
    <CompanyUserGroupQuery
      query={companyUserGroupQuery}
      variables={{
        limit: PAGINATION_PAGE_SIZE,
        company_id,
        group_id,
      }}>
      {(result) => {
        return (
          <LoadingContainer
            result={result}
            mapData={({ data }) => {
              if (isNil(data.company) || isNil(data.company.userGroupOnPlatform)) {
                return undefined;
              }

              return {
                ...data.company.userGroupOnPlatform,
                company: data.company?.userGroupOnPlatform.company ?? undefined,
                users: getNodes(data.company.userGroupOnPlatform.users) ?? [],
              };
            }}>
            {(userGroupOnPlatform) => {
              if (userGroupOnPlatform === undefined) {
                return 'No such group';
              }

              return (
                <InfiniteScroll
                  triggerOnWindow={false}
                  loadMoreEntries={() =>
                    loadMoreFromConnection(companyUserGroupQuery, result, ['company', 'userGroupOnPlatform', 'users'])
                  }>
                  <UserGroupOnPlatformView userGroupOnPlatform={userGroupOnPlatform} />
                </InfiniteScroll>
              );
            }}
          </LoadingContainer>
        );
      }}
    </CompanyUserGroupQuery>
  );
};
