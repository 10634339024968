import { Button, Modal, ModalFooter, ModalHeader, Stack, useNotifications } from '@sixfold/common-ui';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { DeleteCompanyIdentifierInput, MutationDeleteCompanyIdentifierArgs } from '../../lib/graphql';
import { companyQuery, deleteCompanyIdentifierMutation } from '../graphql';

type Props = {
  trigger: React.ReactElement | string;
  companyIdentifierId: string;
  companyId: string;
};

class DeleteCompanyIdentifierMutation extends Mutation<
  DeleteCompanyIdentifierInput,
  MutationDeleteCompanyIdentifierArgs
> {}

export const CompanyIdentifierModifyModal = (props: Props) => {
  const notify = useNotifications();
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async (
    mutationFn: MutationFn<DeleteCompanyIdentifierInput, MutationDeleteCompanyIdentifierArgs>,
  ) => {
    try {
      await mutationFn({
        variables: {
          input: {
            companyIdentifierId: props.companyIdentifierId,
          },
        },
        refetchQueries: [
          {
            query: companyQuery,
            variables: {
              company_id: props.companyId,
            },
          },
        ],
      });
      onToggle();
      notify.success({ title: 'Company identifier deleted.' });
    } catch (error) {
      notify.error({ title: 'Deleting the identifier failed.' });
    }
    onToggle();
  };

  return (
    <Modal trigger={props.trigger} open={isOpen} onToggle={onToggle}>
      <ModalHeader title={'Delete company identifier?'} />
      <ModalFooter>
        <Stack justifyContent="end">
          <Button kind="subtle" onClick={onToggle}>
            Cancel
          </Button>
          <DeleteCompanyIdentifierMutation mutation={deleteCompanyIdentifierMutation}>
            {(fn) => (
              <Button onClick={async () => await handleDelete(fn)} disabled={false} kind="primary">
                Delete
              </Button>
            )}
          </DeleteCompanyIdentifierMutation>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
