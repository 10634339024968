import { BackofficeScope } from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import { CompanyPlatform } from '../../company';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { Routes } from '../../routes';

export interface PlatformListProps {
  data: {
    platforms: CompanyPlatform[];
    company_id: string;
  };
}

class PlatformTable extends Table<CompanyPlatform> {}

export const PlatformList: React.FC<PlatformListProps> = ({ data }) => {
  const { platforms, company_id } = data;
  const isAdmin = useIsAdmin();
  const hasPlatformsPermission = useHasScopes([BackofficeScope.companyWritePlatforms]);

  return (
    <div>
      <h2 className="table__header">
        <span>Platforms</span>
        {(isAdmin || hasPlatformsPermission) && (
          <NavLink to={Routes.CompanyPlatformCreate.generatePath({ company_id })} className="ui button primary tiny">
            Add Platform
          </NavLink>
        )}
      </h2>

      <Message
        size="tiny"
        color="orange"
        icon="exclamation circle"
        header="This is powerful, use with precaution!"
        content="This will potentially have an impact on vehicles,
          tours and sites communicated to and from the selected platform"
      />

      <PlatformTable
        className="ui very basic sortable table"
        data={platforms}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { keyPath: 'platform_id', value: '#' },
            { keyPath: 'platform_name', value: 'Name' },
            { keyPath: 'company_id_on_platform', value: 'Company ID on platform' },
          ],
        }}
        defaultSortBy={{ keyPath: 'platform_id', value: 'ASC' }}
        emptyStatePlaceholder={emptyTableState('No platforms')}>
        {({ row }) => {
          const { platform_id, company_id_on_platform, platform_name } = row.data;
          return (
            <tr key={platform_id}>
              <td className="right aligned">{platform_id}</td>
              <td>
                <NavLink to={Routes.CompanyPlatform.generatePath({ company_id, platform_id })} className="item">
                  {platform_name}
                </NavLink>
              </td>
              <td>{company_id_on_platform}</td>
            </tr>
          );
        }}
      </PlatformTable>
    </div>
  );
};
