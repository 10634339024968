import { Box } from '@sixfold/common-ui';
import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha-enterprise';

import { getEmbedConfig } from '../../lib/data';

import styles from './styles.module.css';

interface CaptchaProps {
  onChange: (captcha_token: string | null) => void;
  onClickAway: () => void;
}

export const Captcha: React.FunctionComponent<CaptchaProps> = ({ onChange, onClickAway }) => {
  const config = getEmbedConfig();
  const ref = React.useRef();
  const onEscape = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClickAway();
      }
    },
    [onClickAway],
  );
  React.useEffect(() => {
    document.addEventListener('click', onClickAway, true);
    document.addEventListener('keydown', onEscape, true);

    return () => {
      document.removeEventListener('click', onClickAway, true);
      document.removeEventListener('keydown', onEscape, true);
    };
  }, [ref, onEscape, onClickAway]);
  if (config === null) {
    throw Error('Missing config');
  }

  return (
    <Box ref={ref} className={styles.captcha}>
      <ReCAPTCHA sitekey={config.google_captcha_key_id} onChange={onChange} />
    </Box>
  );
};
