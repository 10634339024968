import React from 'react';

import { TrafficEntry } from './types';
import { FormattedDate } from '../../date_formatting/formatted_date';
import { DiffViewerModal } from '../../diff_modal/diff_modal';

interface Props {
  compare: number[];
  payloads: TrafficEntry[];
}

export const TourTrafficDiffViewer: React.FunctionComponent<Props> = ({ compare, payloads }) => {
  const oldPayload: TrafficEntry | undefined = payloads[compare[0]];
  const newPayload: TrafficEntry | undefined = payloads[compare[1]];

  return (
    <DiffViewerModal
      dataKey="tour_api_traffic"
      isCompareButtonDisabled={compare.length !== 2}
      showDiffViewer={compare.length === 2}
      oldValue={JSON.stringify(newPayload?.entry, null, 2)}
      newValue={JSON.stringify(oldPayload?.entry, null, 2)}
      leftTitle={(useUnifiedView) =>
        useUnifiedView ? (
          <span>
            <FormattedDate date={newPayload?.timestamp} />
            {' - '}
            <FormattedDate date={oldPayload?.timestamp} />
          </span>
        ) : (
          <FormattedDate date={newPayload?.timestamp} />
        )
      }
      rightTitle={<FormattedDate date={oldPayload?.timestamp} />}
    />
  );
};
