import { Loader, NetworkStatus } from '@sixfold/loader-container';
import { exhaustiveCheck, notNil, JSONObject } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import React, { useReducer, useEffect } from 'react';
import { Query } from 'react-apollo';
import { Loader as LoadingIndicator } from 'semantic-ui-react';

import { TourTrafficDiffViewer } from './tour_traffic_diff_viewer';
import { TourTrafficQueryInput } from './tour_traffic_query_input';
import { TrafficState, TrafficEntry, TrafficAction } from './types';
import {
  TrafficApiName,
  getNodes,
  loadMoreFromConnection,
  TourTrafficEntriesQuery,
  TourTrafficEntriesQueryVariables,
} from '../../../lib/graphql';
import { Tour } from '../../../tour/entities';
import { FormattedDate } from '../../date_formatting/formatted_date';
import { InfiniteScroll } from '../../infinitescroll';
import { BigqueryMessage } from '../common/bigquery_message';
import { tourTrafficEntriesQuery } from '../common/graphql';

import styles from '../css/bigquery.module.css';

class LoadingContainer extends Loader<
  TourTrafficEntriesQuery,
  { tourTrafficEntries: TrafficEntry[] },
  TourTrafficEntriesQueryVariables
> {}

class TourTrafficContainerQuery extends Query<TourTrafficEntriesQuery, TourTrafficEntriesQueryVariables> {}

function trafficReducer(state: TrafficState, action: TrafficAction): TrafficState {
  switch (action.type) {
    case 'setInput':
      return {
        ...action.value,
      };
    case 'setItemsToCompare':
      return { ...state, itemsToCompare: action.value };
    case 'setShouldSkipQuery':
      return { ...state, shouldSkipQuery: action.value };
    case 'setLoading':
      return { ...state, loading: action.value };
    case 'setLoadingAndItemAmount':
      return { ...state, itemAmount: action.value.itemAmount, loading: action.value.loading };
  }
  exhaustiveCheck(action['type']);
}

function initialTrafficState(tour: Tour): TrafficState {
  const toDate = (tour.trackingEndTime !== null ? DateTime.fromISO(tour.trackingEndTime) : DateTime.now()).endOf('day');
  const fromDate = toDate.minus({ day: 1 }).startOf('day');
  return {
    shouldSkipQuery: true,
    fromDate,
    toDate,
    itemsToCompare: [],
    apiOperation: TrafficApiName.CREATE_OR_UPDATE_TRANSPORT,
    loading: false,
    itemAmount: 0,
  };
}

function createQueryVariables(tour: Tour, state: TrafficState): TourTrafficEntriesQueryVariables {
  const from = state.fromDate.toJSDate().toISOString();
  const to = state.toDate.toJSDate().toISOString();
  return { tour_id: tour.tour_id, apiName: state.apiOperation as TrafficApiName, from, to };
}

const HeaderUpdater = ({
  loading,
  itemAmount,
  trafficDispatcher,
}: {
  loading: boolean;
  itemAmount: number;
  trafficDispatcher: React.Dispatch<TrafficAction>;
}) => {
  useEffect(() => {
    trafficDispatcher({ type: 'setLoadingAndItemAmount', value: { itemAmount, loading } });
  }, [loading, itemAmount, trafficDispatcher]);
  return null;
};

export const TourTrafficTab: React.FunctionComponent<{ tour: Tour }> = ({ tour }) => {
  const [state, trafficDispatcher] = useReducer(trafficReducer, initialTrafficState(tour));

  return (
    <>
      <TrafficHeader loading={state.loading} itemAmount={state.itemAmount} />
      <TourTrafficQueryInput trafficState={state} trafficDispatcher={trafficDispatcher} loading={state.loading} />
      <TourTrafficContainerQuery
        query={tourTrafficEntriesQuery}
        variables={createQueryVariables(tour, state)}
        skip={state.shouldSkipQuery}
        fetchPolicy={'cache-first'}
        onCompleted={() => {
          trafficDispatcher({ type: 'setLoading', value: false });
        }}>
        {(trafficQueryResult) => {
          return (
            <LoadingContainer
              result={trafficQueryResult}
              loadingPlaceholder={<LoadingIndicator active={true} inline="centered" />}
              options={{
                showLoadingPlaceholder: ({ networkStatus, loading }) => {
                  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
                  return isFetchingMore ? false : loading;
                },
              }}
              mapData={({ data }) => {
                return {
                  tourTrafficEntries: trafficDataMapper(data),
                };
              }}>
              {({ tourTrafficEntries }) => {
                return (
                  <div className={classnames('data-area', styles.bigquery__data_area)}>
                    <HeaderUpdater
                      loading={trafficQueryResult.loading}
                      itemAmount={tourTrafficEntries.length}
                      trafficDispatcher={trafficDispatcher}
                    />
                    <TourTrafficDiffViewer payloads={tourTrafficEntries} compare={state.itemsToCompare} />
                    <InfiniteScroll
                      className="ui list"
                      loadMoreEntries={() =>
                        loadMoreFromConnection(tourTrafficEntriesQuery, trafficQueryResult, [
                          'tour',
                          'tourTrafficEntries',
                        ])
                      }>
                      <TourTrafficMessages
                        trafficEntries={tourTrafficEntries}
                        trafficDispatcher={trafficDispatcher}
                        trafficState={state}
                      />
                    </InfiniteScroll>
                  </div>
                );
              }}
            </LoadingContainer>
          );
        }}
      </TourTrafficContainerQuery>
    </>
  );
};

type TrafficHeaderProps = { loading: boolean; itemAmount: number };
const TrafficHeader = ({ loading, itemAmount }: TrafficHeaderProps) => {
  return <h3>{`Tour API traffic messages (${loading ? 'fetching...' : itemAmount})`}</h3>;
};

type TourTrafficMessagesProps = {
  trafficEntries: TrafficEntry[];
  trafficDispatcher: React.Dispatch<TrafficAction>;
  trafficState: TrafficState;
};

const TourTrafficMessages = ({ trafficEntries, trafficState, trafficDispatcher }: TourTrafficMessagesProps) => {
  return (
    <>
      {trafficEntries.map((trafficEntry, index) => (
        <BigqueryMessage
          key={index}
          payload={{ firstRow: trafficEntry.timestamp, data: trafficEntry.entry ?? {} }}
          checkBoxLabel={(item) => <FormattedDate date={item.firstRow} />}
          compare={trafficState.itemsToCompare}
          setCompare={(input) => trafficDispatcher({ type: 'setItemsToCompare', value: input })}
          index={index}
          isJustOne={trafficEntries.length === 1}
        />
      ))}
    </>
  );
};

function trafficDataMapper(data: Partial<TourTrafficEntriesQuery>): TrafficEntry[] {
  return getNodes(data.tour?.tourTrafficEntries).map((value) => {
    const entry = value.entry ?? {};
    const jsonPayload = entry.jsonPayload;
    const body = (jsonPayload as JSONObject)?.['body'];
    if (notNil(jsonPayload) && notNil(body) && typeof body === 'string' && typeof jsonPayload === 'object') {
      try {
        const parsedBody = JSON.parse(body);
        Object.assign(entry, {
          jsonPayload: {
            ...jsonPayload,
            body: parsedBody,
          },
        });
      } catch (e) {
        if (!(e instanceof SyntaxError)) {
          throw e;
        }
      }
    }
    return {
      timestamp: value.timestamp,
      entry,
    };
  });
}
