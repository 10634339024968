import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';

import { useIsAdmin } from '../../lib/authorization';

export type UsersFilterInput = {
  email?: string;
};

export const UsersFilter: React.FC<{
  activeFilters: {
    email?: string;
  };
  onFilterToggle: (filter: { name: keyof UsersFilterInput; value: string | undefined }) => void;
  onClearFilters: () => void;
}> = ({ activeFilters: { email }, onFilterToggle, onClearFilters }) => (
  <h2 className="table__header">
    <span>Users</span>
    {useIsAdmin() && (
      <NavLink to={'/users/new'} className="ui button primary tiny">
        Add User
      </NavLink>
    )}
    <Input
      placeholder="Email search..."
      value={email !== undefined ? email : ''}
      onChange={(_e, { value }) => {
        onFilterToggle({ name: 'email', value: value === '' ? undefined : value });
      }}
    />
    {email !== undefined && (
      <Button onClick={onClearFilters} size="tiny">
        Clear filters
      </Button>
    )}
  </h2>
);
