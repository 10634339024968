import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  CreateAllVehicleGrantsMutationVariables,
  RevokeAllVehicleGrantsMutationVariables,
  CreateAllVehicleGrantsMutation as CreateAllVehicleGrantsMutationResult,
  RevokeAllVehicleGrantsMutation as RevokeAllVehicleGrantsMutationResult,
  PAGINATION_PAGE_SIZE,
  CompanyRelationshipType,
} from '../../lib/graphql';
import { createAllVehicleGrantsMutation, revokeAllVehicleGrantsMutation, grantRelationshipsQuery } from '../graphql';

interface Props {
  children: Children<{
    createVehicleGrants: (input: CreateAllVehicleGrantsMutationVariables['input']) => Promise<void>;
    revokeVehicleGrants: (input: RevokeAllVehicleGrantsMutationVariables['input']) => Promise<void>;
  }>;
}

class CreateAllVehicleGrantsMutation extends Mutation<
  CreateAllVehicleGrantsMutationResult,
  CreateAllVehicleGrantsMutationVariables
> {}

class RevokeAllVehicleGrantsMutation extends Mutation<
  RevokeAllVehicleGrantsMutationResult,
  RevokeAllVehicleGrantsMutationVariables
> {}

export const VehicleGrantMutations: React.SFC<Props> = (props) => {
  const notify = useNotifications();

  return (
    <CreateAllVehicleGrantsMutation mutation={createAllVehicleGrantsMutation}>
      {(createVehicleGrants) => (
        <RevokeAllVehicleGrantsMutation mutation={revokeAllVehicleGrantsMutation}>
          {(revokeVehicleGrants) => {
            return renderChildren(props.children, {
              createVehicleGrants: async (input: CreateAllVehicleGrantsMutationVariables['input']) => {
                const response = await createVehicleGrants({
                  variables: { input },
                  refetchQueries: [
                    {
                      query: grantRelationshipsQuery,
                      variables: {
                        companyId: input.grantedByCompany,
                        sourceCompanyId: input.grantedByCompany,
                        relationshipType: CompanyRelationshipType.SUBCARRIER,
                        limit: PAGINATION_PAGE_SIZE,
                      },
                    },
                  ],
                });
                notify.success({
                  title: 'Vehicles granted',
                });
                return response;
              },
              revokeVehicleGrants: async (input: RevokeAllVehicleGrantsMutationVariables['input']) => {
                const response = await revokeVehicleGrants({
                  variables: { input },
                  refetchQueries: [
                    {
                      query: grantRelationshipsQuery,
                      variables: {
                        companyId: input.revokedByCompany,
                        sourceCompanyId: input.revokedByCompany,
                        relationshipType: CompanyRelationshipType.SUBCARRIER,
                        limit: PAGINATION_PAGE_SIZE,
                      },
                    },
                  ],
                });
                notify.success({
                  title: 'Vehicle grants revoked',
                });
                return response;
              },
            });
          }}
        </RevokeAllVehicleGrantsMutation>
      )}
    </CreateAllVehicleGrantsMutation>
  );
};
