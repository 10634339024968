import { isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { MutationFn } from 'react-apollo';

import { FeatureFlagsView } from '../../components/feature_flag';
import {
  CompanyWithFeatureFlagMetadataQuery,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  getNodes,
} from '../../lib/graphql';

type CompanyFeatureFlags = {
  onFlagsChange: MutationFn<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
  currentSetFlags: Record<string, boolean>;
  loadMoreAuditLogs: () => Promise<unknown> | undefined;
  company?: CompanyWithFeatureFlagMetadataQuery['company'];
  allFlags?: CompanyWithFeatureFlagMetadataQuery['featureFlagMetadata'] | null;
};

export const CompanyFeatureFlags: React.FunctionComponent<CompanyFeatureFlags> = ({
  allFlags,
  onFlagsChange,
  currentSetFlags,
  company,
  loadMoreAuditLogs,
}) => {
  if (isNil(allFlags)) {
    return null;
  }

  if (isNil(company)) {
    return null;
  }

  const auditLog = getNodes(company.featureFlagAuditLogConnection);
  return (
    <FeatureFlagsView
      allFlags={allFlags}
      auditLog={auditLog ?? []}
      loadMoreAuditLogs={loadMoreAuditLogs}
      onFlagsChange={async (newFlags) => {
        await onFlagsChange({
          variables: {
            company_id: company.company_id,
            input: {
              feature_flags: newFlags,
              company_name: company.company_name,
            },
          },
        });
      }}
      currentSetFlags={currentSetFlags}
    />
  );
};
