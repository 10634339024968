import React from 'react';

export class DateInput extends React.Component<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> {
  private input: HTMLInputElement | null;

  constructor(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
    super(props);

    this.input = null;
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(event: Event) {
    const { onChange } = this.props;
    if (onChange !== undefined) {
      onChange({
        target: event.target,
        currentTarget: event.currentTarget,
        nativeEvent: event,
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }

  componentDidMount() {
    if (this.input !== null) {
      this.input.addEventListener('change', this.handleDateChange);
    }
  }

  componentWillUnmount() {
    if (this.input !== null) {
      this.input.removeEventListener('change', this.handleDateChange);
    }
  }

  render() {
    return (
      <input
        {...this.props}
        ref={(input) => {
          this.input = input;
        }}
        type="date"
      />
    );
  }
}
