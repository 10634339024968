import { isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Dropdown, Grid, GridColumn, GridRow } from 'semantic-ui-react';

import { Button } from '../../components/button';
import {
  CompanyConfigurationQuery,
  ConfigurationUpdateInput,
  TransportIdentifier,
  UpdateCompanyConfigurationInput,
} from '../../lib/graphql';

export interface CompanyConfigurationViewProps {
  data: CompanyConfigurationQuery;
  updateConfiguration: (input: UpdateCompanyConfigurationInput) => Promise<void>;
}

const transportIdentifierOptions = [
  { value: TransportIdentifier.DELIVERY_NUMBER, text: TransportIdentifier.DELIVERY_NUMBER },
  { value: TransportIdentifier.ORDER_NUMBER, text: TransportIdentifier.ORDER_NUMBER },
  { value: TransportIdentifier.PURCHASE_ORDER_NUMBER, text: TransportIdentifier.PURCHASE_ORDER_NUMBER },
];

export const CompanyConfigurationView: React.FC<CompanyConfigurationViewProps> = (props) => {
  const configurations = props.data.company?.configuration?.configurations;

  const [customerPortalPrimaryTI, setCustomerPortalPrimaryTI] = React.useState<TransportIdentifier>(
    configurations?.customerPortal?.primaryTransportIdentifier ?? TransportIdentifier.TRANSPORT_NUMBER,
  );

  React.useEffect(() => {
    setCustomerPortalPrimaryTI(
      configurations?.customerPortal?.primaryTransportIdentifier ?? TransportIdentifier.TRANSPORT_NUMBER,
    );
  }, [configurations?.customerPortal?.primaryTransportIdentifier]);

  const [customerPortalDetailViewTIs, setCustomerPortalDetailViewTIs] = React.useState<TransportIdentifier[]>(
    configurations?.customerPortal?.detailViewTransportIdentifiers ?? [],
  );

  React.useEffect(() => {
    setCustomerPortalDetailViewTIs(configurations?.customerPortal?.detailViewTransportIdentifiers ?? []);
  }, [configurations?.customerPortal?.detailViewTransportIdentifiers]);

  const [sharedStopViewVisibleTIs, setSharedStopViewVisibleTIs] = React.useState<TransportIdentifier[]>(
    configurations?.sharedStopView?.visibleTransportIdentifiers ?? [
      TransportIdentifier.DELIVERY_NUMBER,
      TransportIdentifier.PURCHASE_ORDER_NUMBER,
      TransportIdentifier.ORDER_NUMBER,
    ],
  );

  React.useEffect(() => {
    setSharedStopViewVisibleTIs(
      configurations?.sharedStopView?.visibleTransportIdentifiers ?? [
        TransportIdentifier.DELIVERY_NUMBER,
        TransportIdentifier.PURCHASE_ORDER_NUMBER,
        TransportIdentifier.ORDER_NUMBER,
      ],
    );
  }, [configurations?.sharedStopView?.visibleTransportIdentifiers]);

  const [sharedTourViewVisibleTIs, setSharedTourViewVisibleTIs] = React.useState<TransportIdentifier[]>(
    configurations?.sharedTourView?.visibleTransportIdentifiers ?? [],
  );

  React.useEffect(() => {
    setSharedTourViewVisibleTIs(configurations?.sharedTourView?.visibleTransportIdentifiers ?? []);
  }, [configurations?.sharedTourView?.visibleTransportIdentifiers]);

  const updateConfiguration = async (configuration: ConfigurationUpdateInput) => {
    if (isNil(props.data.company)) {
      return;
    }

    const input = {
      companyId: props.data.company.company_id,
      configuration,
    };

    await props.updateConfiguration(input);
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn width="8">
          <h2>Customer portal</h2>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="4">Primary transport identifier</GridColumn>
        <GridColumn width="4">
          <Dropdown
            fluid
            selection
            options={Object.keys(TransportIdentifier).map((type) => ({
              value: type,
              text: type,
            }))}
            value={customerPortalPrimaryTI}
            onChange={(_e, { value }) => {
              setCustomerPortalPrimaryTI(value as TransportIdentifier);
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="4">Detail view transport identifiers</GridColumn>
        <GridColumn width="4">
          <Dropdown
            fluid
            multiple
            selection
            options={transportIdentifierOptions}
            value={customerPortalDetailViewTIs}
            onChange={(_e, { value }) => {
              setCustomerPortalDetailViewTIs(value as TransportIdentifier[]);
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="8" textAlign="right">
          <Button
            type="button"
            size="tiny"
            onClick={() =>
              updateConfiguration({
                customerPortal: {
                  value: undefined,
                },
              })
            }>
            Reset to default
          </Button>
          <Button
            type="submit"
            primary
            size="tiny"
            disabled={isNil(customerPortalPrimaryTI) || customerPortalDetailViewTIs.length === 0}
            onClick={() =>
              updateConfiguration({
                customerPortal: {
                  value: {
                    primaryTransportIdentifier: customerPortalPrimaryTI,
                    detailViewTransportIdentifiers: customerPortalDetailViewTIs,
                  },
                },
              })
            }>
            Save
          </Button>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="8">
          <h2>Shared Stop View</h2>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="4">Visible transport identifiers</GridColumn>
        <GridColumn width="4">
          <Dropdown
            fluid
            multiple
            selection
            options={transportIdentifierOptions}
            value={sharedStopViewVisibleTIs}
            onChange={(_e, { value }) => {
              setSharedStopViewVisibleTIs(value as TransportIdentifier[]);
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="8" textAlign="right">
          <Button
            type="button"
            size="tiny"
            onClick={() =>
              updateConfiguration({
                sharedStopView: {
                  value: undefined,
                },
              })
            }>
            Reset to default
          </Button>
          <Button
            type="submit"
            primary
            size="tiny"
            disabled={sharedStopViewVisibleTIs.length === 0}
            onClick={() =>
              updateConfiguration({
                sharedStopView: {
                  value: {
                    visibleTransportIdentifiers: sharedStopViewVisibleTIs,
                  },
                },
              })
            }>
            Save
          </Button>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="8">
          <h2>Shared Tour View</h2>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="4">Visible transport identifiers</GridColumn>
        <GridColumn width="4">
          <Dropdown
            fluid
            multiple
            selection
            options={transportIdentifierOptions}
            value={sharedTourViewVisibleTIs}
            onChange={(_e, { value }) => {
              setSharedTourViewVisibleTIs(value as TransportIdentifier[]);
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="8" textAlign="right">
          <Button
            type="button"
            size="tiny"
            onClick={() =>
              updateConfiguration({
                sharedTourView: {
                  value: undefined,
                },
              })
            }>
            Reset to default
          </Button>
          <Button
            type="submit"
            primary
            size="tiny"
            disabled={sharedTourViewVisibleTIs.length === 0}
            onClick={() =>
              updateConfiguration({
                sharedTourView: {
                  value: {
                    visibleTransportIdentifiers: sharedTourViewVisibleTIs,
                  },
                },
              })
            }>
            Save
          </Button>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
