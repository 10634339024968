import React from 'react';

type HighlightedStopIdState = {
  highlightedStopId: number | undefined;
  setHighlightedStopId: (value: number | undefined) => void;
};

const initialHighlightedStopId: number | undefined = undefined;

export const HighlightedStopIdContext = React.createContext<HighlightedStopIdState>({
  highlightedStopId: initialHighlightedStopId,
  setHighlightedStopId: () => undefined,
});

export const useHighlightedStopIdContext = () => React.useContext(HighlightedStopIdContext);

export const HighlightedStopIdProvider = ({ children }: JSX.ElementChildrenAttribute) => {
  const [highlightedStopId, setHighlightedStopId] = React.useState<number | undefined>(initialHighlightedStopId);
  const state = React.useMemo(
    () => ({ highlightedStopId, setHighlightedStopId }),
    [highlightedStopId, setHighlightedStopId],
  );

  return <HighlightedStopIdContext.Provider value={state}>{children}</HighlightedStopIdContext.Provider>;
};
