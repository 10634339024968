import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { BackofficeRole, CredentialType } from '../../lib/graphql';
import { Omit } from '../../lib/types';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';
import { User } from '../entities';

export interface UserListDataProps {
  data: {
    users: User[] | null;
  };
}

class UserTable extends Table<
  Omit<User, 'backoffice_role'> & {
    backoffice_role: BackofficeRole | string;
    name: string;
    credential_types: CredentialType[];
  }
> {}

export const UserList: React.FC<UserListDataProps> = ({ data }) => {
  const { users } = data;

  return (
    <UserTable
      className="ui very basic sortable unstackable table"
      data={(users ?? []).map((user) => {
        const credentialTypes = Array.from(new Set((user.credentials ?? []).map((credential) => credential.type)));
        credentialTypes.sort();

        return {
          ...user,
          email: user.email ?? '',
          name: [user.first_name, user.last_name].filter((i) => i).join(' '),
          backoffice_role: user.backoffice_role ?? '-',
          credential_types: credentialTypes,
          credential_types_sort: credentialTypes.join(',') ?? '-',
        };
      })}
      defaultSortBy={{ keyPath: 'user_id', value: 'ASC' }}
      onSortByChange={(sortBy) => {
        return { ...sortBy, fallback: 'user_id' };
      }}
      tableHeaders={{
        defaultClassName: getTableHeaderClassNames,
        columns: [
          {
            keyPath: 'user_id',
            value: '#',
            className: (sortBy) => `right aligned ${getTableHeaderClassNames(sortBy)}`,
          },
          { keyPath: 'name', value: 'Name' },
          { keyPath: 'email', value: 'Email' },
          { keyPath: 'backoffice_role', value: 'Backoffice role' },
          { keyPath: 'credential_types_sort', value: 'Credentials' },
          { value: 'View' },
        ],
      }}
      emptyStatePlaceholder={emptyTableState('No users')}>
      {({ row }) => {
        const { data: user } = row;

        return (
          <tr key={user.user_id} className="top aligned">
            <td className="right aligned">{user.user_id}</td>
            <td>{user.name}</td>
            <td>{user.email ?? '-'}</td>
            <td>
              {user.backoffice_role !== '-' ? (
                <div className="ui label tiny">{beautifyString(user.backoffice_role)}</div>
              ) : (
                '-'
              )}
            </td>
            <td>
              {user.credential_types.length === 0 && '-'}
              {user.credential_types.indexOf(CredentialType.API_KEY) !== -1 && (
                <div className="ui label blue tiny">API key</div>
              )}
              {user.credential_types.indexOf(CredentialType.EXT_SHIPMENT_STATUS_API_KEY) !== -1 && (
                <div className="ui label blue tiny">EXT API key</div>
              )}
            </td>
            <td>
              <NavLink to={Routes.User.generatePath({ user_id: user.user_id })}>User</NavLink>{' '}
            </td>
          </tr>
        );
      }}
    </UserTable>
  );
};
