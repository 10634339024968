import { gql } from 'graphql-tag';

export const vehiclesQuery = gql`
  query VehicleList(
    $limit: Int
    $cursor: String
    $companyId: String
    $licensePlateSearch: String
    $integrationIds: [String!]
  ) {
    vehicles(
      first: $limit
      after: $cursor
      company_id: $companyId
      licensePlateSearch: $licensePlateSearch
      integrationIds: $integrationIds
    ) {
      edges {
        node {
          vehicle_id
          license_plate_number
          created_at

          status {
            latitude
            longitude
            heading
            timestamp
          }

          company {
            company_id
            company_name
          }

          telematicsIntegrations {
            telematics_integration_id
          }

          vehicleTrackers {
            ... on MobileVehicleTracker {
              vehicleTrackerId
              trackerType
            }
            ... on FMSVehicleTracker {
              vehicleTrackerId
              trackerType
            }
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const managedVehiclesQuery = gql`
  query ManagedVehicleList(
    $limit: Int
    $cursor: String
    $companyId: String
    $licensePlateSearch: String
    $integrationIds: [String!]
  ) {
    vehicles(
      first: $limit
      after: $cursor
      company_id: $companyId
      licensePlateSearch: $licensePlateSearch
      integrationIds: $integrationIds
    ) {
      edges {
        node {
          vehicle_id
          license_plate_number
          created_at
          telematicsIntegrations {
            telematics_integration_id
          }

          vehicleTrackers {
            ... on MobileVehicleTracker {
              vehicleTrackerId
              trackerType
            }
            ... on FMSVehicleTracker {
              vehicleTrackerId
              telematicsIntegration {
                telematicsIntegrationId
              }
              trackerType
              fmsRemoteId: remoteId
            }
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const vehicleQuery = gql`
  query Vehicle($vehicle_id: String!) {
    vehicle(vehicle_id: $vehicle_id) {
      vehicle_id
      license_plate_number
      inGlobalPool
      created_at
      updated_at

      company {
        company_id
        company_name
      }

      status {
        latitude
        longitude
        heading
        timestamp
      }

      vehicleTrackers {
        ... on MobileVehicleTracker {
          vehicleTrackerId
          trackerType
          mobileRemoteId: remoteId
          phoneNumber
          createdAt
          updatedAt
          trackerData
        }

        ... on FMSVehicleTracker {
          vehicleTrackerId
          trackerType
          fmsRemoteId: remoteId
          createdAt
          updatedAt
          trackerData
          telematicsIntegration {
            telematicsIntegrationId
            telematicsProvider {
              telematicsProviderName
            }
          }
        }
      }

      latestVehicleTemperatures {
        reeferId
        timestamp
        visibilitySource {
          eventLevel
          primaryEventSource
          eventOrigin
        }
        sensorReadings {
          sensorId
          compartmentId
          temperatureCelsius
        }
      }
    }
  }
`;

export const vehicleHistoryQuery = gql`
  query VehicleHistory(
    $vehicle_id: String!
    $history_start_time: String!
    $history_end_time: String!
    $simplification_tolerance: Float
  ) {
    vehicle(vehicle_id: $vehicle_id) {
      vehicle_id
      history(
        start_time: $history_start_time
        end_time: $history_end_time
        simplification_tolerance: $simplification_tolerance
        limit: 100000
      ) {
        lat
        lng
        hdg
        timestamp
        received_at
        source
      }

      breakHistory(startTime: $history_start_time, endTime: $history_end_time) {
        breakId
        from
        to

        center {
          lat
          lng
        }
      }
    }
  }
`;

export const deleteVehicleMutation = gql`
  mutation DeleteVehicle($input: DeleteVehicleInput!) {
    deleteVehicle(input: $input)
  }
`;

export const deleteVehiclesMutation = gql`
  mutation DeleteVehicles($input: DeleteVehiclesInput!) {
    deleteVehicles(input: $input)
  }
`;
