import { isNil, notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { ParsedVehicleHistory } from './entities';
import { isOutdatedTimestamp } from './utils';
import { FormattedDate } from '../../../components/date_formatting/formatted_date';
import { formattedDuration } from '../../../lib/date';
import { Routes } from '../../../routes';
import { isFMSTracker } from '../../../vehicle/utils';

interface Timeline {
  startTime: string;
  endTime: string;
}

interface TourTimelineHeaderProps {
  timeline: Timeline;
  selectedPosition: { timestamp: Date; event?: string; externalEvent?: string } | undefined;
}

interface Vehicle {
  vehicleId: string;
  licensePlateNumber: string | null;
  position?: ParsedVehicleHistory;
  isMultiModal: boolean;
}

interface VehicleTimelineHeaderProps {
  currentTimestamp?: Date;
  vehicle: Vehicle;
  timeline: Timeline;
}

function vehicleUrl(vehicle: Vehicle, timeline: Timeline, timestamp?: Date): string {
  const timestampQuery = notNil(timestamp) ? `&timestamp=${timestamp.toISOString()}` : '';
  const query = `?timeline_start=${timeline.startTime}&timeline_end=${timeline.endTime}${timestampQuery}`;

  return `/vehicles/${vehicle.vehicleId}${query}`;
}

export const TourTimelineHeader: React.StatelessComponent<TourTimelineHeaderProps> = ({
  selectedPosition,
  timeline,
}) => {
  return (
    <React.Fragment>
      <ul className="map-slider__details">
        <li>
          Start: <b>{timeline.startTime !== '' ? <FormattedDate date={timeline.startTime} /> : '-'}</b>
        </li>
        <li>
          Current: <b>{selectedPosition !== undefined ? <FormattedDate date={selectedPosition.timestamp} /> : '-'}</b>
        </li>
        <li>
          End: <b>{timeline.endTime !== '' ? <FormattedDate date={timeline.endTime} /> : '-'}</b>
        </li>
      </ul>
      <ul className="map-slider__details">
        <li className="wide-column">
          Event: <b>{selectedPosition?.event ?? '-'}</b>
        </li>
        <li className="wide-column">
          External event: <b>{selectedPosition?.externalEvent ?? '-'}</b>
        </li>
      </ul>
    </React.Fragment>
  );
};

export const VehicleTimelineHeader: React.SFC<VehicleTimelineHeaderProps> = ({
  vehicle,
  currentTimestamp,
  timeline,
}) => {
  const isOutdatedVehicleTimestamp =
    currentTimestamp !== undefined &&
    vehicle.position !== undefined &&
    isOutdatedTimestamp(currentTimestamp, vehicle.position.timestamp);

  const telematicsIntegrationIdFromPosition = isFMSTracker(vehicle.position?.vehicleTracker)
    ? vehicle.position?.vehicleTracker.telematicsIntegration?.telematicsIntegrationId
    : undefined;
  const companyId = vehicle.position?.company?.company_id;
  const integrationLinkFromVehiclePosition = React.useMemo(() => {
    if (isNil(telematicsIntegrationIdFromPosition) || isNil(companyId)) {
      return;
    }
    return (
      <NavLink
        to={Routes.CompanyTelematicsIntegration.generatePath({
          company_id: companyId,
          telematics_integration_id: telematicsIntegrationIdFromPosition,
        })}>
        #{telematicsIntegrationIdFromPosition}
      </NavLink>
    );
  }, [telematicsIntegrationIdFromPosition, companyId]);

  return (
    <React.Fragment>
      <ul className="map-slider__details">
        <li>
          Vehicle:{' '}
          <NavLink to={vehicleUrl(vehicle, timeline, currentTimestamp)}>
            {vehicle.licensePlateNumber ?? vehicle.vehicleId}
          </NavLink>
        </li>
        <li>
          Status:{' '}
          <b
            style={isOutdatedVehicleTimestamp ? { color: 'red' } : {}}
            title="Last received vehicle position timestamp">
            {vehicle !== undefined && vehicle.position !== undefined ? (
              isOutdatedVehicleTimestamp ? (
                <FormattedDate
                  date={vehicle.position.timestamp}
                  additionalMessage={` (${formattedDuration(vehicle.position.timestamp, currentTimestamp)})`}
                />
              ) : (
                <FormattedDate date={vehicle.position.timestamp} />
              )
            ) : (
              '-'
            )}
          </b>
        </li>
        <li>
          Received at: <FormattedDate date={vehicle.position?.received_at} />
        </li>
        <li>
          {vehicle.isMultiModal && (
            <>
              Type: {vehicle.position?.vehicleType ?? '-'} ({vehicle.position?.modeChangesLeft ?? '-'} mode changes
              left)
              <br />
            </>
          )}
          Lat, Lng:{' '}
          <b style={{ fontVariantNumeric: 'tabular-nums' }}>
            {vehicle.position !== undefined &&
              [vehicle.position.lat.toFixed(5), vehicle.position.lng.toFixed(5)].join(', ')}
          </b>
        </li>
        <li>
          Source: {vehicle.position?.source ?? '–  '}&ensp; Integration:{' '}
          {notNil(integrationLinkFromVehiclePosition) ? integrationLinkFromVehiclePosition : '-'}
        </li>
      </ul>
    </React.Fragment>
  );
};
