import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';

import { CompanyInvitationsQuery, CompanyInvitationsQueryVariables } from '../../lib/graphql';
import { CompanyInvitations, InvitationsViewProps } from '../components/company_invitations';
import { companyInvitationsQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyInvitationsQuery,
  InvitationsViewProps,
  CompanyInvitationsQueryVariables
> {}

class InvitationsContainerQuery extends Query<CompanyInvitationsQuery, CompanyInvitationsQueryVariables> {}

export const CompanyInvitationsContainer = (props: { companyId: string }) => (
  <InvitationsContainerQuery
    query={companyInvitationsQuery}
    variables={{
      company_id: props.companyId,
    }}>
    {(result) => (
      <LoadingContainer result={result} mapData={({ data }) => ({ companyInvitations: data.companyInvitations ?? [] })}>
        {(props) => <CompanyInvitations {...props} />}
      </LoadingContainer>
    )}
  </InvitationsContainerQuery>
);
