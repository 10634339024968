import { useNotifications } from '@sixfold/common-ui';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  AddCredentialsToUserInput,
  AddCredentialsToUserMutation as AddCredentialsToUserMutationResult,
  AddCredentialsToUserMutationVariables,
  CreateUserMutation as CreateUserMutationResult,
  CreateUserMutationVariables,
  DeleteUserMutation as DeleteUserMutationResult,
  DeleteUserMutationVariables,
  RemoveCredentialsFromUserInput,
  RemoveCredentialsFromUserMutation as RemoveCredentialsFromUserMutationResult,
  RemoveCredentialsFromUserMutationVariables,
  UpdateUserMutation as UpdateUserMutationResult,
  UpdateUserMutationVariables,
} from '../../lib/graphql';
import { addCredentialsToUserMutation, removeCredentialsFromUserMutation, userQuery } from '../graphql';

const createUserMutation = gql`
  mutation CreateUser($auth_service: String!, $input: UserAttributesInput) {
    createUser(auth_service: $auth_service, input: $input) {
      user_id
      email
      locale
      backoffice_role
      backoffice_scopes
      first_name
      last_name
      avatar_url
      created_at
      updated_at
      deleted_at
    }
  }
`;

const updateUserMutation = gql`
  mutation UpdateUser($user_id: String!, $input: UserAttributesInput) {
    updateUser(user_id: $user_id, input: $input) {
      user_id
      email
      locale
      backoffice_role
      backoffice_scopes
      first_name
      last_name
      avatar_url
      updated_at
      deleted_at
    }
  }
`;

const deleteUserMutation = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

class CreateUserMutation extends Mutation<CreateUserMutationResult, CreateUserMutationVariables> {}
class UpdateUserMutation extends Mutation<UpdateUserMutationResult, UpdateUserMutationVariables> {}
class DeleteUserMutation extends Mutation<DeleteUserMutationResult, DeleteUserMutationVariables> {}
class AddCredentialsToUserMutation extends Mutation<
  AddCredentialsToUserMutationResult,
  AddCredentialsToUserMutationVariables
> {}
class RemoveCredentialsFromUserMutation extends Mutation<
  RemoveCredentialsFromUserMutationResult,
  RemoveCredentialsFromUserMutationVariables
> {}

interface Props {
  children: Children<{
    createUser: (authService: string, input: CreateUserMutationVariables['input']) => Promise<void>;
    updateUser: (userId: string, input: UpdateUserMutationVariables['input']) => Promise<void>;
    deleteUser: (userId: string) => Promise<void>;
    addCredentialsToUser: (input: AddCredentialsToUserInput) => Promise<void>;
    removeCredentialsFromUser: (input: RemoveCredentialsFromUserInput) => Promise<void>;
  }>;
}

export const UserMutations: React.FC<Props> = (props) => {
  const notify = useNotifications();

  return (
    <CreateUserMutation mutation={createUserMutation}>
      {(createUser) => (
        <UpdateUserMutation mutation={updateUserMutation}>
          {(updateUser) => (
            <DeleteUserMutation mutation={deleteUserMutation}>
              {(deleteUser) => (
                <AddCredentialsToUserMutation mutation={addCredentialsToUserMutation}>
                  {(addCredentialsToUser) => (
                    <RemoveCredentialsFromUserMutation mutation={removeCredentialsFromUserMutation}>
                      {(removeCredentialsFromUser) => {
                        return renderChildren(props.children, {
                          createUser: async (authService: string, input: CreateUserMutationVariables['input']) => {
                            const response = await createUser({
                              variables: {
                                input,
                                auth_service: authService,
                              },
                            });
                            notify.success({ title: 'User created' });
                            return response;
                          },
                          updateUser: async (userId: string, input: UpdateUserMutationVariables['input']) => {
                            const response = await updateUser({
                              variables: {
                                input,
                                user_id: userId,
                              },
                            });
                            notify.success({ title: 'User updated' });
                            return response;
                          },
                          deleteUser: async (userId: string) => {
                            const response = await deleteUser({
                              variables: {
                                userId,
                              },
                            });
                            notify.success({ title: 'User deleted' });
                            return response;
                          },
                          addCredentialsToUser: async (input: AddCredentialsToUserInput) => {
                            const response = await addCredentialsToUser({
                              variables: {
                                input,
                              },
                              refetchQueries: [
                                {
                                  query: userQuery,
                                  variables: {
                                    user_id: input.userId,
                                  },
                                },
                              ],
                            });

                            notify.success({ title: 'User API credentials added' });
                            return response;
                          },
                          removeCredentialsFromUser: async (input: RemoveCredentialsFromUserInput) => {
                            const response = await removeCredentialsFromUser({
                              variables: {
                                input,
                              },
                              refetchQueries: [
                                {
                                  query: userQuery,
                                  variables: {
                                    user_id: input.userId,
                                  },
                                },
                              ],
                            });
                            notify.success({ title: 'User API credentials removed' });
                            return response;
                          },
                        });
                      }}
                    </RemoveCredentialsFromUserMutation>
                  )}
                </AddCredentialsToUserMutation>
              )}
            </DeleteUserMutation>
          )}
        </UpdateUserMutation>
      )}
    </CreateUserMutation>
  );
};
