import { BackofficeScope } from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';

import { ConfirmButton } from '../../components/confirm_button';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { differenceInHours, differenceInMinutes } from '../../lib/date';
import { ExternalRoutes, Routes } from '../../routes';
import { Vehicle, VehicleStatus, VehicleTracker } from '../entities';
import { isMobileTracker } from '../utils';

interface VehicleRow extends Vehicle {
  company: NonNullable<Vehicle['company']>;
  created_at: string | null;
  status: VehicleStatus | null;
  telematicsIntegrations: { telematics_integration_id: string }[];
  vehicleTrackers: Pick<VehicleTracker, 'vehicleTrackerId' | 'trackerType'>[];
}

class VehiclesTable extends Table<VehicleRow> {}

export interface VehiclesListDataProps {
  data: {
    vehicles: VehicleRow[];
  };
  company?: {
    company_name?: string | null;
    company_id: string;
  };
  search?: string;
}

interface VehiclesListProps extends VehiclesListDataProps {
  onDeleteVehicles: (vehicleIdsToDelete: string[]) => Promise<void>;
}

interface SelectedVehiclesState {
  vehicles: Record<string, boolean | undefined>;
  isSelectAllEnabled: boolean;
}

const isOwnedByCompany = (vehicle: VehicleRow, selectedCompanyId: string | null) =>
  vehicle.company.company_id === selectedCompanyId;

export const VehiclesList: React.FC<VehiclesListProps> = (props) => {
  const [selectedVehicles, setSelectedVehicles] = React.useState<SelectedVehiclesState>({
    vehicles: {},
    isSelectAllEnabled: false,
  });
  const selectedCompanyId = props.company?.company_id ?? null;

  const vehiclesToDelete = Object.entries(selectedVehicles.vehicles)
    .map(([key, value]) => {
      if (value !== true) {
        return;
      }

      return key;
    })
    .filter(notNil);

  const vehiclesToDeleteCount = vehiclesToDelete.length;
  const isAdmin = useIsAdmin();
  const hasVehiclesEditPermission = useHasScopes([BackofficeScope.companyWriteVehicles]);
  const isPrivilegedUser = isAdmin || hasVehiclesEditPermission;
  const isDeleteButtonDisabled = !isPrivilegedUser || vehiclesToDeleteCount === 0 || props.company === undefined;

  React.useEffect(() => {
    if (!selectedVehicles.isSelectAllEnabled) {
      return;
    }

    const vehiclesToSelect = props.data.vehicles.reduce(
      (memo, curr) => ({ ...memo, [curr.vehicle_id]: isOwnedByCompany(curr, selectedCompanyId) ? true : false }),
      {},
    );
    setSelectedVehicles({
      vehicles: vehiclesToSelect,
      isSelectAllEnabled: selectedVehicles.isSelectAllEnabled,
    });
  }, [props.data.vehicles, props.data.vehicles.length, selectedCompanyId, selectedVehicles.isSelectAllEnabled]);

  const onSelectAllVehicles = React.useCallback(() => {
    setSelectedVehicles(({ isSelectAllEnabled }) => {
      const vehiclesToSelect = props.data.vehicles.reduce((memo, curr) => {
        return { ...memo, [curr.vehicle_id]: isOwnedByCompany(curr, selectedCompanyId) ? !isSelectAllEnabled : false };
      }, {});

      return {
        vehicles: vehiclesToSelect,
        isSelectAllEnabled: !isSelectAllEnabled,
      };
    });
  }, [props.data.vehicles, selectedCompanyId]);

  const onSelectVehicle = React.useCallback((vehicleId: string) => {
    setSelectedVehicles(({ vehicles }) => {
      const currentlySelectedVehicleState = vehicles[vehicleId] ?? false;

      vehicles[vehicleId] = !currentlySelectedVehicleState;

      return {
        isSelectAllEnabled: false,
        vehicles,
      };
    });
  }, []);

  const onDeleteVehiclesClick = React.useCallback(
    async (vehicleIdsToDelete: string[]) => {
      await props.onDeleteVehicles(vehicleIdsToDelete);
      setSelectedVehicles({ isSelectAllEnabled: false, vehicles: {} });
    },
    [props],
  );

  return (
    <>
      <ConfirmButton
        initialButtonColor="red"
        label={vehiclesToDeleteCount === 0 ? 'Delete vehicles' : `Delete ${vehiclesToDeleteCount} vehicles`}
        className="mini"
        disabled={isDeleteButtonDisabled}
        onConfirm={() => onDeleteVehiclesClick(vehiclesToDelete)}
      />
      {isDeleteButtonDisabled && (
        <Popup
          position="top right"
          header="Deleting vehicles"
          wide
          content={
            <>
              <ul>
                <li>A company must be selected</li>
                <li>At least one vehicle must be selected</li>
                <li>Only vehicles belonging to the company can be selected</li>
              </ul>
            </>
          }
          hoverable
          trigger={<Icon circular name="question" />}
        />
      )}

      <VehiclesTable
        className="ui very basic padded sortable unstackable table"
        data={props.data.vehicles}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { value: <Checkbox onClick={onSelectAllVehicles} checked={selectedVehicles.isSelectAllEnabled} /> },
            { keyPath: 'vehicle_id', value: 'ID' },
            { value: 'License plate' },
            { value: 'Company' },
            { value: 'Created at' },
            { value: 'Last status at' },
            { value: 'Tours' },
            { value: 'Integrations' },
          ],
        }}
        defaultSortBy={{ keyPath: 'vehicle_id', value: 'ASC' }}
        emptyStatePlaceholder={emptyTableState(
          <>
            <span>
              No vehicles
              {props.search !== undefined && <React.Fragment> matching &quot;{props.search}&quot;</React.Fragment>}
              {props.company !== undefined && props.company.company_name !== undefined && (
                <React.Fragment>
                  {' for '}
                  <NavLink to={Routes.Company.generatePath({ company_id: props.company.company_id })}>
                    {props.company.company_name}
                  </NavLink>
                </React.Fragment>
              )}
            </span>
            <p>
              <em>This vehicle may have been deleted:</em>{' '}
              <a href={ExternalRoutes.deletedVehiclesDashboard} target="_blank" rel="noreferrer">
                Deleted Vehicles Dashboard
              </a>
            </p>
          </>,
        )}>
        {({ row }) => {
          const { data } = row;
          const statusTimestampString = data.status?.timestamp ?? null;
          const statusTimestamp = statusTimestampString !== null ? new Date(statusTimestampString) : null;
          const now = new Date();

          const statusIsFresh = statusTimestamp !== null && differenceInMinutes(now, statusTimestamp) < 30;
          const statusIsStale =
            statusTimestamp !== null && !statusIsFresh && differenceInHours(now, statusTimestamp) < 24;
          const statusIsOld = statusTimestamp !== null && !statusIsFresh && !statusIsStale;
          const isMobile = data.vehicleTrackers.some(isMobileTracker);

          const isOwner = isOwnedByCompany(data, selectedCompanyId);

          return (
            <tr key={data.vehicle_id}>
              <td>
                <Checkbox
                  disabled={!isOwner}
                  checked={selectedVehicles.vehicles[data.vehicle_id] ?? false}
                  onClick={() => {
                    if (!isOwner) {
                      return;
                    }

                    onSelectVehicle(data.vehicle_id);
                  }}
                />
              </td>
              <td>{data.vehicle_id}</td>
              <td>
                {isMobile && (
                  <Popup
                    content="Linked to Sixfold mobile app"
                    position="top center"
                    trigger={<Icon name="mobile alternate" size="large" style={{ display: 'inline' }} />}
                  />
                )}
                <NavLink to={Routes.Vehicle.generatePath({ vehicle_id: data.vehicle_id })}>
                  {data.license_plate_number}
                </NavLink>
              </td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id: data.company.company_id })}>
                  {data.company.company_name}
                </NavLink>
              </td>
              <td>
                <FormattedDate date={data.created_at} />
              </td>
              <td
                className={classnames({
                  ['vehicle_status_timestamp']: true,
                  ['fresh']: statusIsFresh,
                  ['outdated']: statusIsStale,
                  ['old']: statusIsOld,
                })}>
                <FormattedDate date={statusTimestamp} />
              </td>
              <td>
                <NavLink
                  to={`${Routes.Tours.generatePath({})}?vehicle_id=${data.vehicle_id}&carrier_id=${
                    data.company.company_id
                  }`}>
                  Show
                </NavLink>
              </td>
              <td>
                <div>
                  {data.telematicsIntegrations.length === 0 && '-'}
                  {data.telematicsIntegrations.map(({ telematics_integration_id }, idx) => (
                    <React.Fragment key={telematics_integration_id}>
                      {idx > 0 && ', '}
                      <NavLink
                        to={Routes.CompanyTelematicsIntegration.generatePath({
                          company_id: data.company.company_id,
                          telematics_integration_id,
                        })}>
                        #{telematics_integration_id}
                      </NavLink>
                    </React.Fragment>
                  ))}
                </div>
              </td>
            </tr>
          );
        }}
      </VehiclesTable>
    </>
  );
};
