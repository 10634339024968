import { createQueryString, getQueryParameters } from '@sixfold/query-string';
import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { TIMELINE_TIMESTAMP_QS } from '../../map/components/map_slider';

interface TimestampLinkProps {
  timestamp: string | null;
}

type Props = TimestampLinkProps & RouteComponentProps<object>;

const TimestampLinkComponent = ({ timestamp, history }: Props) => {
  const { location } = history;

  const existingQueryParameters = getQueryParameters(location.search);

  const timestampUrl = `${location.pathname}?${createQueryString({
    ...existingQueryParameters,
    [TIMELINE_TIMESTAMP_QS]: timestamp,
  })}`;
  return (
    <NavLink to={timestampUrl}>
      <FormattedDate date={timestamp} />
    </NavLink>
  );
};

export const TimestampLink = withRouter(TimestampLinkComponent);
