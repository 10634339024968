import { BackofficeScope } from '@sixfold/session-interface';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Input } from 'semantic-ui-react';

import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  CompanyTypeInput,
  UnifiedCompanyOnboardingStatus,
  UnifiedCompanyOnboardingStuckReason,
} from '../../lib/graphql';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';

export type CompanyFilterInput = CompanyTypeInput & {
  text_search?: string;
  tag_name?: string;
  onboarding_status?: UnifiedCompanyOnboardingStatus;
  onboarding_stuck_reason?: UnifiedCompanyOnboardingStuckReason;
};

export interface CompanyFiltersProps {
  activeFilters: {
    textSearch?: string;
    tagName?: string;
    type: {
      is_carrier?: boolean;
      is_shipper?: boolean;
      is_paying_customer?: boolean;
      is_test?: boolean;
      is_pilot?: boolean;
      is_pending_signup?: boolean;
    };
    onboardingStatus?: UnifiedCompanyOnboardingStatus;
    onboardingStuckReason?: UnifiedCompanyOnboardingStuckReason;
  };
  onFilterToggle: (filter: { name: keyof CompanyFilterInput; value: string | undefined }) => void;
  onClearFilters: () => void;
  availableTags: { name: string }[];
}

export const CompanyFilters: React.FC<CompanyFiltersProps> = (props) => {
  const { textSearch, onboardingStatus, onboardingStuckReason, tagName } = props.activeFilters;
  const { activeFilters, onFilterToggle, onClearFilters, availableTags } = props;
  const { is_shipper, is_carrier, is_pilot, is_test, is_paying_customer, is_pending_signup } = activeFilters.type;
  const isAdmin = useIsAdmin();
  const hasCompanyEditPermission = useHasScopes([BackofficeScope.companyWriteCompany]);
  const isPrivilegedUser = isAdmin || hasCompanyEditPermission;
  const tagOptions = availableTags.map((tag) => ({ key: tag.name, value: tag.name, text: tag.name }));

  return (
    <div className="ui form">
      <h2 className="table__header m8__horizontal">
        <span>Companies</span>
        {isPrivilegedUser && (
          <NavLink to={Routes.CompanyCreate.generatePath({})} className="ui button primary">
            Add
          </NavLink>
        )}
      </h2>
      <div className="six fields">
        <div className="three wide field">
          <Input
            className="tiny"
            placeholder="Company search..."
            value={textSearch !== undefined ? textSearch : ''}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'text_search', value: value === '' ? undefined : value });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Shipper"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Shipper', value: '' },
              { text: 'Is a shipper', value: 'true' },
              { text: 'Not a shipper', value: 'false' },
            ]}
            value={is_shipper === undefined ? '' : is_shipper.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_shipper', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Carrier"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Carrier', value: '' },
              { text: 'Is a carrier', value: 'true' },
              { text: 'Not a carrier', value: 'false' },
            ]}
            value={is_carrier === undefined ? '' : is_carrier.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_carrier', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Test"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Test', value: '' },
              { text: 'Is test', value: 'true' },
              { text: 'Is not test', value: 'false' },
            ]}
            value={is_test === undefined ? '' : is_test.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_test', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Paying"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Paying', value: '' },
              { text: 'Is paying', value: 'true' },
              { text: 'Is not paying', value: 'false' },
            ]}
            value={is_paying_customer === undefined ? '' : is_paying_customer.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_paying_customer', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Pilot"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Pilot', value: '' },
              { text: 'Is a pilot', value: 'true' },
              { text: 'Is not a pilot', value: 'false' },
            ]}
            value={is_pilot === undefined ? '' : is_pilot.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_pilot', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
      </div>
      <div className="five fields">
        <div className="three wide field">
          <Dropdown
            placeholder="Reason"
            className="tiny"
            selection
            compact
            disabled={
              onboardingStatus !== undefined && onboardingStatus !== UnifiedCompanyOnboardingStatus.ONBOARDING_STUCK
            }
            options={[
              { text: 'Reason', value: '' },
              ...Object.keys(UnifiedCompanyOnboardingStuckReason).map((key) => ({
                text: beautifyString(UnifiedCompanyOnboardingStuckReason[key]),
                value: UnifiedCompanyOnboardingStuckReason[key].toLowerCase(),
              })),
            ]}
            value={onboardingStuckReason?.toLowerCase() ?? ''}
            onChange={(_e, { value }) => {
              onFilterToggle({
                name: 'onboarding_stuck_reason',
                value: value === '' ? undefined : value?.toString(),
              });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Status"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Status', value: '' },
              ...Object.keys(UnifiedCompanyOnboardingStatus).map((key) => ({
                text: beautifyString(UnifiedCompanyOnboardingStatus[key]),
                value: UnifiedCompanyOnboardingStatus[key].toLowerCase(),
              })),
            ]}
            value={onboardingStatus?.toLowerCase() ?? ''}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'onboarding_status', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Signup status"
            className="tiny"
            selection
            compact
            options={[
              { text: 'Signup status', value: '' },
              { text: 'Is pending signup', value: 'true' },
              { text: 'Is not pending signup', value: 'false' },
            ]}
            value={is_pending_signup === undefined ? '' : is_pending_signup.toString()}
            onChange={(_e, { value }) => {
              onFilterToggle({ name: 'is_pending_signup', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="two wide field">
          <Dropdown
            placeholder="Tags"
            className="tiny"
            selection
            search
            compact
            options={[{ text: 'Tags', value: '' }, ...tagOptions]}
            value={tagName === undefined ? '' : tagName.toString()}
            onChange={(_e, { value }) => {
              console.log(value);
              onFilterToggle({ name: 'tag_name', value: value === '' ? undefined : value?.toString() });
            }}
          />
        </div>
        <div className="one wide field">
          {(is_carrier !== undefined ||
            is_shipper !== undefined ||
            is_test !== undefined ||
            is_pilot !== undefined ||
            is_paying_customer !== undefined ||
            textSearch !== undefined ||
            tagName !== undefined ||
            onboardingStatus !== undefined ||
            onboardingStuckReason !== undefined) && <Button onClick={onClearFilters}>Clear</Button>}
        </div>
      </div>
    </div>
  );
};
