import { Stack, Heading, Box, Input } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import { updateQueryString, OptionalString, parseQueryString } from '@sixfold/query-string';
import { BackofficeScope } from '@sixfold/session-interface';
import { isNil } from '@sixfold/typed-primitives';
import React, { useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';

import { ManageVehicleModal } from './manage_vehicle_modal';
import { ManagedVehicleList, ManagedVehicleListProps } from './managed_vehicle_list';
import { InfiniteScroll } from '../../components/infinitescroll';
import { useIsAdmin, useHasScopes } from '../../lib/authorization';
import {
  ManagedVehicleListQuery,
  ManagedVehicleListQueryVariables,
  loadMoreFromConnection,
  getNodes,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import { managedVehiclesQuery } from '../../vehicle/graphql';

class LoadingContainer extends Loader<
  ManagedVehicleListQuery,
  Omit<ManagedVehicleListProps, 'telematicsIntegrationId' | 'hasEditPermission'>,
  ManagedVehicleListQueryVariables
> {}

class ManagedVehicleListContainerQuery extends Query<ManagedVehicleListQuery, ManagedVehicleListQueryVariables> {}

type Props = {
  telematics_integration_id: string;
};

export const ManagedVehicleListContainer: React.FunctionComponent<Props> = ({ telematics_integration_id }) => {
  const location = useLocation();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const hasCompanyWritePermission = useHasScopes([BackofficeScope.companyWriteVehicles]);
  const hasEditPermission = isAdmin || hasCompanyWritePermission;

  const { license_plate } = parseQueryString({
    queryParametersToParse: {
      license_plate: OptionalString,
    },
    queryString: location.search,
  });
  const [licensePlate, setLicensePlate] = useState<string | undefined>(license_plate);
  const debouncedLicensePlate = useDebounce(licensePlate);

  const handleFilterToggle = React.useCallback(
    (filterType: string, filterValue?: string) => {
      return history.push(
        `${location.pathname}?${updateQueryString(location.search, {
          param: filterType,
          value: filterValue,
        })}`,
      );
    },
    [history, location.pathname, location.search],
  );

  useEffect(() => {
    const value = debouncedLicensePlate !== null ? debouncedLicensePlate : undefined;
    handleFilterToggle('license_plate', value);
  }, [debouncedLicensePlate, handleFilterToggle]);

  const isValidLicensePlateSearch = !isNil(debouncedLicensePlate) && debouncedLicensePlate.trim().length >= 2;

  return (
    <Box>
      <Heading>Vehicles</Heading>
      <Stack justifyContent="space-between" alignItems="center">
        <Box paddingBlockStart="medium" paddingBlockEnd="medium">
          <Input
            name="license_plate"
            value={licensePlate}
            onChange={(e) => {
              setLicensePlate(e.value);
            }}
            label="License plate"
            labelPosition="inline"
          />
        </Box>
        {hasEditPermission && (
          <ManageVehicleModal telematicsIntegrationId={telematics_integration_id} trigger="Add vehicle" />
        )}
      </Stack>
      <ManagedVehicleListContainerQuery
        query={managedVehiclesQuery}
        variables={{
          integrationIds: [telematics_integration_id],
          licensePlateSearch: isValidLicensePlateSearch ? debouncedLicensePlate : undefined,
          limit: PAGINATION_PAGE_SIZE,
        }}>
        {(result) => (
          <LoadingContainer
            result={result}
            mapData={({ data }) => ({
              vehicles: getNodes(data.vehicles),
            })}>
            {(data) => (
              <InfiniteScroll
                triggerOnWindow={true}
                loadMoreEntries={() => loadMoreFromConnection(managedVehiclesQuery, result, ['vehicles'])}>
                <ManagedVehicleList
                  hasEditPermission={hasEditPermission}
                  vehicles={data.vehicles}
                  telematicsIntegrationId={telematics_integration_id}
                />
              </InfiniteScroll>
            )}
          </LoadingContainer>
        )}
      </ManagedVehicleListContainerQuery>
    </Box>
  );
};
