import { CompanyProfile } from '../company';
import {
  DateTime,
  GraphQLJSON,
  VehicleTrackerType,
  VehicleTemperatureEventLevel,
  VehicleTemperatureSource,
} from '../lib/graphql';

export interface Vehicle {
  vehicle_id: string;
  license_plate_number: string | null;
  inGlobalPool?: boolean | null;
  company?: {
    company_id: string;
    company_name: string | null;
  } | null;
  status?: VehicleStatus | null;
  latestVehicleTemperatures?: LatestVehicleTemperature[] | null;
}

export type VehicleWithLocation = Vehicle & {
  status: { latitude: number; longitude: number; heading: number; timestamp: string | null };
};

export interface VehicleHistory {
  lat: number;
  lng: number;
  hdg: number | null;
  timestamp: string;
  modeChangesLeft: number | null;
  vehicleType: string | null;
  source?: string | null;
  type?: string | null;
  created_at?: string | null;
  received_at?: string | null;
  vehicleTracker?: VehicleTracker | null;
  company?: CompanyProfile | null;
}

export type VehicleStatus = {
  latitude: number | null;
  longitude: number | null;
  heading: number | null;
  timestamp: string | null;
};

export enum BreakType {
  rest = 'Rest',
  weekendBreak = 'Weekend Break',
  break = 'Break',
}

export enum VehicleType {
  TRACTOR,
  TRAILER,
  VESSEL,
  BARGE,
  RAIL,
  TERMINAL,
  UNKNOWN,
}

export interface VehicleBreakHistory {
  breakId: string;
  type: BreakType;
  durationInHours: number;
  formattedDuration: string;
  from: DateTime;
  to: DateTime | null;
  center: {
    lat: number;
    lng: number;
  };
}

export type VehicleTracker = MobileVehicleTracker | FMSVehicleTracker;

export interface MobileVehicleTracker {
  vehicleTrackerId: string;
  trackerType: string;
  mobileRemoteId: string | null;
  phoneNumber: string | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
  trackerData?: GraphQLJSON | null;
}

export interface FMSVehicleTracker {
  vehicleTrackerId: string;
  fmsRemoteId: string;
  trackerType: VehicleTrackerType;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
  trackerData?: GraphQLJSON | null;
  telematicsIntegration: {
    telematicsIntegrationId: string;
    telematicsProvider: {
      telematicsProviderName: string;
    };
  } | null;
}

export interface VehicleTemperatureVisibilitySource {
  eventLevel: VehicleTemperatureEventLevel;
  primaryEventSource: VehicleTemperatureSource;
  eventOrigin?: string | null;
}

export interface VehicleTemperatureSensorReading {
  compartmentId: string;
  sensorId: string;
  temperatureCelsius: number;
}

export interface LatestVehicleTemperature {
  reeferId: string;
  timestamp: string;
  visibilitySource: VehicleTemperatureVisibilitySource;
  sensorReadings: VehicleTemperatureSensorReading[];
}
