import { notNil } from '@sixfold/typed-primitives';
import { DocumentNode } from 'graphql';
import { isNil, path, assocPath } from 'ramda';
import { QueryResult } from 'react-apollo';

// prettier-ignore
type Connection<T> = {
  __typename: string;
  edges?: ({
    node: T | null;
  } | null)[] | null;
  pageInfo?: {
    hasNextPage: boolean | null;
    endCursor: string | null;
  } | null;
} | null;

export function notEmpty<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined;
}

export function getNodes<T>(items: Connection<T> | undefined): T[] {
  const edges = (notNil(items) ? items.edges : []) ?? [];
  return edges.map((edge) => edge?.node).filter(notEmpty);
}

export const loadMoreFromConnection = <TData, TVariables>(
  query: DocumentNode,
  result: QueryResult<TData, TVariables>,
  connectionKeyPath: string[],
) => {
  const connection = path(connectionKeyPath, result.data) as Connection<TData>;

  if (
    result.data === undefined ||
    isNil(result.fetchMore) ||
    isNil(connection) ||
    isNil(connection.pageInfo) ||
    isNil(connection.pageInfo.hasNextPage)
  ) {
    return Promise.reject('Invalid input connection');
  }

  if (!connection.pageInfo.hasNextPage) {
    return;
  }

  return result.fetchMore({
    query,
    variables: Object.assign({}, result.variables, {
      cursor: connection.pageInfo.endCursor,
    }),
    updateQuery: (previousResult: TData, { fetchMoreResult }: { fetchMoreResult?: TData }) => {
      if (fetchMoreResult === undefined) {
        return previousResult;
      }

      const newConnection = (path(connectionKeyPath, fetchMoreResult) ?? {}) as Connection<TData>;

      if (isNil(newConnection)) {
        return previousResult;
      }

      const newEdges = newConnection.edges;
      const pageInfo = newConnection.pageInfo;

      const result = assocPath(
        connectionKeyPath,
        {
          ...newConnection,
          edges: notNil(newEdges) && notNil(connection.edges) ? [...connection.edges, ...newEdges] : null,
          pageInfo,
        },
        fetchMoreResult,
      );

      return result;
    },
  });
};
