import { gql } from 'graphql-tag';

export const userInCompanyDetailsFragment = gql`
  fragment UserInCompanyDetails on UserInCompanyProfile {
    user {
      user_id
      first_name
      last_name
      email
      avatar_url
      deleted_at

      credentials {
        type
      }
    }

    metadata {
      persona
    }

    invitedByUser {
      user_id
      first_name
      last_name
      email
    }

    role
  }
`;

export const companyUsersListQuery = gql`
  ${userInCompanyDetailsFragment}

  query CompanyUsersList($company_id: String!, $first: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id
      users {
        users(first: $first, after: $cursor) {
          edges {
            node {
              ...UserInCompanyDetails
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const companyUserQuery = gql`
  ${userInCompanyDetailsFragment}

  query CompanyUser($company_id: String!, $userId: String!) {
    company(company_id: $company_id) {
      company_id
      user(userId: $userId) {
        ...UserInCompanyDetails
      }
    }
  }
`;
