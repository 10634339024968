import { Button, Stack } from '@sixfold/common-ui';
import { BackofficeScope } from '@sixfold/session-interface';
import { isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { MutationFn } from 'react-apollo';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

import { CompanyForm } from './company_form';
import { CompanyIdentifierAddModal } from './company_identifier_add_modal';
import { CompanyIdentifierModifyModal } from './company_identifier_modify_modal';
import { getCompanyKafkaQueryTimespan, KafkaContextProvider } from '../../components/bigquery/common/kafka_context';
import { CompanyKafkaTab } from '../../components/bigquery/company_kafka/company_kafka_tab';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { TagsInput } from '../../components/tags_input';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CompanyDetailsQuery, UpdateCompanyMutation, UpdateCompanyMutationVariables } from '../../lib/graphql';
import { shipmentsViewUrl } from '../../lib/util/url';
import { MetricsContainer } from '../../metrics/containers/metrics_container';
import { PlatformContainer } from '../../platform/containers/platform_container';
import { PlatformListContainer } from '../../platform/containers/platform_list_container';
import { RelationshipContainer } from '../../relationships/containers/relationship_container';
import { RelationshipsContainer } from '../../relationships/containers/relationships_container';
import { Routes } from '../../routes';
import { CreateIntegrationContainer } from '../../telematic_integration/containers/create_integration_container';
import { IntegrationContainer } from '../../telematic_integration/containers/integration_container';
import { CompanyUserGroupContainer } from '../../user_group_on_platform/containers/company_user_group_container';
import { CompanyUserGroupsContainer } from '../../user_group_on_platform/containers/company_user_groups_container';
import { UserInCompanyContainer, UserInCompanyListContainer } from '../../user_in_company/containers';
import { VehicleGrantListContainer } from '../../vehicle_grant/containers/vehicle_grant_list_container';
import { CompanyAssetsContainer } from '../containers/company_assets_container';
import { CompanyConfigurationContainer } from '../containers/company_configuration_container';
import { CompanyDashboardsContainer } from '../containers/company_dashboard_container';
import { CompanyFeatureFlagsContainer } from '../containers/company_feature_flags_container';
import { CompanyIntegrationsContainer } from '../containers/company_integrations';
import { CompanyOnboardingContainer } from '../containers/company_onboarding_container';
import { mapIdentifierType } from '../utils';

export interface CompanyViewDataProps {
  data: {
    company?: CompanyDetailsQuery['company'] | null;
    availableTags: CompanyDetailsQuery['availableCompanyTags'];
    identifiers: CompanyIdentifierProps[];
  };
}

type TagMutation = (input: { variables: { input: { companyId: string; tagName: string } } }) => void;

interface CompanyViewProps {
  updateCompany: MutationFn<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
  onAddCompanyTag: TagMutation;
  onRemoveCompanyTag: TagMutation;
}

export const CompanyView: React.FC<CompanyViewDataProps & CompanyViewProps> = ({
  data: { company, availableTags, identifiers },
  updateCompany,
  onAddCompanyTag,
  onRemoveCompanyTag,
}) => {
  const isAdmin = useIsAdmin();
  const hasCompanyEditPermission = useHasScopes([BackofficeScope.companyWriteCompany]);
  const isPrivilegedUser = isAdmin || hasCompanyEditPermission;

  if (isNil(company)) {
    return <div>Company not found</div>;
  }

  const {
    company_id,
    company_name,
    is_carrier,
    is_shipper,
    is_pilot,
    is_paying_customer,
    is_test,
    fms_integrated_at,
    isPendingSignup,
    tags,
    signupCompletedAt,
  } = company;

  const { initialFromDate, initialToDate } = getCompanyKafkaQueryTimespan();
  return (
    <React.Fragment>
      <Route
        path={Routes.CompanySupportedRoutes.routerPath}
        render={() => {
          return (
            <div className="company">
              <h1 className="table__header">
                <span>{`#${company_id} ${company_name}`}</span>
                {isPrivilegedUser && (
                  <NavLink to={Routes.CompanyEdit.generatePath({ company_id })} className="ui button primary tiny">
                    Edit
                  </NavLink>
                )}
                <a
                  className="ui button primary tiny"
                  target="_blank"
                  href={shipmentsViewUrl(company_id)}
                  rel="noreferrer">
                  Open Shipments UI
                </a>
              </h1>
              <Stack justifyContent="space-between" className="company__metadata-container">
                <Stack direction="row" justifyContent="center">
                  <div className="company__flags">
                    {isPendingSignup !== null && isPendingSignup && (
                      <div className="ui label tiny red">Pending signup</div>
                    )}
                    {is_carrier !== null && is_carrier && <div className="ui label blue tiny">Carrier</div>}
                    {is_shipper !== null && is_shipper && <div className="ui label green tiny">Shipper</div>}
                    {is_pilot !== null && is_pilot && <div className="ui label teal tiny">Pilot</div>}
                    {is_paying_customer !== null && is_paying_customer && (
                      <div className="ui label purple tiny">Paying</div>
                    )}
                    {is_test !== null && is_test && <div className="ui label yellow tiny">Test</div>}
                    {fms_integrated_at !== null && (
                      <Popup
                        trigger={
                          <div className="ui label tiny">
                            FMS integrated at <FormattedDate date={fms_integrated_at} />
                          </div>
                        }
                        on="hover">
                        Moment when the company first got vehicles over FMS integration OR when the first vehicle
                        tracker was created for it
                      </Popup>
                    )}
                    {signupCompletedAt !== null && (
                      <Popup
                        trigger={
                          <div className="ui label tiny">
                            Signup completed at <FormattedDate date={signupCompletedAt} />
                          </div>
                        }
                        on="hover">
                        Moment when the company completed the signup process - consented to signing up to our platform
                      </Popup>
                    )}
                  </div>
                  <div className="company__tags">
                    <TagsInput
                      tags={tags}
                      addTag={(tagName) =>
                        onAddCompanyTag({
                          variables: {
                            input: {
                              companyId: company.company_id,
                              tagName,
                            },
                          },
                        })
                      }
                      removeTag={(tagName) =>
                        onRemoveCompanyTag({
                          variables: {
                            input: {
                              companyId: company.company_id,
                              tagName,
                            },
                          },
                        })
                      }
                      availableTags={availableTags}
                    />
                  </div>
                </Stack>

                <CompanyIdentifiers identifiers={identifiers} companyId={company.company_id} />
              </Stack>

              <div className="ui pointing menu submenu">
                <NavLink to={Routes.CompanyUsers.generatePath({ company_id })} className="item">
                  Users
                </NavLink>
                <NavLink to={Routes.CompanyTelematicsIntegrations.generatePath({ company_id })} className="item">
                  Integrations
                </NavLink>
                <NavLink to={Routes.CompanyPlatforms.generatePath({ company_id })} className="item">
                  Platforms
                </NavLink>
                <NavLink to={Routes.CompanyCarrierPartners.generatePath({ company_id })} className="item">
                  Carriers
                </NavLink>
                <NavLink to={Routes.CompanyShipperPartners.generatePath({ company_id })} className="item">
                  Shippers
                </NavLink>
                <NavLink to={`${Routes.Vehicles.generatePath({})}?company_id=${company_id}`} className="item">
                  Vehicles
                </NavLink>
                <NavLink to={Routes.CompanyRelationships.generatePath({ company_id })} className="item">
                  Relationships
                </NavLink>
                <NavLink to={Routes.CompanyVehicleGrants.generatePath({ company_id })} className="item">
                  Grants
                </NavLink>
                {isPrivilegedUser && (
                  <NavLink to={Routes.CompanyAssets.generatePath({ company_id })} className="item">
                    Assets
                  </NavLink>
                )}
                <NavLink to={Routes.CompanyOnboarding.generatePath({ company_id })} className="item">
                  Onboarding
                </NavLink>
                <NavLink to={Routes.CompanyUserGroups.generatePath({ company_id })} className="item">
                  User groups
                </NavLink>
                <NavLink to={Routes.CompanyConfiguration.generatePath({ company_id })} className="item">
                  Configuration
                </NavLink>
                <NavLink to={Routes.CompanyFeatureFlags.generatePath({ company_id })} className="item">
                  Feature flags
                </NavLink>
                <NavLink to={Routes.CompanyDashboards.generatePath({ company_id })} className="item">
                  Dashboards
                </NavLink>
                <NavLink to={Routes.CompanyKafka.generatePath({ company_id })} className="item">
                  Kafka
                </NavLink>
              </div>
            </div>
          );
        }}
      />
      <KafkaContextProvider initialFromDate={initialFromDate} initialToDate={initialToDate}>
        <Switch>
          <Route
            path={Routes.CompanyUser.routerPath}
            render={(routeProps) => <UserInCompanyContainer {...routeProps} />}
          />
          <Route path={Routes.CompanyUsers.routerPath} component={UserInCompanyListContainer} />
          <Route path={Routes.CompanyTelematicsIntegrationCreate.routerPath} component={CreateIntegrationContainer} />
          <Route path={Routes.CompanyTelematicsIntegration.routerPath} component={IntegrationContainer} />
          <Route path={Routes.CompanyTelematicsIntegrations.routerPath} component={CompanyIntegrationsContainer} />
          <Route path={Routes.CompanyPlatform.routerPath} component={PlatformContainer} />
          <Route path={Routes.CompanyPlatforms.routerPath} component={PlatformListContainer} />
          <Route path={Routes.CompanyRelationship.routerPath} component={RelationshipContainer} />
          <Route path={Routes.CompanyRelationships.routerPath} component={RelationshipsContainer} />
          {isPrivilegedUser && <Route path={Routes.CompanyAssets.routerPath} component={CompanyAssetsContainer} />}
          <Route path={Routes.CompanyOnboarding.routerPath} component={CompanyOnboardingContainer} />
          <Route path={Routes.CompanyConfiguration.routerPath} component={CompanyConfigurationContainer} />
          <Route path={Routes.CompanyFeatureFlags.routerPath} component={CompanyFeatureFlagsContainer} />
          <Route path={Routes.CompanyDashboards.routerPath} component={CompanyDashboardsContainer} />
          <Route path={Routes.CompanyKafka.routerPath} component={CompanyKafkaTab} />
          <Route
            path={Routes.CompanyUserGroup.routerPath}
            render={(routeProps) => <CompanyUserGroupContainer {...routeProps} />}
          />
          <Route path={Routes.CompanyUserGroups.routerPath} component={CompanyUserGroupsContainer} />
          <Route
            path={Routes.CompanyVehicleGrants.routerPath}
            render={(routeProps) => <VehicleGrantListContainer company={company} {...routeProps} />}
          />
          <Route
            path={Routes.CompanyCarrierPartners.routerPath}
            render={(routeProps) => <MetricsContainer show="carriers" {...routeProps} />}
          />
          <Route
            path={Routes.CompanyShipperPartners.routerPath}
            render={(routeProps) => <MetricsContainer show="shippers" {...routeProps} />}
          />
          <Route
            path={Routes.CompanyEdit.routerPath}
            render={(routeProps) => (
              <CompanyForm
                data={{ company }}
                routeProps={{ goBack: routeProps.history.goBack }}
                updateCompany={updateCompany}
              />
            )}
          />
          <Redirect to={Routes.CompanyUsers.generatePath({ company_id })} />
        </Switch>
      </KafkaContextProvider>
    </React.Fragment>
  );
};

export const CompanyIdentifiers: React.FunctionComponent<{
  identifiers: CompanyIdentifierProps[];
  companyId: string;
}> = ({ identifiers, companyId }) => {
  return (
    <Stack justifyContent="center" columnGap="xsmall" className="id_labels">
      {identifiers.map((identifier, index) => (
        <CompanyIdentifier
          companyIdentifierId={identifier.companyIdentifierId}
          identifierType={identifier.identifierType}
          country={identifier.country}
          identifierValue={identifier.identifierValue}
          source={identifier.source}
          companyId={identifier.companyId}
          key={index}
        />
      ))}
      <CompanyIdentifierAddModal
        trigger={<Button type="button">{'\u2795'}</Button>}
        companyId={companyId}></CompanyIdentifierAddModal>
    </Stack>
  );
};

interface CompanyIdentifierProps {
  companyIdentifierId: string;
  identifierType: string;
  identifierValue: string;
  country: string | undefined;
  source: string;
  companyId: string;
}

export const CompanyIdentifier: React.FunctionComponent<CompanyIdentifierProps> = ({
  companyIdentifierId,
  identifierType,
  country,
  identifierValue,
  source,
  companyId,
}) => {
  return (
    <CompanyIdentifierModifyModal
      companyId={companyId}
      companyIdentifierId={companyIdentifierId}
      trigger={
        <Stack className="ui label gray tiny right aligned companyIdentifierLabel">
          {mapIdentifierType(identifierType)}: {identifierValue}
          <Stack justifyContent="space-between" className="smallerRow">
            <span className="country">{country}</span>
            <span>{source}</span>
          </Stack>
        </Stack>
      }></CompanyIdentifierModifyModal>
  );
};
