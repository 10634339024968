import { handeInputChangeEvent } from '@sixfold/form-component';
import React from 'react';

import { ConnectionParameterFieldType } from '../../lib/graphql';
import { getConnectionDataInputType } from '../lib';

interface Props {
  fieldType: ConnectionParameterFieldType;
  settingsValue: string;
  fieldName: string;
  disabled: boolean;
  defaultChecked: boolean;
  enumFieldValues: { value: string; display_name: string | null; is_default: boolean }[];
  onChange: (e: handeInputChangeEvent) => void;
  hasError: boolean;
}

export const IntegrationInputField: React.FunctionComponent<Props> = ({
  fieldType,
  settingsValue,
  fieldName,
  disabled,
  defaultChecked,
  enumFieldValues,
  onChange,
  hasError,
}) => {
  const backgroundColor = hasError ? 'rgba(255,0,0,0.1)' : 'transparent';

  switch (fieldType) {
    case ConnectionParameterFieldType.NUMBER:
    case ConnectionParameterFieldType.BOOLEAN: {
      return (
        <input
          onChange={onChange}
          value={settingsValue}
          name={fieldName}
          type={getConnectionDataInputType(fieldType)}
          disabled={disabled}
          defaultChecked={defaultChecked ? true : undefined}
          style={{ backgroundColor: `${backgroundColor}` }}
        />
      );
    }

    case ConnectionParameterFieldType.TEXT:
    case ConnectionParameterFieldType.URL:
    case ConnectionParameterFieldType.JSON: {
      return (
        <textarea
          onChange={onChange}
          value={settingsValue.toString()}
          name={fieldName}
          disabled={disabled}
          rows={1}
          style={{ backgroundColor: `${backgroundColor}` }}
        />
      );
    }
    case ConnectionParameterFieldType.ENUM: {
      return (
        <select
          className="ui fluid search dropdown"
          name={fieldName}
          value={settingsValue.toString()}
          onChange={onChange}
          disabled={false}
          style={{ backgroundColor: `${backgroundColor}` }}>
          {(enumFieldValues ?? []).map((value, idx) => {
            return (
              <option key={idx} value={value.value}>
                {value.display_name ?? value.value}
              </option>
            );
          })}
        </select>
      );
    }
  }
};
