import { Drawer, DrawerHeader, DrawerMain, Separator, Stack, Text, Box } from '@sixfold/common-ui';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { FeatureFlagAuditLogMetadata } from './feature_flag_audit_log_metadata';
import { FeatureFlagAuditLog, FeatureFlagMetadata, UserProfile } from '../../lib/graphql';
import { Routes } from '../../routes';
import { FormattedDate } from '../date_formatting/formatted_date';
import { InfiniteScroll } from '../infinitescroll';

export type FeatureFlagAuditLogEntry = Omit<FeatureFlagAuditLog, 'user' | 'companyId'> & {
  user: Pick<UserProfile, 'user_id' | 'first_name' | 'last_name'> | null;
};

type Props = {
  allFlags: (FeatureFlagMetadata | null)[];
  auditLog: FeatureFlagAuditLogEntry[];
  loadMoreAuditLogs: () => Promise<unknown> | undefined;
};

export const FeatureFlagAuditLogDrawer = ({ allFlags, auditLog, loadMoreAuditLogs }: Props) => {
  const getFeatureFlagName = (featureFlagId: string | null) => {
    const featureFlag = allFlags.find((flag) => flag?.feature_flag_id === featureFlagId);
    return featureFlag?.display_name;
  };

  const hasData = auditLog.length > 0;

  return (
    <Drawer trigger="Audit log" loading={false}>
      <DrawerHeader title="Feature flag audit log" />
      <DrawerMain padding="none">
        <InfiniteScroll
          triggerOnWindow={false}
          className="company__featureFlags-auditStackList"
          loadMoreEntries={loadMoreAuditLogs}>
          {hasData ? (
            auditLog.map((logItem) => (
              <Box key={logItem.featureFlagAuditLogId} paddingInline="small">
                <Stack direction="row" className="company__featureFlags-auditStackContainer">
                  <Stack direction="column" justifyContent="space-between" className="company__featureFlags-auditStack">
                    <Stack direction="row" justifyContent="space-between">
                      <Text size="medium">Created at</Text>
                      <Text prominence="subtle" size="medium">
                        <FormattedDate date={new Date(logItem.createdAt)} showTime={true} />
                      </Text>
                    </Stack>
                    <Stack direction="column">
                      {logItem.metadata.map((data, idx) => (
                        <FeatureFlagAuditLogMetadata
                          key={idx}
                          featureFlagName={getFeatureFlagName(logItem.featureFlagId)}
                          actionMetadata={data}
                        />
                      ))}
                      {logItem.user && (
                        <NavLink to={Routes.User.generatePath({ user_id: logItem.user.user_id })}>
                          <Text prominence="subtle" size="small">
                            #{logItem.user.user_id} - {logItem.user.first_name} {logItem.user.last_name}
                          </Text>
                        </NavLink>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Separator />
              </Box>
            ))
          ) : (
            <Stack justifyContent="center" alignItems="center">
              <Text>No audit log data available</Text>
            </Stack>
          )}
        </InfiniteScroll>
      </DrawerMain>
    </Drawer>
  );
};
