import { useNotifications } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import {
  CompanyAssetsQuery,
  CompanyAssetsQueryVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '../../lib/graphql';
import { CompanyAssetsView, CompanyAssetsViewProps } from '../components/company_assets';
import { companyAssetsQuery, updateCompanyMutation } from '../graphql';

class LoadingContainer extends Loader<CompanyAssetsQuery, CompanyAssetsViewProps, CompanyAssetsQueryVariables> {}
class CompanyContainerQuery extends Query<CompanyAssetsQuery, CompanyAssetsQueryVariables> {}
class CompanyContainerUpdateCompanyMutation extends Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {}

export const CompanyAssetsContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const { company_id } = props.match.params;
  const notify = useNotifications();

  return (
    <CompanyContainerQuery
      query={companyAssetsQuery}
      variables={{
        company_id: props.match.params.company_id,
      }}>
      {(result) => {
        return (
          <CompanyContainerUpdateCompanyMutation mutation={updateCompanyMutation}>
            {(updateCompany) => (
              <LoadingContainer
                result={result}
                mapData={({ data }) => ({
                  data: { company: data.company ?? null },
                  onAssetUpdate: async (file, asset) => {
                    const data = await updateCompany({
                      variables: {
                        company_id,
                        input: {
                          ...(asset === 'consignee_background' ? { consigneeBackground: { upload: file } } : {}),
                          ...(asset === 'consignee_logo' ? { consigneeLogo: { upload: file } } : {}),
                        },
                      },
                    });
                    notify.success({ title: 'Assets updated' });
                    return typeof data === 'object' && data.errors === undefined;
                  },
                })}>
                {({ data, onAssetUpdate }) => <CompanyAssetsView data={data} onAssetUpdate={onAssetUpdate} />}
              </LoadingContainer>
            )}
          </CompanyContainerUpdateCompanyMutation>
        );
      }}
    </CompanyContainerQuery>
  );
};
