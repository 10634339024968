import { ApolloClient } from 'apollo-client';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Button, Dropdown, Icon, Message, Popup } from 'semantic-ui-react';

import { kafkaMessagesQuery } from './graphql';
import { SORT_ORDER, useKafkaContext } from './kafka_context';
import { MessageData } from './messages';
import { Company } from '../../../company';
import { Tour } from '../../../tour/entities';
import { DateInput } from '../../date_input';

import styles from '../css/bigquery.module.css';

export interface BigQueryMessage {
  produced_at: string;
  message: string;
  message_type: string;
}

interface Props {
  entity: Tour | Company;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCompare: React.Dispatch<React.SetStateAction<number[]>>;
  messageOptions: MessageData[];
}

export const KafkaQueryInput: React.FunctionComponent<Props> = (props: Props) => {
  const {
    messageSelection,
    setMessageSelection,
    payloads,
    setPayloads,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    sortOrder,
    setSortOrder,
  } = useKafkaContext();
  const { entity, loading, setLoading, setCompare, messageOptions } = props;

  const timeZoneText = DateTime.now().toFormat("ZZZZZ (ZZ 'UTC')");

  const formatDate = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd');
  };

  const messageOptionsMap = new Map<string, MessageData>();
  messageOptions.forEach((messageOption) => {
    messageOptionsMap.set(messageOption.messageType, { ...messageOption });
  });

  const runQuery = async (client: ApolloClient<object>) => {
    try {
      setLoading(true);
      setCompare([]);
      setPayloads([]);

      const keys = [];
      const messageTypes = [];
      const topics = [];

      for (const selectedMessage of messageSelection) {
        const message = messageOptionsMap.get(selectedMessage);
        if (message) {
          keys.push(message.keyFn(entity));
          messageTypes.push(message.messageType);
          topics.push(message.topic);
        }
      }

      const unique = (arr: string[]) => arr.sort().filter((el, pos, array) => !pos || el !== array[pos - 1]);

      const result = await client.query({
        query: kafkaMessagesQuery,
        variables: {
          keys: unique(keys),
          messageTypes,
          from: fromDate.toISO(),
          to: toDate.toISO(),
          topics: unique(topics),
        },
      });

      const payloads =
        sortOrder === 'asc' ? [...result.data.bigQueryMessages] : [...result.data.bigQueryMessages].reverse();
      setPayloads(payloads);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ui form">
      <div className="field">
        <label>Message type</label>
        <Dropdown
          className="tiny"
          search
          selection
          options={messageOptions.map((messageOption) => ({
            text: messageOption.messageType,
            value: messageOption.messageType,
          }))}
          value={messageSelection}
          onChange={(_e, { value }) => {
            const messageTypes = value as string[];
            setMessageSelection(messageTypes);
          }}
          multiple={true}
        />
      </div>
      <div className={classnames('two fields', styles.bigquery__input__fields)}>
        <div className={classnames('field', styles.bigquery__input__field__from)}>
          <label>
            <span>
              From <Popup content={`From start of day (${timeZoneText})`} trigger={<Icon name="question circle" />} />
            </span>
          </label>
          <DateInput
            placeholder={'From'}
            value={formatDate(fromDate)}
            onChange={({ target: { value } }) => {
              const fromDate = DateTime.fromISO(value).startOf('day');
              if (fromDate) {
                setFromDate(fromDate);
              }
            }}
            min={formatDate(DateTime.now().minus({ months: 3 }))}
            max={formatDate(DateTime.now())}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
        <div className={classnames('field', styles.bigquery__input__field__to)}>
          <label>
            <span>
              To <Popup content={`To end of day (${timeZoneText})`} trigger={<Icon name="question circle" />} />
            </span>
          </label>
          <DateInput
            placeholder={'To'}
            value={formatDate(toDate)}
            onChange={({ target: { value } }) => {
              const toDate = DateTime.fromISO(value).endOf('day');
              if (toDate) {
                setToDate(toDate);
              }
            }}
            min={formatDate(fromDate)}
            max={formatDate(DateTime.now())}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
      </div>
      {fromDate > toDate && (
        <div className="field">
          <Message warning visible size="tiny">
            <Message.Header>&apos;From&apos; date must be before &apos;To&apos; date!</Message.Header>
          </Message>
        </div>
      )}
      {toDate.diff(fromDate, 'months').months > 1 && (
        <div className="field">
          <Message warning visible size="tiny">
            <Message.Header>Querying for a period longer than 1 month not allowed.</Message.Header>
          </Message>
        </div>
      )}
      <div className={styles.bigquery__input__query__bar}>
        <ApolloConsumer>
          {(client) => {
            return (
              <Button
                onClick={() => runQuery(client)}
                loading={loading}
                disabled={
                  loading ||
                  fromDate > toDate ||
                  toDate.diff(fromDate, 'months').months > 1 ||
                  messageSelection.length < 1
                }>
                Run query
              </Button>
            );
          }}
        </ApolloConsumer>
        <Dropdown
          className="tiny"
          selection
          compact
          options={[
            { text: 'asc', value: 'asc' },
            { text: 'desc', value: 'desc' },
          ]}
          value={sortOrder}
          onChange={(_e, { value }) => {
            if (value) {
              setSortOrder(value as SORT_ORDER);
              localStorage.setItem('kafka.sortOrder', value as string);
              const reversedPayloads = [...payloads.reverse()];
              setPayloads(reversedPayloads);

              setCompare([]);
            }
          }}
        />
      </div>
    </div>
  );
};
