import { useNotifications } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { companyIntegrationsListQuery } from '../../company/graphql';
import {
  getNodes,
  TelematicsProvidersSimpleListQuery,
  CreateTelematicsIntegrationMutation,
  CreateTelematicsIntegrationMutationVariables,
} from '../../lib/graphql';
import { CreateIntegrationViewDataProps, CreateIntegrationView } from '../components/create_integration';
import { telematicsProvidersSimpleListQuery, createTelematicsIntegrationMutation } from '../graphql';

class LoadingContainer extends Loader<TelematicsProvidersSimpleListQuery, CreateIntegrationViewDataProps, object> {}
class CreateIntegrationContainerQuery extends Query<TelematicsProvidersSimpleListQuery> {}
class CreateIntegrationContainerCreateIntegrationMutation extends Mutation<
  CreateTelematicsIntegrationMutation,
  CreateTelematicsIntegrationMutationVariables
> {}

export const CreateIntegrationContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const { company_id } = props.match.params;
  const notify = useNotifications();

  return (
    <CreateIntegrationContainerQuery query={telematicsProvidersSimpleListQuery}>
      {(result) => (
        <CreateIntegrationContainerCreateIntegrationMutation
          mutation={createTelematicsIntegrationMutation}
          refetchQueries={[
            {
              query: companyIntegrationsListQuery,
              variables: {
                company_id,
              },
            },
          ]}>
          {(createIntegration) => (
            <LoadingContainer
              result={result}
              mapData={({ data }) => ({
                data: { company_id, telematicsProviders: getNodes(data.telematicsProviders) },
              })}>
              {({ data }) => (
                <CreateIntegrationView
                  data={data}
                  createIntegration={async (input) => {
                    const response = await createIntegration(input);
                    notify.success({ title: 'Integration added' });
                    return response;
                  }}
                  routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                />
              )}
            </LoadingContainer>
          )}
        </CreateIntegrationContainerCreateIntegrationMutation>
      )}
    </CreateIntegrationContainerQuery>
  );
};
