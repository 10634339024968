import { notNil } from '@sixfold/typed-primitives';
import { default as DeckGL } from 'deck.gl';
import React from 'react';

import { createVehiclesLayer, createVehiclesHistoryLayer, createGhostVehicleLayer } from './vehicles_layer';
import { notEmpty } from '../../lib/graphql';
import { Viewport } from '../../map/components/map_viewport';
import { createVehicleBreaksLayer } from '../../tour/components/tour_layer';
import { Vehicle, VehicleHistory, VehicleBreakHistory, VehicleStatus } from '../entities';

interface OverlayVehicle extends Vehicle {
  history?: VehicleHistory[];
  breakHistory?: VehicleBreakHistory[];
  ghost?: VehicleHistory;
  status: VehicleStatus | null;
}

interface Props {
  vehicles: OverlayVehicle[];
  viewport: Viewport;
  onLayerClick(coordinates: { lat: number | null; lng: number | null; hdg?: number | null }): void;
}

export const VehiclesOverlay: React.StatelessComponent<Props> = ({ vehicles, viewport, onLayerClick }) => {
  const vehiclesLayer = createVehiclesLayer({
    vehicles,
    onClick: (vehicle) => onLayerClick({ lat: vehicle.status.latitude, lng: vehicle.status.longitude }),
  });
  const { pointLayer, pathLayer } = createVehiclesHistoryLayer({ vehicles, onClick: onLayerClick });

  const ghostLayer = vehicles
    .map((vehicle) => vehicle.ghost)
    .filter(notEmpty)
    .map((vehicle, idx) => createGhostVehicleLayer(vehicle, idx));

  const combinedBreakHistory = vehicles
    .map((vehicle) => vehicle.breakHistory)
    .filter(notEmpty)
    .reduce((memo, history) => {
      return [...memo, ...history];
    }, [] as VehicleBreakHistory[]);

  const { breakIconsLayer, breakLabelsLayer } = createVehicleBreaksLayer({
    vehicleBreaks: combinedBreakHistory,
    onBreakClick: onLayerClick,
  });

  return (
    <DeckGL
      {...viewport}
      layers={[ghostLayer, pathLayer, pointLayer, breakIconsLayer, breakLabelsLayer, vehiclesLayer].filter(notNil)}
    />
  );
};
