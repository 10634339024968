import { InputHTMLAttributes } from 'react';

import { ConnectionParameterFieldType, GraphQLJSON, TelematicsIntegrationQuery } from '../../lib/graphql';

export function getConnectionDataInputType(
  fieldType: ConnectionParameterFieldType,
): InputHTMLAttributes<HTMLInputElement>['type'] {
  switch (fieldType) {
    case ConnectionParameterFieldType.NUMBER:
      return 'number';
    case ConnectionParameterFieldType.BOOLEAN:
      return 'checkbox';
    case ConnectionParameterFieldType.ENUM:
      return 'enum';
    default:
      return 'text';
  }
}

export const getConnectionData = ({
  connectionParameters,
  formData,
  currentFormFieldKeyFn,
}: {
  connectionParameters: NonNullable<
    TelematicsIntegrationQuery['telematicsIntegration']
  >['telematicsProvider']['connectionParameters'];
  formData: GraphQLJSON;
  currentFormFieldKeyFn?: (formFieldKey: string) => string;
}) =>
  connectionParameters.reduce<GraphQLJSON>((memo, current) => {
    memo[current.fieldName] =
      currentFormFieldKeyFn !== undefined
        ? formData[currentFormFieldKeyFn(current.fieldName)]
        : formData[current.fieldName];
    return memo;
  }, {});
