import { Loader } from '@sixfold/loader-container';
import { OptionalBoolean, OptionalString, parseQueryString, updateQueryString } from '@sixfold/query-string';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  CompanyListQuery,
  CompanyListQueryVariables,
  getNodes,
  loadMoreFromConnection,
  PAGINATION_PAGE_SIZE,
  UnifiedCompanyOnboardingStatus,
  UnifiedCompanyOnboardingStuckReason,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import { CompanyFilterInput, CompanyFilters } from '../components/company_filters';
import { CompanyList, CompanyListDataProps } from '../components/company_list';
import { companiesQuery } from '../graphql';

class CompaniesListLoadingContainer extends Loader<CompanyListQuery, CompanyListDataProps, object> {}
class CompaniesListQuery extends Query<CompanyListQuery, CompanyListQueryVariables> {}

const CompaniesList: React.FC<{
  activeFilters: {
    textSearch: string | undefined;
    tagName: string | undefined;
    type: {
      is_carrier: boolean | undefined;
      is_shipper: boolean | undefined;
      is_test: boolean | undefined;
      is_paying_customer: boolean | undefined;
      is_pilot: boolean | undefined;
    };
    onboardingStatus: UnifiedCompanyOnboardingStatus | undefined;
    onboardingStuckReason: UnifiedCompanyOnboardingStuckReason | undefined;
  };
  children: Children<
    CompanyListDataProps & {
      result: QueryResult<CompanyListQuery, CompanyListQueryVariables>;
    }
  >;
  onFilterToggle: (filter: { name: keyof CompanyFilterInput; value: string | undefined }) => void;
  onClearFilters: () => void;
}> = ({ activeFilters, children, onFilterToggle, onClearFilters }) => {
  const debouncedSearchCompanyName = useDebounce(activeFilters.textSearch);

  return (
    <CompaniesListQuery
      fetchPolicy="cache-and-network"
      query={companiesQuery}
      variables={{
        limit: PAGINATION_PAGE_SIZE,
        type: activeFilters.type,
        textSearch: debouncedSearchCompanyName,
        tagName: activeFilters.tagName,
        onboardingStatus: activeFilters.onboardingStatus,
        onboardingStuckReason: activeFilters.onboardingStuckReason,
      }}>
      {(result) => (
        <React.Fragment>
          <CompanyFilters
            availableTags={result.data?.availableCompanyTags || []}
            activeFilters={activeFilters}
            onClearFilters={onClearFilters}
            onFilterToggle={onFilterToggle}
          />
          <CompaniesListLoadingContainer
            result={result}
            mapData={({ data }) => ({
              companies: getNodes(data.companies),
            })}
            options={{
              showLoadingPlaceholder: ({ networkStatus, loading }) => {
                const isFetchingMore = networkStatus === 3;
                return isFetchingMore ? false : loading;
              },
            }}>
            {({ companies }) => renderChildren(children, { companies, result })}
          </CompaniesListLoadingContainer>
        </React.Fragment>
      )}
    </CompaniesListQuery>
  );
};

export const CompanyListContainer = ({ location, history }: RouteComponentProps<object>) => {
  const {
    text_search: textSearch,
    tag_name: tagName,
    onboarding_status: onboardingStatus,
    onboarding_stuck_reason: onboardingStuckReason,
    ...type
  } = parseQueryString({
    queryString: location.search,
    queryParametersToParse: {
      is_carrier: OptionalBoolean,
      is_shipper: OptionalBoolean,
      is_test: OptionalBoolean,
      is_paying_customer: OptionalBoolean,
      is_pilot: OptionalBoolean,
      is_pending_signup: OptionalBoolean,
      text_search: OptionalString,
      tag_name: OptionalString,
      onboarding_status: OptionalString,
      onboarding_stuck_reason: OptionalString,
    },
  });

  return (
    <CompaniesList
      activeFilters={{
        textSearch,
        tagName,
        type,
        onboardingStatus: onboardingStatus?.toUpperCase() as UnifiedCompanyOnboardingStatus | undefined,
        onboardingStuckReason: onboardingStuckReason?.toUpperCase() as UnifiedCompanyOnboardingStuckReason | undefined,
      }}
      onClearFilters={() => history.push(location.pathname)}
      onFilterToggle={({ name, value }) =>
        history.push(
          `${location.pathname}?${updateQueryString(location.search, {
            param: name,
            value,
          })}`,
        )
      }>
      {({ companies, result: companiesResult }) => (
        <InfiniteScroll loadMoreEntries={() => loadMoreFromConnection(companiesQuery, companiesResult, ['companies'])}>
          <CompanyList companies={companies} />
        </InfiniteScroll>
      )}
    </CompaniesList>
  );
};
