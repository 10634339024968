import {
  updateQueryString,
  getQueryParameterValue,
  createQueryString,
  getQueryParameters,
} from '@sixfold/query-string';
import { notNil } from '@sixfold/typed-primitives';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import memoizeOne from 'memoize-one';
import { equals } from 'ramda';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { debounce } from 'throttle-debounce';

import { TimelineBoundaries, TimelinePosition, VehicleWithHistoryAndBreaks, KeyboardKey } from './entities';
import { MapSliderDebug } from './map_slider_debug';
import { TourTimelineHeader, VehicleTimelineHeader } from './map_slider_header';
import { MapSliderTimeline } from './map_slider_timeline';
import { prepareTimelineData, getIndexForTimestamp, getPositionHash } from './utils';
import { Event, ExternalEvent, Stop } from '../../../tour/entities';
import { VehicleHistory } from '../../../vehicle/entities';

interface SliderProps {
  timelineBoundaries: TimelineBoundaries;
  initialTimestamp: string | undefined;
  vehicles: VehicleWithHistoryAndBreaks[];
  tourEvents?: Event[];
  externalEvents?: ExternalEvent[];
  tourStops?: Stop[];
  children?: Children<{
    selectedPositions: VehicleHistory[];
  }>;
}

interface State {
  selectedIndex: number;
}

type Props = SliderProps & RouteComponentProps<object>;

export const HISTORY_SIMPLIFICATION_QS = 'history_simplification_tolerance';
export const TIMELINE_TIMESTAMP_QS = 'timestamp';
export const TIMELINE_START_TIME_QS = 'timeline_start';
export const TIMELINE_END_TIME_QS = 'timeline_end';

class MapSliderComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: this.getTimelineIndex(this.props.initialTimestamp) ?? 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!equals(prevProps.vehicles, this.props.vehicles)) {
      this.setState({
        selectedIndex: this.getTimelineIndex(this.props.initialTimestamp) ?? 0,
      });
    }

    if (!equals(prevProps.initialTimestamp, this.props.initialTimestamp)) {
      this.setState({
        selectedIndex: this.getTimelineIndex(this.props.initialTimestamp) ?? 0,
      });
    }
  }

  getTimelineIndex = (timestamp: string | undefined) => {
    const { tour } = this.constructTimelines();
    return getIndexForTimestamp(tour.timeline, timestamp);
  };

  handleArrowNavigation = (
    {
      currentIndex,
      keyPressed,
    }: {
      currentIndex: number;
      keyPressed: KeyboardKey.ARROW_LEFT | KeyboardKey.ARROW_RIGHT;
    },
    timeline: TimelinePosition[],
  ) => {
    const currentVehiclePosition = timeline[currentIndex].vehiclePosition;

    const currentVehiclePositionHash = getPositionHash(currentVehiclePosition);

    const timelinePortionToScan =
      keyPressed === KeyboardKey.ARROW_RIGHT
        ? timeline.slice(currentIndex, timeline.length)
        : timeline.slice(0, currentIndex + 1).reverse();

    const relativeIndex = Math.max(
      timelinePortionToScan.findIndex((timelinePosition) => {
        return (
          timelinePosition.vehiclePosition !== undefined &&
          currentVehiclePositionHash !== getPositionHash(timelinePosition.vehiclePosition)
        );
      }),
      1,
    );

    const nextIndex =
      keyPressed === KeyboardKey.ARROW_RIGHT ? currentIndex + relativeIndex : currentIndex - relativeIndex;

    return this.handleUpdateTimestamp(nextIndex, timeline);
  };

  handleUpdateTimestamp = (selectedIndex: number, timeline: TimelinePosition[]) => {
    const selectedPosition = timeline[selectedIndex];

    if (selectedPosition !== undefined) {
      this.handleDebouncedTimestampUpdate(selectedPosition.timestamp.toISOString());

      return this.setState({
        selectedIndex,
      });
    }
  };

  handleInputChange = (selectedIndex: number, timeline: TimelinePosition[]) => {
    this.handleUpdateTimestamp(selectedIndex, timeline);
  };

  handleDebouncedTimestampUpdate = debounce(100, (timestamp: string) => {
    const { location } = this.props.history;

    const existingQueryParameters = getQueryParameters(location.search);

    this.props.history.replace(
      `${location.pathname}?${createQueryString({
        ...existingQueryParameters,
        [TIMELINE_TIMESTAMP_QS]: timestamp,
      })}`,
    );
  });

  updateTimestampQueryString = (queryStringValue: string | undefined) => {
    this.props.history.push(
      `${location.pathname}?${updateQueryString(location.search, {
        param: HISTORY_SIMPLIFICATION_QS,
        value: queryStringValue,
      })}`,
    );
  };

  handleToggleHistorySimplification = () => {
    if (this.isHistorySimplificationEnabled()) {
      return this.updateTimestampQueryString('0');
    }
    this.updateTimestampQueryString(undefined);
  };

  handleUpdateTimelineBoundaries = ({ startTime, endTime }: { startTime: string; endTime: string }) => {
    const startTimeInUTC = new Date(startTime).toISOString();
    const endTimeInUTC = new Date(endTime).toISOString();

    const existingQueryParameters = getQueryParameters(this.props.history.location.search);
    this.props.history.push(
      `${location.pathname}?${createQueryString({
        ...existingQueryParameters,
        [TIMELINE_START_TIME_QS]: startTimeInUTC,
        [TIMELINE_END_TIME_QS]: endTimeInUTC,
      })}`,
    );
  };

  isHistorySimplificationEnabled = () => {
    return getQueryParameterValue(this.props.location.search, HISTORY_SIMPLIFICATION_QS) === undefined;
  };

  memoizedPrepareMapSliderData = memoizeOne(prepareTimelineData, (newArgs, lastArgs) => equals(newArgs, lastArgs));

  constructTimelines = () =>
    this.memoizedPrepareMapSliderData(
      this.props.vehicles,
      this.props.tourEvents ?? [],
      this.props.externalEvents ?? [],
      this.props.tourStops ?? [],
      this.props.timelineBoundaries,
    );

  render() {
    const { selectedIndex } = this.state;
    const timelines = this.constructTimelines();
    const rangeInputOptions = { value: selectedIndex, min: 0, step: 1, max: timelines.tour.timeline.length - 1 };
    const selectedPositions = timelines.vehicles
      .map(({ timeline }) => {
        return timeline[selectedIndex];
      })
      .filter(notNil);

    return (
      <React.Fragment>
        <div className="map-slider__container">
          <MapSliderDebug
            timeline={this.props.timelineBoundaries}
            isHistorySimplificationEnabled={this.isHistorySimplificationEnabled()}
            onToggleHistorySimplification={this.handleToggleHistorySimplification}
            onUpdateTimelineBoundaries={this.handleUpdateTimelineBoundaries}>
            {({ startTime, endTime }) => (
              <React.Fragment>
                <div className="map-slider-tour">
                  <TourTimelineHeader
                    timeline={{ startTime, endTime }}
                    selectedPosition={timelines.tour.timeline[selectedIndex]}
                  />
                  <MapSliderTimeline
                    sliderTicks={timelines.tour.timelineTicks}
                    onChange={(selectedIndex) => this.handleInputChange(selectedIndex, timelines.tour.timeline)}
                    onTickClick={(timelineIndex) => this.handleInputChange(timelineIndex, timelines.tour.timeline)}
                    range={rangeInputOptions}
                  />
                </div>
                {timelines.vehicles.map(
                  ({ timelineTicks, vehicleId, licensePlateNumber, timeline, isMultiModal }, idx) => {
                    const selectedPosition = selectedPositions[idx];

                    return (
                      <React.Fragment key={idx}>
                        <div className="map-slider-vehicle">
                          <VehicleTimelineHeader
                            currentTimestamp={selectedPosition?.timestamp}
                            vehicle={{
                              licensePlateNumber,
                              vehicleId,
                              position: selectedPosition?.vehiclePosition,
                              isMultiModal,
                            }}
                            timeline={{ startTime, endTime }}
                          />
                          <MapSliderTimeline
                            vehicleIndex={idx}
                            sliderTicks={timelineTicks}
                            onTickClick={(timelineIndex) => this.handleInputChange(timelineIndex, timeline)}
                            onChange={(selectedIndex) => this.handleInputChange(selectedIndex, timeline)}
                            onArrowNavigation={(event) => this.handleArrowNavigation(event, timeline)}
                            range={rangeInputOptions}
                          />
                        </div>
                      </React.Fragment>
                    );
                  },
                )}
              </React.Fragment>
            )}
          </MapSliderDebug>
        </div>
        {renderChildren(this.props.children, {
          selectedPositions: notNil(selectedPositions)
            ? selectedPositions.map(({ vehiclePosition }) => vehiclePosition)
            : [],
        })}
      </React.Fragment>
    );
  }
}

export const MapSlider = withRouter(MapSliderComponent);
