import { Form as FormFactory } from '@sixfold/form-component';
import { BackofficeScope } from '@sixfold/session-interface';
import { History } from 'history';
import React from 'react';

import { CompanyPlatform } from '../../company/entities';
import { Button } from '../../components/button';
import { ConfirmButton } from '../../components/confirm_button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { Platform } from '../entities';

export interface PlatformViewDataProps {
  data: {
    companyPlatform: CompanyPlatform | null;
    companyPlatforms: CompanyPlatform[];
    platforms: Platform[];
  };
}
export interface PlatformViewProps {
  routeProps: {
    goBack: History['goBack'];
    company_id: string;
    platform_id: string;
  };
  isNewPlatform: boolean;
  createCompanyPlatform: (input: {
    company_id: string;
    platform_id: string;
    company_id_on_platform: string;
  }) => Promise<void>;
  deleteCompanyPlatform: (input: { company_id: string; platform_id: string }) => Promise<void>;
}

const getAvailablePlatforms = (platforms: Platform[], companyPlatforms: CompanyPlatform[]) => {
  const companyPlatformIds = companyPlatforms.map((companyPlatform) => companyPlatform.platform_id);

  return platforms
    .filter((platform) => !companyPlatformIds.some((companyPlatformId) => companyPlatformId === platform.platform_id))
    .sort((a, b) => parseInt(a.platform_id, 10) - parseInt(b.platform_id, 10));
};

class Form extends FormFactory<{ platform_id: string; company_id_on_platform: string }> {}

export const PlatformView: React.FC<PlatformViewDataProps & PlatformViewProps> = ({
  routeProps,
  data,
  isNewPlatform,
  createCompanyPlatform,
  deleteCompanyPlatform,
}) => {
  const companyPlatform = data.companyPlatform;

  const isNotAdmin = !useIsAdmin();
  const hasPlatformsPermission = useHasScopes([BackofficeScope.companyWritePlatforms]);
  const isPrivilegedUser = !isNotAdmin || hasPlatformsPermission;
  const availablePlatforms = getAvailablePlatforms(data.platforms, data.companyPlatforms);

  if (!isNewPlatform && companyPlatform === null) {
    return <React.Fragment>Platform not found</React.Fragment>;
  }

  if (isNewPlatform && availablePlatforms.length === 0) {
    return (
      <React.Fragment>
        <h3>All platforms have been selected for this company</h3>
        <Button type="button" onClick={() => routeProps.goBack()}>
          Go back
        </Button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {companyPlatform !== null && (
        <h3>
          <span style={{ marginRight: 20 }}>{`#${companyPlatform.platform_id} ${companyPlatform.platform_name}`}</span>
          <ConfirmButton
            size="tiny"
            label="Delete"
            initialButtonColor={'red'}
            disabled={!isPrivilegedUser}
            onConfirm={async () => {
              await deleteCompanyPlatform({
                company_id: routeProps.company_id,
                platform_id: routeProps.platform_id,
              });

              routeProps.goBack();
            }}
          />
        </h3>
      )}
      <Form
        validateFields={{
          company_id_on_platform: {
            exist: 'Enter a company id on platform',
            custom: {
              error: 'The ID must be a string containing only alphanumeric characters',
              condition: (value) => {
                if (typeof value !== 'string') {
                  return false;
                }

                return value.match(/^[a-zA-Z0-9_-]+$/) !== null;
              },
            },
          },
          ...(isNewPlatform && {
            platform_id: {
              exist: 'Please select a platform',
            },
          }),
        }}
        initialValues={{
          platform_id: companyPlatform !== null ? companyPlatform.platform_id : '',
          company_id_on_platform: companyPlatform !== null ? companyPlatform.company_id_on_platform : '',
        }}
        onSubmit={async (form) => {
          const { company_id } = routeProps;
          await createCompanyPlatform({
            company_id,
            platform_id: form.platform_id,
            company_id_on_platform: form.company_id_on_platform,
          });
          routeProps.goBack();
        }}>
        {({ form, errors, onSubmit, onChange, isSubmittingForm }) => {
          return (
            <form className="ui form" onSubmit={onSubmit}>
              {isNewPlatform && (
                <div className="field">
                  <label>{errors.platform_id ?? 'Select a platform'}</label>
                  <select
                    className="ui fluid search dropdown"
                    name="platform_id"
                    value={form.platform_id}
                    disabled={!isPrivilegedUser}
                    onChange={onChange}>
                    <option disabled value="">
                      Platform
                    </option>
                    {availablePlatforms.map((platform) => {
                      return (
                        <option key={platform.platform_id} value={platform.platform_id}>
                          {platform.platform_name} (#{platform.platform_id})
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="field">
                <label>{errors.company_id_on_platform ?? 'Company ID on platform'}</label>
                <input
                  type="text"
                  name="company_id_on_platform"
                  placeholder="Company ID on platform"
                  value={form.company_id_on_platform}
                  onChange={onChange}
                  disabled={!isPrivilegedUser || !isNewPlatform}
                />
              </div>
              {isNewPlatform && (
                <Button
                  type="submit"
                  primary
                  loading={isSubmittingForm}
                  disabled={!isPrivilegedUser || isSubmittingForm}>
                  Create
                </Button>
              )}
              <Button type="button" onClick={() => routeProps.goBack()}>
                Cancel
              </Button>
            </form>
          );
        }}
      </Form>
    </React.Fragment>
  );
};
