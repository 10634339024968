import { AuthService, LoginWrapper } from '@sixfold/login-container';
import classnames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { errorDetails, ErrorMessage } from './error_message';

interface LoginContainerProps {
  google_client_id: string;
  authService: AuthService;
}

export const LoginContainer: React.StatelessComponent<LoginContainerProps & RouteComponentProps<object>> = (props) => {
  return (
    <LoginWrapper {...props}>
      {({ loading, error, refLoginButton }) => (
        <div className="Login">
          <div className="ui middle center aligned grid">
            <div className="row">{error !== undefined && <ErrorMessage {...errorDetails(error)} />}</div>
            <div className="row">
              <div className="column">
                <button ref={refLoginButton} className={classnames(['big ui labeled red icon button', { loading }])}>
                  <i className="google icon" />
                  Log in with @trimble.com account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </LoginWrapper>
  );
};
