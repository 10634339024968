import { isNil, notNil } from '@sixfold/typed-primitives';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import React from 'react';

interface DataToListProps {
  data: Record<string, string | number | boolean | object> | null | undefined;
  children: Children<{ key: string; value: string | number | null | object }>;
}

interface ScrollableMenuProps {
  data: React.ReactNode;
}

function formatValue(value: string | number | boolean | object) {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  return value;
}

export const DataToList: React.FC<DataToListProps> = ({ data, children }) => {
  if (isNil(data)) {
    return null;
  }

  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }

  return (
    <div className="data-to-list">
      {keys.map((key, idx) => (
        <React.Fragment key={idx}>
          {renderChildren(children, { key, value: notNil(data) ? formatValue(data[key]) : null })}
        </React.Fragment>
      ))}
    </div>
  );
};

export const ScrollableMenu: React.FC<ScrollableMenuProps> = ({ data }) => {
  return <div className="data-to-list">{data}</div>;
};
