import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getTableHeaderClassNames, emptyTableState } from '../../components/table';
import { Routes } from '../../routes';
import { UserGroupOnPlatform } from '../entities';

class UserGroupOnPlatformTable extends Table<UserGroupOnPlatform> {}

export interface UserGroupsOnPlatformViewProps {
  company_id: string;
  userGroupsOnPlatform: UserGroupOnPlatform[];
}

export const UserGroupsOnPlatformView: React.SFC<UserGroupsOnPlatformViewProps> = ({
  company_id,
  userGroupsOnPlatform,
}) => {
  return (
    <React.Fragment>
      <h2>User Groups</h2>
      <UserGroupOnPlatformTable
        className="ui very basic padded sortable unstackable table"
        data={userGroupsOnPlatform}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { value: 'Group id' },
            { value: 'Group name' },
            { value: 'Platform' },
            { value: 'Company' },
            { value: 'Users' },
          ],
        }}
        defaultSortBy={{ keyPath: 'group_id', value: 'ASC' }}
        emptyStatePlaceholder={emptyTableState(
          <>
            <span>No Groups</span>
          </>,
        )}>
        {({ row }) => {
          const { data } = row;
          return (
            <tr key={data.groupId}>
              <td>
                <NavLink
                  to={Routes.CompanyUserGroup.generatePath({
                    company_id,
                    group_id: data.groupId,
                  })}>
                  {data.groupId}
                </NavLink>
              </td>
              <td>{data.name}</td>
              <td>{data.platform.platform_name}</td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id })}>{data.company?.company_name}</NavLink>
              </td>
              <td>
                <NavLink
                  to={Routes.CompanyUserGroup.generatePath({
                    company_id,
                    group_id: data.groupId,
                  })}>
                  Show
                </NavLink>
              </td>
            </tr>
          );
        }}
      </UserGroupOnPlatformTable>
    </React.Fragment>
  );
};
