import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  CompanyUsersListQuery as CompanyUsersListQueryResult,
  CompanyUsersListQueryVariables,
  getNodes,
  loadMoreFromConnection,
} from '../../lib/graphql';
import { UserInCompanyList, UserInCompanyListDataProps } from '../components/user_in_company_list';
import { companyUsersListQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyUsersListQueryResult,
  UserInCompanyListDataProps,
  CompanyUsersListQueryVariables
> {}
class CompanyUsersListQuery extends Query<CompanyUsersListQueryResult, CompanyUsersListQueryVariables> {}

export const UserInCompanyListContainer = (props: RouteComponentProps<{ company_id: string }>) => (
  <CompanyUsersListQuery query={companyUsersListQuery} variables={{ company_id: props.match.params.company_id }}>
    {(result) => (
      <LoadingContainer
        result={result}
        mapData={({ data }) => ({
          data: {
            usersInCompany: getNodes(data?.company?.users.users).map(({ user, role, metadata, invitedByUser }) => ({
              user,
              role,
              persona: metadata !== null ? metadata.persona : null,
              invitedByUser,
            })),
          },
        })}>
        {({ data }) => (
          <InfiniteScroll
            loadMoreEntries={() =>
              loadMoreFromConnection(companyUsersListQuery, result, ['company', 'users', 'users'])
            }>
            <UserInCompanyList data={data} company={{ company_id: props.match.params.company_id }} />
          </InfiniteScroll>
        )}
      </LoadingContainer>
    )}
  </CompanyUsersListQuery>
);
