import { VehicleHistory, VehicleBreakHistory } from '../../../vehicle/entities';

export type PositionWithEvent = VehicleHistory & { event?: string };
export type ParsedVehicleHistory = Omit<VehicleHistory, 'timestamp'> & { timestamp: Date };

export interface HistoryWithEvent {
  event?: string;
  lat: number;
  lng: number;
  hdg: number | null;
  timestamp: string;
}

export interface TimelineTick {
  position: number;
  type: TimelineTickType;
  timelineIndex: number;
  width?: number;
  label?: string;
  subtype?: string;
}

export type TimelineBoundaries = {
  startTime: string;
  endTime: string;
};

export interface TimelinePosition {
  timestamp: Date;
  event?: string;
  externalEvent?: string;
  vehiclePosition?: ParsedVehicleHistory;
}

export enum TimelineTickType {
  EVENT = 'event',
  EXTERNAL_EVENT = 'external-event',
  TOUR_EVENTS_RANGE = 'tour-range',
  STOP_ZONE_RANGE = 'stop-zone-range',
  TELEMETRY_CHUNK_RANGE = 'telemetry-chunk-range',
  TIMESLOT_LOADING_RANGE = 'timeslot-loading-range',
  TIMESLOT_UNLOADING_RANGE = 'timeslot-unloading-range',
  TIMESLOT_OTHER_RANGE = 'timeslot-other-range',
  BREAK_RANGE = 'break-range',
  REST_BREAK_RANGE = 'rest-break-range',
  WEEKEND_BREAK_RANGE = 'weekend-break-range',
}

export type VehicleWithHistoryAndBreaks = {
  history: VehicleHistory[];
  breakHistory: VehicleBreakHistory[];
  licensePlateNumber: string | null;
  vehicleId: string;
};

export enum KeyboardKey {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}
