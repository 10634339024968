import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CompanyContainer } from './company_container';
import { CompanyListContainer } from './company_list_container';
import { Routes } from '../../routes';

export const CompaniesContainer = () => (
  <Switch>
    <Route path={Routes.Company.routerPath} component={CompanyContainer} />
    <Route path={Routes.Companies.routerPath} component={CompanyListContainer} />
  </Switch>
);
