import { createPropertyNotNilFilter } from '@sixfold/typed-primitives';
import { IconLayer, ScatterplotLayer, PathLayer, TextLayer, LineLayer, PolygonLayer } from 'deck.gl';
import polyline from 'polyline';
import { isNil } from 'ramda';

import { GeofenceType, GeofenceZoneType } from '../../lib/graphql';
import {
  Stop,
  Route,
  Event,
  VehicleBreakHistory,
  BreakType,
  GeofenceZone,
  GeofenceCircle,
  GeofencePolygon,
  Ruler,
  ExternalEvent,
  MappableStop,
  MapboxStyle,
  Geofence,
} from '../entities';
import { externalEventLabel, getCircleGeofencesByType, getPolygonGeofencesByType } from '../utils';

type LayerClickHandler = ({ lat, lng }: { lat: number; lng: number }) => void;

const IconMapping = {
  loading: { x: 4, y: 4, width: 32, height: 32 },
  unloading: { x: 4, y: 40, width: 32, height: 32 },
  event: { x: 4, y: 76, width: 32, height: 32 },
  [BreakType.break]: { x: 4, y: 112, width: 32, height: 32 },
  [BreakType.rest]: { x: 4, y: 148, width: 32, height: 32 },
  [BreakType.weekendBreak]: { x: 4, y: 184, width: 32, height: 32 },
  externalEvent: { x: 4, y: 220, width: 32, height: 32 },
  waypoint: { x: 4, y: 256, width: 32, height: 32 },
};

const BLACK_COLOR: [number, number, number] = [0, 0, 0];
const WHITE_COLOR: [number, number, number] = [255, 255, 255];

export const createRouteLayer = ({ route, mapStyle }: { route: Route; mapStyle: MapboxStyle }) => {
  const path = route.legs.reduce<number[][]>(
    (memo, leg) => [...memo, ...polyline.decode(leg.polyline.points).map(([lat, lng]) => [lng, lat])],
    [],
  );

  return new PathLayer({
    id: 'routeLayer',
    data: [{ path, color: mapStyle === MapboxStyle.SATELLITE ? [255, 255, 255, 180] : [0, 0, 0, 95] }],
    rounded: false,
    widthMinPixels: 3,
    widthScale: 10,
    getColor: (l) => l.color,
  });
};

export const createTourAreaLayer = ({ tourArea, mapStyle }: { tourArea: Geofence | null; mapStyle: MapboxStyle }) => {
  if (isNil(tourArea)) {
    return null;
  }

  const tourAreaEdgeColor = mapStyle === MapboxStyle.SATELLITE ? [255, 255, 255, 180] : [5, 0, 0, 95];

  return getTourAreaLayer(tourArea, tourAreaEdgeColor);

  function getTourAreaLayer(tourArea: Geofence, color: number[]) {
    if (tourArea.type === GeofenceType.POLYGON) {
      return new PathLayer({
        id: 'tourAreaPolygonPath',
        data: [transformTourAreaPolygon(color)(tourArea as GeofencePolygon)],
        getPath: (p) => p.path,
        getColor: (p) => p.color,
        getDashArray: () => [4, 2], // Dash pattern: 4px dash, 2px gap
        rounded: false,
        widthMinPixels: 3,
        widthScale: 10,
      });
    } else if (tourArea.type === GeofenceType.CIRCLE) {
      return new ScatterplotLayer({
        id: 'tourAreaCircle',
        data: [transformCircleZone(color)(tourArea as GeofenceCircle)],
        getRadius: (l) => l.radius,
        getFillColor: () => [0, 0, 0, 0],
        getLineColor: (l) => l.color,
        stroked: true,
        lineWidthMinPixels: 3,
        lineWidthScale: 10,
      });
    }
    return null;
  }
};

export const createStopLayer = ({
  stops,
  onStopIconClick,
  mapStyle,
  highlightedStopId,
}: {
  stops: MappableStop[];
  onStopIconClick: LayerClickHandler;
  mapStyle: MapboxStyle;
  highlightedStopId: number | undefined;
}) => {
  const zones = stops.reduce((previous: GeofenceZone[], current: Stop) => [...previous, ...current.geofenceZones], []);
  const highlightedStop = stops.filter((stop) => stop.stop_id === highlightedStopId);

  const arrivalGeofenceColor = mapStyle === MapboxStyle.SATELLITE ? [255, 255, 255, 120] : [5, 0, 0, 70];
  const departureGeofenceColor = mapStyle === MapboxStyle.SATELLITE ? [255, 255, 255, 80] : [5, 0, 0, 50];

  const approachingZonesCircular = getCircleGeofencesByType(zones, GeofenceZoneType.ARRIVAL).map(
    transformCircleZone(arrivalGeofenceColor),
  );
  const departingZonesCircular = getCircleGeofencesByType(zones, GeofenceZoneType.DEPARTURE).map(
    transformCircleZone(departureGeofenceColor),
  );

  const approachingZonesPolygon = getPolygonGeofencesByType(zones, GeofenceZoneType.ARRIVAL).map(
    transformPolygonZone(arrivalGeofenceColor),
  );
  const departingZonesPolygon = getPolygonGeofencesByType(zones, GeofenceZoneType.DEPARTURE).map(
    transformPolygonZone(departureGeofenceColor),
  );

  const stopIds = stops.map((stop) => ({
    coordinates: [stop.lng, stop.lat],
    text: `ID: ${stop.stop_id}`,
    color: mapStyle === MapboxStyle.SATELLITE ? WHITE_COLOR : BLACK_COLOR,
  }));

  return {
    highlightedStopLayer: new IconLayer({
      id: 'highlightedStoplayer',
      data: highlightedStop,
      iconAtlas: '/assets/map_icons_v4.png',
      iconMapping: IconMapping,
      pickable: true,
      onClick: ({ object }) => onStopIconClick(object),
      getPosition: ({ lng, lat }) => [lng, lat],
      getSize: () => 50,
      getIcon: ({ type }) => (type === 'loading' ? 'loading' : type === 'unloading' ? 'unloading' : 'waypoint'),
    }),
    stopLabelsLayer: new TextLayer({
      id: 'stopIdLayer',
      getPosition: (l) => l.coordinates,
      getPixelOffset: [0, -30],
      getColor: (l) => l.color,
      data: stopIds,
      getSize: 16,
    }),
    stopZonesLayers: [
      new PolygonLayer({
        id: 'stopZonesPolygon',
        data: [...approachingZonesPolygon, ...departingZonesPolygon],
        getPolygon: (p) => p.polygon,
        getFillColor: (p) => p.color,
        stroked: false,
      }),
      new ScatterplotLayer({
        id: 'stopZonesCircle',
        data: [...approachingZonesCircular, ...departingZonesCircular],
        getRadius: (l) => l.radius,
        getFillColor: (l) => l.color,
      }),
    ],
    stopIconsLayer: new IconLayer({
      id: 'stopIconLayer',
      data: stops,
      iconAtlas: '/assets/map_icons_v4.png',
      iconMapping: IconMapping,
      pickable: true,
      onClick: ({ object }) => onStopIconClick(object),
      getPosition: ({ lng, lat }) => [lng, lat],
      getSize: () => 20,
      getIcon: ({ type }) => (type === 'loading' ? 'loading' : type === 'unloading' ? 'unloading' : 'waypoint'),
    }),
  };
};

export const createVehicleBreaksLayer = ({
  vehicleBreaks,
  onBreakClick,
  mapStyle,
}: {
  vehicleBreaks: VehicleBreakHistory[];
  onBreakClick: LayerClickHandler;
  mapStyle?: MapboxStyle;
}) => {
  if (vehicleBreaks.length === 0) {
    return {
      breakIconsLayer: null,
      breakLabelsLayer: null,
    };
  }
  const breaksLabels = vehicleBreaks.map((breakUnit) => ({
    coordinates: [breakUnit.center.lng, breakUnit.center.lat],
    text: `${breakUnit.type} #${breakUnit.breakId}`,
    color: mapStyle === MapboxStyle.SATELLITE ? WHITE_COLOR : BLACK_COLOR,
  }));

  return {
    breakIconsLayer: new IconLayer({
      id: 'breakIconsLayer',
      data: vehicleBreaks,
      iconAtlas: '/assets/map_icons_v4.png',
      iconMapping: IconMapping,
      pickable: true,
      onClick: ({ object }) => onBreakClick(object.center),
      getPosition: ({ center }) => [center.lng, center.lat],
      getSize: () => 12,
      getIcon: ({ type }) => type,
    }),
    breakLabelsLayer: new TextLayer({
      id: 'breakIdLayer',
      getPosition: (l) => l.coordinates,
      getPixelOffset: [0, -30],
      data: breaksLabels,
      getSize: 16,
      getColor: (l) => l.color,
    }),
  };
};

export const createEventLayer = ({
  events,
  onClick,
  mapStyle,
}: {
  events: Event[];
  onClick: LayerClickHandler;
  mapStyle: MapboxStyle;
}) => {
  const statusPointsHashmap = events.reduce(
    (memo, event) => {
      const vehicleLat = event.vehicle_lat;
      const vehicleLng = event.vehicle_lng;
      if (vehicleLat === null || vehicleLng === null) {
        return memo;
      }

      const uid = `${vehicleLat}:${vehicleLng}`;
      const eventName = event.event_name + (event.__typename === 'StopEvent' ? ` #${event.stop_id}` : '');
      if (memo[uid] === undefined) {
        memo[uid] = {
          coordinates: [vehicleLng, vehicleLat],
          lat: vehicleLat,
          lng: vehicleLng,
          text: eventName,
          color: mapStyle === MapboxStyle.SATELLITE ? WHITE_COLOR : BLACK_COLOR,
        };
      } else {
        memo[uid] = {
          ...memo[uid],
          text: [memo[uid].text, eventName].join(', '),
        };
      }

      return memo;
    },
    {} as Record<
      string,
      {
        coordinates: [number, number];
        lat: number;
        lng: number;
        text: string;
        color: [number, number, number];
      }
    >,
  );

  const statusPoints = Object.keys(statusPointsHashmap).map((key) => statusPointsHashmap[key]);

  return {
    eventLabelsLayer: new TextLayer({
      id: 'eventLabelLayer',
      data: statusPoints,
      getPosition: (l) => l.coordinates,
      getPixelOffset: [0, 20],
      getSize: 16,
      getColor: (l) => l.color,
    }),
    eventIconsLayer: new IconLayer({
      id: 'eventIconLayer',
      data: statusPoints,
      iconAtlas: '/assets/map_icons_v4.png',
      iconMapping: IconMapping,
      pickable: true,
      onClick: ({ object }) => onClick(object),
      getPosition: ({ lng, lat }) => [lng, lat],
      getSize: () => 12,
      getIcon: () => 'event',
    }),
  };
};

export const createExternalEventLayer = ({
  externalEvents,
  onClick,
}: {
  externalEvents: ExternalEvent[];
  onClick: LayerClickHandler;
}) => {
  const statusPointsHashmap = externalEvents.reduce(
    (memo, event) => {
      const eventLat = event.latitude;
      const eventLng = event.longitude;
      if (eventLat === null || eventLng === null) {
        return memo;
      }

      const uid = `${eventLat}:${eventLng}`;

      const eventName = externalEventLabel(event);

      if (memo[uid] === undefined) {
        memo[uid] = {
          coordinates: [eventLng, eventLat],
          lat: eventLat,
          lng: eventLng,
          text: eventName,
        };
      } else {
        memo[uid] = {
          ...memo[uid],
          text: [memo[uid].text, eventName].join(', '),
        };
      }

      return memo;
    },
    {} as Record<
      string,
      {
        coordinates: [number, number];
        lat: number;
        lng: number;
        text: string;
      }
    >,
  );

  const statusPoints = Object.keys(statusPointsHashmap).map((key) => statusPointsHashmap[key]);

  return {
    externalEventLabelsLayer: new TextLayer({
      id: 'externalEventLabelLayer',
      data: statusPoints,
      getPosition: (l) => l.coordinates,
      getPixelOffset: [0, 20],
      getSize: 16,
    }),
    externalEventIconsLayer: new IconLayer({
      id: 'externalEventIconLayer',
      data: statusPoints,
      iconAtlas: '/assets/map_icons_v4.png',
      iconMapping: IconMapping,
      pickable: true,
      onClick: ({ object }) => onClick(object),
      getPosition: ({ lng, lat }) => [lng, lat],
      getSize: () => 12,
      getIcon: () => 'externalEvent',
    }),
  };
};

export const createRulerLayer = ({ rulers }: { rulers: Ruler[] }) => {
  const rulerTips = rulers.reduce<{ position: [number, number] }[]>((memo, ruler) => {
    if (ruler.toLngLat !== undefined) {
      return [...memo, { position: ruler.fromLngLat }, { position: ruler.toLngLat }];
    }
    return [...memo, { position: ruler.fromLngLat }];
  }, []);

  const rulersWithLegend = rulers
    .filter(createPropertyNotNilFilter('toLngLat', 'distanceInMeters'))
    .map((ruler) => ({ ...ruler, text: beautifyRulerDistance(ruler.distanceInMeters) }));

  return {
    rulersLabelLayer:
      rulersWithLegend.length !== 0
        ? new TextLayer({
            id: 'rulersLabelLayer',
            data: rulersWithLegend,
            getPosition: (l) => l.toLngLat,
            getPixelOffset: [0, 20],
            getSize: 16,
          })
        : null,
    rulersLayer: new LineLayer({
      id: 'rulerLayer',
      data: rulersWithLegend,
      pickable: false,
      getSourcePosition: (ruler) => ruler.fromLngLat,
      getTargetPosition: (ruler) => ruler.toLngLat,
    }),
    rulersTipsLayer: new ScatterplotLayer({
      id: 'rulerTipsLayer',
      data: rulerTips,
      getFillColor: () => BLACK_COLOR,
      radiusMinPixels: 3,
    }),
  };
};

const beautifyRulerDistance = (distanceInMeters: number) =>
  distanceInMeters > 1000 ? `${(distanceInMeters / 1000).toFixed(2)}km` : `${Math.floor(distanceInMeters)}m`;

const transformCircleZone =
  (color: number[]) =>
  ({ radiusInMeters, origin }: GeofenceCircle) => ({
    position: [origin.lng, origin.lat],
    radius: radiusInMeters,
    color,
  });

const transformPolygonZone =
  (color: number[]) =>
  ({ points }: GeofencePolygon) => ({
    color,
    polygon: points.map(({ lat, lng }) => [lng, lat] as [number, number]),
  });

const transformTourAreaPolygon = (color: number[]) => (tourArea: GeofencePolygon) => {
  const points = tourArea.points.map(({ lat, lng }) => [lng, lat] as [number, number]);
  const closedPath = [...points, points[0]];

  return {
    color,
    path: closedPath,
  };
};
