import classnames from 'classnames';
import React from 'react';

import { format } from '../../lib/date';

interface Props {
  eta: string | Date | null;
  status: string | null;
}

const formatEta = (eta: string | Date | null) => {
  if (eta === null) {
    return 'N/A';
  }

  const utc = typeof eta === 'string' ? { toUTC: true } : undefined;

  if (format(eta, 'dd.LL.yyyy', utc) === format(new Date(), 'dd.LL.yyyy', utc)) {
    return format(eta, 'HH:mm', utc);
  }
  return format(eta, 'dd MMM', utc);
};

export const ETALabel: React.StatelessComponent<Props> = ({ eta, status }) => (
  <div
    className={classnames('ui tiny label', {
      green: status === 'on_time',
      yellow: status === 'delay_possible',
      red: status === 'delayed',
    })}>
    ETA:
    <div className="detail">{formatEta(eta)}</div>
  </div>
);
