import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getTableHeaderClassNames, emptyTableState } from '../../components/table';
import { Routes } from '../../routes';
import { UserProfile } from '../../user_in_company/entities';
import { UserGroupOnPlatform } from '../entities';

class UserTable extends Table<UserProfile> {}
export interface UserGroupOnPlatformViewProps {
  userGroupOnPlatform: UserGroupOnPlatform;
}

export const UserGroupOnPlatformView: React.SFC<UserGroupOnPlatformViewProps> = ({ userGroupOnPlatform }) => {
  return (
    <React.Fragment>
      <h2>User Group </h2>

      <div>
        Id: <b>#{userGroupOnPlatform.groupId}</b>
      </div>
      <div>
        Name: <b>{userGroupOnPlatform.name}</b>
      </div>
      <div>
        Platform: <b>{userGroupOnPlatform.platform.platform_name}</b>
      </div>
      <div>
        Company:
        {userGroupOnPlatform.company !== undefined ? (
          <b>
            <NavLink to={Routes.Company.generatePath({ company_id: userGroupOnPlatform.company.company_id })}>
              {userGroupOnPlatform.company.company_name}
            </NavLink>
          </b>
        ) : (
          <span>No company</span>
        )}
      </div>

      <br />
      <div>
        <h3>Users</h3>
        <UserTable
          className="ui very basic padded sortable unstackable table"
          data={userGroupOnPlatform.users ?? []}
          tableHeaders={{
            defaultClassName: getTableHeaderClassNames,
            columns: [{ value: 'User id' }, { value: 'First name' }, { value: 'Last name' }, { value: 'Email' }],
          }}
          defaultSortBy={{ keyPath: 'user_id', value: 'ASC' }}
          emptyStatePlaceholder={emptyTableState(
            <>
              <span>No Users</span>
            </>,
          )}>
          {({ row }) => {
            const { data } = row;
            return (
              <tr key={data.user_id}>
                <td>
                  <NavLink
                    to={Routes.Users.generatePath({
                      user_id: data.user_id,
                    })}>
                    #{data.user_id}
                  </NavLink>
                </td>
                <td>{data.first_name}</td>
                <td>{data.last_name}</td>
                <td>{data.email}</td>
              </tr>
            );
          }}
        </UserTable>
      </div>
    </React.Fragment>
  );
};
