import { notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactDiffViewer, { DiffMethod, ReactDiffViewerProps } from 'react-diff-viewer-continued';
import { Button, Checkbox, Dropdown, Icon, Modal, Popup } from 'semantic-ui-react';

import styles from './diff_modal.module.css';

interface Props {
  // Key used to save modal preferences to localStorage
  dataKey: string;
  isCompareButtonDisabled: boolean;
  showDiffViewer: boolean;
  oldValue: ReactDiffViewerProps['oldValue'] | undefined;
  newValue: ReactDiffViewerProps['newValue'] | undefined;
  leftTitle: ReactDiffViewerProps['leftTitle'] | ((useUnifiedView: boolean) => ReactDiffViewerProps['leftTitle']);
  rightTitle: ReactDiffViewerProps['rightTitle'] | ((useUnifiedView: boolean) => ReactDiffViewerProps['rightTitle']);
}

export const DiffViewerModal: React.FunctionComponent<Props> = (props: Props) => {
  const { dataKey, showDiffViewer, isCompareButtonDisabled, newValue, oldValue, leftTitle, rightTitle } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [unifiedView, setUnifiedView] = useState<boolean>(localStorage.getItem(`${dataKey}.unifiedView`) === 'true');
  const [useDarkTheme, setUseDarkTheme] = useState<boolean>(localStorage.getItem(`${dataKey}.useDarkTheme`) === 'true');
  const [compareMethod, setCompareMethod] = useState<DiffMethod>(
    (localStorage.getItem(`${dataKey}.compareMethod`) as DiffMethod) ?? DiffMethod.WORDS,
  );

  return (
    <Modal
      size={unifiedView ? 'large' : 'fullscreen'}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmar="blurring"
      closeIcon
      trigger={<Button disabled={isCompareButtonDisabled}>Compare</Button>}>
      <Modal.Header className={styles.diff_modal__header}>
        <div>Message diff</div>{' '}
        <div className={styles.diff_modal__header__options}>
          <Checkbox
            label="Unified view"
            checked={unifiedView}
            onChange={(_event, { checked }) => {
              if (notNil(checked)) {
                setUnifiedView(checked);
                localStorage.setItem(`${dataKey}.unifiedView`, checked.toString());
              }
            }}
          />
          <div className={styles.diff_modal__separator}>｜</div>
          <Checkbox
            label="Dark theme"
            checked={useDarkTheme}
            onChange={(_event, { checked }) => {
              if (notNil(checked)) {
                setUseDarkTheme(checked);
                localStorage.setItem(`${dataKey}.useDarkTheme`, checked.toString());
              }
            }}
          />
          <div className={styles.diff_modal__separator}>｜</div>
          <Dropdown
            selection
            options={[
              { text: 'Chars', value: DiffMethod.CHARS },
              { text: 'Words', value: DiffMethod.WORDS },
              { text: 'Words with space', value: DiffMethod.WORDS_WITH_SPACE },
              { text: 'Lines', value: DiffMethod.LINES },
              { text: 'Trimmed lines', value: DiffMethod.TRIMMED_LINES },
              { text: 'Sentences', value: DiffMethod.SENTENCES },
              { text: 'CSS', value: DiffMethod.CSS },
            ]}
            value={compareMethod}
            onChange={(_e, { value }) => {
              if (value) {
                setCompareMethod(value as DiffMethod);
                localStorage.setItem(`${dataKey}.compareMethod`, value.toString());
              }
            }}
          />
          <Popup
            content="Click here for info about diff methods"
            trigger={
              <a href={'https://github.com/kpdecker/jsdiff/tree/v4.0.1#api'} target="_blank" rel="noreferrer">
                <Icon name="question circle" />
              </a>
            }
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className={classnames('scrolling content', styles.diff_modal)}>
          {showDiffViewer && (
            <ReactDiffViewer
              leftTitle={typeof leftTitle === 'function' ? leftTitle(unifiedView) : leftTitle}
              rightTitle={typeof rightTitle === 'function' ? rightTitle(unifiedView) : rightTitle}
              oldValue={oldValue}
              newValue={newValue}
              splitView={!unifiedView}
              useDarkTheme={useDarkTheme}
              compareMethod={compareMethod}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
