import { Button, Icon, Tooltip } from '@sixfold/common-ui';
import { BackofficeScope } from '@sixfold/session-interface';
import { ApolloClient, ApolloQueryResult } from 'apollo-client';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import { telematicsIntegrationSensitiveConnectionDataQuery } from '../../company/graphql';
import { DataToList } from '../../components/data_to_list';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { SensitiveConnectionDataQuery } from '../../lib/graphql';
import { Captcha } from '../components/captcha';

import styles from './styles.module.css';

type ConnectionParameter = {
  fieldName: string;
  fieldType: string;
  isOptional: boolean;
  isInternal: boolean;
  isSensitive: boolean;
  fieldDisplayName: string | null;
  enumFieldValues: { value: string; display_name?: string | null | undefined; is_default: boolean }[];
};

interface Props {
  integrationId: string;
  connectionData: Record<string, string | number | boolean | object>;
  connectionParameters: ConnectionParameter[];
}

export const IntegrationCredentialsList: React.FC<Props> = (props) => {
  const [connectionData, setConnectionData] = useState(props.connectionData);
  const isAdmin = useIsAdmin();
  const hasSensitiveConnectionDataPermission = useHasScopes([BackofficeScope.telematicsReadSensitiveConnectionData]);

  const connectionDataHasSensitiveField = props.connectionParameters.some((param) => {
    if (param.isSensitive) {
      return param.fieldName in connectionData;
    }
    return false;
  });
  const showFetchCredsBtn =
    (isAdmin || hasSensitiveConnectionDataPermission) &&
    Object.keys(connectionData).length > 0 &&
    connectionDataHasSensitiveField;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '400px' }}>
        <DataToList data={connectionData}>
          {({ key, value }) => {
            return (
              <div>
                <b>{key}</b>: {value}
              </div>
            );
          }}
        </DataToList>
        {showFetchCredsBtn && (
          <FetchIntegrationCredentialsButton
            connectionDataSetter={setConnectionData}
            integrationId={props.integrationId}></FetchIntegrationCredentialsButton>
        )}
      </div>
    </>
  );
};

interface FetchButtonProps {
  connectionDataSetter: Dispatch<SetStateAction<Props['connectionData']>>;
  integrationId: string;
}

export const FetchIntegrationCredentialsButton: React.FC<FetchButtonProps> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const runQuery = async (client: ApolloClient<object>, captcha_token: string) => {
    const { data }: ApolloQueryResult<SensitiveConnectionDataQuery> = await client.query({
      query: telematicsIntegrationSensitiveConnectionDataQuery,
      variables: { telematics_integration_id: props.integrationId, captcha_token },
    });
    props.connectionDataSetter(data.sensitiveConnectionData ?? {});
    setShowCaptcha(false);
    setDisabled(true);
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <>
          {showCaptcha && (
            <Captcha
              onClickAway={() => setShowCaptcha(false)}
              onChange={(captcha_token) => runQuery(client, captcha_token ?? '')}
            />
          )}
          <Tooltip content={'Get sensitive credentials'} placement={'top'}>
            <Button onClick={() => setShowCaptcha(true)} disabled={disabled} size={'xsmall'} kind={'subtle'}>
              <Icon name={'small-visible'} className={styles.sensitive} size={20} />
            </Button>
          </Tooltip>
        </>
      )}
    </ApolloConsumer>
  );
};
