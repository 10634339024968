import React from 'react';

export const errorDetails = (errorCode: string) => {
  switch (errorCode) {
    case 'email_not_registered':
      return {
        header: 'Email not registered',
        message: 'No user with such account is present in the system.',
      };
    case 'cancelled_by_user':
      return {
        header: 'Login cancelled',
        message: 'You cancelled login from Google dialog. Feel free to try again later.',
      };
    default:
      return {
        header: 'Login failed',
        message: 'Unexpected login error occurred. Please contact the system administrator.',
      };
  }
};

export const ErrorMessage = ({ header, message }: { header: string; message: string }) => (
  <div className="ui icon message">
    <i className="warning sign icon" />
    <div className="content">
      <div className="header">{header}</div>
      <p>{message}</p>
    </div>
  </div>
);
