import React from 'react';
import { Dropdown, Button, Input, DropdownProps } from 'semantic-ui-react';
import { debounce } from 'throttle-debounce';

import { CompaniesDropdownContainer } from '../../company/containers/companies_dropdown_container';
import { DateInput } from '../../components/date_input';
import {
  notEmpty,
  TourStatusFilter,
  TourDelayStatusFilter,
  TourTextSearchField,
  TourFilteringTransportMode,
} from '../../lib/graphql';
import { Platform } from '../../platform/entities';
import { comparePlatform } from '../../platform/utils';

export interface TourFiltersProps {
  filters: {
    platforms: Platform[];
    places?: { place_id: string; name: string }[];
    vehicle: { vehicle_id: string; license_plate_number: string | null } | null;
  };

  activeFilters: {
    status?: string;
    delay?: string;
    shipper_id?: string;
    carrier_id?: string;
    platform_id?: string;
    platform_branch_id?: string;
    vehicle_id?: string;
    start_time_start?: string;
    start_time_end?: string;
    place_id?: string;
    search?: string;
    search_field?: string;
    transport_mode?: string;
  };
  onFilterToggle: (filter: { name: string; value: string | undefined }) => void;
  onClearFilters: () => void;
}

export const TourFilters: React.FunctionComponent<TourFiltersProps> = (props) => {
  const { filters, activeFilters, onFilterToggle, onClearFilters } = props;

  const sortedPlatforms = filters.platforms.filter(notEmpty).sort(comparePlatform);
  const sortedPlaces =
    filters.places !== undefined ? [...filters.places].sort((a, b) => a.name.localeCompare(b.name)) : [];

  const statusOptions = [
    { value: TourStatusFilter.UNALLOCATED.toLowerCase(), text: 'Unallocated' },
    { value: TourStatusFilter.ALLOCATED.toLowerCase(), text: 'Allocated' },
    { value: TourStatusFilter.WAITING.toLowerCase(), text: 'Waiting' },
    { value: TourStatusFilter.WORKING.toLowerCase(), text: 'Working' },
    { value: TourStatusFilter.COMPLETED.toLowerCase(), text: 'Completed' },
    { value: TourStatusFilter.ABORTED.toLowerCase(), text: 'Aborted' },
    { value: TourStatusFilter.ABANDONED.toLowerCase(), text: 'Abandoned' },
    { value: TourStatusFilter.FAILED.toLowerCase(), text: 'Failed' },
  ];

  const delayOptions = [
    { value: TourDelayStatusFilter.ON_TIME.toLowerCase(), text: 'On time' },
    { value: TourDelayStatusFilter.DELAYED.toLowerCase(), text: 'Delay on pickup/delivery' },
    { value: TourDelayStatusFilter.DELAYED_PICKUP.toLowerCase(), text: 'Delay on pickup' },
    { value: TourDelayStatusFilter.DELAYED_DELIVERY.toLowerCase(), text: 'Delay on delivery' },
  ];

  const transportModeOptions = [
    { value: TourFilteringTransportMode.Road.toLowerCase(), text: 'Road' },
    { value: TourFilteringTransportMode.Ocean.toLowerCase(), text: 'Ocean' },
    { value: TourFilteringTransportMode.Air.toLowerCase(), text: 'Air' },
  ];

  const platformOptions = sortedPlatforms.map((platform) => ({
    value: platform.platform_id,
    text: platform.platform_name,
  }));

  const platformBranchOptions = Array.from(new Set(sortedPlatforms.flatMap((platform) => platform.platform_branches)))
    .filter(notEmpty)
    .map((platformBranchId) => ({
      value: platformBranchId,
      text: platformBranchId,
    }));

  const placeOptions = sortedPlaces?.map((place) => ({
    value: place.place_id,
    text: place.name,
  }));

  statusOptions.unshift({ value: '', text: 'All statuses' });
  platformOptions.unshift({ value: '', text: 'All platforms' });
  platformBranchOptions.unshift({ value: '', text: 'All platform branches' });
  transportModeOptions.unshift({ value: '', text: 'All transport modes' });
  placeOptions.unshift({ value: '', text: 'All places' });
  delayOptions.unshift({ value: '', text: 'All delays' });

  const searchOptions = [
    { text: 'All', value: TourTextSearchField.ALL_EXTENDED },
    { text: 'Tour ID', value: TourTextSearchField.TOUR_ID },
    { text: 'Delivery No.', value: TourTextSearchField.DELIVERY_NUMBER },
    { text: 'Order No.', value: TourTextSearchField.DELIVERY_ORDER_NUMBER },
    { text: 'Delivery PO No.', value: TourTextSearchField.DELIVERY_PURCHASE_ORDER_NUMBER },
    { text: 'Shipper transport No.', value: TourTextSearchField.SHIPPER_TRANSPORT_NUMBER },
    { text: 'Platform transport No.', value: TourTextSearchField.PLATFORM_TRANSPORT_NUMBER },
    { text: 'Stop address', value: TourTextSearchField.STOP_ADDRESS },
    { text: 'Stop name', value: TourTextSearchField.STOP_NAME },
  ];

  const handleSearchDebounced = debounce(
    200,
    (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      onFilterToggle({
        name: 'search_field',
        value: value === TourTextSearchField.ALL_EXTENDED ? undefined : (value as string).toLowerCase(),
      });
    },
  );

  return (
    <div>
      <div className="ui form">
        <div className="fields">
          <h2 className="table__header">
            {activeFilters.vehicle_id !== undefined && filters.vehicle !== null ? (
              <div style={{ display: 'flex' }}>
                Tours with
                <div className="ui label large" style={{ margin: '0 8px 0 4px' }}>
                  <i className="icon">🚚</i>
                  {filters.vehicle.license_plate_number ?? '-'}
                  <i className="delete icon" onClick={() => onFilterToggle({ name: 'vehicle_id', value: undefined })} />
                </div>
              </div>
            ) : (
              <span>Tours</span>
            )}
          </h2>
          <div className="two wide field">
            <Dropdown
              placeholder="All statuses"
              selection
              search
              compact
              options={statusOptions}
              value={activeFilters.status ?? ''}
              onChange={(_e, { value }) => {
                onFilterToggle({ name: 'status', value: value === '' ? undefined : (value as string) });
              }}
            />
          </div>
          <div className="two wide field">
            <Dropdown
              placeholder="All delays"
              selection
              search
              compact
              options={delayOptions}
              value={activeFilters.delay ?? ''}
              onChange={(_e, { value }) => {
                onFilterToggle({ name: 'delay', value: value === '' ? undefined : (value as string) });
              }}
            />
          </div>
          <div className="four wide field">
            <CompaniesDropdownContainer
              inputProps={{
                placeholder: 'All shippers',
              }}
              selectedCompany={
                activeFilters.shipper_id !== undefined
                  ? {
                      company_id: activeFilters.shipper_id,
                    }
                  : null
              }
              onSelectCompany={(selectedCompany) =>
                onFilterToggle({
                  name: 'shipper_id',
                  value: selectedCompany === null ? undefined : selectedCompany.company_id,
                })
              }
              companyType={{
                is_shipper: true,
              }}
            />
          </div>
          <div className="four wide field">
            <CompaniesDropdownContainer
              inputProps={{
                placeholder: 'All carriers',
              }}
              selectedCompany={
                activeFilters.carrier_id !== undefined
                  ? {
                      company_id: activeFilters.carrier_id,
                    }
                  : null
              }
              onSelectCompany={(selectedCompany) =>
                onFilterToggle({
                  name: 'carrier_id',
                  value: selectedCompany === null ? undefined : selectedCompany.company_id,
                })
              }
              companyType={{
                is_carrier: true,
              }}
            />
          </div>
          <div className="two wide field">
            <Dropdown
              placeholder="All transport modes"
              selection
              multiple
              search
              options={transportModeOptions}
              value={activeFilters.transport_mode?.split(',') ?? []}
              onChange={(_e, { value }) => {
                const valueArray = value as unknown[];
                onFilterToggle({
                  name: 'transport_mode',
                  value:
                    valueArray?.length === 0 || valueArray?.includes('') ? undefined : (value?.toString() as string),
                });
              }}
            />
          </div>
        </div>
        <div className="fields">
          <div className="two wide field">
            <DateInput
              onChange={({ target: { value } }) => {
                onFilterToggle({ name: 'start_time_start', value: value === '' ? undefined : value });
              }}
              max={activeFilters.start_time_end}
              placeholder="Started after"
              value={activeFilters.start_time_start === undefined ? '' : activeFilters.start_time_start}
            />
          </div>
          <div className="two wide field">
            <DateInput
              onChange={({ target: { value } }) => {
                onFilterToggle({ name: 'start_time_end', value: value === '' ? undefined : value });
              }}
              placeholder="Started before"
              min={activeFilters.start_time_start}
              value={activeFilters.start_time_end === undefined ? '' : activeFilters.start_time_end}
            />
          </div>
          <div className="two wide field">
            <Dropdown
              placeholder="All platforms"
              selection
              search
              compact
              options={platformOptions}
              value={activeFilters.platform_id ?? ''}
              onChange={(_e, { value }) => {
                onFilterToggle({ name: 'platform_id', value: value === '' ? undefined : (value as string) });
              }}
            />
          </div>
          <div className="four wide field">
            <Dropdown
              placeholder="All platform branches"
              selection
              search
              compact
              options={platformBranchOptions}
              value={activeFilters.platform_branch_id ?? ''}
              onChange={(_e, { value }) => {
                onFilterToggle({ name: 'platform_branch_id', value: value === '' ? undefined : (value as string) });
              }}
            />
          </div>
          <div className="two wide field">
            <Dropdown
              placeholder="All places"
              selection
              search
              compact
              options={placeOptions}
              value={activeFilters.place_id ?? ''}
              onChange={(_e, { value }) => {
                onFilterToggle({ name: 'place_id', value: value === '' ? undefined : (value as string) });
              }}
            />
          </div>
          <div className="six wide field">
            <div className="ui input">
              <Input
                placeholder="Search (type >=3 characters)"
                value={activeFilters.search === undefined ? '' : activeFilters.search}
                label={
                  <Dropdown
                    options={searchOptions}
                    onChange={handleSearchDebounced}
                    compact
                    value={
                      activeFilters.search_field === undefined
                        ? TourTextSearchField.ALL_EXTENDED
                        : activeFilters.search_field.toUpperCase()
                    }
                  />
                }
                onChange={({ target: { value } }) => {
                  onFilterToggle({ name: 'search', value: value === '' ? undefined : value });
                }}
              />
            </div>
          </div>
          <div className="two wide field">
            {Object.keys(activeFilters).some((key) => activeFilters[key] !== undefined) ? (
              <Button onClick={onClearFilters} size="tiny">
                Clear filters
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
