import { Heading, Paragraph, Stack, Text } from '@sixfold/common-ui';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyDashboardsQuery, CompanyDashboardsQueryVariables } from '../../lib/graphql';
import { companyDashboardsQuery } from '../graphql';

class CompanyDashboardContainerQuery extends Query<CompanyDashboardsQuery, CompanyDashboardsQueryVariables> {}

export const CompanyDashboardsContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  return (
    <CompanyDashboardContainerQuery
      query={companyDashboardsQuery}
      variables={{
        company_id: props.match.params.company_id,
      }}>
      {({ data, loading }) =>
        !loading && (
          <Stack direction="column">
            <Heading size="large">Lightdash</Heading>
            <Paragraph>
              The links below are valid for the <Text weight="bold">next 8 hours</Text> from the time of loading this
              view. To get new tokens refresh the page.
            </Paragraph>
            <ul>
              <li>
                <a
                  href={data?.company?.lightdashEmbeddings.allocationSourceAndSuccessUrl}
                  target="_blank"
                  rel="noreferrer">
                  Allocation source and success
                </a>
              </li>
            </ul>
          </Stack>
        )
      }
    </CompanyDashboardContainerQuery>
  );
};
