import { Pill, Stack, Text } from '@sixfold/common-ui';
import React from 'react';

import { ActionMetadata, FlagCreatedMetadata, FlagUpdatedMetadata } from '../../lib/graphql';

type Props = {
  featureFlagName: string | undefined;
  actionMetadata: ActionMetadata | null;
};

const isFlagCreated = (data: ActionMetadata): data is FlagCreatedMetadata => {
  return data.action === 'FLAG_CREATED';
};

const isFlagUpdated = (data: ActionMetadata): data is FlagUpdatedMetadata => {
  return data.action === 'FLAG_UPDATED';
};

export const FeatureFlagAuditLogMetadata = (props: Props) => {
  const { featureFlagName, actionMetadata } = props;

  if (actionMetadata === null) {
    return null;
  }

  const renderValuePill = (value: boolean) => {
    return <Pill kind={value ? 'success' : 'neutral'}>{value ? 'Enabled' : 'Disabled'}</Pill>;
  };

  if (isFlagCreated(actionMetadata)) {
    return (
      <Text>
        {featureFlagName ? <Text weight="bold">{featureFlagName} </Text> : '-'}
        was {renderValuePill(actionMetadata.value)} at company creation
      </Text>
    );
  }

  if (isFlagUpdated(actionMetadata)) {
    return (
      <Text>
        {featureFlagName ? <Text weight="bold">{featureFlagName}</Text> : '-'} was changed to{' '}
        {renderValuePill(actionMetadata.newValue)}
      </Text>
    );
  }

  return (
    <Stack>Unknown action performed on {featureFlagName ? <Text weight="bold">{featureFlagName}</Text> : '-'}</Stack>
  );
};
