import { Button, Input, Modal, ModalFooter, ModalHeader, ModalMain, Stack, useNotifications } from '@sixfold/common-ui';
import React, { useEffect } from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import {
  CompanyIdentifierType,
  CreateCompanyIdentifierInput,
  MutationCreateCompanyIdentifierArgs,
} from '../../lib/graphql';
import { companyQuery, createCompanyIdentifierMutation } from '../graphql';

type Props = {
  companyId: string;
  trigger: React.ReactElement | string;
};

/**
 * These are the known VAT country codes and their corresponding ISO 3166-1 alpha-2 codes.
 * @see https://en.wikipedia.org/wiki/VAT_identification_number
 */
const knownVATCountryCodesToIso3166_1Alpha2 = new Map([
  ['AT', ['AT']],
  ['BE', ['BE']],
  ['BG', ['BG']],
  ['HR', ['HR']],
  ['CY', ['CY']],
  ['CZ', ['CZ']],
  ['DK', ['DK']],
  ['EL', ['GR']], // Greece
  ['EE', ['EE']],
  ['FI', ['FI']],
  ['FR', ['FR', 'MC']], // France or Monaco
  ['DE', ['DE']],
  ['HU', ['HU']],
  ['IE', ['IE']],
  ['IT', ['IT']],
  ['LV', ['LV']],
  ['LT', ['LT']],
  ['LU', ['LU']],
  ['MT', ['MT']],
  ['NL', ['NL']],
  ['PL', ['PL']],
  ['PT', ['PT']],
  ['RO', ['RO']],
  ['SK', ['SK']],
  ['SI', ['SI']],
  ['ES', ['ES']],
  ['SE', ['SE']],
  ['GB', ['GB']],
]);

class CreateCompanyIdentifierMutation extends Mutation<
  CreateCompanyIdentifierInput,
  MutationCreateCompanyIdentifierArgs
> {}

function extractVatCountryCode(value: string | undefined) {
  if (value === undefined || value.length < 2) {
    return undefined;
  }
  return value.slice(0, 2).toUpperCase();
}

export const CompanyIdentifierAddModal = (props: Props) => {
  const notify = useNotifications();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState<CompanyIdentifierType>(CompanyIdentifierType.MISCELLANEOUS);
  const [value, setValue] = React.useState<string>();

  const onToggle = () => {
    setValue('');
    setIsOpen(!isOpen);
  };

  const vatCountryCode = extractVatCountryCode(value);
  const isFilteredCountryCode =
    vatCountryCode !== undefined && knownVATCountryCodesToIso3166_1Alpha2.has(vatCountryCode);
  const filteredCountryCodes: string[] = isFilteredCountryCode
    ? (knownVATCountryCodesToIso3166_1Alpha2.get(vatCountryCode) ?? [])
    : Array.from(knownVATCountryCodesToIso3166_1Alpha2.values()).flat();

  const firstCountryCode: string | undefined = filteredCountryCodes[0];
  const [selectedRegion, setSelectedRegion] = React.useState<string | undefined>(
    isFilteredCountryCode ? firstCountryCode : undefined,
  );

  useEffect(() => {
    setSelectedRegion(isFilteredCountryCode ? firstCountryCode : undefined);
  }, [firstCountryCode, isFilteredCountryCode]);

  const handleConfirm = async (
    mutationFn: MutationFn<CreateCompanyIdentifierInput, MutationCreateCompanyIdentifierArgs>,
  ) => {
    try {
      await mutationFn({
        variables: {
          input: {
            companyId: props.companyId,
            identifierType: selectedType,
            identifierValue: value!.trim().toUpperCase(),
            discriminatorCountry: selectedRegion,
          },
        },
        refetchQueries: [
          {
            query: companyQuery,
            variables: {
              company_id: props.companyId,
            },
          },
        ],
      });
      onToggle();
      notify.success({ title: 'Company identifier successfully created.' });
    } catch (error) {
      notify.error({ title: 'Creating the identifier failed.' });
    }
  };

  return (
    <Modal trigger={props.trigger} open={isOpen} onToggle={onToggle}>
      <ModalHeader title={'Add company identifier'} />
      <ModalMain>
        <label htmlFor={'identifier_type'}>Identifier type</label>
        <select
          className="ui fluid search dropdown"
          name="identifier_type"
          value={selectedType}
          onChange={(e) => {
            setSelectedType(e.target.value as CompanyIdentifierType);
          }}
          disabled={false}>
          <option value={CompanyIdentifierType.VAT_NUMBER}>VAT Number</option>
          <option value={CompanyIdentifierType.SCAC}>SCAC</option>
          <option value={CompanyIdentifierType.MC_NUMBER}>MC Number</option>
          <option value={CompanyIdentifierType.USDOT_NUMBER}>USDOT Number</option>
          <option value={CompanyIdentifierType.MISCELLANEOUS}>Miscellaneous</option>
        </select>
        <label htmlFor={'identifier_value'}>Identifier value</label>
        <Input
          onChange={(event) => {
            setValue(event.value);
          }}
          value={value}
          errorMessage={undefined}
          label={undefined}
          name="identifier_value"
        />
        {selectedType === CompanyIdentifierType.VAT_NUMBER && (
          <>
            <label htmlFor={'discriminator_country'}>Country code</label>
            <select
              className="ui fluid search dropdown"
              onChange={(e) => {
                setSelectedRegion(e.target.value);
              }}
              value={selectedRegion}
              name="discriminator_country"
              disabled={value === undefined || value.length < 2}>
              <option value={undefined}>None</option>
              {filteredCountryCodes.map((countryCode) => (
                <option key={countryCode} value={countryCode}>
                  {countryCode}
                </option>
              ))}
            </select>
          </>
        )}
      </ModalMain>
      <ModalFooter>
        <Stack justifyContent="end">
          <Button kind="subtle" onClick={onToggle}>
            Cancel
          </Button>
          <CreateCompanyIdentifierMutation mutation={createCompanyIdentifierMutation}>
            {(fn) => (
              <Button
                onClick={async () => await handleConfirm(fn)}
                disabled={value === undefined || value.trim().length === 0}
                kind="primary">
                {'Add'}
              </Button>
            )}
          </CreateCompanyIdentifierMutation>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
