import React, { useState } from 'react';

import { Tour } from '../../../tour/entities';
import { FormattedDate } from '../../date_formatting/formatted_date';
import { BigqueryMessage } from '../common/bigquery_message';
import { useKafkaContext } from '../common/kafka_context';
import { KafkaDiffViewer } from '../common/kafka_diff_viewer';
import { KafkaQueryInput } from '../common/kafka_query_input';
import { getMessageOptions } from '../common/messages';

export const TourKafkaTab: React.FunctionComponent<{ tour: Tour }> = ({ tour }) => {
  const { payloads } = useKafkaContext();
  const [loading, setLoading] = useState(false);
  const [compare, setCompare] = useState<number[]>([]);

  return (
    <>
      <h3>{`Kafka messages (${loading ? 'fetching...' : payloads.length})`}</h3>
      <KafkaQueryInput
        entity={tour}
        loading={loading}
        setLoading={setLoading}
        setCompare={setCompare}
        messageOptions={getMessageOptions('tour')}
      />
      <div className="ui list">
        {payloads.map((payload, index) => (
          <BigqueryMessage
            key={index}
            payload={{
              firstRow: payload.produced_at,
              data: JSON.parse(payload.message),
              secondRow: payload.message_type,
            }}
            checkBoxLabel={(item) => (
              <div>
                <FormattedDate date={item.firstRow} showSecondsAndMillis={true} />
                <div>{item.secondRow}</div>
              </div>
            )}
            compare={compare}
            setCompare={setCompare}
            index={index}
            isJustOne={payloads.length === 1}
          />
        ))}
        <KafkaDiffViewer compare={compare} />
      </div>
    </>
  );
};
