import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  DeleteVehicleMutation as DeleteVehicleMutationResult,
  DeleteVehiclesMutation as DeleteVehiclesMutationResult,
  DeleteVehicleMutationVariables,
  DeleteVehiclesMutationVariables,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { vehicleQuery, vehiclesQuery, deleteVehicleMutation, deleteVehiclesMutation } from '../graphql';

interface Props {
  children: Children<{
    deleteVehicle: (vehicleId: string, companyId: string) => Promise<void>;
    deleteVehicles: (vehicleIds: string[], companyId: string) => Promise<void>;
  }>;
}

class DeleteVehicleMutation extends Mutation<DeleteVehicleMutationResult, DeleteVehicleMutationVariables> {}
class DeleteVehiclesMutation extends Mutation<DeleteVehiclesMutationResult, DeleteVehiclesMutationVariables> {}

export const VehicleMutation: React.SFC<Props> = (props) => {
  const notify = useNotifications();

  return (
    <DeleteVehiclesMutation mutation={deleteVehiclesMutation}>
      {(deleteVehicles) => (
        <DeleteVehicleMutation mutation={deleteVehicleMutation}>
          {(deleteVehicle) => {
            return renderChildren(props.children, {
              deleteVehicle: async (vehicleId: string, companyId: string) => {
                const response = await deleteVehicle({
                  variables: {
                    input: {
                      vehicle_id: vehicleId,
                    },
                  },
                  refetchQueries: [
                    {
                      query: vehicleQuery,
                      variables: {
                        vehicle_id: vehicleId,
                      },
                    },
                    {
                      query: vehiclesQuery,
                      variables: {
                        limit: PAGINATION_PAGE_SIZE,
                        companyId,
                      },
                    },
                  ],
                });

                notify.success({ title: 'Vehicle deleted' });
                return response;
              },
              deleteVehicles: async (vehicleIds: string[], companyId: string) => {
                const response = await deleteVehicles({
                  variables: {
                    input: {
                      companyId,
                      vehicleIds,
                    },
                  },
                  refetchQueries: [
                    {
                      query: vehiclesQuery,
                      variables: {
                        limit: PAGINATION_PAGE_SIZE,
                        companyId,
                      },
                    },
                  ],
                });

                notify.success({ title: 'Vehicles deleted' });
                return response;
              },
            });
          }}
        </DeleteVehicleMutation>
      )}
    </DeleteVehiclesMutation>
  );
};
