import { DateTime } from 'luxon';
import React from 'react';

import { BigQueryMessage } from './kafka_query_input';
import { Tour } from '../../../tour/entities';

export type SORT_ORDER = 'asc' | 'desc';

type KafkaTabState = {
  messageSelection: string[];
  setMessageSelection: React.Dispatch<React.SetStateAction<string[]>>;
  fromDate: DateTime;
  setFromDate: React.Dispatch<React.SetStateAction<DateTime>>;
  toDate: DateTime;
  setToDate: React.Dispatch<React.SetStateAction<DateTime>>;
  payloads: BigQueryMessage[];
  setPayloads: React.Dispatch<React.SetStateAction<BigQueryMessage[]>>;
  sortOrder: SORT_ORDER;
  setSortOrder: React.Dispatch<React.SetStateAction<SORT_ORDER>>;
};

// Default context which is only used when Context Provider is not ... provided, which should never happen
export const KafkaContext = React.createContext<KafkaTabState>({
  messageSelection: [],
  setMessageSelection: () => undefined,
  fromDate: DateTime.now(),
  setFromDate: () => undefined,
  toDate: DateTime.now(),
  setToDate: () => undefined,
  payloads: [],
  setPayloads: () => undefined,
  sortOrder: 'asc',
  setSortOrder: () => undefined,
});

export const useKafkaContext = () => React.useContext(KafkaContext);

export interface KafkaContextProviderProps {
  initialFromDate: DateTime;
  initialToDate: DateTime;
  children: React.ReactNode;
}

export const KafkaContextProvider = (props: KafkaContextProviderProps) => {
  const [messageSelection, setMessageSelection] = React.useState<string[]>([]);
  const [fromDate, setFromDate] = React.useState<DateTime>(props.initialFromDate);
  const [toDate, setToDate] = React.useState<DateTime>(props.initialToDate);
  const [payloads, setPayloads] = React.useState<BigQueryMessage[]>([]);
  const [sortOrder, setSortOrder] = React.useState<SORT_ORDER>(
    (localStorage.getItem('kafka.sortOrder') as SORT_ORDER) || 'asc',
  );

  const state = React.useMemo(
    () => ({
      messageSelection,
      setMessageSelection,
      fromDate,
      setFromDate,
      toDate,
      setToDate,
      payloads,
      setPayloads,
      sortOrder,
      setSortOrder,
    }),
    [
      messageSelection,
      setMessageSelection,
      fromDate,
      setFromDate,
      toDate,
      setToDate,
      payloads,
      setPayloads,
      sortOrder,
      setSortOrder,
    ],
  );
  return <KafkaContext.Provider value={state}>{props.children}</KafkaContext.Provider>;
};

export const getTourKafkaQueryTimespan = (tour: Tour) => {
  const initialToDate = DateTime.now().endOf('day');
  const oneDayBeforeToDate = initialToDate.minus({ day: 1 }).startOf('day');
  const tourCreatedAtDate = DateTime.fromISO(tour.created_at);
  const initialFromDate = oneDayBeforeToDate < tourCreatedAtDate ? tourCreatedAtDate : oneDayBeforeToDate;

  return { initialFromDate, initialToDate };
};

export const getCompanyKafkaQueryTimespan = () => {
  const initialToDate = DateTime.now().endOf('day');
  const initialFromDate = initialToDate.minus({ day: 1 }).startOf('day');

  return { initialFromDate, initialToDate };
};
