import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { UserMutations } from './user_mutations_container';
import {
  BackofficeScopesQuery,
  BackofficeScopesQueryVariables,
  UserQuery,
  UserQueryVariables,
} from '../../lib/graphql';
import { UserView, UserViewDataProps } from '../components/user';
import { backofficeScopesQuery, userQuery } from '../graphql';

class LoadingContainer extends Loader<UserQuery, UserViewDataProps, UserQueryVariables> {}
class UserContainerQuery extends Query<UserQuery, UserQueryVariables> {}
class BackofficeScopesContainerQuery extends Query<BackofficeScopesQuery, BackofficeScopesQueryVariables> {}
class BackofficeScopesLoadingContainer extends Loader<
  BackofficeScopesQuery,
  { data: { backofficeScopes: string[] | undefined } },
  BackofficeScopesQueryVariables
> {}

export const UserContainer = (props: RouteComponentProps<{ user_id: string }>) => {
  const { user_id } = props.match.params;
  const isNewUser = user_id === 'new';

  return (
    <UserContainerQuery query={userQuery} variables={{ user_id }} skip={isNewUser}>
      {(result) => {
        return (
          <BackofficeScopesContainerQuery query={backofficeScopesQuery}>
            {(scopesResult) => (
              <BackofficeScopesLoadingContainer
                result={scopesResult}
                mapData={({ data }) => ({
                  data: {
                    backofficeScopes: data.backofficeScopes,
                  },
                })}>
                {({ data: scopesResult }) => {
                  if (isNewUser) {
                    return (
                      <UserMutations>
                        {({ createUser }) => {
                          return (
                            <UserView
                              isNewUser={isNewUser}
                              createUser={createUser}
                              routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                              scopes={scopesResult.backofficeScopes ?? []}
                            />
                          );
                        }}
                      </UserMutations>
                    );
                  }

                  return (
                    <UserMutations>
                      {({ updateUser, deleteUser, removeCredentialsFromUser, addCredentialsToUser }) => {
                        return (
                          <LoadingContainer
                            result={result}
                            mapData={({ data }) => ({
                              data: { user: data.user },
                            })}>
                            {({ data }) => (
                              <UserView
                                isNewUser={false}
                                data={data}
                                updateUser={updateUser}
                                deleteUser={deleteUser}
                                removeCredentialsFromUser={removeCredentialsFromUser}
                                addCredentialsToUser={addCredentialsToUser}
                                routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                                scopes={scopesResult.backofficeScopes ?? []}
                              />
                            )}
                          </LoadingContainer>
                        );
                      }}
                    </UserMutations>
                  );
                }}
              </BackofficeScopesLoadingContainer>
            )}
          </BackofficeScopesContainerQuery>
        );
      }}
    </UserContainerQuery>
  );
};
