import { ErrorBoundary } from '@sixfold/app-utils';
import { BackofficeScope } from '@sixfold/session-interface';
import { isNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Header, List, Statistic, Table, TableRow } from 'semantic-ui-react';

import { VehiclesMap } from './vehicles_map';
import { ConfirmButton } from '../../components/confirm_button';
import { DataToList } from '../../components/data_to_list';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { useTimeZone } from '../../components/date_formatting/timezone_context';
import { ErrorPlaceholder } from '../../components/error_placeholder';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { formatDateWithTimeZone, formattedDuration } from '../../lib/date';
import { DateTime } from '../../lib/graphql';
import { MapSlider } from '../../map/components/map_slider';
import { Routes } from '../../routes';
import {
  BreakType,
  LatestVehicleTemperature,
  Vehicle,
  VehicleBreakHistory,
  VehicleHistory,
  VehicleStatus,
  VehicleTracker,
} from '../entities';
import { isMobileTracker } from '../utils';

export interface VehicleViewDataProps {
  data: {
    vehicle?:
      | (Vehicle & {
          company: NonNullable<Vehicle['company']>;
          status: VehicleStatus | null;
          vehicleTrackers: VehicleTracker[];
          created_at: DateTime | null;
          updated_at: DateTime | null;
        })
      | null;
    history: VehicleHistory[];
    breakHistory: VehicleBreakHistory[];
  };
}

type Props = {
  deleteVehicle: (vehicleId: string) => void;
  timeline: {
    selectedTimestamp: string | undefined;
    startTime: string;
    endTime: string;
  };
} & VehicleViewDataProps;

const FormattedBreakDuration: React.SFC<{ break: VehicleBreakHistory | undefined; earliestTime: string | Date }> = ({
  break: brk,
  earliestTime,
}) => {
  if (brk === undefined) {
    return <React.Fragment>{formattedDuration(earliestTime)}</React.Fragment>;
  }

  return (
    <React.Fragment>
      {brk.to === null ? 'Ongoing for' : ''} {formattedDuration(brk.to !== null ? brk.to : brk.from)}
    </React.Fragment>
  );
};

const BreaksInfo: React.StatelessComponent<VehicleViewDataProps> = (props) => {
  const { breakHistory } = props.data;

  const breaksNewestToOldest = [...breakHistory].reverse();
  const latestBreak = breaksNewestToOldest[0];
  const latestRest = breaksNewestToOldest.find(
    ({ type }) => type === BreakType.rest || type === BreakType.weekendBreak,
  );
  const latestWeekendBreak = breaksNewestToOldest.find(({ type }) => type === BreakType.weekendBreak);
  const earliestBreak = breaksNewestToOldest[breaksNewestToOldest.length - 1];
  const { timeZone } = useTimeZone();

  if (breaksNewestToOldest.length === 0) {
    return <span>No breaks</span>;
  }

  return (
    <React.Fragment>
      <Header sub textAlign="center" className="vehicle__stats_header">
        Time since last
      </Header>
      <Statistic.Group size="mini" widths="three">
        <Statistic>
          <Statistic.Value>
            <FormattedBreakDuration break={latestBreak} earliestTime={earliestBreak.from} />
          </Statistic.Value>
          <Statistic.Label>Break</Statistic.Label>
        </Statistic>
        <Statistic>
          <Statistic.Value>
            <FormattedBreakDuration break={latestRest} earliestTime={earliestBreak.from} />
          </Statistic.Value>
          <Statistic.Label>Rest break</Statistic.Label>
        </Statistic>
        <Statistic>
          <Statistic.Value>
            <FormattedBreakDuration break={latestWeekendBreak} earliestTime={earliestBreak.from} />
          </Statistic.Value>
          <Statistic.Label>Weekend break</Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <List
        className="vehicle__breaks-list"
        items={breaksNewestToOldest.map(({ breakId, type, from, to, formattedDuration }, idx) => {
          return {
            key: idx,
            icon: {
              verticalAlign: 'middle',
              className: classnames({
                'icon circle': true,
                'icon-break--small-break': type === BreakType.break,
                'icon-break--rest-break': type === BreakType.rest,
                'icon-break--we-break': type === BreakType.weekendBreak,
              }),
            },
            header: `${type} #${breakId} (${formattedDuration})`,
            content: `${formatDateWithTimeZone(from, timeZone)} - ${
              to !== null ? formatDateWithTimeZone(to, timeZone) : 'ongoing'
            } (${timeZone})`,
          };
        })}
      />
    </React.Fragment>
  );
};

const LatestVehicleTemperatureRow: React.FunctionComponent<{
  vehicleTemperature: LatestVehicleTemperature;
}> = ({ vehicleTemperature }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Button
            size="tiny"
            compact
            basic
            icon={expanded ? 'angle up' : 'angle down'}
            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
          />
        </Table.Cell>
        <Table.Cell>{vehicleTemperature.reeferId}</Table.Cell>
        <Table.Cell>
          <FormattedDate date={vehicleTemperature.timestamp} />
        </Table.Cell>
        <Table.Cell>{vehicleTemperature.visibilitySource.eventLevel}</Table.Cell>
        <Table.Cell>{vehicleTemperature.visibilitySource.primaryEventSource}</Table.Cell>
        <Table.Cell>{vehicleTemperature.visibilitySource.eventOrigin}</Table.Cell>
      </Table.Row>
      {expanded && (
        <Table.Row>
          <Table.Cell colSpan="6">
            <Table compact="very" size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Compartment ID</Table.HeaderCell>
                  <Table.HeaderCell>Sensor ID</Table.HeaderCell>
                  <Table.HeaderCell>Value °C</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {vehicleTemperature.sensorReadings.map((sensorReading, idx) => (
                  <TableRow key={idx}>
                    <Table.Cell>{sensorReading.compartmentId}</Table.Cell>
                    <Table.Cell>{sensorReading.sensorId}</Table.Cell>
                    <Table.Cell>{sensorReading.temperatureCelsius}</Table.Cell>
                  </TableRow>
                ))}
              </Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export const VehicleView: React.FC<Props> = (props) => {
  const { vehicle, history, breakHistory } = props.data;
  const isAdmin = useIsAdmin();
  const hasVehiclesEditPermission = useHasScopes([BackofficeScope.companyWriteVehicles]);
  const isPrivilegedUser = isAdmin || hasVehiclesEditPermission;

  if (isNil(vehicle)) {
    return <React.Fragment>Vehicle not found</React.Fragment>;
  }

  const vehicleStatusTimestamp = vehicle.status?.timestamp;
  const latestVehicleTemperatures = vehicle.latestVehicleTemperatures ?? [];

  return (
    <div className="ui grid vehicle">
      <div className="row">
        <div className="height wide column vehicle__details">
          <div className="ui large header">Vehicle #{vehicle.vehicle_id}</div>
          <div>
            <NavLink
              to={`${Routes.Tours.generatePath({})}?vehicle_id=${vehicle.vehicle_id}&carrier_id=${
                vehicle.company.company_id
              }`}
              className="ui button mini">
              Tours with this vehicle
            </NavLink>
            <ConfirmButton
              initialButtonColor="red"
              label="Delete this vehicle"
              className="mini"
              disabled={!isPrivilegedUser}
              onConfirm={() => props.deleteVehicle(vehicle.vehicle_id)}
            />
          </div>
          <Table structured basic compact="very" size="small">
            <Table.Body>
              <Table.Row verticalAlign="top">
                <Table.Cell width={3} rowSpan="5">
                  <b>General</b>
                </Table.Cell>
                <Table.Cell width={5}>License Plate</Table.Cell>
                <Table.Cell>{vehicle.license_plate_number ?? '-'}</Table.Cell>
              </Table.Row>
              <Table.Row verticalAlign="top">
                <Table.Cell width={5}>Company</Table.Cell>
                <Table.Cell>
                  <NavLink to={Routes.Company.generatePath({ company_id: vehicle.company.company_id })}>
                    {vehicle.company.company_name ?? '-'}
                  </NavLink>
                </Table.Cell>
              </Table.Row>
              <Table.Row verticalAlign="top">
                <Table.Cell width={5}>Last status update</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={vehicleStatusTimestamp} />
                </Table.Cell>
              </Table.Row>
              <Table.Row verticalAlign="top">
                <Table.Cell width={5}>Is in global vehicle pool</Table.Cell>
                <Table.Cell>{vehicle.inGlobalPool?.toString() ?? 'unknown'}</Table.Cell>
              </Table.Row>
              <Table.Row verticalAlign="top">
                <Table.Cell width={5}>Created at</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={vehicle.created_at} />
                </Table.Cell>
              </Table.Row>
              <Table.Row verticalAlign="top">
                <Table.Cell width={5}>Updated at</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={vehicle.updated_at} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div>
            <h3>Vehicle Trackers</h3>
            {vehicle.vehicleTrackers.length === 0 ? (
              <Table structured basic compact="very" size="small">
                <Table.Body>
                  <Table.Row textAlign="center">
                    <Table.Cell colSpan="3">No vehicle trackers</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            ) : (
              vehicle.vehicleTrackers.map((vehicleTracker, idx) => (
                <Table compact="very" size="small" color={'grey'} key={idx}>
                  <Table.Body>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Tracker ID</Table.Cell>
                      <Table.Cell>{vehicleTracker.vehicleTrackerId}</Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Tracker Type</Table.Cell>
                      <Table.Cell>{vehicleTracker.trackerType}</Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Remote ID</Table.Cell>
                      <Table.Cell>
                        {isMobileTracker(vehicleTracker) ? vehicleTracker.mobileRemoteId : vehicleTracker.fmsRemoteId}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Integration</Table.Cell>
                      <Table.Cell>
                        {isMobileTracker(vehicleTracker) ? (
                          'Mobile Phone'
                        ) : vehicleTracker.telematicsIntegration !== null ? (
                          <NavLink
                            to={Routes.CompanyTelematicsIntegration.generatePath({
                              company_id: vehicle.company.company_id,
                              telematics_integration_id: vehicleTracker.telematicsIntegration?.telematicsIntegrationId,
                            })}>
                            {vehicleTracker.telematicsIntegration?.telematicsProvider.telematicsProviderName}
                          </NavLink>
                        ) : (
                          '–'
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Tracker data</Table.Cell>
                      <Table.Cell>
                        {vehicleTracker.trackerData !== null ? (
                          <DataToList data={vehicleTracker.trackerData}>
                            {({ key, value }) => (
                              <div>
                                {key}: {value}
                              </div>
                            )}
                          </DataToList>
                        ) : (
                          '-'
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Created at</Table.Cell>
                      <Table.Cell>
                        <FormattedDate date={vehicleTracker.createdAt} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign="top">
                      <Table.Cell width={4}>Updated at</Table.Cell>
                      <Table.Cell>
                        <FormattedDate date={vehicleTracker.updatedAt} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ))
            )}
          </div>
          <div className="vehicle__details__temperature">
            <h3>Latest Vehicle Temperatures</h3>
            <Table compact="very" size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Reefer ID</Table.HeaderCell>
                  <Table.HeaderCell>Timestamp</Table.HeaderCell>
                  <Table.HeaderCell>Event Level</Table.HeaderCell>
                  <Table.HeaderCell>Event Source</Table.HeaderCell>
                  <Table.HeaderCell>Event Origin</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {latestVehicleTemperatures.length === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan="6" textAlign="center">
                      No temperatures
                    </Table.Cell>
                  </Table.Row>
                )}
                {latestVehicleTemperatures.length > 0 &&
                  latestVehicleTemperatures.map((vehicleTemperature, idx) => {
                    return <LatestVehicleTemperatureRow vehicleTemperature={vehicleTemperature} key={idx} />;
                  })}
              </Table.Body>
            </Table>
          </div>
          <div className="ui divider" />
          <BreaksInfo data={props.data} />
        </div>
        <div className="height wide column">
          <ErrorBoundary renderError={(error) => <ErrorPlaceholder error={error} />}>
            <MapSlider
              initialTimestamp={props.timeline.selectedTimestamp}
              timelineBoundaries={{
                startTime: props.timeline.startTime,
                endTime: props.timeline.endTime,
              }}
              vehicles={[
                {
                  history,
                  breakHistory,
                  licensePlateNumber: vehicle.license_plate_number,
                  vehicleId: vehicle.vehicle_id,
                },
              ]}>
              {({ selectedPositions }) => (
                <VehiclesMap
                  data={{
                    vehicles: [
                      {
                        ...vehicle,
                        breakHistory,
                        history,
                        ghost: selectedPositions[0],
                      },
                    ],
                  }}
                />
              )}
            </MapSlider>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
