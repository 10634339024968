import { BackofficeScope } from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Company, CompanyGrantRelationship } from '../../company';
import { ConfirmButton } from '../../components/confirm_button';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CreateAllVehicleGrantsMutationVariables, RevokeAllVehicleGrantsMutationVariables } from '../../lib/graphql';
import { Routes } from '../../routes';

export interface VehicleGrantListDataProps {
  data: {
    companyRelationships: CompanyGrantRelationship[];
    company_id: string;
    company: Company;
  };
}

interface VehicleGrantListProps {
  createVehicleGrants: (input: CreateAllVehicleGrantsMutationVariables['input']) => Promise<void>;
  revokeVehicleGrants: (input: RevokeAllVehicleGrantsMutationVariables['input']) => Promise<void>;
}

class RelationshipTable extends Table<CompanyGrantRelationship> {}

export const VehicleGrantList: React.FC<VehicleGrantListProps & VehicleGrantListDataProps> = ({
  data,
  createVehicleGrants,
  revokeVehicleGrants,
}) => {
  const { companyRelationships, company } = data;
  const isNotAdmin = !useIsAdmin();
  const hasCompanyVehicleGrantPermission = useHasScopes([BackofficeScope.companyWriteVehicles]);
  const isPrivilegedUser = !isNotAdmin || hasCompanyVehicleGrantPermission;

  return (
    <div>
      <h2>Vehicle Grants</h2>
      <p>
        This table contains all companies that <strong>{company.company_name}</strong> is a subcarrier of and can grant
        vehicles to.
        <br /> To add companies to this list,{' '}
        <NavLink
          to={Routes.CompanyRelationshipCreate.generatePath({ company_id: company.company_id })}
          className="item">
          add a subcarrier relationship between {company.company_name} and that company
        </NavLink>
      </p>
      <RelationshipTable
        className="ui very basic sortable table"
        data={companyRelationships ?? []}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { keyPath: 'targetCompany.company_name', value: 'Company' },
            { value: 'Vehicles' },
            { value: 'Granted vehicles' },
            { value: 'Actions' },
          ],
        }}
        emptyStatePlaceholder={emptyTableState('No subcarrier relationships defined for this company')}>
        {({ row }) => {
          const { companyRelationshipId, targetCompany, sourceCompany } = row.data;
          return (
            <tr key={companyRelationshipId}>
              <td>
                <NavLink
                  to={Routes.CompanyVehicleGrants.generatePath({ company_id: targetCompany.company_id })}
                  className="item">
                  {targetCompany.company_name}
                </NavLink>
              </td>
              <td>{sourceCompany.vehicles?.all?.count}</td>
              <td>{targetCompany.vehicleGrantPartner?.grantCount ?? 0}</td>
              <td>
                <ConfirmButton
                  label="Grant all"
                  disabled={!isPrivilegedUser}
                  initialButtonColor="green"
                  onConfirm={async () =>
                    await createVehicleGrants({
                      grantedByCompany: sourceCompany.company_id,
                      grantedToCompany: targetCompany.company_id,
                    })
                  }
                />
                <ConfirmButton
                  label="Revoke all"
                  disabled={!isPrivilegedUser}
                  initialButtonColor="red"
                  onConfirm={async () =>
                    await revokeVehicleGrants({
                      revokedByCompany: sourceCompany.company_id,
                      revokedFromCompany: targetCompany.company_id,
                    })
                  }
                />
              </td>
            </tr>
          );
        }}
      </RelationshipTable>
    </div>
  );
};
