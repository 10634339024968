import classnames from 'classnames';
import React from 'react';

export const getTableHeaderClassNames = (sortBy: 'ASC' | 'DESC' | null) =>
  classnames({
    sorted: Boolean(sortBy),
    ascending: sortBy === 'ASC',
    descending: sortBy === 'DESC',
  });

export const emptyTableState = (placeholder: React.ReactNode) => {
  return (
    <tbody>
      <tr>
        <td colSpan={10}>
          <div className="ui message">{placeholder}</div>
        </td>
      </tr>
    </tbody>
  );
};
