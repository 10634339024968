import React from 'react';
import { Label } from 'semantic-ui-react';

interface Props {
  tags: { name: string }[];
}

export const Tags: React.FunctionComponent<Props> = ({ tags }) => (
  <>
    {tags.map((tag) => (
      <Label size="small" key={tag.name}>
        <span style={{ whiteSpace: 'nowrap' }}>{tag.name}</span>
      </Label>
    ))}
  </>
);
