import { Form as FormFactory } from '@sixfold/form-component';
import { BackofficeScope } from '@sixfold/session-interface';
import { History } from 'history';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '../../components/button';
import { ConfirmButton } from '../../components/confirm_button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CompanyUserQuery, UserPersonaInCompany, UserRoleInCompany } from '../../lib/graphql';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';

export type UserInCompanyFormData = {
  first_name?: string;
  last_name?: string;
  email: string;
  role: UserRoleInCompany;
  persona: UserPersonaInCompany | '';
};

export interface UserInCompanyViewDataProps {
  data?: CompanyUserQuery['company'];
}

interface RouteProps {
  routeProps: {
    push: History['push'];
    goBack: History['goBack'];
  };
}

type Props = UserInCompanyViewDataProps &
  RouteProps & {
    company: { company_id: string };
    isNewUser: boolean;
    addUserToCompany?: (input: {
      email: string;
      companyId: string;
      role?: UserRoleInCompany;
      firstName?: string | null;
      lastName?: string | null;
    }) => Promise<{ userId: string; companyId: string } | null>;
    removeUserFromCompany?: (input: { userId: string; companyId: string }) => Promise<void>;
    changeUserRoleInCompany?: (input: { userId: string; companyId: string; role: UserRoleInCompany }) => Promise<void>;
    changeUserMetadataInCompany?: (input: {
      userId: string;
      companyId: string;
      metadata: { persona: UserPersonaInCompany | null } | null;
    }) => Promise<void>;
  };

class Form extends FormFactory<UserInCompanyFormData> {}

export const UserInCompanyView: React.FC<Props> = ({
  routeProps,
  addUserToCompany,
  removeUserFromCompany,
  changeUserRoleInCompany,
  changeUserMetadataInCompany,
  data,
  company,
  isNewUser,
}) => {
  const userInCompany = data?.user ?? null;
  const hasCompanyUserEditPermission = useHasScopes([BackofficeScope.companyWriteUsers]);
  const isPrivilegedUser = !useIsAdmin() || hasCompanyUserEditPermission;

  if (!isNewUser && userInCompany === null) {
    return <div>This user does not belong to this company</div>;
  }

  return (
    <React.Fragment>
      {userInCompany !== null && (
        <h3>
          <span style={{ marginRight: 20 }}>
            {`#${userInCompany.user.user_id} ${userInCompany.user.first_name} ${userInCompany.user.last_name}`.trim()}
          </span>
          <NavLink
            to={Routes.User.generatePath({ user_id: userInCompany.user.user_id })}
            className="ui right floated button">
            View user
          </NavLink>
        </h3>
      )}
      <Form
        validateFields={{ email: { exist: 'Missing email' } }}
        initialValues={{
          first_name: userInCompany?.user.first_name ?? '',
          last_name: userInCompany?.user.last_name ?? '',
          email: userInCompany?.user.email ?? '',
          role: userInCompany?.role ?? UserRoleInCompany.REGULAR_USER,
          persona: userInCompany?.metadata?.persona ?? '',
        }}
        onSubmit={async (form) => {
          if (userInCompany === null && addUserToCompany !== undefined) {
            const createdUserInCompany = await addUserToCompany({
              email: form.email,
              companyId: company.company_id,
              firstName: form.first_name,
              lastName: form.last_name,
              role: form.role,
            });

            if (createdUserInCompany !== null && changeUserMetadataInCompany !== undefined && form.persona !== '') {
              // Metadata must be saved separately (as the mutation for doing so is BO limited)
              await changeUserMetadataInCompany({
                userId: createdUserInCompany.userId,
                companyId: company.company_id,
                metadata: { persona: form.persona },
              });
            }
          }

          if (userInCompany !== null) {
            // Existing user was edited
            if (changeUserRoleInCompany !== undefined && userInCompany.role !== form.role) {
              await changeUserRoleInCompany({
                userId: userInCompany.user.user_id,
                companyId: company.company_id,
                role: form.role,
              });
            }

            if (changeUserMetadataInCompany !== undefined && userInCompany?.metadata?.persona !== form.persona) {
              await changeUserMetadataInCompany({
                userId: userInCompany.user.user_id,
                companyId: company.company_id,
                metadata: form.persona !== '' ? { persona: form.persona } : null,
              });
            }
          }

          return routeProps.push(`/companies/${company.company_id}/users`);
        }}>
        {({ form, errors, onChange, onSubmit, isSubmittingForm }) => {
          return (
            <form className="ui form" onSubmit={onSubmit}>
              <h4 className="ui dividing header">User information</h4>
              <div className="two fields">
                <div className="field">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={form.first_name}
                    onChange={onChange}
                    disabled={!isPrivilegedUser || addUserToCompany === undefined}
                  />
                </div>
                <div className="field">
                  <label>Last name</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={form.last_name}
                    onChange={onChange}
                    disabled={!isPrivilegedUser || addUserToCompany === undefined}
                  />
                </div>
              </div>
              <div className="two fields">
                <div className="field">
                  <label>{errors.email ?? 'Email'}</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={onChange}
                    disabled={!isPrivilegedUser || addUserToCompany === undefined}
                  />
                </div>
              </div>
              <h4 className="ui dividing header">Role & Persona</h4>
              <div className="two fields">
                <div className="field">
                  <label>User role in company</label>
                  <select
                    className="ui fluid search dropdown"
                    name="role"
                    value={form.role}
                    onChange={onChange}
                    disabled={!isPrivilegedUser}>
                    <option value={UserRoleInCompany.REGULAR_USER}>
                      {beautifyString(UserRoleInCompany.REGULAR_USER)}
                    </option>
                    <option value={UserRoleInCompany.ADMIN}>{beautifyString(UserRoleInCompany.ADMIN)}</option>
                  </select>
                </div>
                <div className="field">
                  <label>User persona in company</label>
                  <select
                    className="ui fluid search dropdown"
                    name="persona"
                    value={form.persona}
                    onChange={onChange}
                    disabled={!isPrivilegedUser}>
                    <option value="">Unknown or Not applicable</option>
                    <option value={UserPersonaInCompany.CUSTOMER_SUPPORT}>
                      {beautifyString(UserPersonaInCompany.CUSTOMER_SUPPORT)}
                    </option>
                    <option value={UserPersonaInCompany.TRANSPORT_PLANNER}>
                      {beautifyString(UserPersonaInCompany.TRANSPORT_PLANNER)}
                    </option>
                    <option value={UserPersonaInCompany.WAREHOUSE_MANAGER}>
                      {beautifyString(UserPersonaInCompany.WAREHOUSE_MANAGER)}
                    </option>
                    <option value={UserPersonaInCompany.DEVELOPER}>
                      {beautifyString(UserPersonaInCompany.DEVELOPER)}
                    </option>
                  </select>
                </div>
              </div>
              <Button type="submit" primary loading={isSubmittingForm} disabled={!isPrivilegedUser || isSubmittingForm}>
                {addUserToCompany === undefined ? 'Update' : 'Add user to company'}
              </Button>
              <Button type="button" onClick={() => routeProps.goBack()}>
                Cancel
              </Button>

              {userInCompany !== null && removeUserFromCompany !== undefined && (
                <ConfirmButton
                  disabled={!isPrivilegedUser}
                  initialButtonColor="red"
                  label="Remove from company"
                  className="right floated"
                  onConfirm={async () => {
                    await removeUserFromCompany({ userId: userInCompany.user.user_id, companyId: company.company_id });
                    routeProps.push(`/companies/${company.company_id}/users`);
                  }}
                />
              )}
            </form>
          );
        }}
      </Form>
      {addUserToCompany !== undefined && (
        <div className="helptext">
          <p>Already existing users (potentially belonging to another company) are matched using the email address</p>
        </div>
      )}
    </React.Fragment>
  );
};
