import { gql } from 'graphql-tag';

export const companyPlatformsWithPlatforms = gql`
  query CompanyPlatformsWithPlatforms($company_id: String!) {
    platforms(first: 1000) {
      edges {
        node {
          platform_id
          platform_name
        }
      }
    }
    company(company_id: $company_id) {
      company_id
      platforms {
        platform_id
        platform_name
      }
    }
  }
`;

export const companyPlatformsListQuery = gql`
  query CompanyPlatformsList($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      platforms {
        platform_id
        platform_name
        company_platform_id
        company_id_on_platform
      }
    }
  }
`;

export const getCompanyPlatformQuery = gql`
  query CompanyPlatform($company_id: String!, $platform_id: String!) {
    companyPlatform(company_id: $company_id, platform_id: $platform_id) {
      platform_name
      platform_id
      company_id
      company_platform_id
      company_id_on_platform
    }
  }
`;

export const deleteCompanyPlatformMutation = gql`
  mutation DeleteCompanyPlatform($company_id: String!, $input: DeleteCompanyPlatformInput) {
    deleteCompanyPlatform(company_id: $company_id, input: $input)
  }
`;

export const createCompanyPlatformMutation = gql`
  mutation CreateCompanyPlatform($company_id: String!, $input: CompanyPlatformInput) {
    createCompanyPlatform(company_id: $company_id, input: $input) {
      platform_name
      platform_id
      company_id
      company_platform_id
      company_id_on_platform
    }
  }
`;
