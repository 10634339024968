import { AuthService } from '@sixfold/login-container';
import { hasOneOfScopes } from '@sixfold/session-interface';
import { default as ApolloClient } from 'apollo-client';
import { gql } from 'graphql-tag';
import React from 'react';
import { ApolloContext } from 'react-apollo';

import { BackofficeRole, ViewerBackofficeRoleQuery } from '../graphql';

const backofficeRoleQuery = gql`
  query ViewerBackofficeRole {
    viewer {
      backoffice_role
      backoffice_scopes
    }
  }
`;

export function useIsAdmin() {
  // TODO: Replace this with an apollo hook once apollo-client and react-apollo have been upgraded
  const context = React.useContext(ApolloContext);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(() => {
    if (context === undefined) {
      return false;
    }

    const { client } = context;

    try {
      const data = client?.readQuery<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery });
      return data?.viewer?.backoffice_role === BackofficeRole.admin;
    } catch {
      return false;
    }
  });

  React.useEffect(() => {
    if (context === undefined) {
      return;
    }

    const { client } = context;

    client
      ?.query<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery, fetchPolicy: 'cache-first' })
      .then((result) => {
        setIsAdmin(result?.data?.viewer?.backoffice_role === BackofficeRole.admin);
      })
      .catch(console.error);
  }, [context]);

  return isAdmin;
}

export function useHasScopes(requiredScopes: string[]) {
  const context = React.useContext(ApolloContext);
  const [hasScopes, setHasScopes] = React.useState<boolean>(() => {
    if (context === undefined) {
      return false;
    }

    const { client } = context;
    try {
      const data = client?.readQuery<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery });
      return hasOneOfScopes(data?.viewer?.backoffice_scopes ?? [], requiredScopes);
    } catch {
      return false;
    }
  });

  React.useEffect(() => {
    if (context === undefined) {
      return;
    }

    const { client } = context;
    client
      ?.query<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery, fetchPolicy: 'cache-first' })
      .then((result) => {
        setHasScopes(hasOneOfScopes(result.data?.viewer?.backoffice_scopes ?? [], requiredScopes));
      })
      .catch(console.error);
  }, [context, requiredScopes]);

  return hasScopes;
}

export function useIsAuthenticated(client: ApolloClient<object>) {
  // TODO: Replace this with an apollo hook once apollo-client and react-apollo have been upgraded
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(() => {
    try {
      const data = client.readQuery<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery });
      return data?.viewer?.backoffice_role !== undefined;
    } catch {
      return false;
    }
  });

  React.useEffect(() => {
    client
      .query<ViewerBackofficeRoleQuery>({ query: backofficeRoleQuery, fetchPolicy: 'cache-first' })
      .then((result) => {
        setIsAuthenticated(result?.data?.viewer?.backoffice_role !== undefined);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  }, [client]);

  return { isAuthenticated, isLoading };
}

export const getAuthService = () => {
  const authService: AuthService = {
    async authenticate(serviceIdToken: string) {
      const response = await fetch('/authenticate', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ token: serviceIdToken }),
      });

      const data = await response.json();

      if (data.error) {
        return await Promise.reject(data);
      }

      // TODO: We shouldn't need this, the redirect should happen in some other way
      window.location.href = '/';
      return await Promise.resolve({ isLoggedIn: true });
    },
  };

  return authService;
};
