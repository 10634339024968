import { Loader } from '@sixfold/loader-container';
import { parseQueryString, OptionalString } from '@sixfold/query-string';
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { TourMapContainer } from './tour_map_container';
import { Mutations as TourTagMutations } from './tour_tag_container';
import {
  TourQuery,
  TourVehicleHistoryQuery,
  AbortTourMutation,
  RecalculateTourMutation,
  notEmpty,
  TourQueryVariables,
  AbortTourMutationVariables,
  RecalculateTourMutationVariables,
} from '../../lib/graphql';
import {
  TIMELINE_END_TIME_QS,
  TIMELINE_START_TIME_QS,
  HISTORY_SIMPLIFICATION_QS,
} from '../../map/components/map_slider';
import { mergeTourVehiclesWithVehiclesHistory } from '../../vehicle/utils';
import { HighlightedStopIdProvider } from '../components/stop_highlight_context';
import { TourDetailsView } from '../components/tour_details';
import { Tour } from '../entities';
import { tourQuery, abortTourMutation, recalculateTourMutation, vehicleHistoryQuery } from '../graphql';
import { extractVehicleHistoryVariables, transformCombinedEventsToEvents } from '../utils';

class LoadingContainer extends Loader<TourQuery, Tour | null, TourQueryVariables> {}
class HistoryQueryComponent extends Query<TourVehicleHistoryQuery> {}
class TourContainerQuery extends Query<TourQuery, TourQueryVariables> {}
class TourContainerAbortTourMutation extends Mutation<AbortTourMutation, AbortTourMutationVariables> {}
class TourContainerRecalculateTourMutation extends Mutation<
  RecalculateTourMutation,
  RecalculateTourMutationVariables
> {}

export const TourContainer = (props: RouteComponentProps<{ tour_id: string }>) => {
  const { tour_id } = props.match.params;
  const { history_simplification_tolerance, timeline_start, timeline_end } = parseQueryString({
    queryParametersToParse: {
      [HISTORY_SIMPLIFICATION_QS]: OptionalString,
      [TIMELINE_START_TIME_QS]: OptionalString,
      [TIMELINE_END_TIME_QS]: OptionalString,
    },
    queryString: props.location.search,
  });

  const [tourSidebarActive, setTourSidebarActive] = React.useState<boolean>(true);

  return (
    <HighlightedStopIdProvider>
      <TourContainerQuery query={tourQuery} variables={{ tour_id: parseInt(tour_id, 10) }}>
        {(result) => (
          <TourContainerAbortTourMutation mutation={abortTourMutation}>
            {(abortTour) => (
              <TourContainerRecalculateTourMutation mutation={recalculateTourMutation}>
                {(recalculateTour) => (
                  <LoadingContainer
                    result={result}
                    mapData={({ data }) => {
                      const { tour, availableTourTags } = data;
                      if (tour === null || tour === undefined) {
                        return null;
                      }
                      return {
                        ...tour,
                        availableTourTags,
                        events: transformCombinedEventsToEvents(tour.events),
                        stops: (tour.stops ?? []).filter(notEmpty).map((stop) => ({
                          ...stop,
                          status_events: (stop.status_events ?? []).filter(notEmpty),
                          geofenceZones: stop.geofenceZones,
                        })),
                        route:
                          tour.route !== null
                            ? {
                                provider: tour.route.provider,
                                legs: (tour.route.legs ?? []).filter(notEmpty).filter((leg) => leg.polyline !== null),
                              }
                            : null,
                        status_events: (tour.status_events ?? []).filter(notEmpty),
                      } as Tour;
                    }}>
                    {(tour) => {
                      if (tour === null) {
                        return <span>No tour to display</span>;
                      }

                      const vehicleHistoryQueryVariables =
                        timeline_start !== undefined && timeline_end !== undefined
                          ? {
                              tour_id: tour.tour_id,
                              start_time: new Date(timeline_start),
                              end_time: new Date(timeline_end),
                            }
                          : extractVehicleHistoryVariables(tour);
                      const tourActions = {
                        onTourAbort: (tourId: number) => abortTour({ variables: { tour_id: tourId } }),
                        onTourRecalculate: (tourId: number) => recalculateTour({ variables: { tour_id: tourId } }),
                        onAddTourTag: () => false,
                        onRemoveTourTag: () => false,
                      };

                      return (
                        <div className="ui grid tour">
                          {tourSidebarActive && (
                            <TourTagMutations>
                              {({ addTourTag, removeTourTag }) => (
                                <TourDetailsView
                                  className="six wide mobile six wide tablet six wide computer six wide large screen five wide widescreen column"
                                  data={{
                                    tour,
                                    availableTourTags: tour.availableTourTags,
                                  }}
                                  {...tourActions}
                                  onAddTourTag={(options) =>
                                    addTourTag({ variables: { tour_id: options.tourId, tag_name: options.tagName } })
                                  }
                                  onRemoveTourTag={(options) =>
                                    removeTourTag({ variables: { tour_id: options.tourId, tag_name: options.tagName } })
                                  }
                                />
                              )}
                            </TourTagMutations>
                          )}
                          <HistoryQueryComponent
                            {...props}
                            query={vehicleHistoryQuery}
                            variables={{
                              ...(vehicleHistoryQueryVariables !== null ? vehicleHistoryQueryVariables : {}),
                              simplification_tolerance:
                                history_simplification_tolerance !== undefined
                                  ? parseFloat(history_simplification_tolerance)
                                  : undefined,
                            }}>
                            {({ data }) => {
                              const vehicles = mergeTourVehiclesWithVehiclesHistory(
                                data,
                                tour.vehicles,
                                vehicleHistoryQueryVariables.end_time,
                              );
                              return (
                                <TourMapContainer
                                  {...props}
                                  tour={tour}
                                  vehicles={vehicles}
                                  tourSidebarState={{
                                    tourSidebarActive,
                                    setTourSidebarActive,
                                  }}
                                  historyFetchingBoundaries={{
                                    startTime: vehicleHistoryQueryVariables.start_time.toISOString(),
                                    endTime: vehicleHistoryQueryVariables.end_time.toISOString(),
                                  }}
                                />
                              );
                            }}
                          </HistoryQueryComponent>
                        </div>
                      );
                    }}
                  </LoadingContainer>
                )}
              </TourContainerRecalculateTourMutation>
            )}
          </TourContainerAbortTourMutation>
        )}
      </TourContainerQuery>
    </HighlightedStopIdProvider>
  );
};
