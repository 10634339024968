import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  getNodes,
  CompanyUserGroupsOnPlatformQuery,
  CompanyUserGroupsOnPlatformQueryVariables,
  PAGINATION_PAGE_SIZE,
  loadMoreFromConnection,
} from '../../lib/graphql';
import { UserGroupsOnPlatformView } from '../components/user_groups_on_platform';
import { UserGroupOnPlatform } from '../entities';
import { companyUserGroupsQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyUserGroupsOnPlatformQuery,
  { userGroupsOnPlatform: UserGroupOnPlatform[] },
  CompanyUserGroupsOnPlatformQueryVariables
> {}
class CompanyUserGroupsQuery extends Query<
  CompanyUserGroupsOnPlatformQuery,
  CompanyUserGroupsOnPlatformQueryVariables
> {}

export const CompanyUserGroupsContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const { company_id } = props.match.params;

  return (
    <CompanyUserGroupsQuery
      query={companyUserGroupsQuery}
      variables={{
        limit: PAGINATION_PAGE_SIZE,
        company_id,
      }}>
      {(result) => {
        return (
          <LoadingContainer
            result={result}
            mapData={({ data }) => ({
              userGroupsOnPlatform: (getNodes(data.company?.userGroupsOnPlatform) ?? []).map((group) => ({
                ...group,
                company: group.company ?? undefined,
              })),
            })}>
            {({ userGroupsOnPlatform }) => (
              <InfiniteScroll
                triggerOnWindow={false}
                loadMoreEntries={() =>
                  loadMoreFromConnection(companyUserGroupsQuery, result, ['company', 'userGroupsOnPlatform'])
                }>
                <UserGroupsOnPlatformView userGroupsOnPlatform={userGroupsOnPlatform} company_id={company_id} />
              </InfiniteScroll>
            )}
          </LoadingContainer>
        );
      }}
    </CompanyUserGroupsQuery>
  );
};
