import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  UpdateCompanyOnboardingStatusMutation as MutationType,
  UpdateCompanyOnboardingStatusMutationVariables,
  UnifiedCompanyOnboardingStatusUpdateInput,
} from '../../lib/graphql';
import { updateCompanyOnboardingStatusMutation, companyOnboardingQuery } from '../graphql';

class UpdateCompanyOnboardingStatus extends Mutation<MutationType, UpdateCompanyOnboardingStatusMutationVariables> {}

interface Props {
  children: Children<{
    updateCompanyOnboardingStatus: (
      companyId: string,
      input: UnifiedCompanyOnboardingStatusUpdateInput,
    ) => Promise<void>;
  }>;
}

export const UpdateCompanyOnboardingStatusMutation: React.SFC<Props> = ({ children }) => {
  const notify = useNotifications();

  return (
    <UpdateCompanyOnboardingStatus mutation={updateCompanyOnboardingStatusMutation}>
      {(updateCompanyOnboardingStatus) =>
        renderChildren(children, {
          updateCompanyOnboardingStatus: async (
            companyId: string,
            input: UnifiedCompanyOnboardingStatusUpdateInput,
          ) => {
            const response = await updateCompanyOnboardingStatus({
              refetchQueries: [
                {
                  query: companyOnboardingQuery,
                  variables: { company_id: companyId },
                },
              ],
              variables: {
                company_id: companyId,
                input,
              },
            });
            notify.success({ title: 'Company onboarding status updated' });
            return response;
          },
        })
      }
    </UpdateCompanyOnboardingStatus>
  );
};
