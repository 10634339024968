import { isNil, notNil } from '@sixfold/typed-primitives';
import React from 'react';

import { useTimeZone } from './timezone_context';
import { toTimezoneDateTime } from '../../lib/date';

interface FormattedDateProps {
  date: string | Date | null | undefined;
  propsTimezone?: string;
  showDate?: boolean;
  showTime?: boolean;
  showTimezone?: boolean;
  additionalMessage?: string;
  showSecondsAndMillis?: boolean;
}

export const FormattedDate: React.FunctionComponent<FormattedDateProps> = React.memo(
  function FormattedDate({
    date,
    propsTimezone,
    showDate = true,
    showTime = true,
    showTimezone = true,
    additionalMessage = '',
    showSecondsAndMillis = false,
  }) {
    const { timeZone } = useTimeZone();

    if (isNil(date) || (!showDate && !showTime)) {
      return <>{' - '}</>;
    }

    const timezoneToUse = notNil(propsTimezone) ? propsTimezone : timeZone;
    const datetime = toTimezoneDateTime(date, timezoneToUse);

    const dateFormat = 'dd.LL.yyyy';
    const timeFormat = `HH:mm${showSecondsAndMillis ? ':ss.SSS' : ''}`;
    let returnFormat = `${dateFormat} ${timeFormat}`;

    if (showTime && !showDate) {
      returnFormat = timeFormat;
    } else if (!showTime && showDate) {
      returnFormat = dateFormat;
    }
    return (
      <>{`${datetime.toFormat(returnFormat)}${
        showTimezone && timezoneToUse !== 'local' ? ` (${timezoneToUse})` : ''
      }${additionalMessage}`}</>
    );
  },
  (previous, next) =>
    previous.showDate === next.showDate &&
    previous.showTime === next.showTime &&
    previous.showSecondsAndMillis === next.showSecondsAndMillis &&
    previous.propsTimezone === next.propsTimezone &&
    previous.showTimezone === next.showTimezone &&
    previous.additionalMessage === next.additionalMessage &&
    notNil(previous.date) &&
    notNil(next.date) &&
    previous.date?.valueOf() === next.date?.valueOf(),
);
