import React from 'react';

import { TimeZoneProvider } from '../../components/date_formatting/timezone_context';
import { Workspace } from '../components/workspace';

type Props = {
  onSignout(): Promise<void>;
};

export const WorkspaceContainer: React.StatelessComponent<Props> = ({ onSignout }) => {
  return (
    <TimeZoneProvider>
      <Workspace onSignout={onSignout} />
    </TimeZoneProvider>
  );
};
