import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserContainer } from './user_container';
import { UserListContainer } from './user_list_container';
import { Routes } from '../../routes';

export const UsersContainer = () => (
  <Switch>
    <Route path={Routes.User.routerPath} component={UserContainer} />
    <Route path={Routes.Users.routerPath} component={UserListContainer} />
  </Switch>
);
