import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import {
  CompanyConfigurationQuery,
  CompanyConfigurationQueryVariables,
  UpdateCompanyConfigurationMutation,
  UpdateCompanyConfigurationMutationVariables,
} from '../../lib/graphql';
import { CompanyConfigurationViewProps, CompanyConfigurationView } from '../components/company_configuration';
import { companyConfigurationQuery, updateCompanyConfigurationMutation } from '../graphql';

class LoadingContainer extends Loader<
  CompanyConfigurationQuery,
  CompanyConfigurationViewProps,
  CompanyConfigurationQueryVariables
> {}
class CompanyConfigurationContainerQuery extends Query<CompanyConfigurationQuery, CompanyConfigurationQueryVariables> {}

class CompanyConfigurationContainerUpdateConfigurationMutation extends Mutation<
  UpdateCompanyConfigurationMutation,
  UpdateCompanyConfigurationMutationVariables
> {}

export const CompanyConfigurationContainer = (props: RouteComponentProps<{ company_id: string }>) => (
  <CompanyConfigurationContainerQuery
    query={companyConfigurationQuery}
    variables={{
      company_id: props.match.params.company_id,
    }}>
    {(result) => {
      return (
        <CompanyConfigurationContainerUpdateConfigurationMutation mutation={updateCompanyConfigurationMutation}>
          {(updateCompanyConfiguration) => (
            <LoadingContainer
              result={result}
              mapData={({ data }) => ({
                data: { company: data.company ?? null, __typename: 'Query' },
                updateConfiguration: async (input: UpdateCompanyConfigurationMutationVariables['input']) => {
                  await updateCompanyConfiguration({
                    refetchQueries: [
                      {
                        query: companyConfigurationQuery,
                        variables: { company_id: props.match.params.company_id },
                      },
                    ],
                    variables: {
                      input,
                    },
                  });
                },
              })}>
              {({ data, updateConfiguration }) => (
                <CompanyConfigurationView data={data} updateConfiguration={updateConfiguration} />
              )}
            </LoadingContainer>
          )}
        </CompanyConfigurationContainerUpdateConfigurationMutation>
      );
    }}
  </CompanyConfigurationContainerQuery>
);
