import { interpolate } from './string';
import { getEmbedConfig } from '../data';

export const shipmentViewUrl = (companyId: string | null, tourId: string | null, mode: 'road' | 'ocean') => {
  const config = getEmbedConfig();

  if (config === null || companyId === null || tourId === null) {
    return;
  }
  return interpolate(mode === 'ocean' ? config.ocean_shipment_view_url : config.shipment_view_url, {
    company_id: companyId,
    tour_id: tourId,
  });
};

export const shipmentsViewUrl = (companyId: string | null) => {
  const config = getEmbedConfig();

  if (config === null || companyId === null) {
    return;
  }

  return interpolate(config.shipments_view_url, { company_id: companyId });
};

export const classicMapViewByCoordinatesUrl = ({ lat, lng }: { lat: number | null; lng: number | null }) => {
  const config = getEmbedConfig();

  if (lat === null || lng === null || config === null) {
    return;
  }

  return interpolate(config.google_maps_lat_lng_url, {
    lat,
    lng,
  });
};

export const classicMapViewByAddressUrl = ({ address }: { address: string }) => {
  const config = getEmbedConfig();

  if (config === null) {
    return;
  }

  return interpolate(config.google_maps_address_url, {
    address: encodeURIComponent(address),
  });
};

export const openSatelliteViewUrl = ({ lat, lng }: { lat: number | null; lng: number | null }) => {
  const config = getEmbedConfig();

  if (lat === null || lng === null || config === null) {
    return;
  }
  return window.open(interpolate(config.satelliteview_lat_lng_url, { lat, lng }), '_blank');
};

export const tourStopEtaHistoryUrl = ({ tourId, stopId }: { tourId: number; stopId: number }) => {
  const config = getEmbedConfig();

  if (config === null) {
    return;
  }

  const stopSpecification = {
    'ds0.tour_id': tourId.toString(),
    'ds0.stop_id': stopId.toString(),
  };

  return interpolate(config.data_studio_stop_eta_dashboard_url, {
    params: encodeURIComponent(JSON.stringify(stopSpecification)),
  });
};
