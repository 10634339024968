import { Button, Input, Modal, ModalFooter, ModalHeader, ModalMain, Stack, Textarea } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import { isNil, notNil, nullToUndefined } from '@sixfold/typed-primitives';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Label } from 'semantic-ui-react';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { InfiniteScroll } from '../../components/infinitescroll';
import {
  getNodes,
  loadMoreFromConnection,
  TelematicsIntegrationDiagnosticStatus,
  TelematicsProviderContactInfo,
  TelematicsProviderQuery,
  TelematicsProviderQueryVariables,
  UpdateTelematicsIntegrationMutation,
  UpdateTelematicsIntegrationMutationVariables,
  UpdateTelematicsProviderMutation,
  UpdateTelematicsProviderMutationVariables,
} from '../../lib/graphql';
import { embedURLsInText, IntegrationList, IntegrationListProps } from '../components/integration_list';
import {
  telematicsProviderQuery,
  updateTelematicsIntegrationMutation,
  updateTelematicsProviderMutation,
} from '../graphql';

export interface ProviderIntegrationsProps {
  data: {
    name: string;
    integrationInstructionsURL: string | null;
    isInternalUseOnly: boolean;
    isIntegrationImplemented: boolean;
    isStandardTelematicsPushProvider: boolean | null;
    isVehicleManagementEnabled: boolean | null;
    isExternalConfigurationNeeded: boolean | null;
    isGlobalAllocationForbidden: boolean | null;
    isDeprecated: boolean;
    contact_info: TelematicsProviderContactInfo | null;
    integrations: IntegrationListProps['data']['integrations'];
  } | null;
}

class LoadingContainer extends Loader<
  TelematicsProviderQuery,
  ProviderIntegrationsProps,
  TelematicsProviderQueryVariables
> {}
class TelematicsProviderContainerQuery extends Query<TelematicsProviderQuery, TelematicsProviderQueryVariables> {}

class TelematicsProviderContainerUpdateTelematicsIntegrationMutation extends Mutation<
  UpdateTelematicsIntegrationMutation,
  UpdateTelematicsIntegrationMutationVariables
> {}

class TelematicsProviderContainerUpdateTelematicsProviderMutation extends Mutation<
  UpdateTelematicsProviderMutation,
  UpdateTelematicsProviderMutationVariables
> {}

interface SetProviderInvalidProps {
  onClick: () => Promise<void>;
}

interface ProviderContactInformationProps {
  contactInfo: TelematicsProviderContactInfo | null;
  providerId: string;
}

export const SetProviderInvalidButton: React.FC<SetProviderInvalidProps> = ({ onClick }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onCancel = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const onConfirm = React.useCallback(async () => {
    await onClick();
    setIsOpen(false);
  }, [onClick]);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        size="large"
        trigger={
          <Button onClick={() => setIsOpen(true)} kind="danger" style={{ float: 'right' }}>
            Set provider invalid
          </Button>
        }
        open={isOpen}
        onToggle={handleToggle}>
        <ModalHeader title="Are you sure?" />
        <ModalMain>
          <p>
            Please make sure you only proceed if you fully understand what you are doing, as reversal may be
            challenging.
          </p>
        </ModalMain>
        <ModalFooter>
          <Stack justifyContent="end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button iconEnd="small-check" onClick={onConfirm} kind="primary">
              Confirm
            </Button>
          </Stack>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const ProviderContactInformationButton: React.FC<ProviderContactInformationProps> = ({
  contactInfo,
  providerId,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [email, setEmail] = React.useState(contactInfo?.email_address);
  const [comment, setComment] = React.useState(contactInfo?.comment);
  const [updatedAt, setUpdatedAt] = React.useState(contactInfo?.updated_at);

  const onSave = React.useCallback(
    async (updateTelematicsProvider) => {
      await updateTelematicsProvider({
        variables: {
          input: {
            telematics_provider_id: providerId,
            contact_info: {
              email_address: nullToUndefined(email),
              comment: nullToUndefined(comment),
            },
          },
        },
      });
      setIsEditing(false);
      setIsOpen(true);
    },
    [email, comment, providerId],
  );
  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);
  return (
    <TelematicsProviderContainerUpdateTelematicsProviderMutation
      mutation={updateTelematicsProviderMutation}
      onCompleted={(data) => {
        setEmail(data.updateTelematicsProvider?.contact_info?.email_address);
        setComment(data.updateTelematicsProvider?.contact_info?.comment);
        setUpdatedAt(data.updateTelematicsProvider?.contact_info?.updated_at);
      }}>
      {(updateTelematicsProvider) => (
        <div>
          <Modal
            size="xlarge"
            trigger={
              <Button
                size="small"
                hideLabel
                iconStart="small-email"
                onClick={() => setIsOpen(true)}
                style={{ marginLeft: 'auto', backgroundColor: 'var(--color-neutral-bg-accent-subtle-active)' }}>
                Email
              </Button>
            }
            onToggle={handleToggle}
            open={isOpen}>
            <ModalHeader title="Contact Information" />
            <ModalMain>
              <Grid columns={2}>
                <Grid.Column>
                  {isEditing ? (
                    <Input
                      label="Email address"
                      name="email"
                      value={nullToUndefined(email)}
                      onChange={(e) => setEmail(e.value)}
                      placeholder="Email address"
                    />
                  ) : (
                    <div>
                      <p style={{ fontWeight: 'bold', color: 'black' }}>Email address</p>
                      <p>{notNil(email) && email !== '' ? email : 'No email address available'}</p>
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column>
                  {isEditing ? (
                    <Textarea
                      label="Comment"
                      name="comment"
                      value={comment !== null ? comment : undefined}
                      onChange={(e) => setComment(e.value)}
                      placeholder="Comment"
                      rows={20}
                    />
                  ) : (
                    <div>
                      <p style={{ fontWeight: 'bold', color: 'black' }}>Comment</p>

                      {notNil(comment) && comment !== '' ? (
                        comment?.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {embedURLsInText(line)}
                            <br />
                          </React.Fragment>
                        ))
                      ) : (
                        <p>No comments</p>
                      )}
                    </div>
                  )}
                  {notNil(updatedAt) ? (
                    <div style={{ textAlign: 'right', marginTop: '50px' }}>
                      <p>Updated at: {<FormattedDate date={updatedAt} />}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </Grid.Column>
              </Grid>
            </ModalMain>
            <ModalFooter>
              <Stack justifyContent="end">
                {isEditing ? (
                  <Stack direction="row">
                    <Button color="grey" onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                    <Button kind="primary" onClick={() => onSave(updateTelematicsProvider)}>
                      Save
                    </Button>
                  </Stack>
                ) : (
                  <Button iconStart="small-pencil" kind="primary" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                )}
              </Stack>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </TelematicsProviderContainerUpdateTelematicsProviderMutation>
  );
};

export const TelematicsProviderContainer = (props: RouteComponentProps<{ provider_id: string }>) => {
  const { provider_id } = props.match.params;
  return (
    <TelematicsProviderContainerQuery
      variables={{ telematics_provider_id: provider_id, first: 1000 }}
      query={telematicsProviderQuery}>
      {(result) => (
        <LoadingContainer
          result={result}
          mapData={({ data: { telematicsProvider } }) => ({
            data: notNil(telematicsProvider)
              ? {
                  name: telematicsProvider.telematicsProviderName,
                  integrationInstructionsURL: telematicsProvider.integrationInstructionsURL,
                  isInternalUseOnly: telematicsProvider.isInternalUseOnly,
                  isIntegrationImplemented: telematicsProvider.isIntegrationImplemented,
                  isExternalConfigurationNeeded: telematicsProvider.isExternalConfigurationNeeded,
                  isVehicleManagementEnabled: telematicsProvider.isVehicleManagementEnabled,
                  isStandardTelematicsPushProvider: telematicsProvider.isStandardTelematicsPushProvider,
                  isGlobalAllocationForbidden: telematicsProvider.isGlobalAllocationForbidden,
                  isDeprecated: telematicsProvider.isDeprecated ?? false,
                  contact_info: telematicsProvider.contact_info,
                  integrations: getNodes(telematicsProvider.telematicsIntegrations.integrations),
                }
              : null,
          })}>
          {({ data }) => (
            <InfiniteScroll
              loadMoreEntries={() =>
                loadMoreFromConnection(telematicsProviderQuery, result, [
                  'telematicsProvider',
                  'telematicsIntegrations',
                  'integrations',
                ])
              }>
              {data !== null && (
                <React.Fragment>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h1 style={{ margin: 0, marginRight: '10px' }}>{data.name}</h1>
                          <ProviderContactInformationButton contactInfo={data.contact_info} providerId={provider_id} />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          {data.isInternalUseOnly && (
                            <Label color="yellow" size="tiny">
                              Internal use only
                            </Label>
                          )}
                          {data.isIntegrationImplemented && (
                            <Label color="purple" size="tiny">
                              Implemented
                            </Label>
                          )}
                          {data.isStandardTelematicsPushProvider && (
                            <Label color="green" size="tiny">
                              Standard telematics push provider
                            </Label>
                          )}
                          {data.isVehicleManagementEnabled && (
                            <Label color="blue" size="tiny">
                              Vehicle management enabled
                            </Label>
                          )}
                          {data.isExternalConfigurationNeeded && (
                            <Label color="pink" size="tiny">
                              External configuration needed
                            </Label>
                          )}
                          {data.isGlobalAllocationForbidden && (
                            <Label color="orange" size="tiny">
                              Global allocation forbidden
                            </Label>
                          )}
                          {data.isDeprecated && (
                            <Label color="red" size="tiny">
                              Deprecated
                            </Label>
                          )}
                        </div>
                        {data.integrationInstructionsURL !== null ? (
                          <div style={{ marginTop: 10 }}>
                            <Label size="tiny" style={{ marginRight: 10 }}>
                              Instructions URL is set
                            </Label>
                            <a href={data.integrationInstructionsURL}>{data.integrationInstructionsURL}</a>
                          </div>
                        ) : (
                          '-'
                        )}
                      </Grid.Column>
                      <Grid.Column verticalAlign="bottom">
                        <TelematicsProviderContainerUpdateTelematicsIntegrationMutation
                          mutation={updateTelematicsIntegrationMutation}>
                          {(updateTelematicsIntegration) => (
                            <SetProviderInvalidButton
                              onClick={async () => {
                                if (notNil(data.integrations)) {
                                  data.integrations.forEach(async (integration) => {
                                    if (isNil(integration.diagnosticStatus) && integration.enabled) {
                                      await updateTelematicsIntegration({
                                        variables: {
                                          input: {
                                            telematics_integration_id: integration.telematics_integration_id,
                                            company_integration_id: integration.company_integration_id,
                                            enabled: integration.enabled,
                                            diagnosticStatus: TelematicsIntegrationDiagnosticStatus.PROVIDER_ERROR,
                                          },
                                        },
                                      });
                                    }
                                  });
                                }
                              }}
                            />
                          )}
                        </TelematicsProviderContainerUpdateTelematicsIntegrationMutation>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <h2>Integrations</h2>
                  <IntegrationList data={data} />
                </React.Fragment>
              )}
            </InfiniteScroll>
          )}
        </LoadingContainer>
      )}
    </TelematicsProviderContainerQuery>
  );
};
