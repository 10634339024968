import { Children, renderChildren } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Popup } from 'semantic-ui-react';

import { Button } from '../../components/button';
import {
  SensitiveConnectionDataQueryVariables,
  ValidateTelematicsIntegrationMutation,
  ValidateTelematicsIntegrationMutationVariables,
} from '../../lib/graphql';

type ValidateIntegrationCredentials = (
  input: ValidateTelematicsIntegrationMutationVariables['input'],
) => Promise<{ areValidCredentials: boolean; error: string | null }>;

type IntegrationValidationInput = {
  connection_data?: ValidateTelematicsIntegrationMutationVariables['input']['connection_data'];
  telematics_provider_id: ValidateTelematicsIntegrationMutationVariables['input']['telematics_provider_id'];
  telematics_integration_id?: SensitiveConnectionDataQueryVariables['telematics_integration_id'];
};

interface Props {
  integration: IntegrationValidationInput;
}

export const ValidateIntegrationButton: React.FunctionComponent<Props> = ({ integration }) => (
  <ValidateIntegrationMutation>
    {({ validateIntegrationCredentials }) => (
      <ValidateButton integration={integration} validateTelematicsIntegration={validateIntegrationCredentials} />
    )}
  </ValidateIntegrationMutation>
);

const validateTelematicsIntegrationMutation = gql`
  mutation ValidateTelematicsIntegration($input: ValidateTelematicsIntegrationInput!) {
    validateTelematicsIntegration(input: $input) {
      areValidCredentials
    }
  }
`;

class ValidateTelematicsMutation extends Mutation<
  ValidateTelematicsIntegrationMutation,
  ValidateTelematicsIntegrationMutationVariables
> {}

interface MutationProps {
  children: Children<{
    validateIntegrationCredentials: ValidateIntegrationCredentials;
  }>;
}

const ValidateIntegrationMutation: React.FunctionComponent<MutationProps> = (props) => (
  <ValidateTelematicsMutation mutation={validateTelematicsIntegrationMutation} ignoreResults>
    {(validateTelematicsIntegration) => {
      return renderChildren(props.children, {
        validateIntegrationCredentials: async (input: ValidateTelematicsIntegrationMutationVariables['input']) => {
          try {
            const result = await validateTelematicsIntegration({ variables: { input } });
            const areValidCredentials =
              result !== undefined ? result.data?.validateTelematicsIntegration?.areValidCredentials : undefined;

            if (areValidCredentials === undefined) {
              return;
            }

            return {
              areValidCredentials,
              error: null,
            };
          } catch (e) {
            return {
              areValidCredentials: false,
              error: e.message,
            };
          }
        },
      });
    }}
  </ValidateTelematicsMutation>
);

class ValidateButton extends React.Component<
  {
    integration: IntegrationValidationInput;
    validateTelematicsIntegration: ValidateIntegrationCredentials;
  },
  { areValidCredentials: boolean | null; error: string | null }
> {
  state = {
    areValidCredentials: null,
    error: null,
  };

  handleValidationClick = async () => {
    const response = await this.props.validateTelematicsIntegration({
      telematics_provider_id: this.props.integration.telematics_provider_id,
      telematics_integration_id: this.props.integration.telematics_integration_id,
      connection_data: this.props.integration.connection_data,
    });
    this.setState(response);
  };

  render() {
    const { areValidCredentials, error } = this.state;

    const invalidButton = (
      <div style={{ display: 'flex' }}>
        <Button color="red" className="left attached" size="small" style={{ cursor: 'default' }}>
          Invalid
        </Button>
        <Button className="right attached" size="small" onClick={() => this.handleValidationClick()}>
          Check
        </Button>
      </div>
    );

    const validButton = (
      <div style={{ display: 'flex' }}>
        <Button color="green" className="left attached" size="small" style={{ cursor: 'default' }}>
          Valid
        </Button>
        <Button className="right attached" size="small" onClick={() => this.handleValidationClick()}>
          Check
        </Button>
      </div>
    );

    if (error !== null) {
      return <Popup position="top right" open header="Error occured" content={error} trigger={invalidButton} />;
    }

    if (areValidCredentials === null) {
      return (
        <Button size="small" onClick={() => this.handleValidationClick()}>
          Check
        </Button>
      );
    }

    return areValidCredentials === true ? validButton : invalidButton;
  }
}
