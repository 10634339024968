import { Heading, Stack } from '@sixfold/common-ui';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Company } from '../../../company';
import { FormattedDate } from '../../date_formatting/formatted_date';
import { BigqueryMessage } from '../common/bigquery_message';
import { useKafkaContext } from '../common/kafka_context';
import { KafkaDiffViewer } from '../common/kafka_diff_viewer';
import { KafkaQueryInput } from '../common/kafka_query_input';
import { getMessageOptions } from '../common/messages';

export const CompanyKafkaTab = (props: RouteComponentProps<{ company_id: string }>) => {
  const { payloads } = useKafkaContext();
  const [loading, setLoading] = useState(false);
  const [compare, setCompare] = useState<number[]>([]);

  return (
    <Stack direction={'column'}>
      <Heading size="large">{`Kafka messages (${loading ? 'fetching...' : payloads.length})`}</Heading>
      <div style={{ width: 800 }}>
        <KafkaQueryInput
          entity={{ company_id: props.match.params.company_id } as Company}
          loading={loading}
          setLoading={setLoading}
          setCompare={setCompare}
          messageOptions={getMessageOptions('company')}
        />
        <div className="ui list">
          {payloads.map((payload, index) => (
            <BigqueryMessage
              key={index}
              payload={{
                firstRow: payload.produced_at,
                data: JSON.parse(payload.message),
                secondRow: payload.message_type,
              }}
              checkBoxLabel={(item) => (
                <div>
                  <FormattedDate date={item.firstRow} showSecondsAndMillis={true} />
                  <div>{item.secondRow}</div>
                </div>
              )}
              compare={compare}
              setCompare={setCompare}
              index={index}
              isJustOne={payloads.length === 1}
            />
          ))}
          <KafkaDiffViewer compare={compare} />
        </div>
      </div>
    </Stack>
  );
};
