import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { VehicleGrantMutations } from './vehicle_grant_mutations_container';
import { Company } from '../../company/entities';
import { InfiniteScroll } from '../../components/infinitescroll';
import {
  getNodes,
  PAGINATION_PAGE_SIZE,
  loadMoreFromConnection,
  CompanyRelationshipType,
  GrantRelationshipsListQuery as GrantRelationshipsListQueryResult,
  GrantRelationshipsListQueryVariables,
} from '../../lib/graphql';
import { VehicleGrantList, VehicleGrantListDataProps } from '../components/vehicle_grant_list';
import { grantRelationshipsQuery } from '../graphql';

class LoadingContainer extends Loader<
  GrantRelationshipsListQueryResult,
  VehicleGrantListDataProps,
  GrantRelationshipsListQueryVariables
> {}

class CompanyGrantListQuery extends Query<GrantRelationshipsListQueryResult, GrantRelationshipsListQueryVariables> {}

interface VehicleGrantListContainerProps extends RouteComponentProps<{ company_id: string }> {
  company: Company;
}

export const VehicleGrantListContainer = (props: VehicleGrantListContainerProps) => (
  <VehicleGrantMutations>
    {({ createVehicleGrants, revokeVehicleGrants }) => (
      <CompanyGrantListQuery
        fetchPolicy="cache-and-network"
        query={grantRelationshipsQuery}
        variables={{
          companyId: props.match.params.company_id,
          sourceCompanyId: props.match.params.company_id,
          relationshipType: CompanyRelationshipType.SUBCARRIER,
          limit: PAGINATION_PAGE_SIZE,
        }}>
        {(result) => (
          <LoadingContainer
            result={result}
            mapData={({ data }) => ({
              data: {
                companyRelationships: getNodes(data.companyRelationships),
                company_id: props.match.params.company_id,
                company: props.company,
              },
            })}>
            {({ data }) => (
              <InfiniteScroll
                triggerOnWindow={false}
                loadMoreEntries={() =>
                  loadMoreFromConnection(grantRelationshipsQuery, result, ['companyRelationships'])
                }>
                <VehicleGrantList
                  data={data}
                  createVehicleGrants={createVehicleGrants}
                  revokeVehicleGrants={revokeVehicleGrants}
                />
              </InfiniteScroll>
            )}
          </LoadingContainer>
        )}
      </CompanyGrantListQuery>
    )}
  </VehicleGrantMutations>
);
