import { parseQueryString, updateQueryString, OptionalBoolean } from '@sixfold/query-string';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { TelematicsProviderContainer } from './provider_container';
import { TelematicsProvidersContainer } from './providers_container';
import { Routes } from '../../routes';

export const TelematicsContainer = () => (
  <Switch>
    <Route path={Routes.TelematicsProvider.routerPath} component={TelematicsProviderContainer} />
    <Route
      path={Routes.Telematics.routerPath}
      render={(routeProps) => (
        <TelematicsProvidersContainer
          onClearFilters={() => routeProps.history.push(location.pathname)}
          activeFilters={parseQueryString({
            queryString: location.search,
            queryParametersToParse: {
              has_instructions_url: OptionalBoolean,
              is_implemented: OptionalBoolean,
              is_internal_use_only: OptionalBoolean,
              is_external_configuration_needed: OptionalBoolean,
              is_vehicle_management_enabled: OptionalBoolean,
              is_standard_telematics_push_provider: OptionalBoolean,
              is_global_allocation_forbidden: OptionalBoolean,
              is_deprecated: OptionalBoolean,
            },
          })}
          onFilterToggle={({ name, value }) =>
            routeProps.history.push(
              `${location.pathname}?${updateQueryString(location.search, {
                param: name,
                value: value !== '' ? value : undefined,
              })}`,
            )
          }
        />
      )}
    />
  </Switch>
);
