import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyRelationshipMutations } from './relationship_mutations_container';
import { RelationshipView } from '../components/relationship';

export const RelationshipContainer = (
  props: RouteComponentProps<{ company_id: string; company_relationship_id: string }>,
) => {
  const { company_relationship_id, company_id } = props.match.params;
  const isNewRelationship = company_relationship_id === 'new';

  return (
    <CompanyRelationshipMutations>
      {({ createCompanyRelationship }) => (
        <RelationshipView
          createCompanyRelationship={createCompanyRelationship}
          isNewRelationship={isNewRelationship}
          routeProps={{
            goBack: props.history.goBack,
            company_id,
          }}
        />
      )}
    </CompanyRelationshipMutations>
  );
};
