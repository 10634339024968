import { isNil, notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';

export type Props = {
  loadMoreEntries?: () => Promise<unknown> | undefined;
};

export interface DisplayProps {
  triggerOnWindow?: boolean;
  threshold?: number;
  className?: string;
}

export interface State {
  isLoading: boolean;
}

const getDistanceLeftToScroll = (window: Window, element: HTMLDivElement | null, triggerOnWindow: boolean = true) => {
  if (element === null) {
    return 0;
  }

  if (triggerOnWindow) {
    return element.getBoundingClientRect().top + element.scrollHeight - window.innerHeight;
  }

  return element.scrollHeight - element.scrollTop - element.clientHeight;
};

export class InfiniteScroll extends React.Component<Props & DisplayProps, State> {
  private container: HTMLDivElement | null;

  static defaultProps: Partial<DisplayProps> = { triggerOnWindow: true, threshold: 250 };

  constructor(props: Props & DisplayProps) {
    super(props);

    this.container = null;
    this.scrollListener = this.scrollListener.bind(this);
    this.state = { isLoading: false };
  }

  componentDidMount() {
    this.attachScrollListener();

    if (this.shouldLoadMore()) {
      this.loadMore();
    }
  }

  componentDidUpdate() {
    const { isLoading } = this.state;

    if (!isLoading) {
      this.attachScrollListener();

      if (this.shouldLoadMore()) {
        this.loadMore();
      }
    }
  }

  scrollListener() {
    window.requestAnimationFrame(() => {
      if (isNil(this.props.threshold)) {
        return;
      }

      if (getDistanceLeftToScroll(window, this.container, this.props.triggerOnWindow) < this.props.threshold) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    this.detachScrollListener();
    const promise = this.props.loadMoreEntries?.();

    if (promise !== undefined) {
      this.setState({ isLoading: true }, () =>
        promise.then(() => {
          this.setState({ isLoading: false });
        }),
      );
    }
  }

  shouldLoadMore(): boolean {
    return (
      this.container !== null &&
      notNil(this.props.threshold) &&
      getDistanceLeftToScroll(window, this.container, this.props.triggerOnWindow) < this.props.threshold
    );
  }

  attachScrollListener() {
    if (this.props.loadMoreEntries === undefined) {
      return;
    }

    if (this.props.triggerOnWindow === false) {
      if (this.container !== null) {
        this.container.addEventListener('scroll', this.scrollListener);
        this.container.addEventListener('resize', this.scrollListener);
      }
    } else {
      window.addEventListener('scroll', this.scrollListener);
      window.addEventListener('resize', this.scrollListener);
    }
  }

  detachScrollListener() {
    if (this.props.loadMoreEntries === undefined) {
      return;
    }

    if (this.props.triggerOnWindow === false) {
      if (this.container !== null) {
        this.container.removeEventListener('scroll', this.scrollListener);
        this.container.removeEventListener('resize', this.scrollListener);
      }
    } else {
      window.removeEventListener('scroll', this.scrollListener);
      window.removeEventListener('resize', this.scrollListener);
    }
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  render() {
    const { children, className } = this.props;
    const { isLoading } = this.state;

    if (isNil(children)) {
      return null;
    }

    return (
      <div className={className} ref={(ref) => (this.container = ref)}>
        {children}
        <div className={classnames('ui centered small inline loader', { active: isLoading })} />
      </div>
    );
  }
}
