import { Form as FormFactory } from '@sixfold/form-component';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import { isValid } from 'date-fns';
import React from 'react';

import { TimelineBoundaries } from './entities';
import { Button } from '../../../components/button';
import { useTimeZone } from '../../../components/date_formatting/timezone_context';
import { toTimezoneDateTime } from '../../../lib/date';

class Form extends FormFactory<TimelineBoundaries> {}

interface Props {
  timeline: TimelineBoundaries;
  isHistorySimplificationEnabled: boolean;
  onToggleHistorySimplification: () => void;
  onUpdateTimelineBoundaries: (boundaries: TimelineBoundaries) => void;
  children?: Children<{
    startTime: string;
    endTime: string;
  }>;
}

export const MapSliderDebug = (props: Props) => {
  const [isEditableBoundaries, setIsEditableBoundaries] = React.useState<boolean>(false);

  const toggleEditBoundaries = () => {
    setIsEditableBoundaries(!isEditableBoundaries);
  };

  const handleUpdateTimelineBoundaries = ({ startTime, endTime }: TimelineBoundaries) => {
    props.onUpdateTimelineBoundaries({ startTime, endTime });
    setIsEditableBoundaries(false);
  };

  const { isHistorySimplificationEnabled, timeline } = props;
  const { timeZone } = useTimeZone();

  return (
    <React.Fragment>
      <div className="map-slider__header-debug">
        {isEditableBoundaries ? (
          <Form
            initialValues={{
              startTime: toTimezoneDateTime(props.timeline.startTime, timeZone).toFormat('yyyy-LL-dd HH:mm').toString(),
              endTime: toTimezoneDateTime(props.timeline.endTime, timeZone).toFormat('yyyy-LL-dd HH:mm').toString(),
            }}
            validateFields={{
              startTime: {
                exist: 'Missing Start',
                custom: {
                  error: 'Invalid date',
                  condition: (value) => isValid(new Date(value as string)),
                },
              },
              endTime: {
                exist: 'Missing End',
                custom: {
                  error: 'Invalid date',
                  condition: (value) => isValid(new Date(value as string)),
                },
              },
            }}
            onSubmit={handleUpdateTimelineBoundaries}>
            {({ onChange, form, errors, onSubmit }) => (
              <React.Fragment>
                <label className="debug-input-container">
                  {errors.startTime !== undefined ? (
                    <span className="debug-input-error">{errors.startTime}</span>
                  ) : (
                    'Start'
                  )}
                  : <input type="text" name="startTime" value={form.startTime} onChange={onChange} />
                </label>
                <label className="debug-input-container">
                  {errors.endTime !== undefined ? <span className="debug-input-error">{errors.endTime}</span> : 'End'}
                  : <input type="text" name="endTime" value={form.endTime} onChange={onChange} />
                </label>
                <Button color="green" className="debug-btn" onClick={onSubmit}>
                  Apply changes
                </Button>
              </React.Fragment>
            )}
          </Form>
        ) : (
          <React.Fragment>
            <Button className="debug-btn" onClick={props.onToggleHistorySimplification}>
              {isHistorySimplificationEnabled ? 'Disable' : 'Enable'} history simplification
            </Button>
            <Button className="debug-btn" onClick={() => toggleEditBoundaries()}>
              Edit Start & End time
            </Button>
          </React.Fragment>
        )}
      </div>
      {renderChildren(props.children, { ...timeline })}
    </React.Fragment>
  );
};
