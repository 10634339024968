import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { UserInCompanyMutations } from './user_in_company_mutations_container';
import { CompanyUserQuery as CompanyUserQueryResult, CompanyUserQueryVariables } from '../../lib/graphql';
import { UserInCompanyView, UserInCompanyViewDataProps } from '../components/user_in_company';
import { companyUserQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyUserQueryResult,
  Partial<UserInCompanyViewDataProps>,
  CompanyUserQueryVariables
> {}
class CompanyUserQuery extends Query<CompanyUserQueryResult, CompanyUserQueryVariables> {}

export const UserInCompanyContainer = (props: RouteComponentProps<{ user_id: string; company_id: string }>) => {
  const { user_id, company_id } = props.match.params;
  const isNewUser = user_id === 'new';
  return (
    <CompanyUserQuery query={companyUserQuery} skip={isNewUser} variables={{ company_id, userId: user_id }}>
      {(result) => (
        <UserInCompanyMutations>
          {({ addUserToCompany, removeUserFromCompany, changeUserRoleInCompany, changeUserMetadataInCompany }) => {
            if (isNewUser) {
              return (
                <UserInCompanyView
                  isNewUser={isNewUser}
                  addUserToCompany={addUserToCompany}
                  changeUserMetadataInCompany={changeUserMetadataInCompany}
                  routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                  company={{ company_id }}
                />
              );
            }

            return (
              <LoadingContainer
                result={result}
                mapData={({ data }) => ({
                  data: data.company,
                })}>
                {({ data }) => {
                  const userInCompany = data ?? null;
                  return (
                    <UserInCompanyView
                      isNewUser={false}
                      data={userInCompany}
                      removeUserFromCompany={removeUserFromCompany}
                      changeUserRoleInCompany={changeUserRoleInCompany}
                      changeUserMetadataInCompany={changeUserMetadataInCompany}
                      routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                      company={{ company_id }}
                    />
                  );
                }}
              </LoadingContainer>
            );
          }}
        </UserInCompanyMutations>
      )}
    </CompanyUserQuery>
  );
};
