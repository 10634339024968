import { BackofficeScope } from '@sixfold/session-interface';
import { History } from 'history';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { RelationshipSnippet } from './relationship_snippet';
import { CompaniesDropdownContainer, CompanySearchResult } from '../../company/containers/companies_dropdown_container';
import { Button } from '../../components/button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CompanyRelationshipType } from '../../lib/graphql';

export interface RelationshipViewProps {
  routeProps: {
    goBack: History['goBack'];
    company_id: string;
  };
  isNewRelationship: boolean;
  createCompanyRelationship: (input: {
    sourceCompanyId: string;
    targetCompanyId: string;
    relationshipType: CompanyRelationshipType;
  }) => Promise<void>;
}

const CompanyRelationshipTypeWithoutReferrer = Object.keys(CompanyRelationshipType).filter(
  (type) => type !== 'REFERRER',
);

export const RelationshipView: React.FunctionComponent<RelationshipViewProps> = ({
  routeProps,
  isNewRelationship,
  createCompanyRelationship,
}) => {
  const isNotAdmin = !useIsAdmin();
  const hasCompanyRelationshipPermission = useHasScopes([BackofficeScope.companyWriteNetworkRelations]);
  const isPrivilegedUser = !isNotAdmin || hasCompanyRelationshipPermission;
  const [sourceCompany, setSourceCompany] = React.useState<CompanySearchResult | null>(null);
  const [targetCompany, setTargetCompany] = React.useState<CompanySearchResult | null>(null);
  const [relationshipType, setRelationshipType] = React.useState<CompanyRelationshipType>(
    CompanyRelationshipType.CARRIER,
  );

  if (!isNewRelationship) {
    return <div>You cannot update existing relationships</div>;
  }

  return (
    <React.Fragment>
      <RelationshipSnippet
        sourceCompany={sourceCompany}
        targetCompany={targetCompany}
        relationshipType={relationshipType}
      />
      <Button
        style={{ margin: '0 4px' }}
        size="tiny"
        compact
        onClick={() => {
          setSourceCompany(targetCompany);
          setTargetCompany(sourceCompany);
        }}>
        Switch companies
      </Button>
      <form className="ui form" style={{ marginTop: 15 }} onSubmit={(e) => e.preventDefault()}>
        <div className="field">
          <label>Source company</label>
          <CompaniesDropdownContainer
            inputProps={{
              disabled: !isPrivilegedUser,
              placeholder: 'Source company',
            }}
            selectedCompany={sourceCompany}
            onSelectCompany={setSourceCompany}
          />
        </div>
        <div className="field">
          <label>Relationship Type</label>
          <Dropdown
            selection
            search
            options={CompanyRelationshipTypeWithoutReferrer.map((type) => ({
              value: type,
              text: type.toLocaleLowerCase(),
            }))}
            disabled={!isPrivilegedUser}
            value={relationshipType}
            name="relationship_type"
            placeholder="Relationship type"
            onChange={(_e, { value }) => setRelationshipType(value as CompanyRelationshipType)}
          />
        </div>
        <div className="field">
          <label>Target company</label>
          <CompaniesDropdownContainer
            inputProps={{
              disabled: !isPrivilegedUser,
              placeholder: 'Target company',
            }}
            selectedCompany={targetCompany}
            onSelectCompany={setTargetCompany}
          />
        </div>
        <Button
          type="submit"
          primary
          disabled={!isPrivilegedUser}
          onClick={async () => {
            if (sourceCompany === null || targetCompany === null) {
              return;
            }

            await createCompanyRelationship({
              sourceCompanyId: sourceCompany.company_id,
              targetCompanyId: targetCompany.company_id,
              relationshipType,
            });

            routeProps.goBack();
          }}>
          Create
        </Button>
        <Button type="button" onClick={() => routeProps.goBack()}>
          Cancel
        </Button>
      </form>
    </React.Fragment>
  );
};
