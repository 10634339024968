import { gql } from 'graphql-tag';

export const grantRelationshipsQuery = gql`
  query GrantRelationshipsList(
    $limit: Int
    $cursor: String
    $companyId: String!
    $sourceCompanyId: String
    $targetCompanyId: String
    $relationshipType: CompanyRelationshipType
  ) {
    companyRelationships(
      first: $limit
      after: $cursor
      companyId: $companyId
      sourceCompanyId: $sourceCompanyId
      targetCompanyId: $targetCompanyId
      relationshipType: $relationshipType
    ) {
      edges {
        node {
          companyRelationshipId
          sourceCompany {
            company_id
            vehicles {
              all {
                count
              }
            }
          }
          targetCompany {
            company_name
            company_id

            vehicleGrantPartner(partnerId: $companyId, grantDirection: GRANTED_BY_PARTNERS) {
              grantCount
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const createAllVehicleGrantsMutation = gql`
  mutation CreateAllVehicleGrants($input: CreateVehicleGrantsInput!) {
    createAllVehicleGrants(input: $input)
  }
`;

export const revokeAllVehicleGrantsMutation = gql`
  mutation RevokeAllVehicleGrants($input: RevokeVehicleGrantsInput!) {
    revokeAllVehicleGrants(input: $input)
  }
`;
