import { Loader } from '@sixfold/loader-container';
import {
  QueryString,
  updateQueryString,
  OptionalString,
  getQueryParameters,
  createQueryString,
} from '@sixfold/query-string';
import { SortBy } from '@sixfold/table-component';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  TourListQuery,
  TourListFilterValuesQuery,
  TourListFilterValuesQueryVariables,
  getNodes,
  loadMoreFromConnection,
  TourListQueryVariables,
  TourStatusFilter,
  PAGINATION_PAGE_SIZE,
  SortDirection,
  TourSortKey,
  TourDelayStatusFilter,
  TourTextSearchField,
} from '../../lib/graphql';
import { TourFilters } from '../components/tour_filters';
import { TourList, Tour } from '../components/tour_list';
import { tourListQuery, tourListFilterValuesQuery } from '../graphql';
import { getTransportModesFromFilter } from '../utils';

class LoadingContainer extends Loader<TourListQuery, { data: { tours: Tour[] } }, TourListQueryVariables> {}
class TourListContainerTourListQuery extends Query<TourListQuery, TourListQueryVariables> {}
class TourlistContainerFilterValuesQuery extends Query<TourListFilterValuesQuery, TourListFilterValuesQueryVariables> {}

export class TourListContainer extends React.Component<RouteComponentProps<object>> {
  handleFilterToggle = ({ name, value }: { name: string; value: string | undefined }) => {
    const { location, history } = this.props;
    return history.push(
      `${location.pathname}?${updateQueryString(location.search, {
        param: name,
        value,
      })}`,
    );
  };

  handleClearFilters = () => {
    return this.props.history.push(this.props.location.pathname);
  };

  handleSortChange = ({ keyPath, value }: SortBy) => {
    const { location, history } = this.props;
    const queryString = getQueryParameters(location.search);
    return history.push({
      ...location,
      search: createQueryString({
        ...queryString,
        sort_key: keyPath,
        sort_direction: value.toLowerCase(),
      }),
    });
  };

  convertTourSortKey = (key: string | undefined): TourSortKey | undefined => {
    switch (key) {
      case 'start_time':
        return TourSortKey.START_TIME;
      case 'shipper_name':
        return TourSortKey.SHIPPER_NAME;
      case 'carrier_name':
        return TourSortKey.CARRIER_NAME;
      case 'license_plate':
        return TourSortKey.VEHICLE_LICENSE_PLATE_NUMBER;
      default:
        return undefined;
    }
  };

  querySortOrder = (sortKey: string | undefined, sortDirection: string | undefined) => {
    const key = this.convertTourSortKey(sortKey);
    const direction = sortDirection === 'asc' ? SortDirection.ASCENDING : SortDirection.DESCENDING;

    if (key === undefined) return;
    return { key, direction };
  };

  getSortByValue(key: string | undefined, direction: string | undefined): SortBy {
    return {
      keyPath: key ?? '',
      value: direction === 'asc' ? 'ASC' : 'DESC',
    };
  }

  render() {
    return (
      <QueryString
        queryString={this.props.location.search}
        queryParametersToParse={{
          status: OptionalString,
          delay: OptionalString,
          shipper_id: OptionalString,
          carrier_id: OptionalString,
          platform_id: OptionalString,
          platform_branch_id: OptionalString,
          place_id: OptionalString,
          vehicle_id: OptionalString,
          start_time_start: OptionalString,
          start_time_end: OptionalString,
          sort_direction: OptionalString,
          sort_key: OptionalString,
          search: OptionalString,
          search_field: OptionalString,
          transport_mode: OptionalString,
        }}>
        {({ parsedQueryString }) => {
          const {
            status,
            delay,
            shipper_id,
            carrier_id,
            vehicle_id,
            platform_id,
            place_id,
            start_time_start,
            start_time_end,
            sort_direction,
            sort_key,
            search,
            search_field,
            platform_branch_id,
            transport_mode,
          } = parsedQueryString;
          const shouldExecuteQuery = Object.keys(parsedQueryString).some((key) => parsedQueryString[key] !== undefined);

          return (
            <TourlistContainerFilterValuesQuery
              query={tourListFilterValuesQuery}
              fetchPolicy="cache-and-network"
              variables={{
                vehicle_id,
                hasVehicleFilter: vehicle_id !== undefined,
                shipperId: shipper_id,
                hasShipperFilter: shipper_id !== undefined,
              }}>
              {(tourListFilterValuesResult) => {
                const platforms = getNodes(tourListFilterValuesResult.data?.platforms ?? undefined);
                const places = getNodes(tourListFilterValuesResult.data?.company?.placesConnection.places ?? undefined);
                const vehicle = tourListFilterValuesResult.data?.vehicle ?? null;

                return (
                  <div>
                    <TourFilters
                      filters={{ platforms, places, vehicle }}
                      activeFilters={{
                        status,
                        delay,
                        shipper_id,
                        carrier_id,
                        platform_id,
                        platform_branch_id,
                        place_id,
                        vehicle_id,
                        start_time_start,
                        start_time_end,
                        search,
                        search_field,
                        transport_mode,
                      }}
                      onClearFilters={this.handleClearFilters}
                      onFilterToggle={this.handleFilterToggle}
                    />
                    {!shouldExecuteQuery && <>Please specify at least one filter to see tours</>}
                    <TourListContainerTourListQuery
                      query={tourListQuery}
                      skip={!shouldExecuteQuery}
                      variables={{
                        first: PAGINATION_PAGE_SIZE,
                        status: status === undefined ? undefined : (status.toUpperCase() as TourStatusFilter),
                        shipper_ids: shipper_id === undefined ? undefined : [shipper_id],
                        carrier_ids: carrier_id === undefined ? undefined : [carrier_id],
                        platform_id,
                        platform_branch_id,
                        place_ids: place_id === undefined ? undefined : [place_id],
                        vehicle_id,
                        delayStatus: delay === undefined ? undefined : (delay.toUpperCase() as TourDelayStatusFilter),
                        start_time_start:
                          start_time_start !== undefined ? new Date(start_time_start).toISOString() : undefined,
                        start_time_end:
                          start_time_end !== undefined ? new Date(start_time_end).toISOString() : undefined,
                        sortOrder: this.querySortOrder(sort_key, sort_direction),
                        textSearch:
                          search !== undefined && search.length >= 3
                            ? {
                                query: search,
                                field:
                                  search_field !== undefined
                                    ? (search_field.toUpperCase() as TourTextSearchField)
                                    : TourTextSearchField.ALL_EXTENDED,
                              }
                            : undefined,
                        transportMode: getTransportModesFromFilter(transport_mode),
                      }}>
                      {(tourListResult) => (
                        <LoadingContainer
                          result={tourListResult}
                          mapData={({ data }) => ({
                            data: {
                              tours: getNodes(data.tours?.tours),
                            },
                          })}>
                          {({ data }) => (
                            <InfiniteScroll
                              loadMoreEntries={() =>
                                loadMoreFromConnection(tourListQuery, tourListResult, ['tours', 'tours'])
                              }>
                              <TourList
                                data={data}
                                onSortChange={this.handleSortChange}
                                sortBy={this.getSortByValue(sort_key, sort_direction)}
                              />
                            </InfiniteScroll>
                          )}
                        </LoadingContainer>
                      )}
                    </TourListContainerTourListQuery>
                  </div>
                );
              }}
            </TourlistContainerFilterValuesQuery>
          );
        }}
      </QueryString>
    );
  }
}
