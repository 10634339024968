import React, { useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
  showAfterMs?: number;
}

export const Delay = ({ children, showAfterMs = 300 }: Props): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, showAfterMs);
    return () => clearTimeout(timer);
  }, [showAfterMs]);

  return isVisible ? <>{children}</> : null;
};
