import { renderChildren, Children } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  AddUserToCompanyMutation as AddUserToCompanyMutationResult,
  AddUserToCompanyMutationVariables,
  RemoveUserFromCompanyMutation as RemoveUserFromCompanyMutationResult,
  RemoveUserFromCompanyMutationVariables,
  ChangeUserRoleInCompanyMutation as ChangeUserRoleInCompanyMutationResult,
  ChangeUserRoleInCompanyMutationVariables,
  ChangeUserMetadataInCompanyMutation as ChangeUserMetadataInCompanyMutationResult,
  ChangeUserMetadataInCompanyMutationVariables,
} from '../../lib/graphql/result_types';
import { userInCompanyDetailsFragment } from '../graphql';

const addUserToCompanyMutation = gql`
  ${userInCompanyDetailsFragment}

  mutation AddUserToCompany($input: AddUserToCompanyInput!) {
    addUserToCompany(input: $input) {
      ...UserInCompanyDetails
    }
  }
`;

const removeUserFromCompany = gql`
  mutation RemoveUserFromCompany($input: RemoveUserFromCompanyInput!) {
    removeUserFromCompany(input: $input)
  }
`;

const changeUserRoleInCompany = gql`
  ${userInCompanyDetailsFragment}

  mutation ChangeUserRoleInCompany($input: ChangeUserRoleInCompanyInput!) {
    changeUserRoleInCompany(input: $input) {
      ...UserInCompanyDetails
    }
  }
`;

const changeUserMetadataInCompany = gql`
  ${userInCompanyDetailsFragment}

  mutation ChangeUserMetadataInCompany($input: ChangeUserMetadataInCompanyInput!) {
    changeUserMetadataInCompany(input: $input) {
      ...UserInCompanyDetails
    }
  }
`;

class AddUserToCompanyMutation extends Mutation<AddUserToCompanyMutationResult, AddUserToCompanyMutationVariables> {}
class RemoveUserFromCompanyMutation extends Mutation<
  RemoveUserFromCompanyMutationResult,
  RemoveUserFromCompanyMutationVariables
> {}
class ChangeUserRoleInCompany extends Mutation<
  ChangeUserRoleInCompanyMutationResult,
  ChangeUserRoleInCompanyMutationVariables
> {}
class ChangeUserMetadataInCompany extends Mutation<
  ChangeUserMetadataInCompanyMutationResult,
  ChangeUserMetadataInCompanyMutationVariables
> {}

interface Props {
  children: Children<{
    addUserToCompany: (
      input: AddUserToCompanyMutationVariables['input'],
    ) => Promise<{ userId: string; companyId: string } | null>;
    removeUserFromCompany: (input: RemoveUserFromCompanyMutationVariables['input']) => Promise<void>;
    changeUserRoleInCompany: (input: ChangeUserRoleInCompanyMutationVariables['input']) => Promise<void>;
    changeUserMetadataInCompany: (input: ChangeUserMetadataInCompanyMutationVariables['input']) => Promise<void>;
  }>;
}

export const UserInCompanyMutations: React.SFC<Props> = (props) => (
  <ChangeUserMetadataInCompany mutation={changeUserMetadataInCompany}>
    {(changeUserMetadataInCompany) => (
      <ChangeUserRoleInCompany mutation={changeUserRoleInCompany}>
        {(changeUserRoleInCompany) => (
          <RemoveUserFromCompanyMutation mutation={removeUserFromCompany}>
            {(removeUserFromCompany) => (
              <AddUserToCompanyMutation mutation={addUserToCompanyMutation}>
                {(addUserToCompany) => {
                  return renderChildren(props.children, {
                    addUserToCompany: async (input: AddUserToCompanyMutationVariables['input']) => {
                      const result = await addUserToCompany({
                        variables: { input },
                      });

                      const addUserToCompanyUserId =
                        result !== undefined ? result.data?.addUserToCompany?.user.user_id : undefined;

                      if (addUserToCompanyUserId === undefined) {
                        return null;
                      }

                      return { userId: addUserToCompanyUserId, companyId: input.companyId };
                    },
                    removeUserFromCompany: (input: RemoveUserFromCompanyMutationVariables['input']) => {
                      return removeUserFromCompany({
                        variables: { input },
                      });
                    },
                    changeUserRoleInCompany: (input: ChangeUserRoleInCompanyMutationVariables['input']) => {
                      return changeUserRoleInCompany({
                        variables: { input },
                      });
                    },
                    changeUserMetadataInCompany: (input: ChangeUserMetadataInCompanyMutationVariables['input']) => {
                      return changeUserMetadataInCompany({
                        variables: { input },
                      });
                    },
                  });
                }}
              </AddUserToCompanyMutation>
            )}
          </RemoveUserFromCompanyMutation>
        )}
      </ChangeUserRoleInCompany>
    )}
  </ChangeUserMetadataInCompany>
);
