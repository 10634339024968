import { Link } from '@sixfold/common-ui';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getTableHeaderClassNames } from '../../components/table';
import { Tags } from '../../components/tags';
import { UnifiedCompanyOnboardingStatus, CompanyIdentifier } from '../../lib/graphql';
import { beautifyString } from '../../lib/util/string';
import { shipmentsViewUrl } from '../../lib/util/url';
import { Routes } from '../../routes';
import { Company } from '../entities';
import { mapIdentifierType } from '../utils';

interface CompanyPlatforms {
  platforms: { platform_id: string; platform_name: string; company_id_on_platform: string }[];
}

export interface CompanyListDataProps {
  companies: (Company & CompanyPlatforms)[];
}

const PLATFORM_COLOURS = ['blue', 'teal', 'purple', 'yellow', 'green', 'pink'];

class CompanyTable extends Table<Company & CompanyPlatforms & { onboardingStatus: string }> {}

function getOnboardingStatusColor(status: UnifiedCompanyOnboardingStatus) {
  switch (status) {
    case UnifiedCompanyOnboardingStatus.REGISTERED:
      return 'yellow';
    case UnifiedCompanyOnboardingStatus.NOT_REGISTERED:
      return 'light blue';
    case UnifiedCompanyOnboardingStatus.ONBOARDING_STUCK:
      return 'red';
    case UnifiedCompanyOnboardingStatus.READY_FOR_ONBOARDING:
      return 'light green';
    case UnifiedCompanyOnboardingStatus.ONBOARDED:
      return 'green';
  }
}

export const CompanyList: React.FC<CompanyListDataProps> = ({ companies }) => {
  const rows = companies.map((company) => ({
    ...company,
    totalToursCount: (company.shipperToursCount ?? 0) + (company.carrierToursCount ?? 0),
    typeSort: [
      company.isPendingSignup === true ? 'Pending signup' : undefined,
      company.is_carrier === true ? 'Carrier' : undefined,
      company.is_shipper === true ? 'Shipper' : undefined,
      company.is_pilot === true ? 'Pilot' : undefined,
      company.is_paying_customer === true ? 'Paying' : undefined,
      company.is_test === true ? 'Test' : undefined,
    ]
      .filter(notNil)
      .join(';'),
    onboardingStatus: [
      notNil(company.unifiedOnboardingStatus) ? beautifyString(company.unifiedOnboardingStatus) : null,
      notNil(company.unifiedOnboardingStuckReason) ? beautifyString(company.unifiedOnboardingStuckReason) : null,
    ]
      .filter(notNil)
      .join(': '),
  }));

  return (
    <div className="company__company-list">
      <CompanyTable
        className="ui very basic sortable unstackable table"
        data={rows}
        onSortByChange={(sortBy) => {
          return {
            keyPath: sortBy.keyPath,
            value: sortBy.value,
            ...(sortBy.keyPath === 'typeSort' || sortBy.keyPath === 'onboardingStatus'
              ? { fallback: 'company_id' }
              : {}),
          };
        }}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            {
              keyPath: 'company_id',
              value: '#',
              className: (sortBy) => `right aligned one wide ${getTableHeaderClassNames(sortBy)}`,
            },
            { keyPath: 'company_name', value: 'Name' },
            { keyPath: 'typeSort', value: 'Type' },
            { keyPath: 'onboardingStatus', value: 'Onboarding status' },
            { keyPath: 'totalToursCount', value: 'Tour count as shipper/carrier' },
            { keyPath: 'totalVehiclesCount', value: 'Vehicles' },
            { value: 'Tags' },
            { value: 'Platforms' },
            { value: 'Identifiers' },
            { value: 'Customer UI' },
          ],
        }}
        defaultSortBy={{ keyPath: 'company_id', value: 'ASC' }}>
        {({ row }) => {
          const {
            company_id,
            company_name,
            is_shipper,
            is_carrier,
            is_pilot,
            is_paying_customer,
            is_test,
            onboardingStatus,
            unifiedOnboardingStatus,
            tags,
            isPendingSignup,
            carrierToursCount,
            shipperToursCount,
            totalVehiclesCount,
            companyIdentifiers = [],
          } = row.data;
          const platforms = row.data.platforms;

          return (
            <tr key={company_id}>
              <td className="right aligned">{company_id}</td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id })}>
                  <span style={{ marginRight: 10 }}>{company_name}</span>
                </NavLink>
              </td>
              <td>
                {isPendingSignup !== null && isPendingSignup && <div className="ui label tiny red">Pending signup</div>}
                {is_carrier !== null && is_carrier && <div className="ui label blue tiny">Carrier</div>}
                {is_shipper !== null && is_shipper && <div className="ui label green tiny">Shipper</div>}
                {is_pilot !== null && is_pilot && <div className="ui label teal tiny">Pilot</div>}
                {is_paying_customer !== null && is_paying_customer && (
                  <div className="ui label purple tiny">Paying</div>
                )}
                {is_test !== null && is_test && <div className="ui label yellow tiny">Test</div>}
              </td>
              <td>
                {notNil(unifiedOnboardingStatus) ? (
                  <div className={classnames('ui label tiny', getOnboardingStatusColor(unifiedOnboardingStatus))}>
                    {onboardingStatus}
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <NavLink to={`${Routes.Tours.generatePath({})}?shipper_id=${company_id}`}>{shipperToursCount}</NavLink>{' '}
                /{' '}
                <NavLink to={`${Routes.Tours.generatePath({})}?carrier_id=${company_id}`}>{carrierToursCount}</NavLink>
              </td>
              <td>
                <NavLink to={`${Routes.Vehicles.generatePath({})}?company_id=${company_id}`}>
                  {totalVehiclesCount}
                </NavLink>
              </td>
              <td>
                <Tags tags={tags} />
              </td>
              <td>
                {platforms.map((platform) => (
                  <div
                    key={platform.platform_id}
                    className={classnames('ui label tiny', PLATFORM_COLOURS[parseInt(platform.platform_id, 10)])}>
                    {`${platform.platform_name}#${platform.company_id_on_platform}`}
                  </div>
                ))}
              </td>
              <td>{<CompanyIdentifierLabels companyIdentifiers={companyIdentifiers} />}</td>
              <td>
                <a target="_blank" href={shipmentsViewUrl(company_id)} title="Open Shipments UI" rel="noreferrer">
                  📦
                </a>
              </td>
            </tr>
          );
        }}
      </CompanyTable>
    </div>
  );
};

type CompanyIdentifierLabelsProps = {
  companyIdentifiers: Pick<CompanyIdentifier, 'identifierType' | 'identifierValue' | 'companyIdentifierId'>[];
};

const CompanyIdentifierLabels = ({ companyIdentifiers }: CompanyIdentifierLabelsProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasMoreThan2 = companyIdentifiers.length > 2;

  const visibleIdentifiers = isExpanded ? companyIdentifiers : companyIdentifiers.slice(0, 2);

  return (
    <>
      {visibleIdentifiers.map(({ identifierType, identifierValue, companyIdentifierId }) => (
        <div className="ui label tiny" key={companyIdentifierId}>
          {mapIdentifierType(identifierType)}: {identifierValue}
        </div>
      ))}
      {hasMoreThan2 && (
        <>
          <br />
          <Link as="button" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Show less' : 'Show more'}
          </Link>
        </>
      )}
    </>
  );
};
