import { Loader } from '@sixfold/loader-container';
import { QueryString, OptionalString, OptionalNumber } from '@sixfold/query-string';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { VehicleMutation } from './vehicle_mutation_container';
import { isValid, endOf, substract } from '../../lib/date';
import {
  VehicleQuery,
  VehicleQueryVariables,
  VehicleHistoryQuery as VehicleHistoryQueryResult,
  VehicleHistoryQueryVariables,
} from '../../lib/graphql';
import {
  HISTORY_SIMPLIFICATION_QS,
  TIMELINE_TIMESTAMP_QS,
  TIMELINE_START_TIME_QS,
  TIMELINE_END_TIME_QS,
} from '../../map/components/map_slider';
import { VehicleViewDataProps, VehicleView } from '../components/vehicle';
import { vehicleQuery, vehicleHistoryQuery } from '../graphql';
import { processVehicleHistoryData } from '../utils';

class LoadingContainer extends Loader<VehicleQuery, VehicleViewDataProps, VehicleQueryVariables> {}
class VehicleContainerQuery extends Query<VehicleQuery, VehicleQueryVariables> {}
class VehicleHistoryQuery extends Query<VehicleHistoryQueryResult, VehicleHistoryQueryVariables> {}

export const VehicleContainer = (props: RouteComponentProps<{ vehicle_id: string }>) => {
  return (
    <QueryString
      queryString={props.location.search}
      queryParametersToParse={{
        [TIMELINE_TIMESTAMP_QS]: OptionalString,
        [HISTORY_SIMPLIFICATION_QS]: OptionalNumber,
        [TIMELINE_START_TIME_QS]: OptionalString,
        [TIMELINE_END_TIME_QS]: OptionalString,
      }}>
      {({ parsedQueryString: { timestamp, history_simplification_tolerance, timeline_start, timeline_end } }) => {
        const selectedTimestamp = isValid(new Date(timestamp ?? '')) ? timestamp : undefined;

        const endTime = timeline_end !== undefined ? timeline_end : endOf(new Date(), 'hour').toISOString();
        const startTime =
          timeline_start !== undefined ? timeline_start : substract(endTime, { days: 10 }).toISOString();

        return (
          <VehicleMutation>
            {({ deleteVehicle }) => (
              <VehicleHistoryQuery
                query={vehicleHistoryQuery}
                variables={{
                  vehicle_id: props.match.params.vehicle_id,
                  history_start_time: startTime,
                  history_end_time: endTime,
                  simplification_tolerance: history_simplification_tolerance,
                }}>
                {({ data }) => {
                  const { vehicleHistory: history, breakHistory } = processVehicleHistoryData(data, new Date(endTime));
                  return (
                    <VehicleContainerQuery
                      query={vehicleQuery}
                      variables={{
                        vehicle_id: props.match.params.vehicle_id,
                      }}>
                      {(result) => (
                        <LoadingContainer
                          result={result}
                          mapData={({ data }) => ({ data: { vehicle: data.vehicle, history, breakHistory } })}>
                          {({ data }) => (
                            <VehicleView
                              deleteVehicle={async (vehicleId) => {
                                await deleteVehicle(vehicleId, data.vehicle?.company.company_id ?? '');
                                props.history.goBack();
                              }}
                              timeline={{
                                selectedTimestamp,
                                startTime,
                                endTime,
                              }}
                              data={data}
                            />
                          )}
                        </LoadingContainer>
                      )}
                    </VehicleContainerQuery>
                  );
                }}
              </VehicleHistoryQuery>
            )}
          </VehicleMutation>
        );
      }}
    </QueryString>
  );
};
