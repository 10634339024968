import classnames from 'classnames';
import React from 'react';
import { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import { Button } from '../button';

interface Props {
  label: string;
  disabled?: boolean;
  initialButtonColor?: 'red' | 'green';
  onConfirm: () => void | Promise<void>;
  size?: SemanticButtonProps['size'];
  className?: string;
  showConfirmationProp?: boolean;
  updateConfirmState?: (newState: boolean) => void;
}

interface State {
  showConfirmation: boolean;
}

export class ConfirmButton extends React.Component<Props, State> {
  state = {
    showConfirmation: false,
  };

  showConfirmationButton = () => {
    this.setState({ showConfirmation: true });
  };

  hideConfirmationButton = () => {
    this.setState({ showConfirmation: false });
  };

  handleConfirmationClick = async () => {
    await this.props.onConfirm();
    if (this.props.updateConfirmState !== undefined) {
      return this.props.updateConfirmState(false);
    }
    return this.hideConfirmationButton();
  };

  render() {
    const { disabled, initialButtonColor, label, size, className, showConfirmationProp, updateConfirmState } =
      this.props;
    const { showConfirmation } = this.state;

    if (showConfirmationProp !== undefined ? !showConfirmationProp : !showConfirmation) {
      return (
        <Button
          size={size}
          disabled={disabled}
          color={initialButtonColor}
          onClick={updateConfirmState !== undefined ? () => updateConfirmState(true) : this.showConfirmationButton}
          className={classnames(className)}>
          {label}
        </Button>
      );
    }

    return (
      <div className={classnames('ui', 'buttons', size, className)}>
        <Button
          onClick={updateConfirmState !== undefined ? () => updateConfirmState(false) : this.hideConfirmationButton}>
          Cancel
        </Button>
        <div className="or" />
        <Button primary onClick={this.handleConfirmationClick}>
          Confirm
        </Button>
      </div>
    );
  }
}
