import { isNil, notNil } from '@sixfold/typed-primitives';
import { DateTime } from 'luxon';
import React from 'react';

import { FormattedDate } from './formatted_date';
import { useTimeZone } from './timezone_context';
import { toTimezoneDateTime } from '../../lib/date';

interface FormattedTimeSlotProps {
  startDate: string | null;
  endDate: string | null;
  timezone?: string | null;
}

export const FormattedTimeSlot: React.FunctionComponent<FormattedTimeSlotProps> = ({
  startDate,
  endDate,
  timezone,
}) => {
  const timeZone = useTimeZone();
  const timezoneToUse = timezone ?? timeZone.timeZone;

  if (!DateTime.local().setZone(timezoneToUse).isValid) {
    return <>Invalid timezone: {timezoneToUse}</>;
  }

  return (
    <>
      {notNil(startDate) && (
        <FormattedDate date={startDate} propsTimezone={timezoneToUse} showTimezone={isNil(endDate)} />
      )}
      {' - '}
      {notNil(endDate) && (
        <FormattedDate
          date={endDate}
          propsTimezone={timezoneToUse}
          showDate={isEndDateVisible(startDate, endDate, timezoneToUse)}
        />
      )}
    </>
  );
};

function isEndDateVisible(startDate: string | null, endDate: string, timezoneToUse: string) {
  // When start date is null, then end date is shown
  if (isNil(startDate)) {
    return true;
  }
  // When start and end dates are on the same day, then end date is not shown
  return !toTimezoneDateTime(startDate, timezoneToUse).hasSame(toTimezoneDateTime(endDate, timezoneToUse), 'day');
}
