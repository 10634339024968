import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyPlatformsListQuery, CompanyPlatformsListQueryVariables } from '../../lib/graphql';
import { PlatformListProps, PlatformList } from '../components/platform_list';
import { companyPlatformsListQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyPlatformsListQuery,
  PlatformListProps,
  CompanyPlatformsListQueryVariables
> {}
class PlatformListContainerQuery extends Query<CompanyPlatformsListQuery, CompanyPlatformsListQueryVariables> {}

export const PlatformListContainer = (props: RouteComponentProps<{ company_id: string }>) => (
  <PlatformListContainerQuery
    fetchPolicy="cache-and-network"
    query={companyPlatformsListQuery}
    variables={{ company_id: props.match.params.company_id }}>
    {(result) => (
      <LoadingContainer
        result={result}
        mapData={({ data }) => ({
          data: {
            platforms: data?.company?.platforms ?? [],
            company_id: props.match.params.company_id,
          },
        })}>
        {({ data }) => <PlatformList data={data} />}
      </LoadingContainer>
    )}
  </PlatformListContainerQuery>
);
