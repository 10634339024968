import { DateTime, DurationLikeObject, Duration, DateTimeUnit } from 'luxon';

export const format = (date: string | Date, format: string, opts: { toUTC: boolean } = { toUTC: false }) => {
  const datetime = toDateTime(date);

  if (opts.toUTC) {
    return datetime.toUTC().toFormat(format);
  }
  return datetime.toFormat(format);
};

export const formatDateWithTimeZone = (date: Date | string, zone: string) => {
  const datetime = toTimezoneDateTime(date, zone);
  return datetime.toFormat('dd.LL.yyyy HH:mm');
};

export const formatDateSimple = (date: string | Date) => {
  const datetime = toDateTime(date);
  return datetime.toFormat('yyyy-LL-dd');
};

export const isValid = (date: string | Date) => {
  const datetime = toDateTime(date);
  return datetime.isValid;
};

export const startOf = (date: string | Date, timeUnit: DateTimeUnit) => {
  const datetime = toDateTime(date);
  return datetime.startOf(timeUnit).toJSDate();
};

export const endOf = (date: string | Date, timeUnit: DateTimeUnit) => {
  const datetime = toDateTime(date);
  return datetime.endOf(timeUnit).toJSDate();
};

export const add = (date: string | Date, timeAmount: DurationLikeObject) => {
  const datetime = toDateTime(date);
  return datetime.plus(timeAmount).toJSDate();
};

export const substract = (date: string | Date, timeAmount: DurationLikeObject) => {
  const datetime = toDateTime(date);
  return datetime.minus(timeAmount).toJSDate();
};

export const min = (...datesArray: Date[]) => {
  let result = datesArray[0];

  datesArray.forEach((currentDate) => {
    if (result === undefined || result > currentDate) {
      result = currentDate;
    }
  });

  return result;
};

export const max = (...datesArray: Date[]) => {
  let result = datesArray[0];

  datesArray.forEach((currentDate) => {
    if (result === undefined || result < currentDate) {
      result = currentDate;
    }
  });

  return result;
};

export const compareAsc = (date: Date, dateToCompare: Date) => {
  const dateDiff = date.getTime() - dateToCompare.getTime();

  if (dateDiff < 0) {
    return -1;
  }

  if (dateDiff > 0) {
    return 1;
  }
  // Return 0 if diff is 0; return NaN if diff is NaN
  return dateDiff;
};

export const differenceInHours = (futureDate: string | Date, pastDate: string | Date) => {
  const future = toDateTime(futureDate);
  const past = toDateTime(pastDate);

  return future.diff(past, 'hours').hours;
};

export const differenceInMinutes = (futureDate: string | Date, pastDate: string | Date) => {
  const future = toDateTime(futureDate);
  const past = toDateTime(pastDate);

  return future.diff(past, 'minutes').minutes;
};

export function toDateTime(date: Date | string) {
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  return DateTime.fromJSDate(date);
}

export function toTimezoneDateTime(date: Date | string, zone: string) {
  if (typeof date === 'string') {
    return DateTime.fromISO(date, { zone });
  }

  return DateTime.fromJSDate(date, { zone });
}

export const formattedDuration = (fromDate: string | Date, toDate: string | Date = new Date()) => {
  const diffInHours = differenceInHours(toDate, fromDate);

  if (diffInHours > 24) {
    return Duration.fromObject({ hours: diffInHours }).toFormat("d'd' h'h'");
  }

  if (diffInHours > 1) {
    return Duration.fromObject({ hours: diffInHours }).toFormat("h'h' m'min'");
  }

  return Duration.fromObject({ hours: diffInHours }).toFormat("m'min'");
};
