import { Icon as CommonUIIcon, Stack } from '@sixfold/common-ui';
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { Button, Icon, List, ListItem, Menu, Popup } from 'semantic-ui-react';

import { CompaniesContainer } from '../../company';
import { useTimeZone } from '../../components/date_formatting/timezone_context';
import { MiscellaneousContainer } from '../../miscellaneous/containers/miscellaneous_container';
import { Routes } from '../../routes';
import { TelematicsContainer } from '../../telematic_integration';
import { ToursContainer } from '../../tour';
import { UsersContainer } from '../../user';
import { VehiclesContainer } from '../../vehicle';

const intranetDocumentationLink =
  'https://intranet.transporeon.com/pages/viewpage.action?spaceKey=SIX&title=Backoffice+-+General+info';
const intranetQuestionsLink = 'https://intranet.transporeon.com/pages/viewpage.action?pageId=920192894';

interface Props {
  onSignout(): void;
}

const handleSignoutClick = (event: React.MouseEvent<HTMLAnchorElement>, props: Props) => {
  event.preventDefault();
  props.onSignout();
};

export const Workspace: React.FC<Props> = (props) => {
  const { timeZone, setTimeZone } = useTimeZone();
  const toggleDateFormat = React.useCallback(() => {
    const updatedTimeZone = timeZone === 'UTC' ? 'local' : 'UTC';
    setTimeZone(updatedTimeZone);
    localStorage.setItem('timeZone', updatedTimeZone);
  }, [setTimeZone, timeZone]);

  return (
    <div className="workspace">
      <div className="ui blue secondary menu">
        <div className="item logo">
          <CommonUIIcon name="logomark-6f" />
        </div>
        <NavLink to={Routes.Home.generatePath({})} exact={true} activeClassName="active" className="item">
          Home
        </NavLink>
        <NavLink to={Routes.Vehicles.generatePath({})} activeClassName="active" className="item">
          Vehicles
        </NavLink>
        <NavLink to={Routes.Tours.generatePath({})} activeClassName="active" className="item">
          Tours
        </NavLink>
        <NavLink to={Routes.Companies.generatePath({})} activeClassName="active" className="item">
          Companies
        </NavLink>
        <NavLink to={Routes.Users.generatePath({})} activeClassName="active" className="item">
          Users
        </NavLink>
        <NavLink to={Routes.Telematics.generatePath({})} activeClassName="active" className="item">
          Telematics
        </NavLink>
        <NavLink to={Routes.Miscellaneous.generatePath({})} activeClassName="active" className="item">
          Miscellaneous
        </NavLink>
        <Menu className="small secondary right menu">
          <Menu.Item>
            <Popup trigger={<Icon name="question circle outline" size="large" />} hoverable position="bottom right">
              <List gap="none">
                <ListItem>
                  <Stack
                    alignItems="center"
                    wrap={false}
                    as="a"
                    href={intranetDocumentationLink}
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    <Icon name="book" />
                    Backoffice documentation
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack
                    alignItems="center"
                    wrap={false}
                    as="a"
                    href={intranetQuestionsLink}
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    <Icon name="comments" />
                    Questions and feedback
                  </Stack>
                </ListItem>
              </List>
            </Popup>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={toggleDateFormat}> Showing {timeZone === 'UTC' ? 'UTC' : 'local'} time</Button>
          </Menu.Item>
          <Menu.Item>
            <a
              href={Routes.Logout.generatePath({})}
              className="ui item"
              onClick={(event) => handleSignoutClick(event, props)}>
              Logout
            </a>
          </Menu.Item>
        </Menu>
      </div>

      <Route path={Routes.Vehicles.routerPath} component={VehiclesContainer} />
      <Route path={Routes.Tours.routerPath} component={ToursContainer} />
      <Route path={Routes.Users.routerPath} component={UsersContainer} />
      <Route path={Routes.Companies.routerPath} component={CompaniesContainer} />
      <Route path={Routes.Telematics.routerPath} component={TelematicsContainer} />
      <Route path={Routes.Miscellaneous.routerPath} component={MiscellaneousContainer} />
    </div>
  );
};
