import { Loader } from '@sixfold/loader-container';
import { BackofficeScope } from '@sixfold/session-interface';
import React from 'react';
import { Query } from 'react-apollo';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { CompanyIntegrationsListQuery, CompanyIntegrationsListQueryVariables, notEmpty } from '../../lib/graphql';
import { Routes } from '../../routes';
import { IntegrationList, IntegrationListProps } from '../../telematic_integration/components/integration_list';
import { DeleteIntegrationMutation } from '../../telematic_integration/containers/delete_integration_container';
import { companyIntegrationsListQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyIntegrationsListQuery,
  IntegrationListProps,
  CompanyIntegrationsListQueryVariables
> {}
class IntegrationListContainerQuery extends Query<
  CompanyIntegrationsListQuery,
  CompanyIntegrationsListQueryVariables
> {}

export const CompanyIntegrationsContainer: React.FunctionComponent<RouteComponentProps<{ company_id: string }>> = ({
  match: {
    params: { company_id },
  },
}) => {
  const hasIntegrationsEditPermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);

  return (
    <React.Fragment>
      <h2>
        Integrations{' '}
        {(useIsAdmin() || hasIntegrationsEditPermission) && (
          <NavLink
            to={Routes.CompanyTelematicsIntegrationCreate.generatePath({ company_id })}
            className="ui button primary tiny">
            Add Integration
          </NavLink>
        )}
      </h2>

      <IntegrationListContainerQuery query={companyIntegrationsListQuery} variables={{ company_id }}>
        {(result) => (
          <DeleteIntegrationMutation companyId={company_id}>
            {({ deleteTelematicsIntegration }) => (
              <LoadingContainer
                result={result}
                mapData={({ data }) => ({
                  data: {
                    integrations: (data.company?.telematics_integrations ?? []).filter(notEmpty),
                    company_id,
                  },
                })}>
                {({ data }) => (
                  <IntegrationList data={data} deleteTelematicsIntegration={deleteTelematicsIntegration} />
                )}
              </LoadingContainer>
            )}
          </DeleteIntegrationMutation>
        )}
      </IntegrationListContainerQuery>
    </React.Fragment>
  );
};
