import { gql } from 'graphql-tag';

export const usersQuery = gql`
  query UserList($limit: Int, $cursor: String, $email: String) {
    users(email: $email, first: $limit, after: $cursor) {
      edges {
        node {
          user_id
          first_name
          last_name
          email
          locale
          avatar_url
          backoffice_role
          backoffice_scopes
          created_at
          updated_at
          deleted_at

          credentials {
            type
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const userQuery = gql`
  query User($user_id: String!) {
    user(user_id: $user_id) {
      user_id
      first_name
      last_name
      email
      locale
      backoffice_role
      backoffice_scopes
      avatar_url
      created_at
      updated_at
      deleted_at

      credentials {
        type
        value
        addedByUserId
        addedAt
      }

      companies {
        company_id
        company_name
        user(userId: $user_id) {
          userOnPlatforms {
            userIdOnPlatform
            platform {
              platform_id
              platform_name
            }
          }
        }
      }
    }
  }
`;

export const addCredentialsToUserMutation = gql`
  mutation AddCredentialsToUser($input: AddCredentialsToUserInput!) {
    addCredentialsToUser(input: $input) {
      type
      value
      addedAt
      addedByUserId
      userId
    }
  }
`;

export const removeCredentialsFromUserMutation = gql`
  mutation RemoveCredentialsFromUser($input: RemoveCredentialsFromUserInput!) {
    removeCredentialsFromUser(input: $input)
  }
`;

export const backofficeScopesQuery = gql`
  query BackofficeScopes {
    backofficeScopes
  }
`;
