import { useNotifications } from '@sixfold/common-ui';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  CreateCompanyPlatformMutation as CreateCompanyPlatformMutationResult,
  CreateCompanyPlatformMutationVariables,
  DeleteCompanyPlatformMutation as DeleteCompanyPlatformMutationResponse,
  DeleteCompanyPlatformMutationVariables,
} from '../../lib/graphql';
import { createCompanyPlatformMutation, deleteCompanyPlatformMutation } from '../graphql';

class CreateCompanyPlatformMutation extends Mutation<
  CreateCompanyPlatformMutationResult,
  CreateCompanyPlatformMutationVariables
> {}

class DeleteCompanyPlatformMutation extends Mutation<
  DeleteCompanyPlatformMutationResponse,
  DeleteCompanyPlatformMutationVariables
> {}

type PlatformMutationBaseInput = {
  company_id: string;
  platform_id: string;
};
interface Props {
  children: Children<{
    createCompanyPlatform: (input: PlatformMutationBaseInput & { company_id_on_platform: string }) => Promise<void>;
    updateCompanyPlatform: (input: PlatformMutationBaseInput & { company_id_on_platform: string }) => Promise<void>;
    deleteCompanyPlatform: (input: PlatformMutationBaseInput) => Promise<void>;
  }>;
}

export const PlatformMutations: React.FC<Props> = (props) => {
  const notify = useNotifications();

  return (
    <CreateCompanyPlatformMutation mutation={createCompanyPlatformMutation}>
      {(createCompanyPlatform) => (
        <DeleteCompanyPlatformMutation mutation={deleteCompanyPlatformMutation}>
          {(deleteCompanyPlatform) => {
            return renderChildren(props.children, {
              createCompanyPlatform: async (input: PlatformMutationBaseInput & { company_id_on_platform: string }) => {
                const { company_id, ...rest } = input;

                const response = await createCompanyPlatform({
                  variables: {
                    company_id,
                    input: rest,
                  },
                });
                notify.success({ title: 'Company ID on platform created' });
                return response;
              },
              deleteCompanyPlatform: async (input: PlatformMutationBaseInput) => {
                const { company_id, ...rest } = input;

                const response = await deleteCompanyPlatform({
                  variables: {
                    company_id,
                    input: rest,
                  },
                });
                notify.success({ title: 'Company ID on platform deleted' });
                return response;
              },
            });
          }}
        </DeleteCompanyPlatformMutation>
      )}
    </CreateCompanyPlatformMutation>
  );
};
