import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup, Table } from 'semantic-ui-react';

import { TimestampLink } from './timestamp_link';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { TourVisibilityPrecisionType } from '../../lib/graphql';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';
import { Event, TourEvent } from '../entities';

export interface EventListProps {
  events: Event[];
}

const isTourEvent = (event: Event): event is TourEvent => event.__typename === 'TourEvent';
const eventMetadataParameter = (event: Event, parameterName: string, label: string) => {
  const id = event.metadata?.parameters?.[parameterName];
  return notNil(id) ? (
    <div>
      {label} #{id}
    </div>
  ) : (
    ''
  );
};

export const getStreamPrecisionIcon = (precision: TourVisibilityPrecisionType | null) => {
  if (precision === TourVisibilityPrecisionType.GPS) {
    return 'rss icon';
  }
  if (precision === TourVisibilityPrecisionType.EVENT) {
    return 'check circle icon';
  }
  return 'question circle icon';
};

export const EventList: React.FC<EventListProps> = ({ events }) => {
  const getNullSafeTime = (eventTime: string | null) => {
    return eventTime === null ? new Date(3001, 6, 18).getTime() : new Date(eventTime).getTime();
  };
  const sortedEvents = events.sort((event1: Event, event2: Event) => {
    return getNullSafeTime(event1.event_time) - getNullSafeTime(event2.event_time);
  });

  return (
    <>
      <h3>Event log</h3>
      <Table compact="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Visibility source</Table.HeaderCell>
            <Table.HeaderCell>Metadata</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedEvents.length === 0 ? (
            <Table.Row textAlign="center">
              <Table.Cell colSpan="5">No events found</Table.Cell>
            </Table.Row>
          ) : (
            sortedEvents.map((event, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{isTourEvent(event) ? 'Tour' : `Stop #${event.stop_id}`}</Table.Cell>
                <Table.Cell>
                  {beautifyString(event.event_name)}
                  {isTourEvent(event) && event.warning?.type !== undefined ? ` (${event.warning.type})` : ''}
                </Table.Cell>
                <Table.Cell>
                  <TimestampLink timestamp={event.event_time} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={event.created_at} />
                </Table.Cell>
                <Table.Cell>
                  {event.visibilitySource !== null ? (
                    <Popup
                      wide
                      hoverable
                      trigger={<i className={getStreamPrecisionIcon(event.visibilitySource.streamPrecision)} />}
                      on="hover">
                      <div>Primary event source: {event.visibilitySource.primaryEventSource ?? '-'}</div>
                      <div>Stream precision: {event.visibilitySource.streamPrecision ?? '-'}</div>
                      <div>Event origin: {event.visibilitySource.eventOrigin ?? '-'}</div>
                      <div>
                        Source company:{' '}
                        {event.visibilitySource.sourceCompany !== null ? (
                          <Link
                            to={Routes.Company.generatePath({
                              company_id: event.visibilitySource.sourceCompany.company_id,
                            })}>
                            {event.visibilitySource.sourceCompany.company_name ??
                              `#${event.visibilitySource.sourceCompany.company_id}`}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </div>
                      <div>Source platform: {event.visibilitySource.sourcePlatform?.platform_name ?? '-'}</div>
                    </Popup>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>
                  {eventMetadataParameter(event, 'created_at', 'Created at: ')}
                  {eventMetadataParameter(event, 'break_id', 'Break: ')}
                  {eventMetadataParameter(event, 'external_stop_event_id', 'Ext. stop. ev: ')}
                  {eventMetadataParameter(event, 'external_tour_event_id', 'Ext. tour. ev: ')}
                  {eventMetadataParameter(event, 'external_delivery_event_id', 'Ext. del. ev: ')}
                  {event.metadata !== null ? (
                    <Popup wide hoverable trigger={<Icon name="code" />} on="hover">
                      <div>Algorithm: {event.metadata.algorithm ?? '-'}</div>
                      <div>
                        Parameters:{' '}
                        {event.metadata.parameters !== null ? JSON.stringify(event.metadata.parameters) : '-'}
                      </div>
                    </Popup>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
};
