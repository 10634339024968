import * as React from 'react';
import { ReactElement } from 'react';
import ReactJson from 'react-json-view';
import { Table, Button, Checkbox } from 'semantic-ui-react';

import styles from '../css/bigquery.module.css';

interface BigqueryItem<T extends object> {
  firstRow: string | null;
  data: T;
  secondRow?: string;
}

export function BigqueryMessage<T extends object>({
  payload,
  compare,
  setCompare,
  index,
  isJustOne,
  checkBoxLabel,
}: {
  payload: BigqueryItem<T>;
  compare: number[];
  setCompare: (items: number[]) => void;
  index: number;
  isJustOne: boolean;
  checkBoxLabel: (item: BigqueryItem<T>) => ReactElement;
}) {
  const [showsPayload, setShowsPayload] = React.useState<boolean>(isJustOne);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const isDisabled = isJustOne || (compare.length === 2 && !compare.includes(index));
  return (
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className={styles.bigquery__message__header}>
            <div className={styles.bigquery__message__header__checkbox}>
              <Checkbox
                checked={compare.includes(index)}
                disabled={isDisabled}
                onChange={(_event, { checked }) => {
                  if (checked) {
                    setCompare([...compare, index].sort());
                  } else {
                    setCompare(compare.filter((item) => item !== index));
                  }
                }}
              />
              <div style={isDisabled ? { color: 'gray' } : {}}>{checkBoxLabel(payload)}</div>
            </div>
            <div>
              {showsPayload && (
                <Button size="mini" onClick={() => setCollapsed(!collapsed)}>
                  {collapsed ? 'Expand' : 'Collapse'}
                </Button>
              )}
              <Button size="mini" onClick={() => setShowsPayload(!showsPayload)}>
                {showsPayload ? 'Hide' : 'Show'}
              </Button>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {showsPayload && (
          <Table.Row>
            <Table.Cell className={styles.bigquery__message__cell}>
              <ReactJson
                src={payload.data}
                displayObjectSize={false}
                displayDataTypes={false}
                indentWidth={2}
                shouldCollapse={(field) => {
                  if (field.namespace.length === 1 && field.namespace[0] === 'root') {
                    return false;
                  }
                  return collapsed;
                }}
              />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
