import { Loader } from '@sixfold/loader-container';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { PlatformMutations } from './platform_mutations_container';
import { CompanyPlatform } from '../../company';
import {
  CompanyPlatformQuery as CompanyPlatformQueryResult,
  CompanyPlatformQueryVariables,
  CompanyPlatformsWithPlatformsQuery as CompanyPlatformsWithPlatformsQueryResult,
  CompanyPlatformsWithPlatformsQueryVariables,
  getNodes,
} from '../../lib/graphql';
import { PlatformView, PlatformViewDataProps } from '../components/platform';
import { companyPlatformsWithPlatforms, getCompanyPlatformQuery } from '../graphql';

const isCompanyPlatformQueryResult = (queryResult: unknown): queryResult is Partial<CompanyPlatformQueryResult> => {
  return (queryResult as CompanyPlatformQueryResult).companyPlatform !== undefined;
};

const isCompanyPlatformsWithPlatformsQueryResult = (
  queryResult: unknown,
): queryResult is Partial<CompanyPlatformsWithPlatformsQueryResult> => {
  const result = queryResult as CompanyPlatformsWithPlatformsQueryResult;
  return result.platforms !== undefined && result.company !== undefined;
};

class LoadingContainer extends Loader<
  CompanyPlatformQueryResult | CompanyPlatformsWithPlatformsQueryResult,
  PlatformViewDataProps,
  CompanyPlatformQueryVariables | CompanyPlatformsWithPlatformsQueryVariables
> {}
class CompanyPlatformQuery extends Query<CompanyPlatformQueryResult, CompanyPlatformQueryVariables> {}
class CompanyPlatformsWithPlatformsQuery extends Query<
  CompanyPlatformsWithPlatformsQueryResult,
  CompanyPlatformsWithPlatformsQueryVariables
> {}

export const PlatformContainer = (props: RouteComponentProps<{ company_id: string; platform_id: string }>) => {
  const { platform_id, company_id } = props.match.params;
  const isNewPlatform = platform_id === 'new';
  return (
    <CompanyPlatformsWithPlatformsQuery
      fetchPolicy="cache-and-network"
      query={companyPlatformsWithPlatforms}
      variables={{ company_id }}
      skip={!isNewPlatform}>
      {(companyPlatformsWithPlatformsResult) => (
        <CompanyPlatformQuery
          query={getCompanyPlatformQuery}
          skip={isNewPlatform}
          variables={{
            company_id,
            platform_id,
          }}>
          {(companyPlatformResult) => (
            <PlatformMutations>
              {({ createCompanyPlatform, deleteCompanyPlatform }) => (
                <LoadingContainer
                  result={
                    (isNewPlatform ? companyPlatformsWithPlatformsResult : companyPlatformResult) as QueryResult<
                      CompanyPlatformQueryResult | CompanyPlatformsWithPlatformsQueryResult,
                      CompanyPlatformQueryVariables | CompanyPlatformsWithPlatformsQueryVariables
                    >
                  }
                  mapData={({ data }) => ({
                    data: {
                      companyPlatform:
                        isCompanyPlatformQueryResult(data) && data?.companyPlatform !== undefined
                          ? data.companyPlatform
                          : null,
                      platforms: getNodes(
                        isCompanyPlatformsWithPlatformsQueryResult(data) ? data.platforms : undefined,
                      ),
                      companyPlatforms:
                        isCompanyPlatformsWithPlatformsQueryResult(data) && notNil(data?.company?.platforms)
                          ? (data?.company?.platforms as unknown as CompanyPlatform[])
                          : [],
                    },
                  })}>
                  {({ data }) => (
                    <PlatformView
                      createCompanyPlatform={createCompanyPlatform}
                      deleteCompanyPlatform={deleteCompanyPlatform}
                      data={data}
                      isNewPlatform={isNewPlatform}
                      routeProps={{
                        goBack: props.history.goBack,
                        company_id: props.match.params.company_id,
                        platform_id: props.match.params.platform_id,
                      }}
                    />
                  )}
                </LoadingContainer>
              )}
            </PlatformMutations>
          )}
        </CompanyPlatformQuery>
      )}
    </CompanyPlatformsWithPlatformsQuery>
  );
};
