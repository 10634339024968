import { CompanyProfile } from '../company';
import {
  TourVehicleAllocationSource,
  TourVehicleAllocationType,
  TourWarningType,
  TourFailureReasonType,
  GeoPositionAccuracy,
  GeofenceType,
  GeofenceZoneType,
  GeoPositionMatchLevel,
  EtaType,
  ExternalEtaSource,
  ExternalEventSource,
  ExternalTourEventQualifier,
  ExternalTourStopEventQualifier,
  ExternalTourDeliveryEventQualifier,
  TourEventName,
  StopEventName,
  TourVisibilitySourceType,
  TourVisibilityPrecisionType,
  VisibilityIssueType,
  TourRemoteAllocationSource,
  TourAllocationDeviceType,
  TourType,
  GraphQLJSON,
  DateTime,
  TourQuery,
  TripDeliveryItem,
  TourAllocationSwitchType,
} from '../lib/graphql';
import { Vehicle, BreakType, VehicleType } from '../vehicle/entities';
// Duplicate import for type only is fine.
// eslint-disable-next-line no-duplicate-imports
import type { VehicleBreakHistory } from '../vehicle/entities';

export { BreakType, VehicleBreakHistory };

export interface Stop {
  stop_id: number;
  type: string;
  lat: number | null;
  lng: number | null;
  geofenceZones: GeofenceZone[];
  timeslot: { begin: string | null; end: string | null; timezone: string | null } | null;
}

export type MappableStop = Stop & { lat: number; lng: number };

export interface BaseEvent {
  created_at: string;
  event_id: number;
  event_time: string | null;
  event_index: number;
  vehicle_lat: number | null;
  vehicle_lng: number | null;
  visibilitySource: TourVisibilitySource | null;
  metadata: EventMetadata | null;
}

export interface TourEvent extends BaseEvent {
  __typename: 'TourEvent';
  event_name: TourEventName;
  warning: TourWarning | null;
}

export interface StopEvent extends BaseEvent {
  __typename: 'StopEvent';
  event_name: StopEventName;
  stop_id: number;
}

export interface EventMetadata {
  algorithm: string | null;
  parameters: GraphQLJSON | null;
}

export interface TourVisibilitySource {
  primaryEventSource: TourVisibilitySourceType;
  streamPrecision: TourVisibilityPrecisionType | null;
  eventOrigin: string | null;
  sourceCompany: {
    company_id: string;
    company_name: string | null;
  } | null;
  sourcePlatform: {
    platform_name: string;
  } | null;
}

export interface VisibilityIssue {
  type: VisibilityIssueType;
  index: number;
}

export enum VisibilityService {
  VISIBILITY_ROAD = 'VISIBILITY_ROAD',
  VISIBILITY_OCEAN = 'VISIBILITY_OCEAN',
  VISIBILITY_AIR = 'VISIBILITY_AIR',
  VISIBILITY_RAIL = 'VISIBILITY_RAIL',
  VISIBILITY_CARBON = 'VISIBILITY_CARBON',
}

export interface TourVolume {
  value: number;
  unit: string;
}

export interface ExternalCarrier {
  companyID: string | null;
  companyName: string | null;
}

export interface TourTransportUnit {
  value: number;
  unit: string;
}

export interface TourLoadingLength {
  value: number;
  unit: string;
}

export interface TourIncoterms {
  term: string;
  city: string;
}

export interface GeoPosition {
  lat: number;
  lng: number;
}

export interface GeofenceCircle {
  __typename: 'GeofenceCircle';
  type: GeofenceType.CIRCLE;
  radiusInMeters: number;
  origin: GeoPosition;
}

export interface GeofencePolygon {
  __typename: 'GeofencePolygon';
  type: GeofenceType.POLYGON;
  points: GeoPosition[];
}

export type Geofence = GeofenceCircle | GeofencePolygon;

export interface GeofenceZone {
  zone: GeofenceZoneType;
  geofence: Geofence;
}

interface TourEventBase {
  __typename: string;
  timestamp: string | null;
  eventId: string;
}

export interface ExternalEvent extends TourEventBase {
  eventId: string;
  eventSource: ExternalEventSource;
  sentByCompanyId: string | null;
  timestamp: DateTime;
  externalEventId: string | null;
  externalEventSource: string | null;
  externalEventQualifier: string;
  qualifier: string;
  latitude: number | null;
  longitude: number | null;
  createdAt: DateTime;
}

export interface TourExternalEvent extends ExternalEvent {
  __typename: 'ExternalTourEvent';
  qualifier: ExternalTourEventQualifier;
  licensePlateNumber: string | null;
  remoteId: string | null;
  vehicleType: VehicleType | null;
  locationName: string | null;
}

export interface TourExternalStopEvent extends ExternalEvent {
  __typename: 'ExternalTourStopEvent';
  qualifier: ExternalTourStopEventQualifier;
  stopId: string;
}

export interface TourExternalDeliveryEvent extends ExternalEvent {
  __typename: 'ExternalTourDeliveryEvent';
  qualifier: ExternalTourDeliveryEventQualifier;
  deliveryId: string;
}
export interface TourExternalTracking {
  tourId: string;
  provider: string;
  createdAt: string;
  updatedAt: string;
  externalId: string | null;
  status: string | null;
  statusReason: string | null;
}
export interface TourExternalTrackingLog {
  content: string;
  tourId: string;
  contentType: string;
  provider: string;
  createdAt: string;
}
export interface TourDemurrageDetentionLog {
  content: string;
  tourId: string;
  createdAt: string;
  demurrageDetentionLogId: string;
}
export interface TourExternalTrackingRequest {
  trackingRequestId: string;
  tourId: string;
  url: string;
  requestPayload: string;
  createdAt: string;
  responsePayload: string | null;
  responseStatus: string | null;
}
export interface RawExternalEvents {
  tourExternalTracking: TourExternalTracking[];
  tourExternalTrackingLogs: TourExternalTrackingLog[];
  tourExternalTrackingRequests: TourExternalTrackingRequest[];
  tourDemurrageDetentionLogs: TourDemurrageDetentionLog[];
}

export const isTourExternalEvent = (p: TourEventBase): p is TourExternalEvent => p.__typename === 'ExternalTourEvent';
export const isTourExternalStopEvent = (p: TourEventBase): p is TourExternalStopEvent =>
  p.__typename === 'ExternalTourStopEvent';
export const isTourExternalDeliveryEvent = (p: TourEventBase): p is TourExternalDeliveryEvent =>
  p.__typename === 'ExternalTourDeliveryEvent';

export type Event = TourEvent | StopEvent;

export interface TourStopLocation {
  address: {
    city: string | null;
    country: string | null;
    full_address: string | null;
    state: string | null;
    street_address: string | null;
    zipcode: string | null;
    shortAreaCode: string | null;
  } | null;
  gate: string | null;
  gate_id: string | null;
  location_reference_id: string | null;
  name: string | null;
  bookingLocationName: string | null;
  position: {
    lat: number | null;
    lng: number | null;
    provider: string | null;
    accuracy: GeoPositionAccuracy | null;
    detail: string[] | null;
    matchLevel: GeoPositionMatchLevel | null;
    geocodingDirection: string | null;
  } | null;
  place: {
    place_id: string;
    name: string;
    isSixfoldGenerated: boolean;
  } | null;
}

export interface TourStopEta {
  stop_id: number;
  etaType: EtaType | null;
  externalEtaSource: ExternalEtaSource | null;
  estimated_arrival: string | null;
  estimated_arrival_regulated: string | null;
  estimated_arrival_unregulated: string | null;
  estimated_arrival_status: string | null;
  estimated_arrival_accuracy: string | null;
  estimated_arrival_distance_in_meters: number | null;
}

export interface TourStop extends TourStopEta {
  location: TourStopLocation | null;
  sequence_number: number;
  actual_sequence_number: number | null;
  platformSequenceNumber: number | null;
  status: string | null;

  departure_time: string | null;
  arrival_time: string | null;
  arrival_delay_in_seconds: number | null;
  expected_duration: string | null;
  deadline: string | null;
  type: string | null;
  timeslot: {
    begin: string | null;
    end: string | null;
    timezone: string | null;
  } | null;
  status_events: Omit<StopEvent, 'metadata'>[] | null;
  delivery_ids: string[] | null;
  stop_reference_id: string | null;
  booking_id: string | null;
  place_reference_id: string | null;
  geofenceZones: GeofenceZone[];
  vehicleAllocation: {
    vehicleId: string | null;
    vehicleLicensePlateNumber: string;
    startTrackingAt: Date | null;
  } | null;

  etas: TourStopEta[] | null;
}

export interface Route {
  legs: {
    polyline: {
      points: string;
    };
  }[];
}

export interface TourVehicleAllocation {
  vehicleId: string | null;
  allocationId?: string | null;
  allocationType: TourVehicleAllocationType;
  vehicleLicensePlateNumber: string | null;
  startTrackingAt: Date | null;
  source: TourVehicleAllocationSource;
  allocatedAt: string | null;
  allocatedByCompany?: {
    company_id: string;
    company_name: string | null;
  } | null;
  remoteTrackerId: string | null;
  remoteAllocationSource: TourRemoteAllocationSource | null;
  allocationDeviceType: TourAllocationDeviceType | null;
  stopAllocations:
    | {
        stopId: string;
        vehicleId: string | null;
        vehicleLicensePlateNumber: string | null;
        startTrackingAt: Date | null;
      }[]
    | null;
  allocationSwitchType: TourAllocationSwitchType | null;
}

export interface TourDelivery {
  deliveryId: string;
  deliveryNumber: string | null;
  purchaseOrderNumber: string | null;
  orderNumber: string | null;
  sequenceNumber: number | null;
  deliveryTourId: string | null;
  mainMode: string | null;
  storagePositions: number | null;
  transportUnit: TourTransportUnit | null;
  volume: TourVolume | null;
  loadingLength: TourLoadingLength | null;
  incoterms: TourIncoterms | null;
  customFields: CustomField[];
}

export interface UserProfile {
  user_id: string;
  first_name: string;
  last_name: string;
}

export interface TourForwardingLink {
  from: CompanyProfile | null;
  to: CompanyProfile;
  forwardedAt: string;
  forwardedBy: UserProfile | null;
  triggeredByAllocationId: string | null;
}

export interface Site {
  site_id: string;
  name: string;
}

export interface TourWarning {
  type: TourWarningType;
}

export interface TourFailureReason {
  type: TourFailureReasonType;
}

export interface CustomField {
  fieldName: string;
  values: {
    value: string;
  }[];
}

export interface PlatformCallback {
  platform: {
    platform_id: string;
    platform_name: string;
  } | null;
  subjectType: string;
  subjectId: string;
  payload: Record<string, unknown>;
  createdAt: string;
}

export interface VisibilitySegment {
  vehicleId: string;
  visibilityStartDate: string;
  visibilityEndDate: string;
}

export interface TrackingSegment {
  vehicleId: string;
  trackingStartDate: string;
  trackingEndDate: string;
}

export interface DeliveryStop {
  stopId: string;
}

export type CustomFields = Record<
  string,
  {
    value: string;
  }
>;

export interface TourDeliveryTree {
  deliveryTreeId: string;
  affectedTourId: string;
  platformDeliveryId: string;
  platformId: string;
  sequenceNumber: number;
  sourceTourId: string | null;
  parentDeliveryTreeId: string | null;
  rootDeliveryTreeId: string;
  rootPlatformDeliveryId: string;
  platformPreviousDeliveryId: string | null;
  deliveryNumber: string | null;
  purchaseOrderNumber: string | null;
  orderNumber: string | null;
  platformSequenceNumber: number | null;
  customFields: CustomFields;
  loadingStop: DeliveryStop | null;
  unloadingStop: DeliveryStop | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface DeliveryTree {
  deliveryTreeId: string;
  platformDeliveryId: string;
  platformId: string;
  sequenceNumber: number;
  customFields: CustomFields;
  createdAt?: string | null;
  updatedAt?: string | null;
  tourId: string | null;
  childDeliveries: DeliveryTree[];
  loadingStop: DeliveryStop | null;
  unloadingStop: DeliveryStop | null;
  deliveryNumber: string | null;
  platformPreviousDeliveryId: string | null;
  platformSequenceNumber: number | null;
  purchaseOrderNumber: string | null;
  orderNumber: string | null;
}

export interface TourDistance {
  totalDistanceInMeters: number;
}

export interface TourFuelBasedEmissions {
  accumulatedFuelConsumptionLiters: number;
  fuelType: string;
  co2WellToTankTonnes: number;
  co2TankToWheelTonnes: number;
  co2WellToWheelTonnes: number;
}

export interface TourDrivenDistance {
  distanceInKilometers: number | null;
  source: string | null;
}

export interface EmissionsCalculationError {
  type: string;
  code: string;
}

export interface TourCO2Emissions {
  co2TankToWheelTonnes: number;
  co2WellToTankTonnes: number;
  co2WellToWheelTonnes: number;
  routePolyline: string | null;
  cargo: {
    containerEmptyWeight: number;
    tonsPerTeu: number;
    tonsPerFeu: number;
    weight: number;
    volumeWeight: string;
    unit: string;
  };
  legs: {
    transportMode: string;
    type: string;
    distanceKM: number | undefined;
    routePolyline: string | undefined;
    startLocation: {
      lat: number;
      lng: number;
    };
    endLocation: {
      lat: number;
      lng: number;
    };
    emissions: {
      emissionMode: string;
      type: string;
      unit: string;
      tankToWheel: number;
      total: number;
      wellToTank: number;
    }[];
    externalCosts:
      | {
          currency: string;
          totalExternalCosts: number;
          accidentCosts: {
            transportMode: string;
            accidentCostValue: number;
          }[];
          noiseCosts: {
            transportMode: string;
            noiseCostValue: number;
          }[];
          emissionCosts: {
            name: string;
            tankToWheel: number;
            wellToTank: number;
            total: number;
          }[];
        }
      | undefined;
    carriageParameters: {
      bioFuelShare: number;
      carriageModeParameters: {
        mode: string;
        emissionClass: string | undefined;
        emptyRunFactor: number | undefined;
        ferryRouting: string | undefined;
        fuelType: string | undefined;
        loadFactor: number | undefined;
        lorryClass: string | undefined;
        driveClass: string | undefined;
        aircraftType: string | undefined;
        seaShipType: string | undefined;
        inlandShipType: string | undefined;
      }[];
    };
  }[];
}
export interface UserGroupOnPlatform {
  groupId: string;
  name: string;
  company: {
    company_id: string;
  } | null;
}

export type TripSegment = NonNullable<NonNullable<TourQuery['tour']>['tripSegments']>[number];
export type TripLocation = NonNullable<NonNullable<TourQuery['tour']>['tripLocations']>[number];
export type TripMilestones = NonNullable<TripSegment['locations']>[number];

export interface Tour {
  tour_id: number;
  tour_type: TourType | null;
  parent_tour_id: string | null;
  events: Event[];
  vehicles: Vehicle[];
  shipper: CompanyProfile | null;
  carrier: CompanyProfile | null;
  createdBy: CompanyProfile | null;
  isSendEtaToPlatformEnabled: boolean;
  working_stop_id: number | null;
  stops: TourStop[];
  status_events: Omit<TourEvent, 'metadata'>[] | null;
  deliveries: TourDelivery[];
  deliveryTree: TourDeliveryTree[];
  start_time: string | null;
  complete_time: string | null;
  abort_time: string | null;
  aborted_by_user: UserProfile | null;
  visibility_start_time: string | null;
  visibility_end_time: string | null;
  visibilitySegments: VisibilitySegment[];
  trackingSegments: TrackingSegment[];
  isTrackingDisabled: boolean | null;
  trackingStartTime: string | null;
  trackingEndTime: string | null;
  created_at: string;
  status: string | null;
  shipper_transport_number: string | null;
  route: (Route & { provider: string }) | null;
  platform: {
    platform_id: string;
    platform_name: string;
  } | null;
  platform_carrier_id: string | null;
  platform_shipper_id: string | null;
  platformBranchId: string | null;
  transport_id: string;
  platform_transport_number: string | null;
  tags: { name: string }[];
  availableTourTags: { name: string }[];
  externalEvents: TourExternalEvent[];
  externalStopEvents: TourExternalStopEvent[];
  externalDeliveryEvents: TourExternalDeliveryEvent[];
  currentVehicle: {
    vehicleId: string | null;
    vehicleLicensePlateNumber: string;
  } | null;
  vehicleAllocations: TourVehicleAllocation[];
  vehicleAllocationHistory: TourVehicleAllocation[];
  activeVehicleAllocation: TourVehicleAllocation;
  forwardingChain: TourForwardingLink[];
  site: Site | null;
  customFields: CustomField[];
  transportMode: string | null;
  tourDistance: TourDistance | null;
  warnings: TourWarning[];
  failureReason: TourFailureReason | null;
  platformCallbacks: PlatformCallback[];
  visibilitySources: TourVisibilitySource[];
  visibilityIndex: number | null;
  mainVisibilityIssue: VisibilityIssue | null;
  visibilityIssues: VisibilityIssue[] | null;
  userGroupsOnPlatform: UserGroupOnPlatform[];
  metadata: Record<string, string> | null;
  plannedEmissions: TourCO2Emissions | null;
  telemetryEmissions: TourCO2Emissions | null;
  fuelBasedEmissions: TourFuelBasedEmissions | null;
  drivenDistance: TourDrivenDistance | null;
  emissionsCalculationErrors: EmissionsCalculationError[] | null;
  visibilityServices: VisibilityService[];
  volume: TourVolume | null;
  externalCarrier: ExternalCarrier | null;
  loadType: string | null;
  mainMode: string | null;
  rawExternalEvents: RawExternalEvents | null;
  tripSegments: TripSegment[];
  tripLocations: TripLocation[];
  tripDeliveryItems: TripDeliveryItem[];
  tour_area: Geofence | null;
}

export interface Ruler {
  id: string;
  fromLngLat: [number, number];
  toLngLat?: [number, number];
  distanceInMeters?: number;
}

export enum MapboxStyle {
  MAP = 'mapbox://styles/mapbox/light-v9',
  SATELLITE = 'mapbox://styles/mapbox/satellite-v9',
}
