import { Loader } from '@sixfold/loader-container';
import { isNil } from '@sixfold/typed-primitives';
import React, { useEffect, useRef } from 'react';
import { Mutation, Query, MutationFn } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { telematicsIntegrationSensitiveDataQuery } from '../../company/graphql';
import {
  TelematicsIntegrationQuery,
  TelematicsIntegrationQueryVariables,
  UpdateTelematicsIntegrationMutation,
  UpdateTelematicsIntegrationMutationVariables,
  Exact,
  UpdateTelematicsIntegrationInput,
  SensitiveDataQuery,
  SensitiveDataQueryVariables,
} from '../../lib/graphql';
import { IntegrationView, IntegrationViewDataProps } from '../components/integration';
import { telematicsIntegrationQuery, updateTelematicsIntegrationMutation } from '../graphql';

class LoadingContainer extends Loader<
  TelematicsIntegrationQuery,
  IntegrationViewDataProps,
  TelematicsIntegrationQueryVariables
> {}
class DataLoadingContainer extends Loader<
  SensitiveDataQuery,
  {
    data: {
      sensitiveData:
        | {
            connectionData: Record<string, string | number | boolean | object> | null;
            syncStateData: string | null;
          }
        | null
        | undefined;
    };
  },
  SensitiveDataQueryVariables
> {}
class IntegrationContainerQuery extends Query<TelematicsIntegrationQuery, TelematicsIntegrationQueryVariables> {}
class IntegrationContainerUpdateTelematicsIntegrationMutation extends Mutation<
  UpdateTelematicsIntegrationMutation,
  UpdateTelematicsIntegrationMutationVariables
> {}
class SensitiveDataQueryContainer extends Query<SensitiveDataQuery, SensitiveDataQueryVariables> {}

export const IntegrationContainer = (
  props: RouteComponentProps<{ company_id: string; telematics_integration_id: string }>,
) => {
  const { telematics_integration_id, company_id } = props.match.params;
  const [variables, setVariables] = React.useState<{ telematics_integration_id: string; captcha_token?: string }>({
    telematics_integration_id,
  });

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const addCaptchaToVariables = (captcha_token: string) => {
    if (isMounted.current) {
      setVariables({ telematics_integration_id, captcha_token });
    }
  };

  const getChildren = (
    data: IntegrationViewDataProps['data'],
    updateTelematicsIntegration: MutationFn<
      UpdateTelematicsIntegrationMutation,
      Exact<{ input: UpdateTelematicsIntegrationInput }>
    >,
  ): React.ReactNode => {
    const captchaToken = variables.captcha_token;
    if (captchaToken) {
      return (
        <SensitiveDataQueryContainer
          query={telematicsIntegrationSensitiveDataQuery}
          variables={{ ...variables, captcha_token: captchaToken }}
          skip={isNil(variables.captcha_token)}>
          {(dataResult) => (
            <DataLoadingContainer
              result={dataResult}
              mapData={({ data }) => ({
                data: {
                  sensitiveData: data.sensitiveData,
                },
              })}>
              {({ data: connDataResult }) => (
                <IntegrationView
                  data={data}
                  sensitiveConnectionData={connDataResult.sensitiveData?.connectionData}
                  sensitiveSyncStateData={connDataResult.sensitiveData?.syncStateData}
                  updateIntegration={updateTelematicsIntegration}
                  routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                  addCaptchaToVariables={addCaptchaToVariables}
                />
              )}
            </DataLoadingContainer>
          )}
        </SensitiveDataQueryContainer>
      );
    }

    return (
      <IntegrationView
        data={data}
        updateIntegration={updateTelematicsIntegration}
        routeProps={{ push: props.history.push, goBack: props.history.goBack }}
        addCaptchaToVariables={addCaptchaToVariables}
      />
    );
  };

  return (
    <IntegrationContainerQuery query={telematicsIntegrationQuery} variables={variables}>
      {(result) => (
        <IntegrationContainerUpdateTelematicsIntegrationMutation mutation={updateTelematicsIntegrationMutation}>
          {(updateTelematicsIntegration) => (
            <LoadingContainer
              result={result}
              mapData={({ data }) => ({
                data: {
                  company_id,
                  telematicsIntegration: data.telematicsIntegration ?? null,
                },
              })}>
              {({ data }) => getChildren(data, updateTelematicsIntegration)}
            </LoadingContainer>
          )}
        </IntegrationContainerUpdateTelematicsIntegrationMutation>
      )}
    </IntegrationContainerQuery>
  );
};
