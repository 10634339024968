import { useNotifications } from '@sixfold/common-ui';
import { Children, renderChildren } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import {
  AddCompanyTagMutation as AddCompanyTagMutationResult,
  AddCompanyTagMutationVariables,
  RemoveCompanyTagMutation as RemoveCompanyTagMutationResult,
  RemoveCompanyTagMutationVariables,
} from '../../lib/graphql';
import { companyQuery } from '../graphql';

const addCompanyTagMutation = gql`
  mutation AddCompanyTag($input: CompanyTagInput!) {
    addCompanyTag(input: $input)
  }
`;

const removeCompanyTagMutation = gql`
  mutation RemoveCompanyTag($input: CompanyTagInput!) {
    removeCompanyTag(input: $input)
  }
`;

interface MutationsProps {
  children?: Children<{
    addCompanyTag: MutationFn<AddCompanyTagMutationResult, AddCompanyTagMutationVariables>;
    removeCompanyTag: MutationFn<RemoveCompanyTagMutationResult, RemoveCompanyTagMutationVariables>;
  }>;
}

class AddCompanyTagMutation extends Mutation<AddCompanyTagMutationResult, AddCompanyTagMutationVariables> {}
class RemoveCompanyTagMutation extends Mutation<RemoveCompanyTagMutationResult, RemoveCompanyTagMutationVariables> {}

export const CompanyTagsMutations: React.FC<MutationsProps> = (props) => {
  const notify = useNotifications();

  return (
    <AddCompanyTagMutation mutation={addCompanyTagMutation} ignoreResults>
      {(addCompanyTag) => (
        <RemoveCompanyTagMutation mutation={removeCompanyTagMutation} ignoreResults>
          {(removeCompanyTag) => {
            return renderChildren(props.children, {
              addCompanyTag: async ({ variables }: { variables: AddCompanyTagMutationVariables }) => {
                const response = await addCompanyTag({
                  variables,
                  refetchQueries: [
                    {
                      query: companyQuery,
                      variables: {
                        company_id: variables.input.companyId,
                        applies_to: 'company',
                      },
                    },
                  ],
                });
                notify.success({ title: 'Company tag added' });
                return response;
              },
              removeCompanyTag: ({ variables }: { variables: RemoveCompanyTagMutationVariables }) => {
                const response = removeCompanyTag({
                  variables,
                  refetchQueries: [
                    {
                      query: companyQuery,
                      variables: {
                        company_id: variables.input.companyId,
                        applies_to: 'company',
                      },
                    },
                  ],
                });
                notify.success({ title: 'Company tag removed' });
                return response;
              },
            });
          }}
        </RemoveCompanyTagMutation>
      )}
    </AddCompanyTagMutation>
  );
};
