import { ErrorBoundary } from '@sixfold/app-utils';
import { QueryString, OptionalString } from '@sixfold/query-string';
import { flatten } from '@sixfold/typed-primitives';
import React from 'react';
import { CSVLink } from 'react-csv';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { ErrorPlaceholder } from '../../components/error_placeholder';
import { isValid } from '../../lib/date';
import { MapSlider, TIMELINE_TIMESTAMP_QS } from '../../map/components/map_slider';
import { VehicleHistory, VehicleBreakHistory, Vehicle, VehicleStatus } from '../../vehicle/entities';
import { isFMSTracker } from '../../vehicle/utils';
import { TourMapView } from '../components/tour_map';
import { Tour } from '../entities';
import { getStopsFromTourStops, getExternalEvents } from '../utils';

export interface Props extends RouteComponentProps<object> {
  tour: Tour;
  vehicles: (Vehicle & {
    history: VehicleHistory[];
    breakHistory: VehicleBreakHistory[];
    status: VehicleStatus | null;
  })[];
  tourSidebarState: {
    tourSidebarActive: boolean;
    setTourSidebarActive: React.Dispatch<React.SetStateAction<boolean>>;
  };
  historyFetchingBoundaries: {
    startTime: string;
    endTime: string;
  };
}

export const TourMapContainer = ({ tour, vehicles, location, historyFetchingBoundaries, tourSidebarState }: Props) => {
  const { startTime, endTime } = historyFetchingBoundaries;
  const csvInput = flatten(
    vehicles.map((vehicle) =>
      vehicle.history.map((history) => {
        return {
          vehicle_id: vehicle.vehicle_id,
          plate: vehicle.license_plate_number,
          source: history.source,
          type: history.type,
          company_id: history.company?.company_id,
          timestamp: history.timestamp,
          latitude: history.lat,
          longitude: history.lng,
          ...(isFMSTracker(history.vehicleTracker)
            ? {
                sixfold_received_position_at: history.received_at ?? 'unknown',
                sixfold_processesed_position_at: history.created_at ?? 'unknown',
                telematics_provider_name:
                  history.vehicleTracker.telematicsIntegration?.telematicsProvider?.telematicsProviderName ?? 'unknown',
                telematics_integration_id:
                  history.vehicleTracker.telematicsIntegration?.telematicsIntegrationId ?? 'unknown',
              }
            : {}),
        };
      }),
    ),
  );

  const toggleTourSidebar = React.useCallback(() => {
    tourSidebarState.setTourSidebarActive(!tourSidebarState.tourSidebarActive);
  }, [tourSidebarState]);

  return (
    <div
      className={
        tourSidebarState.tourSidebarActive
          ? 'ten wide mobile ten wide tablet ten wide computer ten wide large screen eleven wide widescreen column'
          : 'sixteen wide column'
      }>
      <CSVLink filename={`tour_${tour.tour_id}_vehicles_history.csv`} data={csvInput}>
        <Button size="mini">Download tour vehicles history</Button>
      </CSVLink>
      <Button size="mini" onClick={toggleTourSidebar}>
        {tourSidebarState.tourSidebarActive ? 'Hide' : 'Show'} tour sidebar
      </Button>
      <ErrorBoundary renderError={(error) => <ErrorPlaceholder error={error} />}>
        <QueryString queryString={location.search} queryParametersToParse={{ [TIMELINE_TIMESTAMP_QS]: OptionalString }}>
          {({ parsedQueryString: { timestamp } }) => {
            const mapSliderTimestamp = isValid(new Date(timestamp ?? '')) ? timestamp : undefined;

            return (
              <MapSlider
                timelineBoundaries={{ startTime, endTime }}
                initialTimestamp={mapSliderTimestamp}
                vehicles={vehicles.map((vehicle) => ({
                  history: vehicle.history,
                  breakHistory: vehicle.breakHistory,
                  licensePlateNumber: vehicle.license_plate_number,
                  vehicleId: vehicle.vehicle_id,
                }))}
                tourEvents={tour.events}
                tourStops={getStopsFromTourStops(tour.stops)}
                externalEvents={getExternalEvents(tour)}>
                {({ selectedPositions }) => (
                  <TourMapView
                    data={{
                      tour,
                      tourSidebarActive: tourSidebarState.tourSidebarActive,
                      ghostVehicles: selectedPositions,
                      vehicles,
                    }}
                  />
                )}
              </MapSlider>
            );
          }}
        </QueryString>
      </ErrorBoundary>
    </div>
  );
};
