import { Loader } from '@sixfold/loader-container';
import { parseQueryString, OptionalString, updateQueryString } from '@sixfold/query-string';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  UserListQuery,
  UserListQueryVariables,
  getNodes,
  loadMoreFromConnection,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { UserList, UserListDataProps } from '../components/user_list';
import { UsersFilter } from '../components/users_filter';
import { usersQuery } from '../graphql';

class LoadingContainer extends Loader<UserListQuery, UserListDataProps, UserListQueryVariables> {}
class UserListContainerQuery extends Query<UserListQuery, UserListQueryVariables> {}

export const UserListContainer = ({ location, history }: RouteComponentProps<object>) => {
  const { email } = parseQueryString({
    queryString: location.search,
    queryParametersToParse: {
      email: OptionalString,
    },
  });

  return (
    <React.Fragment>
      <UsersFilter
        activeFilters={{ email }}
        onFilterToggle={({ name, value }) => {
          history.push(
            `${location.pathname}?${updateQueryString(location.search, {
              param: name,
              value,
            })}`,
          );
        }}
        onClearFilters={() => history.push(location.pathname)}
      />
      <UserListContainerQuery
        fetchPolicy="cache-and-network"
        query={usersQuery}
        variables={{
          limit: PAGINATION_PAGE_SIZE,
          email,
        }}>
        {(result) => (
          <LoadingContainer
            result={result}
            options={{
              showLoadingPlaceholder: ({ networkStatus, loading }) => {
                const isFetchingMore = networkStatus === 3;
                return isFetchingMore ? false : loading;
              },
            }}
            mapData={({ data }) => ({ data: { users: getNodes(data.users) } })}>
            {({ data }) => (
              <InfiniteScroll loadMoreEntries={() => loadMoreFromConnection(usersQuery, result, ['users'])}>
                <UserList data={data} />
              </InfiniteScroll>
            )}
          </LoadingContainer>
        )}
      </UserListContainerQuery>
    </React.Fragment>
  );
};
