import geoViewport from '@mapbox/geo-viewport';
import { getBounds } from '@sixfold/geo-primitives';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import MapGL from 'react-map-gl';
import { Checkbox, CheckboxProps, Dropdown } from 'semantic-ui-react';

import { TourOverlay } from './tour_overlay';
import { getEmbedConfig } from '../../lib/data';
import { generateRandomHexString } from '../../lib/util/string';
import { openSatelliteViewUrl } from '../../lib/util/url';
import { MapViewport } from '../../map/components/map_viewport';
import { Vehicle, VehicleBreakHistory, VehicleHistory, VehicleStatus } from '../../vehicle/entities';
import { Tour, Ruler, MappableStop, MapboxStyle } from '../entities';
import { getStopsFromTourStops, calculateDistanceInMeters, getExternalEvents } from '../utils';

const MAPBOX_TILE_SIZE = 512;
const MAP_BOUNDS_PADDING = 50;
const MIN_ZOOM_LEVEL = 5;
const MAX_ZOOM_LEVEL = 20;

export interface TourMapViewDataProps {
  data: {
    tour: Tour;
    vehicles: (Vehicle & {
      history: VehicleHistory[];
      breakHistory: VehicleBreakHistory[];
      status: VehicleStatus | null;
    })[];
    ghostVehicles: VehicleHistory[];
    tourSidebarActive?: boolean;
  };
}

const fitBoundsViewport = (coordinates: { lat: number; lng: number }[], width: number, height: number) => {
  // Creates an infinite loop downstream (in deckGL) if coordinates are not present (no error is thrown – false is returned instead)

  if (coordinates.length === 0) {
    return {
      latitude: 0,
      longitude: 0,
      zoom: 0,
      width,
      height,
      maxZoom: MAX_ZOOM_LEVEL,
      pitch: 0,
      bearing: 0,
    };
  }

  const coordinateBounds = getBounds(coordinates);

  const bounds = [
    coordinateBounds.min.lng,
    coordinateBounds.min.lat,
    coordinateBounds.max.lng,
    coordinateBounds.max.lat,
  ];

  const viewport = geoViewport.viewport(
    bounds,
    [Math.max(width - MAP_BOUNDS_PADDING * 2, 1), Math.max(height - MAP_BOUNDS_PADDING * 2, 1)],
    MIN_ZOOM_LEVEL,
    MAX_ZOOM_LEVEL,
    MAPBOX_TILE_SIZE,
  );

  return {
    latitude: viewport.center && viewport.center[1],
    longitude: viewport.center && viewport.center[0],
    zoom: viewport.zoom,
    width,
    height,
    maxZoom: MAX_ZOOM_LEVEL,
    pitch: 0,
    bearing: 0,
  };
};

interface State {
  hideLayers: {
    breaks: Record<string, boolean>;
    history: Record<string, boolean>;
    geofences: boolean;
    events: boolean;
    externalEvents: boolean;
    historyPoints: boolean;
    tourArea: boolean;
  };
  rulers: Ruler[];
  isRulerEditMode: boolean;
  mapStyle: MapboxStyle;
}

enum MapLayerControls {
  HIDE_GEOFENCES = 'hide_geofences',
  HIDE_BREAKS = 'hide_breaks',
  HIDE_EVENTS = 'hide_events',
  HIDE_EXTERNAL_EVENTS = 'hide_external_events',
  HIDE_HISTORY_POINTS = 'hide_history_points',
  HIDE_TOUR_AREA = 'hide_tour_area',
}

export class TourMapView extends React.Component<TourMapViewDataProps, State> {
  constructor(props: TourMapViewDataProps) {
    super(props);

    this.state = {
      hideLayers: {
        geofences: false,
        history: {},
        breaks: {},
        events: false,
        externalEvents: false,
        historyPoints: false,
        tourArea: false,
      },
      rulers: [],
      isRulerEditMode: false,
      mapStyle: MapboxStyle.MAP,
    };
  }

  controlKeyDownListener = (ev: KeyboardEvent) => {
    if (ev.ctrlKey && ev.key === 'Backspace') {
      this.setState({ rulers: this.state.rulers.slice(0, -1) });
    }
    if (ev.key === 'Control') {
      this.setState({ isRulerEditMode: true });
    }
  };

  controlKeyUpListener = (ev: KeyboardEvent) => {
    if (ev.key === 'Control') {
      this.setState({ isRulerEditMode: false });
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.controlKeyDownListener, false);
    window.addEventListener('keyup', this.controlKeyUpListener, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.controlKeyDownListener, false);
    window.removeEventListener('keyup', this.controlKeyUpListener, false);
  }

  handleMapControlCheckboxChange = (_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const name = data.name;
    if (notNil(data.checked)) {
      switch (name) {
        case MapLayerControls.HIDE_GEOFENCES:
          return this.setState({
            hideLayers: {
              ...this.state.hideLayers,
              geofences: !data.checked,
            },
          });
        case MapLayerControls.HIDE_EVENTS:
          return this.setState({
            hideLayers: {
              ...this.state.hideLayers,
              events: !data.checked,
            },
          });
        case MapLayerControls.HIDE_EXTERNAL_EVENTS:
          return this.setState({
            hideLayers: {
              ...this.state.hideLayers,
              externalEvents: !data.checked,
            },
          });
        case MapLayerControls.HIDE_HISTORY_POINTS:
          return this.setState({
            hideLayers: {
              ...this.state.hideLayers,
              historyPoints: !data.checked,
            },
          });
        case MapLayerControls.HIDE_TOUR_AREA:
          return this.setState({
            hideLayers: {
              ...this.state.hideLayers,
              tourArea: !data.checked,
            },
          });
        default:
          return false;
      }
    }
    return false;
  };

  handleToggleVehicleBreakToHide = (_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (notNil(data.name) && notNil(data.checked)) {
      this.setState({
        hideLayers: {
          ...this.state.hideLayers,
          breaks: {
            ...this.state.hideLayers.breaks,
            [data.name]: !data.checked,
          },
        },
      });
      return !data.checked;
    }
    return false;
  };

  handleToggleVehicleHistoryToHide = (_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (notNil(data.name) && notNil(data.checked)) {
      this.setState({
        hideLayers: {
          ...this.state.hideLayers,
          history: {
            ...this.state.hideLayers.history,
            [data.name]: !data.checked,
          },
        },
      });
      return !data.checked;
    }
    return false;
  };

  handleAddRuler = (LngLat: [number, number]) => {
    const openEndedRuler = this.state.rulers.find((ruler) => ruler.toLngLat === undefined);
    if (openEndedRuler !== undefined) {
      return this.setState({
        rulers: [
          ...this.state.rulers.filter((ruler) => ruler.id !== openEndedRuler.id),
          {
            id: openEndedRuler.id,
            fromLngLat: openEndedRuler.fromLngLat,
            toLngLat: LngLat,
            distanceInMeters: calculateDistanceInMeters(openEndedRuler.fromLngLat, LngLat),
          },
        ],
      });
    }

    this.setState({
      rulers: [
        ...this.state.rulers,
        {
          id: generateRandomHexString(12),
          fromLngLat: LngLat,
        },
      ],
    });
  };

  handleMapStyleCheckboxChange = (_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const name = data.name;
    if (notNil(data.checked)) {
      switch (name) {
        case MapboxStyle.MAP:
          return this.setState({
            mapStyle: MapboxStyle.MAP,
          });
        case MapboxStyle.SATELLITE:
          return this.setState({
            mapStyle: MapboxStyle.SATELLITE,
          });
      }
    }
  };

  renderMapControls = (vehicles: Vehicle[]) => {
    const { hideLayers } = this.state;
    return (
      <div className="tour__map-controls">
        <div className="ui label small">
          <Dropdown item simple text="Marker Filters">
            <Dropdown.Menu>
              <Dropdown.Item key={MapLayerControls.HIDE_GEOFENCES}>
                <Checkbox
                  name={MapLayerControls.HIDE_GEOFENCES}
                  label="Geofences"
                  checked={!hideLayers.geofences}
                  onChange={this.handleMapControlCheckboxChange}
                />
              </Dropdown.Item>
              <Dropdown.Item key={MapLayerControls.HIDE_EVENTS}>
                <Checkbox
                  name={MapLayerControls.HIDE_EVENTS}
                  label="Events"
                  checked={!hideLayers.events}
                  onChange={this.handleMapControlCheckboxChange}
                />
              </Dropdown.Item>
              <Dropdown.Item key={MapLayerControls.HIDE_EXTERNAL_EVENTS}>
                <Checkbox
                  name={MapLayerControls.HIDE_EXTERNAL_EVENTS}
                  label="External events"
                  checked={!hideLayers.externalEvents}
                  onChange={this.handleMapControlCheckboxChange}
                />
              </Dropdown.Item>
              <Dropdown.Item key={MapLayerControls.HIDE_HISTORY_POINTS}>
                <Checkbox
                  name={MapLayerControls.HIDE_HISTORY_POINTS}
                  label="History points"
                  checked={!hideLayers.historyPoints}
                  onChange={this.handleMapControlCheckboxChange}
                />
              </Dropdown.Item>
              <Dropdown.Item key={MapLayerControls.HIDE_TOUR_AREA}>
                <Checkbox
                  name={MapLayerControls.HIDE_TOUR_AREA}
                  label="Tour area"
                  checked={!hideLayers.tourArea}
                  onChange={this.handleMapControlCheckboxChange}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="ui label small">
          <Dropdown item compact simple text="Vehicle Breaks">
            <Dropdown.Menu>
              {vehicles.map((vehicle) => (
                <Dropdown.Item key={vehicle.vehicle_id}>
                  <Checkbox
                    name={vehicle.vehicle_id}
                    label={vehicle.license_plate_number ?? `ID: ${vehicle.vehicle_id}`}
                    checked={!this.state.hideLayers.breaks[vehicle.vehicle_id]}
                    onChange={this.handleToggleVehicleBreakToHide}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="ui label small">
          <Dropdown item simple text="Vehicle History">
            <Dropdown.Menu>
              {vehicles.map((vehicle) => (
                <Dropdown.Item key={vehicle.vehicle_id}>
                  <Checkbox
                    name={vehicle.vehicle_id}
                    label={vehicle.license_plate_number ?? `ID: ${vehicle.vehicle_id}`}
                    checked={!this.state.hideLayers.history[vehicle.vehicle_id]}
                    onChange={this.handleToggleVehicleHistoryToHide}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="ui label small">
          <Dropdown item simple text="Map type">
            <Dropdown.Menu>
              <Dropdown.Item key={MapboxStyle.MAP}>
                <Checkbox
                  radio
                  name={MapboxStyle.MAP}
                  label={'Map'}
                  checked={this.state.mapStyle === MapboxStyle.MAP}
                  onChange={this.handleMapStyleCheckboxChange}
                />
              </Dropdown.Item>
              <Dropdown.Item key={MapboxStyle.SATELLITE}>
                <Checkbox
                  radio
                  name={MapboxStyle.SATELLITE}
                  label={'Satellite'}
                  checked={this.state.mapStyle === MapboxStyle.SATELLITE}
                  onChange={this.handleMapStyleCheckboxChange}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    const { vehicles, tour, ghostVehicles, tourSidebarActive } = this.props.data;
    const config = getEmbedConfig();

    if (config === null) {
      throw Error('Missing config');
    }

    const overlayStops = getStopsFromTourStops(tour.stops).filter(
      (stop): stop is MappableStop => stop.lat !== null && stop.lng !== null,
    );
    const tourArea = tour.tour_area;
    const externalEvents = getExternalEvents(tour);

    const defaultViewportResolver = (width: number, height: number) => fitBoundsViewport(overlayStops, width, height);
    const route = { legs: tour.route?.legs ?? [] };

    return (
      <React.Fragment>
        {this.renderMapControls(vehicles)}
        <MapViewport
          style={{ backgroundColor: 'rgba(0,0,0,0.1)', minHeight: '100%' }}
          defaultViewport={defaultViewportResolver}
          tourSidebarActive={tourSidebarActive}>
          {({ width, height, viewport, onViewportChange }) => (
            <MapGL
              {...{ ...viewport, width, height }}
              mapStyle={this.state.mapStyle}
              onViewportChange={onViewportChange}
              onClick={({ lngLat }: { lngLat: [number, number] }) => {
                if (this.state.isRulerEditMode) {
                  this.handleAddRuler(lngLat);
                }
              }}
              mapboxApiAccessToken={config.mapbox_token}>
              <TourOverlay
                layersToHide={this.state.hideLayers}
                shouldDisplayCrosshairCursor={this.state.isRulerEditMode}
                viewport={{ ...viewport, width, height }}
                stops={overlayStops}
                tourArea={tourArea}
                rulers={this.state.rulers}
                events={tour.events}
                externalEvents={externalEvents}
                route={route}
                vehicles={vehicles}
                ghostVehicles={ghostVehicles}
                onLayerClick={(coordinates) => {
                  if (this.state.isRulerEditMode) {
                    return;
                  }
                  openSatelliteViewUrl(coordinates);
                  return;
                }}
                mapStyle={this.state.mapStyle}
              />
            </MapGL>
          )}
        </MapViewport>
      </React.Fragment>
    );
  }
}
