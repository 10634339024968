import {
  Stack,
  Heading,
  Button as CommonUIButton,
  Modal,
  ModalHeader,
  ModalMain,
  ModalFooter,
} from '@sixfold/common-ui';
import { Form as FormFactory } from '@sixfold/form-component';
import { BackofficeScope } from '@sixfold/session-interface';
import { isNil, notNil } from '@sixfold/typed-primitives';
import classNames from 'classnames';
import { History } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Icon, Message, Popup } from 'semantic-ui-react';

import { Captcha } from './captcha';
import { ManagedVehicleListContainer } from './managed_vehicle_list_container';
import { Button } from '../../components/button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { getEmbedConfig } from '../../lib/data';
import {
  DeleteTelematicsIntegrationSyncStateMutation,
  DeleteTelematicsIntegrationSyncStateMutationVariables,
  TelematicsIntegrationDiagnosticStatus,
  TelematicsIntegrationQuery,
  UpdateTelematicsIntegrationMutation,
  UpdateTelematicsIntegrationMutationVariables,
} from '../../lib/graphql';
import { IntegrationInputField } from '../containers/provider_input_container';
import { ValidateIntegrationButton } from '../containers/telematics_validation_container';
import { deleteTelematicsIntegrationSyncStateMutation } from '../graphql';
import { getConnectionData, TelematicsProviderIdentifier } from '../lib';

interface DeleteSyncStateProps {
  onClick: () => Promise<void>;
}

class IntegrationContainerDeleteSyncStateMutation extends Mutation<
  DeleteTelematicsIntegrationSyncStateMutation,
  DeleteTelematicsIntegrationSyncStateMutationVariables
> {}

export interface IntegrationViewDataProps {
  data?: {
    telematicsIntegration: TelematicsIntegrationQuery['telematicsIntegration'];
    company_id: string;
  };
  sensitiveConnectionData?: Record<string, string | number | boolean | object> | null;
  sensitiveSyncStateData?: string | null;
}
export interface IntegrationViewProps {
  updateIntegration: MutationFn<UpdateTelematicsIntegrationMutation, UpdateTelematicsIntegrationMutationVariables>;
  routeProps: {
    push: History['push'];
    goBack: History['goBack'];
  };
  addCaptchaToVariables: (captcha_token: string) => void;
}

class Form extends FormFactory<
  {
    telematicsProvider?: string;
    company_integration_id: string;
    enabled: boolean;
    diagnostic_status?: TelematicsIntegrationDiagnosticStatus | '';
    diagnostic_comment?: string;
  } & Record<string, string | number | boolean>
> {}

const SixfoldCarrierTelematicsApiWarning = () => (
  <Message
    size="tiny"
    color="orange"
    icon="exclamation circle"
    header="This integration is meant for Carrier API users"
    content="It is enabled / disabled by toggling the 'Enable Carrier Telematics API' feature flag"
  />
);

export const DeleteSyncStateButton: React.FC<DeleteSyncStateProps> = ({ onClick }) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isOpen, setIsOpen] = React.useState(false);

  const onCancel = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const onConfirm = React.useCallback(async () => {
    await onClick();
    if (isMounted.current) {
      setIsOpen(false);
    }
  }, [onClick]);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        size="large"
        trigger={
          <CommonUIButton type="button" onClick={() => setIsOpen(true)} kind="danger" style={{ float: 'right' }}>
            Delete
          </CommonUIButton>
        }
        open={isOpen}
        onToggle={handleToggle}>
        <ModalHeader title="Are you sure?" />
        <ModalMain>
          <p>
            Please be aware that after clicking the confirm button, the current sync state data will be deleted{' '}
            <strong style={{ fontWeight: 'bold' }}>immediately</strong>.
          </p>
        </ModalMain>
        <ModalFooter>
          <Stack justifyContent="end">
            <CommonUIButton type="button" onClick={onCancel}>
              Cancel
            </CommonUIButton>
            <CommonUIButton iconEnd="small-check" kind="primary" onClick={onConfirm}>
              Confirm
            </CommonUIButton>
          </Stack>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const IntegrationView: React.FunctionComponent<IntegrationViewDataProps & IntegrationViewProps> = ({
  routeProps,
  updateIntegration,
  data,
  sensitiveConnectionData,
  sensitiveSyncStateData,
  addCaptchaToVariables,
}) => {
  const isNotAdmin = !useIsAdmin();
  const hasIntegrationsEditPermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);
  const hasSensitiveDataReadingPermission = useHasScopes([BackofficeScope.telematicsReadSensitiveConnectionData]);
  const isPrivilegedUser = !isNotAdmin || hasIntegrationsEditPermission;

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hasSyncStateData, setHasSyncStateData] = useState(notNil(sensitiveSyncStateData) ? true : false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const config = getEmbedConfig();
  if (config === null) {
    throw Error('Missing config');
  }

  if (data?.telematicsIntegration?.telematicsProvider === undefined) {
    return (
      <div>
        <p>Something went wrong, no data returned from server</p>
      </div>
    );
  }

  const { connectionParameters, telematicsProviderId } = data.telematicsIntegration.telematicsProvider;
  const connectionSettings = connectionParameters.reduce(
    (memo, current) => {
      isNil(sensitiveConnectionData)
        ? (memo[current.fieldName] = notNil(data?.telematicsIntegration?.connection_data)
            ? data?.telematicsIntegration?.connection_data[current.fieldName]
            : null)
        : (memo[current.fieldName] = notNil(sensitiveConnectionData)
            ? sensitiveConnectionData[current.fieldName]
            : null);
      return memo;
    },
    {} as Record<string, unknown>,
  );

  const initialValues = {
    telematicsProvider: data.telematicsIntegration.telematics_provider_id,
    company_integration_id: data.telematicsIntegration.company_integration_id,
    enabled: data.telematicsIntegration.enabled,
    ...(data.telematicsIntegration.diagnosticStatus !== null
      ? { diagnostic_status: data.telematicsIntegration.diagnosticStatus }
      : {}),
    ...(data.telematicsIntegration.diagnosticComment !== null
      ? { diagnostic_comment: data.telematicsIntegration.diagnosticComment }
      : {}),
    ...connectionSettings,
  };

  const validateFields = connectionParameters
    .filter(({ isOptional }) => !isOptional)
    .reduce(
      (memo, current) => {
        memo[current.fieldName] = { exist: `Enter ${current.fieldName}` };
        return memo;
      },
      {} as Record<string, { exist: string }>,
    );

  const isUpdateDisabled =
    data.telematicsIntegration.isImmutable || !isPrivilegedUser || (isPrivilegedUser && !sensitiveConnectionData);

  const isManagedVehicles = data.telematicsIntegration.telematicsProvider.isVehicleManagementEnabled;

  return (
    <Stack>
      <div
        className={classNames({
          'company__integrations-left': true,
          'company__integrations-left-widthLimit': isManagedVehicles,
        })}>
        <Heading>Details</Heading>
        {telematicsProviderId === TelematicsProviderIdentifier.SixfoldCarrierTelematicsApi && (
          <SixfoldCarrierTelematicsApiWarning />
        )}
        {showCaptcha && (
          <Captcha
            onClickAway={() => setShowCaptcha(false)}
            onChange={(captcha_token) => {
              if (captcha_token !== null) {
                addCaptchaToVariables(captcha_token);
              }
            }}
          />
        )}
        <Form
          validateFields={validateFields}
          initialValues={initialValues}
          onSubmit={async (form) => {
            if (sensitiveConnectionData) {
              await updateIntegration({
                variables: {
                  input: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    telematics_integration_id: data.telematicsIntegration!.telematics_integration_id,
                    company_integration_id: form.company_integration_id,
                    enabled: form.enabled,
                    diagnosticStatus: form.diagnostic_status === '' ? null : form.diagnostic_status,
                    diagnosticComment: form.diagnostic_comment,
                    connection_data: getConnectionData({
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      connectionParameters: data.telematicsIntegration!.telematicsProvider!.connectionParameters,
                      formData: form,
                    }),
                  },
                },
              });
              if (isMounted.current) {
                routeProps.push(`/companies/${data.company_id}/integrations`);
              }
            } else {
              if (isMounted.current) {
                setShowCaptcha(true);
              }
            }
          }}>
          {({ form, onChange, errors, onSubmit, isSubmittingForm }) => {
            return (
              <form className="ui form" onSubmit={onSubmit}>
                <div className="field">
                  <label>Telematics provider</label>
                  <select
                    className="ui fluid search dropdown"
                    name="telematicsProvider"
                    value={form.telematicsProvider}
                    disabled
                    onChange={onChange}>
                    <option key={data.telematicsIntegration?.telematics_provider_id}>
                      {data.telematicsIntegration?.telematicsProvider?.telematicsProviderName}
                    </option>
                  </select>
                </div>

                {data.telematicsIntegration?.telematicsProvider?.connectionParameters.map((setting) => {
                  const displayName = setting.fieldDisplayName ?? setting.fieldName;
                  const hasError = errors[setting.fieldName] !== undefined;

                  return (
                    <div key={setting.fieldName} className="field">
                      <label>
                        {hasError
                          ? errors[setting.fieldName]
                          : `${displayName}${setting.isOptional ? ' (optional)' : ''}${
                              setting.isInternal ? ' (internal)' : ''
                            }`}
                      </label>
                      <IntegrationInputField
                        fieldType={setting.fieldType}
                        settingsValue={(form[setting.fieldName] ?? '').toString()}
                        fieldName={setting.fieldName}
                        disabled={isUpdateDisabled}
                        defaultChecked={form[setting.fieldName] === true}
                        enumFieldValues={setting.enumFieldValues ?? []}
                        onChange={onChange}
                        hasError={hasError}
                      />
                    </div>
                  );
                })}
                {form.telematicsProvider !== undefined && (
                  <React.Fragment>
                    <div className="field">
                      <label>Company integration id</label>
                      <input
                        type="text"
                        name="company_integration_id"
                        value={form.company_integration_id}
                        disabled={isUpdateDisabled}
                        onChange={onChange}
                      />
                    </div>
                    <div className="field">
                      <label>
                        <span
                          style={{
                            marginRight: '5px',
                          }}>
                          Diagnostic status
                        </span>
                        <Popup trigger={<Icon circular name="question" />} wide>
                          <b>{TelematicsIntegrationDiagnosticStatus.INTERMITTENT}</b> - the integration is operating
                          less than optimally. For example, GPS data might be sent inconsistently, resulting in GPS data
                          gaps.
                          <br />
                          <br />
                          <b>{TelematicsIntegrationDiagnosticStatus.INVESTIGATING}</b> - the integration is not
                          operational and needs to be investigated from our side. Usually means that the integration
                          should also be disabled.
                          <br />
                          <br />
                          <b>{TelematicsIntegrationDiagnosticStatus.INVALID_CREDENTIALS}</b> - the integration is marked
                          as having invalid credentials either manually or automatically by alert service.
                          <br />
                          <br />
                          <b>{TelematicsIntegrationDiagnosticStatus.PROVIDER_ERROR}</b> - the provider is not
                          operational and needs to be investigated from our side.
                        </Popup>
                      </label>
                      <select
                        name="diagnostic_status"
                        disabled={isUpdateDisabled}
                        onChange={onChange}
                        value={form.diagnostic_status}>
                        <option value="">-</option>
                        <option value={TelematicsIntegrationDiagnosticStatus.INTERMITTENT}>
                          {TelematicsIntegrationDiagnosticStatus.INTERMITTENT}
                        </option>
                        <option value={TelematicsIntegrationDiagnosticStatus.INVESTIGATING}>
                          {TelematicsIntegrationDiagnosticStatus.INVESTIGATING}
                        </option>
                        <option value={TelematicsIntegrationDiagnosticStatus.INVALID_CREDENTIALS}>
                          {TelematicsIntegrationDiagnosticStatus.INVALID_CREDENTIALS}
                        </option>
                        <option value={TelematicsIntegrationDiagnosticStatus.PROVIDER_ERROR}>
                          {TelematicsIntegrationDiagnosticStatus.PROVIDER_ERROR}
                        </option>
                      </select>
                    </div>
                    <div className="field">
                      <label>Diagnostic comment</label>
                      <input
                        type="text"
                        name="diagnostic_comment"
                        value={form.diagnostic_comment}
                        disabled={isUpdateDisabled}
                        onChange={onChange}
                      />
                    </div>
                    {hasSensitiveDataReadingPermission && hasSyncStateData && (
                      <div className="field">
                        <label>Sync state</label>
                        <div className="sync-state-box">
                          <div>
                            {sensitiveSyncStateData
                              ? JSON.stringify(JSON.parse(sensitiveSyncStateData), null, 2).replace(/\\n/g, '\n')
                              : null}
                          </div>
                          <div className="delete-sync-state-button">
                            <IntegrationContainerDeleteSyncStateMutation
                              mutation={deleteTelematicsIntegrationSyncStateMutation}>
                              {(deleteSyncState) => (
                                <DeleteSyncStateButton
                                  onClick={async () => {
                                    if (sensitiveSyncStateData) {
                                      await deleteSyncState({
                                        variables: {
                                          telematics_integration_id:
                                            data.telematicsIntegration!.telematics_integration_id,
                                        },
                                      });
                                      if (isMounted.current) {
                                        setHasSyncStateData(false);
                                      }
                                    } else {
                                      if (isMounted.current) {
                                        setShowCaptcha(true);
                                      }
                                    }
                                  }}
                                />
                              )}
                            </IntegrationContainerDeleteSyncStateMutation>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="field">
                      <label>Enabled</label>
                      <input
                        type="checkbox"
                        name="enabled"
                        defaultChecked={form.enabled}
                        disabled={isUpdateDisabled}
                        onChange={onChange}
                      />
                    </div>
                    <div className="integration__configuration-footer">
                      <div>
                        {!data?.telematicsIntegration?.isImmutable && isPrivilegedUser && !sensitiveConnectionData ? (
                          <Button type="button" primary onClick={() => setShowCaptcha(true)}>
                            Start updating
                          </Button>
                        ) : (
                          <Button type="submit" primary disabled={isUpdateDisabled || isSubmittingForm}>
                            Update
                          </Button>
                        )}
                        <Button type="button" onClick={() => routeProps.goBack()}>
                          Cancel
                        </Button>
                      </div>
                      <ValidateIntegrationButton
                        integration={
                          !isPrivilegedUser
                            ? {
                                telematics_provider_id: form.telematicsProvider,
                                telematics_integration_id: data.telematicsIntegration?.telematics_integration_id,
                              }
                            : {
                                telematics_provider_id: form.telematicsProvider,
                                connection_data: getConnectionData({
                                  connectionParameters:
                                    data.telematicsIntegration!.telematicsProvider.connectionParameters, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                  formData: form,
                                }),
                              }
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </form>
            );
          }}
        </Form>
      </div>
      <div className="company__integrations-right">
        {isManagedVehicles && (
          <ManagedVehicleListContainer
            telematics_integration_id={
              data.telematicsIntegration.telematics_integration_id
            }></ManagedVehicleListContainer>
        )}
      </div>
    </Stack>
  );
};
