import { getDistance } from '@sixfold/geo-primitives';
import { createPropertyNotNilFilter, notNil, safeParseDate } from '@sixfold/typed-primitives';
import { closestIndexTo } from 'date-fns';

import {
  ParsedVehicleHistory,
  TimelineBoundaries,
  TimelinePosition,
  TimelineTick,
  TimelineTickType,
  VehicleWithHistoryAndBreaks,
} from './entities';
import { formattedDuration } from '../../../lib/date';
import { GeofenceZoneType } from '../../../lib/graphql';
import { Event, ExternalEvent, Stop } from '../../../tour/entities';
import { externalEventLabel, getCircleGeofencesByType } from '../../../tour/utils';
import { BreakType, VehicleBreakHistory } from '../../../vehicle/entities';

const MINIMUM_VISIBLE_WIDTH_ON_SCREEN_IN_PERCENT = 0.5;
const TIME_INCREMENT_IN_MINUTES = 1;
// Number of minutes between telemetry gps points to consider it as gap.
const TELEMETRY_GAP_THRESHOLD_MINUTES = 10;
const OUTDATED_TIMESTAMP_TRESHOLD_IN_MINUTES = 5;

interface TimelineData {
  vehicles: {
    timeline: TimelinePosition[];
    timelineTicks: TimelineTick[];
    vehicleId: string;
    licensePlateNumber: string | null;
    isMultiModal: boolean;
  }[];
  tour: {
    timeline: TimelinePosition[];
    timelineTicks: TimelineTick[];
  };
}

type ParsedVehicleBreakHistory = Omit<VehicleBreakHistory, 'from' | 'to'> & { to: Date | null; from: Date };

type ParsedVehicleWithHistoryAndBreaks = {
  history: ParsedVehicleHistory[];
  breakHistory: ParsedVehicleBreakHistory[];
  licensePlateNumber: string | null;
  vehicleId: string;
};

type ParsedTimelineBoundaries = Omit<TimelineBoundaries, 'startTime' | 'endTime'> & {
  startTime: Date;
  endTime: Date;
};

type ParsedEvent = Omit<Event, 'event_time' | 'created_at'> & { event_time: Date | null; created_at: Date };
type ParsedExternalEvent = Omit<ExternalEvent, 'timestamp'> & { timestamp: Date };

export const prepareTimelineData = (
  vehiclesInput: VehicleWithHistoryAndBreaks[],
  tourEventsInput: Event[],
  tourExternalEventsInput: ExternalEvent[],
  tourStopsInput: Stop[],
  boundaries: TimelineBoundaries,
): TimelineData => {
  const parsedVehiclesInput: ParsedVehicleWithHistoryAndBreaks[] = vehiclesInput.map((vehicle) => ({
    ...vehicle,
    history: vehicle.history.map((history) => ({ ...history, timestamp: new Date(history.timestamp) })),
    breakHistory: vehicle.breakHistory.map((history) => ({
      ...history,
      from: new Date(history.from),
      to: safeParseDate(history.to),
    })),
  }));

  const parsedBoundaries = {
    startTime: new Date(boundaries.startTime),
    endTime: new Date(boundaries.endTime),
  };

  const parsedTourEvents = tourEventsInput.map((event) => ({
    ...event,
    event_time: safeParseDate(event.event_time),
    created_at: new Date(event.created_at),
  }));

  const parsedTourExternalEvents = tourExternalEventsInput.map((event) => ({
    ...event,
    timestamp: new Date(event.timestamp),
  }));

  const slicedTime = sliceTimeInMinutes(parsedBoundaries.startTime, parsedBoundaries.endTime);
  const baseTimeline = createInitialTimeline(parsedBoundaries, slicedTime, parsedTourEvents, parsedTourExternalEvents);

  const vehicles = parsedVehiclesInput.map((vehicle) => {
    const timeline = mergeVehicleHistoryWithTimeline(baseTimeline, vehicle.history);
    const breakTicks = getBreakTicksOnTimeline(timeline, vehicle.breakHistory);
    const stopTicks = getStopsTicksOnTimeline(timeline, tourStopsInput);
    const telemetryChunks = getTelemetryChunksToTimeline(timeline, vehicle.history);

    return {
      timeline,
      timelineTicks: [...breakTicks, ...stopTicks, ...telemetryChunks],
      vehicleId: vehicle.vehicleId,
      licensePlateNumber: vehicle.licensePlateNumber,
      isMultiModal: vehicle.history.some(
        (position) => position.modeChangesLeft !== null || position.vehicleType !== null,
      ),
    };
  });

  const eventTicks = getEventTicksOnTimeline(baseTimeline);
  const externalEventTicks = getExternalEventTicksOnTimeline(baseTimeline);

  const tourEventsRanges = getTourEventsRangesOnTimeline(baseTimeline, eventTicks, tourStopsInput);

  return {
    vehicles,
    tour: {
      timeline: baseTimeline,
      timelineTicks: [...eventTicks, ...tourEventsRanges, ...externalEventTicks],
    },
  };
};

const createInitialTimeline = (
  boundaries: ParsedTimelineBoundaries,
  slicedTime: number[],
  events: ParsedEvent[],
  externalEvents: ParsedExternalEvent[],
): TimelinePosition[] => {
  const groupedEvents = mergeEventsByTimestamp(events);
  const groupedExternalEvents = mergeExternalEventsByTimestamp(externalEvents);

  const uniqueTimes = new Set([
    ...Array.from(groupedEvents.keys()),
    ...Array.from(groupedExternalEvents.keys()),
    ...slicedTime,
  ]);

  const startBoundary = boundaries.startTime.getTime();
  const endBoundary = boundaries.endTime.getTime();
  return Array.from(uniqueTimes.keys())
    .filter((time) => time >= startBoundary && time <= endBoundary)
    .sort((a, b) => a - b)
    .map((timestamp) => ({
      timestamp: new Date(timestamp),
      event: groupedEvents.get(timestamp),
      externalEvent: groupedExternalEvents.get(timestamp),
    }));
};

function joinBy<T>(items: T[], keyFn: (item: T) => number | null, valueFn: (item: T) => string) {
  return items.reduce((memo, item) => {
    const key = keyFn(item);
    const value = valueFn(item);

    if (key == null) {
      return memo;
    }

    if (memo.has(key)) {
      memo.set(key, `${memo.get(key)}, ${value}`);
      return memo;
    }

    memo.set(key, value);
    return memo;
  }, new Map<number, string>());
}

const mergeEventsByTimestamp = (events: ParsedEvent[]) => {
  return joinBy(
    events,
    (ev) => (ev.event_time ?? ev.created_at).getTime(),
    (ev) => ('stop_id' in ev ? `${ev.event_name} #${ev.stop_id}` : ev.event_name),
  );
};

const mergeExternalEventsByTimestamp = (externalEvents: ParsedExternalEvent[]) => {
  return joinBy<ParsedExternalEvent>(
    externalEvents,
    (ev) => ev.timestamp.getTime(),
    (ev) => externalEventLabel({ ...ev, timestamp: ev.timestamp.toISOString() }),
  );
};

const mergeVehicleHistoryWithTimeline = (timeline: TimelinePosition[], vehicleHistory: ParsedVehicleHistory[]) => {
  const mergedVehicleHistory = timeline.reduce<{
    timeline: TimelinePosition[];
    currentVehicleHistoryIndex: number;
  }>(
    (memo, curr) => {
      for (
        let vehicleHistoryIndex = memo.currentVehicleHistoryIndex;
        vehicleHistoryIndex < vehicleHistory.length;
        vehicleHistoryIndex += 1
      ) {
        if (isAfter(vehicleHistory[vehicleHistoryIndex].timestamp, curr.timestamp)) {
          const lastValidIndex = vehicleHistoryIndex - 1;
          memo.timeline.push({
            ...curr,
            vehiclePosition: vehicleHistory[lastValidIndex],
          });

          return {
            timeline: memo.timeline,
            currentVehicleHistoryIndex: vehicleHistoryIndex,
          };
        }
      }

      memo.timeline.push({
        ...curr,
        vehiclePosition: vehicleHistory[memo.currentVehicleHistoryIndex],
      });

      return {
        timeline: memo.timeline,
        currentVehicleHistoryIndex: memo.currentVehicleHistoryIndex,
      };
    },
    { timeline: [], currentVehicleHistoryIndex: 0 },
  );

  if (mergedVehicleHistory.timeline.length !== timeline.length) {
    throw Error('Base and vehicle timeline should be of same length');
  }

  return mergedVehicleHistory.timeline;
};

const getBreakTicksOnTimeline = (timeline: TimelinePosition[], breaks: ParsedVehicleBreakHistory[]): TimelineTick[] =>
  mergeDurationsWithTimeline(
    timeline,
    breaks.map((breakUnit) => ({
      ...breakUnit,
      type: parseBreakType(breakUnit.type),
    })),
    (item) =>
      `Break #${item.breakId} – ${
        item.to !== null ? formattedDuration(item.from, item.to) : formattedDuration(item.from)
      }`,
  );

const getEventTicksOnTimeline = (timeline: TimelinePosition[]): TimelineTick[] => {
  const timelineLength = timeline.length;

  return timeline
    .map(({ event }, idx) => {
      if (event === undefined) {
        return;
      }
      return {
        position: getPercentage(idx, timelineLength),
        label: event,
        type: TimelineTickType.EVENT,
        timelineIndex: idx,
      };
    })
    .filter(notNil);
};

const getExternalEventTicksOnTimeline = (timeline: TimelinePosition[]): TimelineTick[] => {
  const timelineLength = timeline.length;

  return timeline
    .map(({ externalEvent }, idx) => {
      if (externalEvent === undefined) {
        return;
      }
      return {
        position: getPercentage(idx, timelineLength),
        label: externalEvent,
        type: TimelineTickType.EXTERNAL_EVENT,
        timelineIndex: idx,
      };
    })
    .filter(notNil);
};

const getTourEventsRangesOnTimeline = (
  timeline: TimelinePosition[],
  eventTicks: TimelineTick[],
  tourStops: Stop[],
): TimelineTick[] => {
  const tourEventsRange =
    eventTicks.length > 1
      ? {
          type: TimelineTickType.TOUR_EVENTS_RANGE,
          position: eventTicks[0].position,
          timelineIndex: getIndexCenter(eventTicks[0].position, eventTicks[eventTicks.length - 1].position),
          width: eventTicks[eventTicks.length - 1].position - eventTicks[0].position,
        }
      : null;

  const timeslots = tourStops
    .map((stop) => ({
      from: safeParseDate(stop.timeslot?.begin),
      to: safeParseDate(stop.timeslot?.end),
      stop_id: stop.stop_id,
      type:
        stop.type === 'loading'
          ? TimelineTickType.TIMESLOT_LOADING_RANGE
          : stop.type === 'unloading'
            ? TimelineTickType.TIMESLOT_UNLOADING_RANGE
            : TimelineTickType.TIMESLOT_OTHER_RANGE,
    }))
    .filter(createPropertyNotNilFilter('from', 'to'));

  const timeslotRangeTicks = mergeDurationsWithTimeline(
    timeline,
    timeslots,
    (item) => `Timeslot for stop #${item.stop_id}`,
  )
    .map((range) => {
      return {
        ...range,
      };
    })
    .filter(createPropertyNotNilFilter('width', 'type', 'position', 'label', 'timelineIndex'));

  return [tourEventsRange, ...timeslotRangeTicks].filter(notNil);
};

const getStopsTicksOnTimeline = (timeline: TimelinePosition[], stops: Stop[]): TimelineTick[] => {
  // The stop ticks are place based on their respective position on the timeline
  // Instead of filtering out (altering the length of the array), we fill the empty positions with unmatchable pos.
  const timelineWithFilledGaps = timeline.map(({ vehiclePosition }) => vehiclePosition ?? { lat: -1, lng: -1 });

  const closestHistoryPoints = getClosestPointsIndexes(timelineWithFilledGaps);
  const historyPointsCount = timeline.length;

  return stops
    .map((stop) => {
      const geofences = getCircleGeofencesByType(stop.geofenceZones, GeofenceZoneType.ARRIVAL);
      const geofenceRadius = Math.min(...geofences.map(({ radiusInMeters }) => radiusInMeters));

      const pointsIndexes =
        stop.lat !== null && stop.lng !== null
          ? closestHistoryPoints({ lat: stop.lat, lng: stop.lng }, geofenceRadius)
          : [];

      const stopClusters = pointsIndexes.reduce(
        (memo, el) => {
          const previousArray = memo[memo.length - 1];

          if (el > previousArray[previousArray.length - 1] + 10) {
            memo.push([el]);
          } else {
            previousArray.push(el);
          }

          return memo;
        },
        [[]] as [number[]],
      );

      return stopClusters
        .filter((cluster) => {
          return cluster.length >= 1;
        })
        .map((cluster) => {
          const position = getPercentage(cluster[0], historyPointsCount);

          return {
            position,
            width: getPercentage(cluster[cluster.length - 1], historyPointsCount) - position,
            label: `Vehicle near stop #${stop.stop_id}`,
            type: TimelineTickType.STOP_ZONE_RANGE,
            timelineIndex: getIndexCenter(cluster[0], cluster[cluster.length - 1]),
          };
        });
    })
    .flat();
};

const getTelemetryChunksToTimeline = (
  timeline: TimelinePosition[],
  history: ParsedVehicleHistory[],
): TimelineTick[] => {
  const clusteredHistory = clusterTelemetryHistory(history, TELEMETRY_GAP_THRESHOLD_MINUTES).map(({ from, to }) => ({
    from,
    to,
    type: TimelineTickType.TELEMETRY_CHUNK_RANGE,
  }));

  return mergeDurationsWithTimeline(
    timeline,
    clusteredHistory,
    (item) => `Location received from ${item.from.toISOString()} to ${item.to.toISOString()}`,
  );
};

const clusterTelemetryHistory = (history: ParsedVehicleHistory[], gapThresholdInMinutes: number) => {
  const sortedHistory = history.sort((i1, i2) => compareAsc(i1.timestamp, i2.timestamp));

  return sortedHistory.reduce(
    (memo, item) => {
      if (memo.length === 0) {
        return [{ from: item.timestamp, to: item.timestamp }];
      }

      const current = memo[memo.length - 1];

      if (differenceInMinutes(item.timestamp, current.to) < gapThresholdInMinutes) {
        current.to = item.timestamp;
      } else {
        return [...memo, { from: item.timestamp, to: item.timestamp }];
      }

      return memo;
    },
    [] as { from: Date; to: Date }[],
  );
};

const mergeDurationsWithTimeline = <T extends { from: Date; to: Date | null; type: TimelineTickType }>(
  timeline: TimelinePosition[],
  durations: T[],
  label: (item: T) => string,
): TimelineTick[] => {
  const timelineLength = timeline.length - 1;
  const timelineStart = timeline[0] !== undefined ? timeline[0].timestamp : null;

  if (timelineStart === null) {
    return [];
  }

  return durations.map((item) => {
    const startIndex = Math.max(differenceInMinutes(item.from, timelineStart) * TIME_INCREMENT_IN_MINUTES, 0);
    const endIndex =
      item.to !== null
        ? Math.min(differenceInMinutes(item.to, timelineStart) * TIME_INCREMENT_IN_MINUTES, timelineLength)
        : timelineLength;

    const position = getPercentage(startIndex, timelineLength);
    const width = getPercentage(endIndex, timelineLength) - position;

    return {
      position,
      width: Math.max(width, MINIMUM_VISIBLE_WIDTH_ON_SCREEN_IN_PERCENT),
      label: label(item),
      type: item.type,
      timelineIndex: getIndexCenter(startIndex, endIndex),
    };
  });
};

const getClosestPointsIndexes =
  (target: { lat: number; lng: number }[]) =>
  (
    position: {
      lat: number;
      lng: number;
    },
    minDistance: number = 2000,
  ) => {
    return target.reduce((memo, el, targetIdx) => {
      const getDistanceWithPosition = getDistance(position, el);

      if (getDistanceWithPosition < minDistance) {
        memo.push(targetIdx);
      }

      return memo;
    }, [] as number[]);
  };

const getPercentage = (value: number, total: number) => {
  if (total === 0) {
    return 0;
  }

  return (value / total) * 100;
};

export const getIndexForTimestamp = (timestamps: { timestamp: Date }[], timestampStr: string | undefined) => {
  const timestamp = safeParseDate(timestampStr);

  if (timestamp === null) {
    return -1;
  }

  const exactMatch = timestamps.findIndex((h) => {
    return h.timestamp.getTime() === timestamp.getTime();
  });

  return exactMatch !== -1
    ? exactMatch
    : closestIndexTo(
        timestamp,
        timestamps.map(({ timestamp }) => timestamp),
      );
};

export const sliceTimeInMinutes = (startDate: Date, endDate: Date, numberOfMinutes = TIME_INCREMENT_IN_MINUTES) => {
  let movingTimestamp = startDate.getTime();
  const endDateInt = endDate.getTime();
  const timestampArray: number[] = [];

  if (startDate >= endDate) {
    throw Error('startDate should be before endDate');
  }

  while (movingTimestamp < endDateInt) {
    timestampArray.push(movingTimestamp);
    movingTimestamp = movingTimestamp + numberOfMinutes * 60 * 1000;
  }

  if (endDateInt === movingTimestamp) {
    return timestampArray;
  }

  return timestampArray.concat(endDateInt);
};

const getIndexCenter = (fromIndex: number, toIndex?: number) =>
  toIndex === undefined ? Math.floor(fromIndex) : Math.floor(fromIndex + (toIndex - fromIndex) / 2);

const parseBreakType = (breakType: BreakType) => {
  switch (breakType) {
    case BreakType.break:
      return TimelineTickType.BREAK_RANGE;
    case BreakType.rest:
      return TimelineTickType.REST_BREAK_RANGE;
    case BreakType.weekendBreak:
      return TimelineTickType.WEEKEND_BREAK_RANGE;
    default:
      return TimelineTickType.BREAK_RANGE;
  }
};

export const isOutdatedTimestamp = (timestamp: Date, timestampToCompare: Date) =>
  differenceInMinutes(timestamp, timestampToCompare) > OUTDATED_TIMESTAMP_TRESHOLD_IN_MINUTES;

export const getPositionHash = (position?: { lat: number; lng: number; timestamp: Date }) =>
  position !== undefined ? `${position.lat}:${position.lng}:${position.timestamp.toISOString()}` : 'void';

function isAfter(dateA: Date, dateB: Date) {
  return dateA > dateB;
}

function compareAsc(dateA: Date, dateB: Date) {
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
}

function differenceInMinutes(dateLeft: Date, dateRight: Date) {
  const diff = dateLeft.getTime() - dateRight.getTime();
  return Math.floor(diff / (1000 * 60));
}
