import { Button, Stack, useNotifications } from '@sixfold/common-ui';
import { parseQueryString, OptionalString } from '@sixfold/query-string';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Link } from 'react-router-dom';

import { ManageVehicleModal } from './manage_vehicle_modal';
import { ConfirmButton } from '../../components/confirm_button';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import {
  DeleteVehicleTrackerInput,
  MutationDeleteVehicleTrackerArgs,
  VehicleTrackerType,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { Routes } from '../../routes';
import { managedVehiclesQuery } from '../../vehicle/graphql';

export type ManagedVehicle = {
  tracker_id: string;
  vehicle_id: string;
  remote_id: string;
  license_plate: string;
};

class DeleteVehicleTrackerMutation extends Mutation<DeleteVehicleTrackerInput, MutationDeleteVehicleTrackerArgs> {}
const deleteVehicleTrackerMutation = gql`
  mutation DeleteVehicleTracker($input: DeleteVehicleTrackerInput!) {
    deleteVehicleTracker(input: $input)
  }
`;

type VehicleNode = {
  vehicle_id: string;
  license_plate_number: string | null;
  created_at: string | null;
  telematicsIntegrations: { telematics_integration_id: string }[];
  vehicleTrackers: (
    | ({
        vehicleTrackerId: string;
        trackerType: VehicleTrackerType;
        fmsRemoteId: string;
        telematicsIntegration: { telematicsIntegrationId: string } | null;
      } & { __typename: 'FMSVehicleTracker' })
    | ({ vehicleTrackerId: string; trackerType: VehicleTrackerType } & {
        __typename: 'MobileVehicleTracker';
      })
  )[];
};

export interface ManagedVehicleListProps {
  vehicles: VehicleNode[];
  telematicsIntegrationId: string;
  hasEditPermission: boolean;
}

class VehicleTable extends Table<ManagedVehicle> {}

export const ManagedVehicleList: React.FC<ManagedVehicleListProps> = ({
  vehicles,
  telematicsIntegrationId,
  hasEditPermission,
}) => {
  const filteredVehicles: ManagedVehicle[] = (
    vehicles.filter(notNil).map((v) => {
      const trackers = v.vehicleTrackers.filter(
        (tr) =>
          tr.__typename === 'FMSVehicleTracker' &&
          tr.telematicsIntegration?.telematicsIntegrationId === telematicsIntegrationId,
      );

      if (trackers.length !== 1) {
        return null;
      }
      const tracker: { fmsRemoteId: string; vehicleTrackerId: string } = trackers[0] as {
        fmsRemoteId: string;
        vehicleTrackerId: string;
      };

      return {
        remote_id: tracker.fmsRemoteId,
        license_plate: v.license_plate_number ?? '-',
        vehicle_id: v.vehicle_id ?? '-',
        tracker_id: tracker.vehicleTrackerId,
      };
    }) ?? []
  ).filter(notNil);
  const notify = useNotifications();

  const queryString = parseQueryString({
    queryParametersToParse: {
      license_plate: OptionalString,
    },
    queryString: location.search,
  });

  return (
    <VehicleTable
      className="ui very basic sortable table"
      data={filteredVehicles}
      tableHeaders={{
        defaultClassName: getTableHeaderClassNames,
        columns: [
          { keyPath: 'vehicle_id', value: 'Vehicle ID' },
          { keyPath: 'remote_id', value: 'Remote ID' },
          { keyPath: 'license_plate', value: 'License Plate' },
          { value: 'Actions' },
        ],
      }}
      defaultSortBy={{ keyPath: 'remote_id', value: 'ASC' }}
      emptyStatePlaceholder={emptyTableState('No vehicles')}>
      {({ row }) => {
        const { vehicle_id, remote_id, license_plate, tracker_id } = row.data;
        const onConfirmDelete = async (
          mutationFn: MutationFn<DeleteVehicleTrackerInput, MutationDeleteVehicleTrackerArgs>,
        ) => {
          await mutationFn({
            variables: {
              input: {
                trackerId: tracker_id,
              },
            },
            refetchQueries: [
              {
                query: managedVehiclesQuery,
                variables: {
                  integrationIds: [telematicsIntegrationId],
                  limit: PAGINATION_PAGE_SIZE,
                  licensePlateSearch: queryString.license_plate || undefined,
                },
              },
            ],
          });
          notify.success({
            title:
              'Vehicle disconnected from the FMS. If this was the only tracker for the vehicle, then the vehicle was also deleted.',
          });
        };
        return (
          <tr key={vehicle_id}>
            <td className="right aligned">
              <Link to={Routes.Vehicle.generatePath({ vehicle_id })}>{vehicle_id}</Link>
            </td>
            <td>{remote_id}</td>
            <td>{license_plate}</td>
            <td>
              <Stack justifyContent="end">
                <ManageVehicleModal
                  telematicsIntegrationId={telematicsIntegrationId}
                  trigger={<Button disabled={!hasEditPermission}>Edit</Button>}
                  existingVehicle={{ license_plate, remote_id, tracker_id }}
                />
                <DeleteVehicleTrackerMutation mutation={deleteVehicleTrackerMutation}>
                  {(deleteVehicleTracker) => (
                    <ConfirmButton
                      size="tiny"
                      label="Disconnect"
                      initialButtonColor={'red'}
                      disabled={!hasEditPermission}
                      onConfirm={async () => await onConfirmDelete(deleteVehicleTracker)}
                    />
                  )}
                </DeleteVehicleTrackerMutation>
              </Stack>
            </td>
          </tr>
        );
      }}
    </VehicleTable>
  );
};
