import { notNil } from '@sixfold/typed-primitives';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import React from 'react';

interface ViewportProps {
  style?: React.CSSProperties;
  children?: Children<ViewportChildProps>;
  defaultViewport: Viewport | ViewportResolver;
  className?: string;
  tourSidebarActive?: boolean;
}

export type ViewportResolver = (width: number, height: number) => Viewport;

export interface Viewport {
  width: number;
  height: number;
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
}

interface ViewportState {
  width: number;
  height: number;
  viewport: Viewport;
  tourSideBarActiveState: boolean;
}

interface ViewportChildProps {
  width: number;
  height: number;
  viewport: Viewport;
  onViewportChange: (viewport: Viewport) => void;
}

export class MapViewport extends React.Component<ViewportProps, ViewportState> {
  container: HTMLDivElement | null;

  constructor(props: ViewportProps) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      tourSideBarActiveState: this.props.tourSidebarActive ?? true,
      viewport: this.getDefaultViewport(),
    };
    this.container = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));

    this.setState({ viewport: this.getDefaultViewport() });
    this.handleResize();
  }

  componentDidUpdate() {
    if (notNil(this.props.tourSidebarActive) && this.props.tourSidebarActive !== this.state.tourSideBarActiveState) {
      this.setState({ tourSideBarActiveState: this.props.tourSidebarActive });
      this.handleResize();
    }
  }

  getDefaultViewport() {
    const { defaultViewport } = this.props;

    if (defaultViewport instanceof Function) {
      return defaultViewport(
        notNil(this.container) ? this.container.clientWidth : 0,
        notNil(this.container) ? this.container.clientHeight : 0,
      );
    }

    return defaultViewport;
  }

  handleResize() {
    this.setState({
      width: notNil(this.container) ? this.container.clientWidth : 0,
      height: notNil(this.container) ? this.container.clientHeight : 0,
    });
  }

  handleViewportChange(viewport: Viewport) {
    this.setState({ viewport });
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style} ref={(container) => (this.container = container)}>
        {renderChildren(this.props.children, { onViewportChange: this.handleViewportChange.bind(this), ...this.state })}
      </div>
    );
  }
}
