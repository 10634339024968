export function underscore(s: string) {
  return s
    .replace(/::/g, '/')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/-/g, '_')
    .toLowerCase();
}

export function pascalToCamelCase(s: string): string {
  const firstChar = s[0];
  const restOfStr = s.slice(1);
  return `${firstChar.toUpperCase()}${restOfStr}`;
}

export function interpolate(template: string | undefined, values: Record<string, unknown>) {
  if (template === undefined) {
    console.warn('missing string to interpolate');
    return;
  }

  let s = template;
  Object.keys(values).forEach((value) => {
    const variableToInterpolate = `$\{${value}}`;
    const variableValue = `${values[value]}`;

    if (s.indexOf(variableToInterpolate) !== -1) {
      const buffer = s.replace(variableToInterpolate, variableValue);
      s = buffer;
    }
  });
  return s;
}

export const capitalizeFirstLetter = (x: string): string => x.charAt(0).toUpperCase() + x.slice(1);

export const beautifyString = (s: string) => {
  return capitalizeFirstLetter(s.replace(/_/g, ' ').toLowerCase());
};

export const generateRandomHexString = (length: number) => {
  if (!Number.isFinite(length)) {
    throw new TypeError('Expected string length to be finite');
  }

  const array = new Uint8Array(Math.ceil(length / 2));
  window.crypto.getRandomValues(array);

  return Buffer.from(array).toString('hex').slice(0, length);
};

export const indexValueToPercentage = (index: number | null): string =>
  index !== null ? (index * 100).toFixed(2) : '-';
