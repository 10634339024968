import React from 'react';
import { Table } from 'semantic-ui-react';

import { indexValueToPercentage } from '../../lib/util/string';
import { VisibilityIssue } from '../entities';

export interface Props {
  visibilityIssues: VisibilityIssue[] | null;
  mainVisibilityIssue: VisibilityIssue | null;
}

export const TourVisibilityIssueList: React.FunctionComponent<Props> = ({ visibilityIssues, mainVisibilityIssue }) => {
  const issues = (visibilityIssues ?? []).filter((issue) => issue.index > 0);
  return (
    <>
      <h3>Visibility issues</h3>

      {mainVisibilityIssue !== null && (
        <p>
          Main visibility issue: <strong>{mainVisibilityIssue.type}</strong>
        </p>
      )}
      <Table compact="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Issue type</Table.HeaderCell>
            <Table.HeaderCell>Issue index</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {issues.length === 0 ? (
            <Table.Row textAlign="center">
              <Table.Cell colSpan="2">No visibility issues</Table.Cell>
            </Table.Row>
          ) : (
            issues.map((issue, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{issue.type}</Table.Cell>
                <Table.Cell>{indexValueToPercentage(issue.index)}</Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
};
