import {
  Button,
  Heading,
  Input,
  Modal,
  ModalHeader,
  ModalMain,
  Paragraph,
  Stack,
  useNotifications,
} from '@sixfold/common-ui';
import { notNil } from '@sixfold/typed-primitives';
import copy from 'copy-to-clipboard';
import React from 'react';
import { Query } from 'react-apollo';

import { GenerateIntercomTokenUrlQuery, GenerateIntercomTokenUrlQueryVariables } from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import { getGenerateIntercomTokenQuery } from '../graphql';

class IntercomTokenUrlQuery extends Query<GenerateIntercomTokenUrlQuery, GenerateIntercomTokenUrlQueryVariables> {}

export const MiscellaneousContainer = () => {
  const { success } = useNotifications();
  const [websiteUrl, setWebsiteUrl] = React.useState<string | null>(null);

  const debouncedWebsiteUrl = useDebounce(websiteUrl, 500);

  const isUrlEntered = debouncedWebsiteUrl !== null && debouncedWebsiteUrl.length > 0;

  const onModalToggle = React.useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setWebsiteUrl(null);
      }
    },
    [setWebsiteUrl],
  );

  return (
    <Modal size="large" trigger="Intercom" onToggle={onModalToggle}>
      <ModalHeader title="Intercom" />
      <ModalMain>
        <IntercomTokenUrlQuery
          query={getGenerateIntercomTokenQuery}
          skip={!isUrlEntered}
          variables={{
            baseUrl: debouncedWebsiteUrl ?? '',
          }}>
          {({ data }) => (
            <Stack gap="small">
              <Heading size="large" type="title">
                Generate URL to allow editing of Intercom tours.
              </Heading>
              <Paragraph>
                Here you can generate a URL that will allow loading of the intercom tools package. This package is
                needed to create product tours on the intercom.com website. The generated token is valid for 24 hours.
              </Paragraph>

              <Input
                label="Base URL"
                labelPosition="hidden"
                name="input"
                placeholder="Enter the URL to add token to"
                onChange={({ value }) => {
                  setWebsiteUrl(value);
                }}
              />
              {notNil(data?.intercomTokenUrl) && data?.intercomTokenUrl !== '' && (
                <>
                  <Paragraph className="intercom_token">{data?.intercomTokenUrl}</Paragraph>

                  <Button
                    kind="primary"
                    onClick={() => {
                      copy(data?.intercomTokenUrl ?? '');
                      success({ title: 'Success', message: 'URL copied to clipboard' });
                    }}>
                    Copy URL with token
                  </Button>
                </>
              )}
            </Stack>
          )}
        </IntercomTokenUrlQuery>
      </ModalMain>
    </Modal>
  );
};
