import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { VehicleContainer } from './vehicle_container';
import { VehicleListContainer } from './vehicle_list_container';
import { Routes } from '../../routes';

export const VehiclesContainer = () => (
  <Switch>
    <Route path={Routes.Vehicle.routerPath} component={VehicleContainer} />
    <Route path={Routes.Vehicles.routerPath} component={VehicleListContainer} />
  </Switch>
);
