import React from 'react';

export type TimeZone = 'UTC' | 'local';

type TimeZoneState = {
  timeZone: TimeZone;
  setTimeZone: (value: TimeZone) => void;
};

const initialTimeZone: TimeZone = localStorage.getItem('timeZone') === 'UTC' ? 'UTC' : 'local';

export const TimeZoneContext = React.createContext<TimeZoneState>({
  timeZone: initialTimeZone,
  setTimeZone: () => undefined,
});

export const useTimeZone = () => React.useContext(TimeZoneContext);

export const TimeZoneProvider = ({ children }: JSX.ElementChildrenAttribute) => {
  const [timeZone, setTimeZone] = React.useState<TimeZone>(initialTimeZone);
  const state = React.useMemo(() => ({ timeZone, setTimeZone }), [timeZone, setTimeZone]);

  return <TimeZoneContext.Provider value={state}>{children}</TimeZoneContext.Provider>;
};
