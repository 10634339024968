import React from 'react';

import { useKafkaContext } from './kafka_context';
import { BigQueryMessage } from './kafka_query_input';
import { FormattedDate } from '../../date_formatting/formatted_date';
import { DiffViewerModal } from '../../diff_modal/diff_modal';

interface Props {
  compare: number[];
}

export const KafkaDiffViewer: React.FunctionComponent<Props> = ({ compare }) => {
  const { payloads, sortOrder } = useKafkaContext();
  const oldPayload: BigQueryMessage | undefined = payloads[compare[sortOrder === 'asc' ? 0 : 1]];
  const newPayload: BigQueryMessage | undefined = payloads[compare[sortOrder === 'asc' ? 1 : 0]];

  if (!payloads.length) {
    return null;
  }

  return (
    <DiffViewerModal
      dataKey="kafka"
      isCompareButtonDisabled={compare.length !== 2}
      showDiffViewer={compare.length === 2}
      oldValue={JSON.stringify(JSON.parse(oldPayload?.message ?? '{}'), null, 2)}
      newValue={JSON.stringify(JSON.parse(newPayload?.message ?? '{}'), null, 2)}
      leftTitle={(useUnifiedView) =>
        useUnifiedView ? (
          <span>
            <FormattedDate date={oldPayload?.produced_at} showSecondsAndMillis={true} />
            {' - '}
            <FormattedDate date={newPayload?.produced_at} showSecondsAndMillis={true} />
          </span>
        ) : (
          <FormattedDate date={oldPayload?.produced_at} showSecondsAndMillis={true} />
        )
      }
      rightTitle={<FormattedDate date={newPayload?.produced_at} showSecondsAndMillis={true} />}
    />
  );
};
