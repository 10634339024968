import { useErrorHandler } from '@sixfold/app-utils';
import { SpinnerOverlay, useNotifications } from '@sixfold/common-ui';
import { AuthService } from '@sixfold/login-container';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { KPIContainer } from './kpi/kpi_container';
import { createApolloClient } from './lib/apollo_client';
import { useIsAuthenticated } from './lib/authorization';
import { getEmbedConfig } from './lib/data';
import { Routes } from './routes';
import { LoginContainer } from './sessions/containers/login_container';
import { WorkspaceContainer } from './workspace/containers/workspace_container';

import '@sixfold/design-tokens/build/transporeon/css/fonts.css';
import './local_variables.css';

interface Props {
  authService: AuthService;
}

const AppRoute: React.FunctionComponent = () => {
  const { error } = useNotifications();
  const handleError = useErrorHandler();

  const apolloClient = React.useMemo(() => {
    return createApolloClient({
      handleError,
      onAuthenticationError: () => {
        window.location.href = '/login';
      },
      onGraphQLError: (message) => {
        error({ title: 'Error', message });
      },
    });
  }, [handleError, error]);

  const logout = React.useCallback(async () => {
    window.location.href = '/logout';
  }, []);

  const { isAuthenticated, isLoading } = useIsAuthenticated(apolloClient);

  if (isLoading) {
    return <SpinnerOverlay className="loading" />;
  }

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: Routes.Login.generatePath({}) }} />;
  }

  return (
    <React.Fragment>
      <Route path={Routes.KPI.routerPath} render={() => <KPIContainer />} />
      <Route
        path={Routes.Home.routerPath}
        render={() => (
          <ApolloProvider client={apolloClient}>
            <WorkspaceContainer onSignout={logout} />
          </ApolloProvider>
        )}
      />
    </React.Fragment>
  );
};

const App: React.FunctionComponent<Props> = ({ authService }) => {
  return (
    <Router>
      <Switch>
        <Route
          path={Routes.Login.routerPath}
          render={(route: RouteComponentProps<object>) => {
            const config = getEmbedConfig();
            const googleClientId = config?.google_client_id;

            if (googleClientId === undefined) {
              throw new Error('Google client id is not specified');
            }

            return <LoginContainer {...route} authService={authService} google_client_id={googleClientId} />;
          }}
        />
        <AppRoute />
      </Switch>
    </Router>
  );
};

const HotApp = hot(module)(App);

export { HotApp as App };
