import { isNil } from '@sixfold/typed-primitives';

const ZERO_WIDTH_SPACE = '\u200b';

/**
 * This function breaks text onto multiple lines at input character (_ or .).
 * This is required to save cell width in order for the whole table not to
 * overflow horizontally. Shorter words don't need to be broken.
 */
export function breakWordAtChar(text: string | null, char: '_' | '.') {
  if (isNil(text)) {
    return '-';
  }
  if (text.length < 13) {
    return text;
  }
  return text.split(char).join(`${char}${ZERO_WIDTH_SPACE}`);
}
