import { notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';

import { TimelineTick, TimelineTickType, KeyboardKey } from './entities';
import { GhostVehicleColors } from '../../../vehicle/components/vehicles_layer';

interface Props {
  vehicleIndex?: number;
  sliderTicks: TimelineTick[];
  range: {
    value: number | undefined;
    min: number;
    max: number;
    step: number;
  };
  onChange: (selectedIndex: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  onArrowNavigation?: (
    { currentIndex, keyPressed }: { currentIndex: number; keyPressed: KeyboardKey },
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onTickClick: (timelineIndex: number) => void;
}

export const MapSliderTimeline: React.SFC<Props> = (props) => {
  const { sliderTicks, range, onChange, onArrowNavigation, onTickClick } = props;
  const timelineInput = React.useRef<HTMLInputElement>(null);

  return (
    <div className="map-slider">
      <ol className="map-slider__ticks-container">
        {sliderTicks.map((tick, idx) => {
          switch (tick.type) {
            case TimelineTickType.TOUR_EVENTS_RANGE:
            case TimelineTickType.TIMESLOT_LOADING_RANGE:
            case TimelineTickType.TIMESLOT_UNLOADING_RANGE:
            case TimelineTickType.BREAK_RANGE:
            case TimelineTickType.REST_BREAK_RANGE:
            case TimelineTickType.WEEKEND_BREAK_RANGE:
            case TimelineTickType.STOP_ZONE_RANGE:
            case TimelineTickType.TELEMETRY_CHUNK_RANGE:
              if (tick.width === 0) {
                return;
              }

              return (
                <li
                  key={idx}
                  onClick={() => {
                    if (
                      tick.type !== TimelineTickType.TELEMETRY_CHUNK_RANGE &&
                      timelineInput &&
                      timelineInput.current
                    ) {
                      onTickClick(tick.timelineIndex);
                      timelineInput.current.focus();
                    }
                  }}
                  className={classnames({
                    'map-slider__tick-range': true,
                    'map-slider__tick-tooltip': tick.label,
                    'map-slider__tick-range--events': tick.type === TimelineTickType.TOUR_EVENTS_RANGE,
                    'map-slider__tick-range--timeslot-loading': tick.type === TimelineTickType.TIMESLOT_LOADING_RANGE,
                    'map-slider__tick-range--timeslot-unloading':
                      tick.type === TimelineTickType.TIMESLOT_UNLOADING_RANGE,
                    'map-slider__tick-range--small-break': tick.type === TimelineTickType.BREAK_RANGE,
                    'map-slider__tick-range--rest-break': tick.type === TimelineTickType.REST_BREAK_RANGE,
                    'map-slider__tick-range--we-break': tick.type === TimelineTickType.WEEKEND_BREAK_RANGE,
                    'map-slider__tick-range--stop': tick.type === TimelineTickType.STOP_ZONE_RANGE,
                    'map-slider__tick-range--telemetry-chunk': tick.type === TimelineTickType.TELEMETRY_CHUNK_RANGE,
                  })}
                  aria-label={tick.label}
                  style={{
                    left: `${tick.position}%`,
                    width: `${tick.width}%`,
                    cursor: tick.type !== TimelineTickType.TELEMETRY_CHUNK_RANGE ? 'pointer' : 'default',
                    pointerEvents: tick.label === undefined ? 'none' : 'initial',
                  }}
                />
              );

            case TimelineTickType.EVENT:
              return (
                <li
                  key={idx}
                  onClick={() => {
                    const timelineInputCurrent = timelineInput?.current;
                    if (notNil(timelineInputCurrent)) {
                      onTickClick(tick.timelineIndex);
                      timelineInputCurrent.focus();
                    }
                  }}
                  className={classnames({
                    'map-slider__tick-event': true,
                    'map-slider__tick-tooltip': true,
                    'map-slider__tick-event--tractor-switch':
                      tick.label !== undefined && tick.label.includes('tractor'),
                  })}
                  style={{ left: `${tick.position}%` }}
                  aria-label={tick.label}>
                  ●
                </li>
              );
            case TimelineTickType.EXTERNAL_EVENT:
              return (
                <li
                  key={idx}
                  onClick={() => {
                    const timelineInputCurrent = timelineInput?.current;
                    if (notNil(timelineInputCurrent)) {
                      onTickClick(tick.timelineIndex);
                      timelineInputCurrent.focus();
                    }
                  }}
                  className={classnames({
                    'map-slider__tick-external-event': true,
                    'map-slider__tick-tooltip': true,
                    [`map-slider__tick-external-event--${tick.subtype}`]: tick.subtype,
                  })}
                  style={{ left: `${tick.position}%` }}
                  aria-label={tick.label}>
                  ▲
                </li>
              );
            default:
              return;
          }
        })}
      </ol>
      <input
        ref={timelineInput}
        type="range"
        value={range.value}
        min={range.min}
        max={range.max}
        step={range.step}
        disabled={onChange === undefined}
        onClick={() => {
          const timelineInputCurrent = timelineInput?.current;
          if (notNil(timelineInputCurrent)) {
            // Safari does not register timeline focus on click otherwise
            timelineInputCurrent.focus();
          }
        }}
        onKeyDown={(e) => {
          if (
            onArrowNavigation !== undefined &&
            (e.key === KeyboardKey.ARROW_LEFT || e.key === KeyboardKey.ARROW_RIGHT)
          ) {
            e.preventDefault();
            onArrowNavigation(
              {
                currentIndex: parseInt(e.currentTarget.value, 10),
                keyPressed: e.key,
              },
              e,
            );
          }
        }}
        onChange={(e) => onChange(parseInt(e.currentTarget.value, 10), e)}
        className={'map-slider__input map-slider-vehicle__input'}
        style={{
          color:
            notNil(props.vehicleIndex) && props.vehicleIndex < GhostVehicleColors.length
              ? `rgba(${GhostVehicleColors[props.vehicleIndex][0]},${GhostVehicleColors[props.vehicleIndex][1]},${
                  GhostVehicleColors[props.vehicleIndex][2]
                }, 1)`
              : 'transparent',
        }}
      />
    </div>
  );
};
