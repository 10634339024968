import { useNotifications } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import {
  CompanyWithFeatureFlagMetadataQuery,
  CompanyWithFeatureFlagMetadataQueryVariables,
  PAGINATION_PAGE_SIZE,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  loadMoreFromConnection,
} from '../../lib/graphql';
import { CompanyFeatureFlags } from '../components/company_feature_flags';
import { companyFeatureFlagsMetadataQuery, updateCompanyMutation } from '../graphql';

interface CompanyFeatureFlagsViewDataProps {
  data: {
    company?: CompanyWithFeatureFlagMetadataQuery['company'] | null;
    featureFlags?: CompanyWithFeatureFlagMetadataQuery['featureFlagMetadata'] | null;
  };
}

class LoadingContainer extends Loader<
  CompanyWithFeatureFlagMetadataQuery,
  CompanyFeatureFlagsViewDataProps,
  CompanyWithFeatureFlagMetadataQueryVariables
> {}

class CompanyFeatureFlagContainerQuery extends Query<
  CompanyWithFeatureFlagMetadataQuery,
  CompanyWithFeatureFlagMetadataQueryVariables
> {}

class CompanyContainerUpdateCompanyMutation extends Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {}

export const CompanyFeatureFlagsContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const notify = useNotifications();
  return (
    <CompanyFeatureFlagContainerQuery
      query={companyFeatureFlagsMetadataQuery}
      variables={{
        company_id: props.match.params.company_id,
        applies_to: 'company',
        first: PAGINATION_PAGE_SIZE,
      }}>
      {(result) => (
        <CompanyContainerUpdateCompanyMutation mutation={updateCompanyMutation}>
          {(updateCompany) => (
            <LoadingContainer
              result={result}
              mapData={({ data }) => ({
                data: {
                  company: data.company,
                  featureFlags: data.featureFlagMetadata,
                },
              })}>
              {({ data }) => {
                const LoadMoreData = () => {
                  return loadMoreFromConnection(companyFeatureFlagsMetadataQuery, result, [
                    'company',
                    'featureFlagAuditLogConnection',
                  ]);
                };
                return (
                  <CompanyFeatureFlags
                    loadMoreAuditLogs={LoadMoreData}
                    allFlags={data.featureFlags}
                    currentSetFlags={(data.company?.feature_flags as Record<string, boolean>) ?? {}}
                    company={data.company}
                    onFlagsChange={async (input) => {
                      const response = await updateCompany(input);
                      notify.success({ title: 'Company updated' });
                      return response;
                    }}
                  />
                );
              }}
            </LoadingContainer>
          )}
        </CompanyContainerUpdateCompanyMutation>
      )}
    </CompanyFeatureFlagContainerQuery>
  );
};
