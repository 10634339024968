import { Children, renderChildren } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import { companyIntegrationsListQuery } from '../../company/graphql';
import { DeleteTelematicsIntegrationMutation, DeleteTelematicsIntegrationMutationVariables } from '../../lib/graphql';
import { deleteTelematicsIntegrationMutation } from '../graphql';

class DeleteIntegrationMutationContainer extends Mutation<
  DeleteTelematicsIntegrationMutation,
  DeleteTelematicsIntegrationMutationVariables
> {}

interface Props {
  companyId: string;
  children: Children<{
    deleteTelematicsIntegration: (telematicsIntegrationId: string) => Promise<void>;
  }>;
}

export const DeleteIntegrationMutation: React.FC<Props> = ({ companyId, children }) => {
  return (
    <DeleteIntegrationMutationContainer mutation={deleteTelematicsIntegrationMutation}>
      {(deleteIntegration) =>
        renderChildren(children, {
          deleteTelematicsIntegration: async (telematicsIntegrationId: string) => {
            return await deleteIntegration({
              refetchQueries: [
                {
                  query: companyIntegrationsListQuery,
                  variables: { company_id: companyId },
                },
              ],
              variables: {
                telematics_integration_id: telematicsIntegrationId,
              },
            });
          },
        })
      }
    </DeleteIntegrationMutationContainer>
  );
};
