import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { Dropdown } from 'semantic-ui-react';

import {
  CompaniesDropdownListQuery as CompaniesQueryResult,
  CompaniesDropdownListQueryVariables,
  CompanyDropdownQuery as CompanyQueryResult,
  CompanyDropdownQueryVariables,
  getNodes,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import { Company } from '../entities';
import { companiesDropdownListQuery, companyDropdownQuery } from '../graphql';
import { compareCompany } from '../utils';

class CompanyListQuery extends Query<CompaniesQueryResult, CompaniesDropdownListQueryVariables> {}
class CompanyQuery extends Query<CompanyQueryResult, CompanyDropdownQueryVariables> {}

export type CompanySearchResult = Omit<Company, 'tags'>;

interface Props {
  inputProps: {
    disabled?: boolean;
    placeholder: string;
  };
  selectedCompany: Pick<CompanySearchResult, 'company_id'> | null;
  onSelectCompany: (selectedCompany: CompanySearchResult | null) => void;
  companyType?: NonNullable<CompaniesDropdownListQueryVariables['type']>;
}

export const CompaniesDropdownContainer: React.SFC<Props> = ({
  inputProps,
  onSelectCompany,
  selectedCompany,
  companyType,
}) => {
  const [searchString, setSearchString] = React.useState<string | null>(null);
  const debouncedSearchCompanyName = useDebounce(searchString);

  return (
    <CompanyQuery
      skip={selectedCompany === null || searchString !== null}
      query={companyDropdownQuery}
      variables={selectedCompany !== null ? { companyId: selectedCompany.company_id } : undefined}>
      {({ data, loading: companyLoading }) => {
        const initiallySelectedCompany = data !== undefined ? data.company : null;

        return (
          <CompanyListQuery
            query={companiesDropdownListQuery}
            skip={debouncedSearchCompanyName === null}
            variables={{ textSearch: debouncedSearchCompanyName, type: companyType }}>
            {({ data, loading: companyListLoading }) => {
              const companies =
                data !== undefined ? getNodes(data.companies) : [initiallySelectedCompany].filter(notNil);

              return (
                <Dropdown
                  selection
                  search
                  clearable
                  onSearchChange={(_, { searchQuery }) => {
                    if (searchQuery === '') {
                      return;
                    }

                    setSearchString(searchQuery);
                  }}
                  options={[
                    { value: '', text: inputProps.placeholder },
                    ...companies.sort(compareCompany).map((company) => ({
                      value: company.company_id,
                      text: `${company.company_name} (ID: ${company.company_id}) ${
                        company.is_test === true ? '[Test]' : ''
                      }`,
                    })),
                  ]}
                  loading={companyLoading || companyListLoading}
                  disabled={inputProps.disabled}
                  value={selectedCompany !== null ? selectedCompany.company_id : ''}
                  placeholder={inputProps.placeholder}
                  onChange={(_e, { value }) => {
                    const selectedCompany = companies.find((company) => company.company_id === value);

                    if (selectedCompany === undefined) {
                      onSelectCompany(null);
                      return;
                    }

                    onSelectCompany(selectedCompany);
                  }}
                />
              );
            }}
          </CompanyListQuery>
        );
      }}
    </CompanyQuery>
  );
};
